import { Box } from '@wh-components/core/Box/Box'
import { IconButton } from '@wh-components/core/Button/Button'
import { BaseDrawer } from '@wh-components/core/Drawer/BaseDrawer'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { Text } from '@wh-components/core/Text/Text'
import InfoIcon from '@wh-components/icons/AlertInfo'
import MenuIcon from '@wh-components/icons/Menu'
import { SpaceProps } from '@wh-components/system/space'
import { getUnreadMessages } from '@wh/common/chapter/api/userApiClient'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import {
    isUserLoggedIn,
    useProfileData,
    useUnreadMessagesCount,
} from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { AdInsertionLink } from '@wh/common/chapter/components/Header/AdInsertionLink'
import { HeaderMenu } from '@wh/common/chapter/components/Header/HeaderMenu'
import { MenuLinks } from '@wh/common/chapter/components/Header/menuLinks'
import { MyMessagesLink } from '@wh/common/chapter/components/Header/MyMessagesLink'
import { MyProfileLink } from '@wh/common/chapter/components/Header/MyProfileLink'
import { useScreenSize } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { useInterval } from '@wh/common/chapter/hooks/useInterval'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { incrementShouldShowEnterBirthYearVisitCount } from '@wh/common/chapter/lib/incrementShouldShowEnterBirthYearVisitCount'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import logo from '@wh/common/chapter/static_hashed/img/logo.svg'
import React, { Fragment, FunctionComponent, useContext, useEffect, useRef, useState } from 'react'
import { css } from 'styled-components'
import { shouldShowEnterBirthYearHint } from '../../lib/userHints'
import { findContextLinkWithId } from '../../types/contextLinks'
import { verticals } from '../../types/verticals'
import { EnterBirthYearContext } from '../EnterBirthYearProvider/EnterBirthYearProvider'
import { useFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider'

interface HeaderProps {
    headerBackgroundTransparentTabletDesktop: boolean
    isVerticalMenuTransparent: boolean
    verticalMenuBorderColor: string | undefined
    extraMenuLink?: React.ReactNode
    vertical?: verticals
}

const useMessagePolling = () => {
    const [profileData] = useProfileData()
    const [, setUnreadMessagesCount] = useUnreadMessagesCount()
    const ifVisible = useRef<{ now: (s: string) => boolean } | undefined>(undefined)

    // polling of unread messages
    useInterval(async () => {
        if (ifVisible.current?.now('active') && isUserLoggedIn(profileData)) {
            try {
                const count = await getUnreadMessages(profileData.uuid)
                setUnreadMessagesCount(count.unread)
            } catch {}
        }
    }, 30000)

    useEffect(() => {
        const setupIfVisibleAndFetchUnreadCount = async () => {
            if (!ifVisible.current && isUserLoggedIn(profileData)) {
                ifVisible.current = await import('ifvisible.js')
            }

            if (ifVisible.current && isUserLoggedIn(profileData)) {
                try {
                    const count = await getUnreadMessages(profileData.uuid)
                    setUnreadMessagesCount(count.unread)
                } catch {}
            }
        }
        setupIfVisibleAndFetchUnreadCount()
    }, [profileData, setUnreadMessagesCount])
}

export const Header: FunctionComponent<HeaderProps> = ({
    headerBackgroundTransparentTabletDesktop,
    verticalMenuBorderColor,
    extraMenuLink,
    vertical,
    isVerticalMenuTransparent,
}) => {
    const headerSeparatorsHiddenTabletDesktop = headerBackgroundTransparentTabletDesktop || typeof verticalMenuBorderColor !== 'undefined'
    const [profileData] = useProfileData()
    const showPersonalization = useFeatureToggle('enableSizePersonalization')
    const showMyTransactions = useFeatureToggle('enableMyTransactionsPage')
    const showEditTenant =
        !!findContextLinkWithId('editTenantProfileUri', (profileData === 'unsure' ? undefined : profileData)?.contextLinkList) &&
        !(profileData === 'unsure' ? undefined : profileData)?.isAdminUser
    const showMyJobAds =
        !!findContextLinkWithId('jobsMyAdvertsUri', (profileData === 'unsure' ? undefined : profileData)?.contextLinkList) &&
        !(profileData === 'unsure' ? undefined : profileData)?.isAdminUser

    const enterBirthYearContext = useContext(EnterBirthYearContext)
    const birthYearStateRef = useRef(enterBirthYearContext)
    birthYearStateRef.current = enterBirthYearContext

    useMessagePolling()

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const screenSize = useScreenSize()

    useEffect(() => {
        setIsMenuOpen(false)
    }, [screenSize])

    useEffect(() => {
        if (isUserLoggedIn(profileData) && !profileData?.isAdminUser) {
            incrementShouldShowEnterBirthYearVisitCount()
            birthYearStateRef.current.setEnterBirthYear(shouldShowEnterBirthYearHint(profileData.birthYear))
        }
    }, [profileData])

    const borderBottomColor = !isVerticalMenuTransparent ? (headerSeparatorsHiddenTabletDesktop ? 'transparent' : undefined) : undefined

    return (
        <Fragment>
            <Box
                id="main-header"
                testId="main-header"
                height={{ phone: FIXED_HEADER_HEIGHT_PHONE, tablet: 'auto' }}
                width={{ phone: '100%', tablet: 'auto' }}
                position={{ phone: 'fixed', tablet: 'static' }}
                top="0"
                left="0"
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                zIndex="header"
                backgroundColor={{
                    phone: 'palette.white',
                    tablet: headerBackgroundTransparentTabletDesktop ? 'transparent !important' : undefined,
                }}
                borderBottom={isVerticalMenuTransparent ? '1px solid rgba(255,255,255,0.5)' : 'owl'}
                borderBottomColor={borderBottomColor}
            >
                <Box height="100%" display="flex" alignItems="center">
                    <IconButton
                        Icon={MenuIcon}
                        size="large"
                        height={40}
                        width={40}
                        color="complimentary"
                        variant="transparent"
                        display={{ tablet: 'none' }}
                        marginHorizontal="s"
                        aria-label="Menü"
                        testId="main-header-menu-button"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    />
                    <ClientRoutingAnchorLink
                        type="anchor"
                        href="/"
                        testId="whLogoLink"
                        aria-label="Zur Startseite"
                        display="block"
                        marginTop="s"
                        marginRight={{ phone: 's', tablet: 'm' }}
                        marginLeft={{ phone: 's', tablet: 0 }}
                        onClick={() => callActionEvent('header_click_logo', undefined)}
                    >
                        <ResponsiveImage src={logo} alt="willhaben Logo" cssWidth={{ phone: 67, tablet: 132 }} />
                    </ClientRoutingAnchorLink>
                </Box>
                <Box height="100%" display="flex" flexDirection={{ tablet: 'column' }} flexGrow={1}>
                    <Box
                        height={51}
                        display={{ phone: 'none', tablet: 'flex' }}
                        alignItems="center"
                        justifyContent="flex-end"
                        paddingVertical="s"
                        borderBottom={isVerticalMenuTransparent ? '1px solid rgba(255,255,255,0.5)' : 'owl'}
                        borderBottomColor={borderBottomColor}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="xs"
                            backgroundColor={isVerticalMenuTransparent ? 'transparent' : 'palette.white'}
                            borderRadius="m"
                            gap="sm"
                        >
                            {isUserLoggedIn(profileData) && profileData.isAdminUser ? (
                                <AdminWarning />
                            ) : (
                                <Fragment>
                                    {extraMenuLink}
                                    <MyMessagesLink
                                        onClick={() => {
                                            setIsMenuOpen(false)
                                            return callActionEvent('header_click_messages', undefined)
                                        }}
                                        isVerticalMenuTransparent={isVerticalMenuTransparent}
                                    />
                                    <MyProfileLink
                                        onClick={() => setIsMenuOpen(false)}
                                        isVerticalMenuTransparent={isVerticalMenuTransparent}
                                    />
                                    <AdInsertionLink
                                        onClick={() => setIsMenuOpen(false)}
                                        vertical={vertical}
                                        taggingEvent="header_click_insertAd"
                                    >
                                        {vertical === verticals.JOB ? 'Jobanzeige aufgeben' : 'Neue Anzeige aufgeben'}
                                    </AdInsertionLink>
                                </Fragment>
                            )}
                        </Box>
                    </Box>
                    <Box
                        display={{ phone: 'none', tablet: 'flex' }}
                        backgroundColor={isVerticalMenuTransparent ? 'transparent' : 'palette.white'}
                        borderRadius="m"
                        css={css`
                            box-shadow: 0px 0px 0px 1px ${verticalMenuBorderColor ?? 'transparent'};
                        `}
                    >
                        <MenuLinks
                            onClick={() => setIsMenuOpen(false)}
                            isDeckView={true}
                            isHeaderBoxTransparent={isVerticalMenuTransparent}
                        />
                    </Box>
                    <Box
                        display={{ phone: 'flex', tablet: 'none' }}
                        justifyContent="flex-end"
                        alignItems="center"
                        flexGrow={1}
                        height="100%"
                    >
                        {isUserLoggedIn(profileData) && profileData.isAdminUser ? (
                            <AdminWarning marginRight="m" />
                        ) : (
                            <Fragment>
                                <AdInsertionLink
                                    onClick={() => setIsMenuOpen(false)}
                                    variant="outline"
                                    marginRight="m"
                                    vertical={vertical}
                                    taggingEvent="header_click_insertAd"
                                />
                                {!!profileData && (
                                    <MyMessagesLink
                                        onClick={() => {
                                            setIsMenuOpen(false)
                                            return callActionEvent('header_click_messages', undefined)
                                        }}
                                        marginRight="xs"
                                    />
                                )}
                                <MyProfileLink onClick={() => setIsMenuOpen(false)} />
                            </Fragment>
                        )}
                    </Box>
                </Box>
            </Box>
            <BaseDrawer
                isOpen={isMenuOpen}
                onRequestClose={() => setIsMenuOpen(false)}
                placement="left"
                animationSpeed={400}
                width={300}
                style={{
                    overlay: { top: 50, zIndex: 399 },
                }}
            >
                <HeaderMenu
                    closeMenu={() => setIsMenuOpen(false)}
                    vertical={vertical}
                    showEditTenant={showEditTenant}
                    showMyJobAds={showMyJobAds}
                    showPersonalization={showPersonalization}
                    showMyTransactions={showMyTransactions}
                />
            </BaseDrawer>
        </Fragment>
    )
}

const AdminWarning: FunctionComponent<SpaceProps> = (layoutProps) => (
    <Box {...layoutProps} fontSize="s" display="flex" alignItems="center">
        <InfoIcon marginRight="xs" color="alert.warning.main" size="xsmall" />
        <Box maxWidth="calc(100vw - 200px)">
            <Text fontWeight="bold">Achtung:</Text> Du bist als Admin User eingeloggt.
        </Box>
    </Box>
)
