import { css } from 'styled-components'

/* https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp */
export const hiddenScrollbar = css`
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`
