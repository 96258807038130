/* eslint-disable max-lines */
import { getApp, getContext } from '@wh/common/chapter/lib/commonHelpers'
import { TaggingPage } from '@wh/common/chapter/lib/tagging/taggingTypes'
import {
    ApiPulseClassifiedAdCore,
    ApiPulseClassifiedAdObject,
    ApiPulseData,
    ApiPulseListingObject,
} from '@wh/common/chapter/types/taggingData'
import { removeNullAndUndefined, removeUndefined } from '../functionalHelpers'
import { PulseFormParams } from './taggingPulseTypes'

export interface PageSpeedMetric {
    id: string
    label: string
    name: string
    startTime: number
    value: number
}

export type Params = Readonly<
    Record<string, string | number | Array<string | number | Record<string, unknown>> | Record<string, unknown> | undefined> &
        PulseFormParams
>

export const createPulseViewEventPayload = (eventName: TaggingPage, params: Params) => {
    switch (eventName) {
        case 'adview':
        case 'adview_nc': {
            const object = getClassifiedAdObjectFromPulseData(params)
            return removeUndefined({
                name: 'Ad detail viewed',
                object: object,
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                published: published(params),
                publicationDate: published(params),
                provider,
                $schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/view-ad-event.json/0.json',
                schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/view-ad-event.json/0.json',
            })
        }
        case 'jobs_addetail': {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            const publisher = params.company_id ? getPublisher(`jobs:${params.company_id}`) : undefined
            const object = removeUndefined({
                id: `job${(params.ad_id as string)?.toLowerCase().replace('j', '') || document.location.href}`,
                type: 'ClassifiedAd',
                adId: parseInt(`${params.ad_id}`.toLowerCase().replace('j', ''), 10),
                adType: adType(params),
                contentId: params.ad_id,
                currency: price(params) ? 'EUR' : undefined,
                price: price(params),
                category: params.category,
                categories: undefined,
                publisher: publisher,
                publisherType: 'pro',
                publicationDate: params.publish_date || params.publication_date,
                location: getLocationAddress(params.region_level_3, params.region_level_2, params.post_code, params.region_level_1),
                name: params.ad_title,
            })
            return removeUndefined({
                name: 'Ad detail viewed',
                object,
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                published: published(params),
                publicationDate: published(params),
                provider,
            })
        }
        case 'jobs_companyprofile_viewed': {
            const pulseData = parsePulseData(params)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const companyProfileViewedData = pulseData!.jobsPulseEvents!['companyProfileViewed' as any]

            return removeUndefined({
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                provider: provider,
                ...companyProfileViewedData,
            })
        }
        case 'jobs_resultlist': {
            const pulseData = parsePulseData(params)
            const object = removeUndefined({
                id: document.location.href,
                type: 'Listing',
                items: pulseData?.items,
                category: params.category,
                categories: undefined,
                location: getLocationAddress(params.region_level_3, params.region_level_2, params.post_code, params.region_level_1),
                filters: pulseData?.filters,
            })
            return removeUndefined({
                name: 'Listing viewed',
                object,
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                published: published(params),
                publicationDate: published(params),
                provider,
            })
        }
        case 'homepage': {
            const object = {
                id: document.location.href,
                type: 'Frontpage',
            }
            return removeUndefined({
                name: 'Page viewed',
                object,
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                published: published(params),
                publicationDate: published(params),
                provider,
            })
        }
        case 'search_result_list':
        case 'seller_profile':
        case 'dealer_profile': {
            const pulseData = parsePulseData(params)
            const listingEvent = pulseData?.pulseEvents?.[0] as ApiPulseListingObject
            const items = listingEvent?.classifiedAdObjects?.map((classifiedAdObject: ApiPulseClassifiedAdCore) =>
                createClassifiedAdWithIdOnlyForListing(classifiedAdObject.adId),
            )
            const object = removeUndefined({
                id: document.location.href,
                type: 'Listing',
                items,
                category: listingEvent.category,
                filters: removeNullAndUndefined({
                    ...listingEvent?.filters,
                }),
            })
            const search = removeNullAndUndefined(listingEvent?.search)
            return removeUndefined({
                name: 'Listing viewed',
                object,
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                published: published(params),
                publicationDate: published(params),
                search,
                provider,
                $schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/listing-viewed-event.json/1.json',
                schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/listing-viewed-event.json/1.json',
            })
        }
        case 'my_ads': {
            const pulseData = parsePulseData(params)
            const listingEvent = pulseData?.pulseEvents?.[0] as ApiPulseListingObject | undefined
            const items = listingEvent?.items?.map((classifiedAdObject: ApiPulseClassifiedAdCore) =>
                createClassifiedAdWithIdOnlyForListing(classifiedAdObject.adId),
            )
            const object = removeUndefined({
                '@id': listingEvent?.id ? `sdrn:willhabenat:listing:myads:${listingEvent?.id}` : undefined,
                '@type': 'MyAdsListing',
                name: 'Meine Anzeigen',
                items,
                filters: removeNullAndUndefined({
                    ...listingEvent?.filters,
                }),
                numItems: listingEvent?.numItems,
                pageNumber: listingEvent?.pageNumber,
            })
            return removeUndefined({
                $schema: 'https://schema.adevinta.com/events/willhabenat/odin/frontend/myads-listing-viewed-event.json/3.json',
                schema: 'https://schema.adevinta.com/events/willhabenat/odin/frontend/myads-listing-viewed-event.json/3.json',
                name: 'Listing viewed',
                page: {
                    pageType: 'my_ads',
                    pageName: 'Meine Anzeigen | willhaben',
                },
                object,
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                published: published(params),
                publicationDate: published(params),
                provider,
            })
        }
        case 'ad_insertion_form_event': {
            const formParams: PulseFormParams = params
            const classifiedAd = getClassifiedAdObjectFromPulseData(params)
            const object = {
                id: `adin:${params.ad_id}`,
                type: 'Form',
                action: 'Create ClassifiedAd',
                stepName: formParams.form_stepName,
                stepNumber: formParams.form_stepNumber,
                timeStart: formParams.form_timeStart,
                statistics: formParams.form_statistics,
                items: [
                    {
                        classifiedAd,
                    },
                ],
                suggestions: formParams.form_suggestions,
            }
            return removeUndefined({
                $schema: 'https://schema.adevinta.com/events/willhabenat/odin/frontend/adin-form-viewed-event.json/20.json',
                schema: 'https://schema.adevinta.com/events/willhabenat/odin/frontend/adin-form-viewed-event.json/20.json',
                title: 'Ad Insertion Form Viewed',
                description: 'Event is fired in every step in the ad insertion',
                object,
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                published: published(params),
                provider,
                page: {
                    '@id': `sdrn:willhabenat:page:adinsertion`,
                    '@type': 'Page',
                    pageType: 'ad_insertion',
                    pageName: 'Ad Insertion Form',
                },
            })
        }
        case 'ad_insertion_confirmation': {
            const object = {
                id: params.ad_id || document.location.href,
                type: 'Confirmation',
            }
            const target = getClassifiedAdObjectFromPulseData(params)
            return removeUndefined({
                name: 'Ad insertion confirmed',
                intent: 'Create',
                object,
                provider,
                target,
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                published: published(params),
                publicationDate: published(params),
            })
        }
        case 'contact_seller_confirmation': {
            const object = {
                id: params.ad_id || document.location.href,
                type: 'Confirmation',
                name: '',
            }
            const target = {
                id: params.create_conversation_message_id || '0',
                type: 'Message',
                inReplyTo: getClassifiedAdObjectFromPulseData(params),
            }
            return removeUndefined({
                name: 'Ad reply submitted',
                intent: 'Send',
                object,
                actor: actor(params.user_uuid),
                target,
                deployStage: deployStage(),
                published: published(params),
                publicationDate: published(params),
                provider,
            })
        }
        default: {
            const object = {
                id: params.ad_id || document.location.href,
                type: 'Page',
            }
            return removeUndefined({
                name: 'Page viewed',
                object,
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                published: published(params),
                publicationDate: published(params),
                provider,
            })
        }
    }
}

export const provider = getApp()
    ? {
          id: 'willhabenat',
          type: 'Organization',
          productType: 'ResponsiveWeb',
          author: `sdrn:willhabenat:tribe:${getApp()}`,
          component: `sdrn:willhabenat:component:frontend:bbx-${getApp()}`,
      }
    : {
          id: 'willhabenat',
          type: 'Organization',
          productType: 'ResponsiveWeb',
      }

const sellAdTypes = [1, 3, 5, 8, 15, 20, 21, 25, 26, 67, 68, 69]
const rentAdTypes = [2, 4, 6, 9]
const jobOfferAdTypes = [40]

export const make_jobs_classified_ad = (params: Readonly<Record<string, unknown>>) => {
    const classifiedId = (params.ad_id as string).toLowerCase().replace('j', '')

    const sdrn = `sdrn:willhabenat:classified:job${classifiedId}`

    const result: Record<string, unknown> = {
        adId: parseInt(classifiedId, 10),
        contentId: classifiedId,
        publicationDate: params.publication_date,
        name: params.ad_title,
        adType: 'jobOffer',
        publisherType: 'pro',
        publisher: {
            '@id': `sdrn:willhabenat:user:jobs:${params.company_id as string}`,
            '@type': 'Account',
        },
        category: params.category,
    }

    result['@id'] = sdrn
    result['@type'] = 'ClassifiedAd'
    return result
}

export const transformPerformanceMetrics = (trackData: PageSpeedMetric) => {
    const { id, name, startTime, value } = trackData

    return {
        type: 'Send',
        object: {
            id,
            type: 'PerformanceMetric',
            metricName: name,
            metricValue: value,
            metricStartTime: startTime,
        },
        schema: 'http://schema.adevinta.com/events/performance-event.json/65.json',
    }
}

export interface ClassifiedAdCore {
    '@id'?: string
    '@type'?: 'ClassifiedAd'
    adUuid?: string
    publisher?: PulsePublisher
    categoryCodePath?: string
    attributes?: {
        attributeName: string
        attributeValue: string[]
    }[]
    vertical?: string
}

export interface ClassifiedAdObject extends ClassifiedAdCore {
    adId?: number
    contentId?: string
    currency?: string
    adType?: string
    name?: string
    publisherType?: string
    price?: number
    category?: string
    categories?: unknown
    location?: unknown
}

export type PulsePublisher =
    | {
          id: string | undefined
          realm?: string
          '@type': string | undefined
      }
    | {
          '@id': string | undefined
          '@type': string | undefined
          accountId?: string | undefined
      }

export const getClassifiedAdCoreFromPulseDataListingEvent = (
    params: Readonly<Record<string, unknown>>,
    adId: string,
): ClassifiedAdCore | undefined => {
    const pulseData = parsePulseData(params)

    const listingEvent = pulseData?.pulseEvents?.[0] as ApiPulseListingObject
    const classifiedAdObject = listingEvent?.classifiedAdObjects?.filter((ad) => String(ad.adId) === String(adId))?.[0]

    return mapClassifiedAdCore(classifiedAdObject)
}

export const getClassifiedAdObjectFromPulseData = (params: Readonly<Record<string, unknown>>): ClassifiedAdObject | undefined => {
    const pulseData = parsePulseData(params)

    const classifiedAdObject = pulseData?.pulseEvents?.[0] as ApiPulseClassifiedAdObject

    return mapClassifiedAdObject(classifiedAdObject)
}

const mapClassifiedAdCore = (classifiedAdCore: ApiPulseClassifiedAdCore | undefined): ClassifiedAdCore | undefined => {
    if (!classifiedAdCore) {
        return undefined
    }

    return {
        '@id': getAdIdSdrn(classifiedAdCore.adId),
        '@type': 'ClassifiedAd',
        adUuid: classifiedAdCore.adUuid,
        publisher: classifiedAdCore.publisher
            ? {
                  '@id': classifiedAdCore.publisher?.id,
                  '@type': classifiedAdCore.publisher?.type,
                  accountId: classifiedAdCore.publisher?.accountId,
              }
            : undefined,
    }
}

const mapClassifiedAdObject = (classifiedAdObject: ApiPulseClassifiedAdObject | undefined): ClassifiedAdObject | undefined => {
    if (!classifiedAdObject) {
        return undefined
    }

    const classifiedAdCore = mapClassifiedAdCore(classifiedAdObject)

    if (!classifiedAdCore) {
        return undefined
    }

    return removeUndefined({
        ...classifiedAdCore,
        adId: classifiedAdObject.adId,
        contentId: classifiedAdObject.adId ? String(classifiedAdObject.adId) : undefined,
        name: classifiedAdObject.name,
        price: classifiedAdObject.priceAsNumber,
        adType: classifiedAdObject.adType,
        currency: classifiedAdObject.currency,
        publisherType: classifiedAdObject.publisherType,
        location: classifiedAdObject.location ? { '@type': 'PostalAddress', ...classifiedAdObject.location } : undefined,
        category: classifiedAdObject.category,
        categories: classifiedAdObject.categories?.map((category) => {
            return {
                '@type': category.type,
                localId: category.localId,
                localName: category.localName,
                level: category.level,
                name: category.name,
            }
        }),
        categoryCodePath: classifiedAdObject.categoryCodePath,
        attributes: classifiedAdObject.attributes,
        vertical: classifiedAdObject.vertical,
    })
}

export const parsePulseData = (params: Readonly<Record<string, unknown>>) => {
    return 'pulse_data' in params && params.pulse_data ? (JSON.parse(params.pulse_data as string) as ApiPulseData) : undefined
}

// the listing event doesn't validate with the @type, to be maybe fixed by defining our own schema for the listing-event
export const createClassifiedAdWithIdOnlyForListing = (id: string | number) => {
    return { '@id': getAdIdSdrn(id) }
}

export const createClassifiedAdWithIdOnly = (id: string | number) => {
    return { '@id': getAdIdSdrn(id), '@type': 'ClassifiedAd' }
}

export const getAdIdSdrn = (classifiedId: string | number) => `sdrn:willhabenat:classified:${classifiedId}`

const getPublisher = (publisherId: string | undefined): PulsePublisher | undefined => {
    if (publisherId) {
        return { '@id': `sdrn:willhabenat:user:${publisherId}`, '@type': 'Account' }
    }
    return undefined
}

const getLocationAddress = (locality: unknown, region: unknown, postalCode: unknown, country: unknown) => {
    const loc = removeUndefined({
        addressLocality: locality,
        addressRegion: region,
        postalCode: postalCode,
        addressCountry: country,
    })
    return Object.keys(loc).length > 0 ? { '@type': 'PostalAddress', ...loc } : undefined
}

export const deployStage = () => {
    switch (getContext()) {
        case 'prod':
            return 'pro'
        case 'dev':
        case 'local-docker':
        case undefined:
            return 'dev'
        default:
            return 'pre'
    }
}

export const actor = (user_uuid: unknown) => {
    return user_uuid ? { id: user_uuid, realm: 'willhabenat' } : undefined
}

export const published = (params: Params) => {
    return params.publish_date || params.publication_date
}

const price = (params: Params) => {
    const floatPrice = parseFloat(`${params.exact_price ?? params.price}`)
    return isNaN(floatPrice) ? undefined : floatPrice
}

const adType = (params: Params) => {
    let adTypeString
    if (params.ad_type_id) {
        const adTypeId = parseInt(params.ad_type_id as string, 10)
        if (sellAdTypes.includes(adTypeId)) {
            adTypeString = params.ad_type_id === '67' && (price(params) === 0 || !price(params)) ? 'give' : 'sell'
        } else if (rentAdTypes.includes(adTypeId)) {
            adTypeString = 'rent'
        } else if (jobOfferAdTypes.includes(adTypeId)) {
            adTypeString = 'jobOffer'
        }
    }
    return adTypeString
}
