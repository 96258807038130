import { isServer } from '@wh/common/chapter/lib/commonHelpers'
import { getContext } from '@wh/common/chapter/lib/commonHelpers'
import { LOCAL_EXPRESS_PROXY } from './constants'
import { BBX_APPLICATION } from '../../types/nextJS'

export const LOCAL_URL = 'http://localhost:3000'

export const isProduction = () => {
    return getContext() === 'prod'
}

export const isUat = () => {
    return getContext() === 'uat'
}

export const getRestApiRoot = () => {
    if (!isServer()) {
        return '/webapi'
    }

    switch (getContext()) {
        case 'prod':
            return 'https://barbarix-bff.prod.whint.at/webapi'
        case 'uat':
            return 'https://barbarix-bff-uat.dev.whint.at/webapi'
        case 'st':
            return 'https://barbarix-bff-st.dev.whint.at/webapi'
        case 'dev':
            return 'https://barbarix-bff-dev.dev.whint.at/webapi'
        case 'local-docker':
            return 'http://host.docker.internal:9999/webapi'
        case undefined:
            if (LOCAL_EXPRESS_PROXY) {
                return `${LOCAL_URL}/webapi`
            } else {
                return 'http://localhost:9999/webapi'
            }
    }

    /* fallback for dynamic environments */
    return `https://${getContext()}.dynamic-envs.willhaben.at/webapi`
}

export const isLocal = () => {
    return getContext() === undefined
}

export const isDynamicEnvironment = () => {
    switch (getContext()) {
        case 'prod':
        case 'uat':
        case 'st':
        case 'dev':
        case 'local-docker':
        case undefined:
            return false
    }

    return true
}

export const getRestApiPath = () => '/webapi'

export const getWillhabenDomain = () => {
    switch (getContext()) {
        case 'prod':
            return 'https://www.willhaben.at'
        case 'uat':
            return 'https://www-uat.willhaben.at'
        case 'st':
            return 'https://www-st.willhaben.at'
        case 'dev':
            return 'https://www-dev.willhaben.at'
        case 'local-docker':
        case undefined:
            return LOCAL_URL
    }

    /* dyn envs */
    return 'https://www-st.willhaben.at'
}

export const getP2pMakeOfferIntegratioName = (): string => {
    switch (getContext()) {
        case 'prod':
            return 'at.willhaben.messaging.p2p'
        case 'uat':
            return 'at.willhaben.messaging.p2p-uat'
        case 'st':
            return 'at.willhaben.messaging.p2p-st'
        case 'dev':
            return 'at.willhaben.messaging.p2p-dev'
        case 'local-docker':
        case undefined:
            return 'at.willhaben.messaging.p2p-dev'
    }

    /* dyn envs */
    return 'at.willhaben.messaging.p2p-st'
}

export const getP2pBuyNowIntegratioName = (): string => {
    switch (getContext()) {
        case 'prod':
            return 'at.willhaben.messaging.p2p-buynow'
        case 'uat':
            return 'at.willhaben.messaging.p2p-buynow-uat'
        case 'st':
            return 'at.willhaben.messaging.p2p-buynow-st'
        case 'dev':
            return 'at.willhaben.messaging.p2p-buynow-dev'
        case 'local-docker':
        case undefined:
            return 'at.willhaben.messaging.p2p-buynow-dev'
    }

    /* dyn envs */
    return 'at.willhaben.messaging.p2p-st'
}

export const getMessagingEnvironment = () => (getContext() === 'prod' ? 'pro' : 'pre')

export const getMessagingIntegrationEnvironment = () => {
    switch (getContext()) {
        case 'uat':
            return 'uat'
        case 'st':
            return 'st'
        case 'prod':
            return 'pro'
        default:
            return 'dev'
    }
}

export const getIntegrationName = () => {
    switch (getMessagingIntegrationEnvironment()) {
        case 'pro':
            return 'at.willhaben.messaging.p2p'
        case 'uat':
            return 'at.willhaben.messaging.p2p-uat'
        case 'st':
            return 'at.willhaben.messaging.p2p-st'
        default:
            return 'at.willhaben.messaging.p2p-dev'
    }
}

export const getPulseDeployStage = () => {
    switch (getContext()) {
        case 'prod':
            return 'pro'
        case 'uat':
            return 'pre'
        default:
            return 'dev'
    }
}

// TODO remove once we get advertisingParameters from API
export const testEnvForAdvertisingParameters = () => {
    switch (getContext()) {
        case 'prod':
            return undefined
        case 'uat':
        case 'st':
        case 'dev':
            return { env: [getContext() as string] }
        case 'local-docker':
            return { env: ['st'] }
        case undefined:
            return { env: ['st'] }
    }

    /* fallback for dynamic environments */
    return { env: ['st'] }
}

export const getFrontendLibraryUrl = () => {
    switch (getContext()) {
        case 'prod':
            return `https://cache.willhaben.at/da/advertising.js`
        case 'uat':
            return `https://cache-uat.willhaben.at/da/advertising.js`
        case 'st':
            return `https://cache-st.willhaben.at/da/advertising.js`
        case 'dev':
            return `https://cache-dev.willhaben.at/da/advertising.js`
        case 'local-docker':
            return `https://cache-dev.willhaben.at/da/advertising.js`
        case undefined:
            return `https://cache-dev.willhaben.at/da/advertising.js`
    }

    /* fallback for dynamic environments */
    return `https://cache-st.willhaben.at/da/advertising.js`
}

export const getUnleashUrl = () => {
    return getContext() === 'prod' ? 'https://unleash4.willhaben.at/api/' : 'https://unleash4-dev.willhaben.at/api/'
}

export const getUnleashEnvironment = () => {
    return getContext() === 'prod' ? 'production' : 'development'
}

export const getVaultUrl = (environment: BBX_APPLICATION) => {
    return `https://vault.willhaben.at/v1/secrets-${getContext() === 'prod' ? 'prod' : 'dev'}/data/bbx-${environment}/${
        getContext() === 'prod' ? 'prod' : 'dev-env'
    }`
}

/* Whitelist domain configuration: https://willhaben.zendesk.com/chat/agent#widget/widget_security */
export const getZendeskUrl = () => {
    const ZENDESK_CHAT_INTEGRATION_URL = 'https://static.zdassets.com/ekr/snippet.js'
    const ZENDESK_CHAT_INTEGRATION_PROD_KEY = '25eb052b-53b3-43e5-9432-38886a66a963'
    return `${ZENDESK_CHAT_INTEGRATION_URL}?key=${ZENDESK_CHAT_INTEGRATION_PROD_KEY}`
}

export const getKeycloakDomain = () => {
    switch (getContext()) {
        case 'prod':
            return `https://sso.willhaben.at`
        case 'st':
            return `https://sso-st.willhaben.at`
        case 'uat':
            return `https://sso-uat.willhaben.at`
        case 'dev':
        case 'local-docker':
        case undefined:
            return `https://sso-dev.willhaben.at`
    }

    /* dyn envs */
    return `https://sso-st.willhaben.at`
}

export const getOptimizelySDKKey = () => {
    switch (getContext()) {
        case 'prod':
            return '8fxS9PiNKHpoJqK5SW8S8'
        default:
            return '98Ybjn4g7jZe2yjQPWnbQ'
    }
}

export const getContextualTargetingBaseUrl = () => {
    return `https://api.permutive.com`
}

export const getContextualTargetingUrl = () => {
    const permutiveAPIKey = 'bad74fab-61f7-4c0b-bf5e-c92adb7feaf8'
    return `/ctx/v1/segment?k=${permutiveAPIKey}`
}

// /iad/logout is handled in server.ts to delete session cookies and then redirect to /iad
const redirectUri = `${getWillhabenDomain()}/iad/logout`

export const ssoLogoutLink = `${getKeycloakDomain()}/auth/realms/willhaben/protocol/openid-connect/logout?redirect_uri=${escape(
    redirectUri,
)}`

export const getWohnkrediteDomain = () => {
    switch (getContext()) {
        case 'prod':
            return 'https://wohnkredite.willhaben.at'
        default:
            return 'https://wohnkredite-dev.willhaben.at'
    }
}

export const getWohnkrediteApiUrl = () => {
    switch (getContext()) {
        case 'prod':
            return 'https://wohnkredite-api.willhaben.at'
        default:
            return 'https://wohnkredite-api-dev.willhaben.at'
    }
}
