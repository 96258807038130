/**
 * converts an object into URLSearchParams.
 * string[] values will be converted to multiple params, e.g. param1=value1&param1=value2
 * keys with undefined values will be removed
 */
export const urlSearchParamsFromObject = (object: Record<string, string | string[] | readonly string[] | number | boolean | undefined>) =>
    new URLSearchParams(
        Object.entries(object)
            .filter(([_, value]) => typeof value !== 'undefined')
            .flatMap(([key, value]) =>
                Array.isArray(value) ? value.map((v) => [`${key}`, `${v ?? ''}`]) : [[`${key}`, `${value ?? ''}`]],
            ),
    )
