import React, { FunctionComponent, useContext, useRef } from 'react'
import { css } from 'styled-components'
import { RenderSlotId } from '@wh/common/digital-advertising/config'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { useInterval } from '@wh/common/chapter/hooks/useInterval'
import { werbungPlaceholderEmptyAfter } from '@wh/common/digital-advertising/components/werbungPlaceholderEmptyAfter'
import { werbungBeforeCss } from '../werbungBeforeCss'

export const LeaderboardLargeRenderSlot: FunctionComponent = () => {
    const advertisingState = useContext(AdvertisingStateContext)
    const isLad =
        advertisingState.pageModifications.apnSkyscraperStyle && advertisingState.pageModifications.apnSkyscraperStyle.baseStyle === 'lad'
    const isClosedAdSlot = (advertisingState.pageModifications.closedAdSlots || []).includes(RenderSlotId.LEADERBOARD_LARGE)
    const headerAndContentSeparator = advertisingState.pageModifications.borders?.['header-and-content-separator']
    // we need to set the leaderboard top position on the AdvertisingState since for LADs, the apn-skyscraper needs to be top-aligned with the leaderboard
    // we could instead use the ResizeObserver on the header, but that would make a polyfill necessary, and the current solution does not pose much runtime overhead
    const divRef = useRef<HTMLDivElement>(null)
    useInterval(() => {
        if (divRef.current) {
            const isDisplayed = divRef.current.offsetParent !== null
            if (!isDisplayed) {
                // avoid onSetCurrentLeaderboardTop being called with a changed value on each scroll on Phone and Tablet
                return
            }

            const scrollTop = (document.scrollingElement || document.documentElement).scrollTop
            if (scrollTop < 0) {
                // avoid yank on safari when elastic scrolling over the top
                return
            }

            advertisingState.onSetCurrentLeaderboardTop(Math.round(divRef.current.getBoundingClientRect().top + scrollTop))
        }
    }, 250)

    return (
        <div
            aria-hidden="true"
            css={css`
                flex: 1 1 auto;
                justify-content: ${isLad ? 'flex-end' : 'center'};
                align-items: center;
                width: 100%;
                /* we cannot set min-height instead of height, as that would break in IE11,  */
                height: 250px;
                overflow: hidden;

                position: relative;
                z-index: ${(p) => p.theme.zIndices.content};

                display: none;
                ${(p) => p.theme.media.desktop} {
                    display: ${isClosedAdSlot ? 'none' : 'flex'};
                    box-shadow: ${headerAndContentSeparator
                        ? `inset ${headerAndContentSeparator.width}px 0px 0px 0px ${headerAndContentSeparator.color}`
                        : null};
                }

                ${(p) => p.theme.media.print} {
                    display: none;
                }
            `}
        >
            <div
                ref={divRef}
                id={RenderSlotId.LEADERBOARD_LARGE}
                css={css`
                    /* this implicitly makes child elements display:block, which is necessary to avoid the extra spacing at the bottom */
                    display: flex;
                    flex: 1 1 auto;
                    justify-content: ${isLad ? 'flex-end' : 'center'};
                    align-items: center;
                    width: 100%;

                    iframe {
                        /* avoids extra spacing at the bottom */
                        display: block;
                    }
                    ${werbungBeforeCss}
                    /* defines :after */
                    ${werbungPlaceholderEmptyAfter(250)}
                `}
            />
        </div>
    )
}
