import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { storyblokTransformSlugToTag } from './storyblokTransformSlugToTag'

export const storyblokCallActionEvent = (eventName: string, slug: string) => {
    if (eventName) {
        callActionEvent('storyblok_click', undefined, {
            prefix: storyblokTransformSlugToTag(slug),
            taggingId: eventName,
        })
    }
}
