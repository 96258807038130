import React, { Fragment, PropsWithChildren, RefObject, useContext, useRef } from 'react'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { UserAgentContext } from '@wh/common/chapter/components/UserAgentProvider/UserAgentProvider'
import { SSGContext } from '../SSGProvider/SSGProvider'

interface DynamicRenderingProps<T extends Element, U extends Element | undefined> {
    forceRendering?: boolean
    // forceRencering will override the value of disableIntersectionChecking
    disableIntersectionChecking?: boolean
    renderPlaceholder: (ref: RefObject<T>) => React.ReactNode
    offset?: string
    scrollRootRef?: RefObject<U>
}

export const DynamicRendering = <T extends Element, U extends Element | undefined = undefined>(
    props: PropsWithChildren<DynamicRenderingProps<T, U>>,
) => {
    const {
        forceRendering = false,
        disableIntersectionChecking = false,
        renderPlaceholder,
        offset = '200px',
        scrollRootRef,
        children,
    } = props

    const { isBot } = useContext(UserAgentContext)
    const isSSG = useContext(SSGContext)
    if (forceRendering || isBot || isSSG) {
        return <Fragment>{children}</Fragment>
    }

    return (
        <IsInViewportRendering
            disableIntersectionChecking={disableIntersectionChecking}
            renderPlaceholder={renderPlaceholder}
            offset={offset}
            scrollRootRef={scrollRootRef}
        >
            {children}
        </IsInViewportRendering>
    )
}

const IsInViewportRendering = <T extends Element, U extends Element | undefined>(
    props: PropsWithChildren<
        Pick<DynamicRenderingProps<T, U>, 'disableIntersectionChecking' | 'renderPlaceholder' | 'offset' | 'scrollRootRef'>
    >,
) => {
    const { disableIntersectionChecking, renderPlaceholder, offset, scrollRootRef, children } = props

    const ref = useRef<T>(null)
    const [isInView] = useIsInView(ref, offset, scrollRootRef)

    return <Fragment>{!disableIntersectionChecking && isInView ? children : renderPlaceholder(ref)}</Fragment>
}
