import { useEffect, useRef } from 'react'
import { getRelevantDmpSegmentsForJobs } from './jobsDmpSegmentsApiClient'
import { getStoryblokDataSourceEntries } from '../../../cms/api/storyblokIadProxyClient'

declare global {
    interface Window {
        permutive: Permutive
    }
}

interface Permutive {
    ready: (callback: () => void, type: string) => void
    segments: (callback: (segments: number[]) => void) => void
}

const DAYS_IN_MS = 1000 * 60 * 60 * 24
const expiresInOneMonth = new Date(new Date().getTime() + DAYS_IN_MS * 30)

export const useSyncDmpSegmentsToCookie = () => {
    const segmentsSynced = useRef<boolean>(false)
    const counter = useRef<number>(0)

    useEffect(() => {
        const syncSegments = async () => {
            window.permutive.segments(async (segments) => {
                try {
                    const maxNumberOfSegmentsInCookie = 80 // limit the max size of the cookie

                    const [relevantJobSegments, relevantFashionSegments] = await Promise.all([
                        getRelevantDmpSegmentsForJobs().catch(() => ({ dmpSegmentIds: [] as number[] })),
                        getStoryblokDataSourceEntries('dmp-segments').catch(() => ({ dmpSegmentIds: [] as number[] })),
                    ])

                    const filteredSegments = segments
                        .filter(
                            (segment) =>
                                relevantJobSegments.dmpSegmentIds?.includes(segment) ||
                                relevantFashionSegments.dmpSegmentIds?.includes(segment),
                        )
                        .slice(0, maxNumberOfSegmentsInCookie)

                    const value = JSON.stringify(filteredSegments)

                    const cookieKey = 'bbxDmpSegments'
                    document.cookie = `${cookieKey}=${value};path=/;expires=${expiresInOneMonth.toUTCString()}`
                } catch (error) {
                    console.error('permutive sync error', error)
                }
            })
        }

        const interval = setInterval(() => {
            counter.current++
            if (counter.current > 10) {
                clearInterval(interval)
            }

            window.permutive?.ready(() => {
                clearInterval(interval)
                if (!segmentsSynced.current) {
                    syncSegments()
                    segmentsSynced.current = true
                }
            }, 'initialised')
        }, 500)
    }, [])
}
