import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import type { theme } from '@wh-components/core/theme'
import React, { FunctionComponent } from 'react'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'

type Spacing = '0' | keyof typeof theme.space

export interface StoryblokBoxType extends StoryblokComponentType<string> {
    children: SbBlokData[]
    paddingTopMobile: Spacing
    paddingLeftMobile: Spacing
    paddingTopDesktop: Spacing
    paddingLeftDesktop: Spacing
    paddingRightMobile: Spacing
    paddingBottomMobile: Spacing
    paddingRightDesktop: Spacing
    paddingBottomDesktop: Spacing
    flex?: {
        isFlex: boolean
        justifyContent: string
    }
}

type Props = StoryblokDefaultProps<StoryblokBoxType>

export const StoryblokBox: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <Box
            paddingTop={{ mobile: blok.paddingTopMobile, tablet: blok.paddingTopDesktop }}
            paddingRight={{ mobile: blok.paddingRightMobile, tablet: blok.paddingRightDesktop }}
            paddingBottom={{ mobile: blok.paddingBottomMobile, tablet: blok.paddingBottomDesktop }}
            paddingLeft={{ mobile: blok.paddingLeftMobile, tablet: blok.paddingLeftDesktop }}
            display={blok.flex?.isFlex ? 'flex' : undefined}
            justifyContent={blok.flex?.justifyContent}
            {...storyblokEditable(blok as unknown as SbBlokData)}
        >
            {blok.children.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
            ))}
        </Box>
    )
}
