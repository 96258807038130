import React, { FunctionComponent } from 'react'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { Box } from '@wh-components/core/Box/Box'
import styled from 'styled-components'
import { Badge } from '@wh-components/core/Badge/Badge'
import { ResponsiveValue } from '@wh-components/system'
import { Property } from 'csstype'

export const ReservedBadge: FunctionComponent<{
    advertStatus: { id: string; description: string } | null
    position?: ResponsiveValue<Property.Position>
}> = ({ advertStatus, position = 'absolute' }) => {
    return advertStatus && advertStatus.id === adStatusFlag.RESERVED ? (
        <Box position={position} top="xs" left="xs">
            <AdBadge color1="palette.elephant" paddingHorizontal="6px" paddingVertical="3px">
                {advertStatus.description}
            </AdBadge>
        </Box>
    ) : null
}

export const AdBadge = styled(Badge)`
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: ${(p) => p.theme.fontSizes.xs};
    padding: 4px 6px;
    line-height: 1;
    text-transform: capitalize;
    user-select: none;
`
