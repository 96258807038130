import React from 'react'
import { useRouter } from 'next/router'
import { Box } from '@wh-components/core/Box/Box'
import { ServerRoutingAnchorLink } from '../AnchorLink/AnchorLink'
import { callActionEvent } from '../../lib/tagging/tagging'
import { Text } from '@wh-components/core/Text/Text'
import { storageAvailable } from '../../lib/storageAvailable'
import { REDIRECT_AFTER_VERIFY_EMAIL_STORAGE_KEY } from '../../lib/localStorage'

export const LoginAndRegisterLinks: React.FunctionComponent<{
    onClick: () => void
    isVerticalMenuTransparent?: boolean
}> = ({ onClick, isVerticalMenuTransparent }) => {
    const router = useRouter()

    return (
        <Box>
            <Box display={{ phone: 'none', tablet: 'block' }} fontSize="s">
                <ServerRoutingAnchorLink
                    type="anchor"
                    href={`/iad/myprofile/login?r=${encodeURIComponent(router.asPath)}`}
                    rel="nofollow"
                    underline="none"
                    color={isVerticalMenuTransparent ? 'palette.white' : 'palette.verydarkgrey'}
                    testId="loginTopLink"
                    onClick={() => {
                        setRedirectUriForEmailVerification(router.asPath)
                        onClick()
                        return callActionEvent('header_click_login', undefined)
                    }}
                >
                    <Text fontWeight="bold">Einloggen</Text>
                </ServerRoutingAnchorLink>
                <Text marginHorizontal={2} color={isVerticalMenuTransparent ? 'palette.white' : 'palette.verydarkgrey'} aria-hidden="true">
                    |
                </Text>
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="/iad/myprofile/register"
                    rel="nofollow"
                    underline="none"
                    color={isVerticalMenuTransparent ? 'palette.white' : 'palette.verydarkgrey'}
                    testId="registerTopLink"
                    onClick={() => {
                        setRedirectUriForEmailVerification(router.asPath)
                        onClick()
                        return callActionEvent('header_click_register', undefined)
                    }}
                >
                    <Text fontWeight="bold">Registrieren</Text>
                </ServerRoutingAnchorLink>
            </Box>
            <ServerRoutingAnchorLink
                type="button"
                display={{ tablet: 'none' }}
                size="xsmall"
                variant="outline"
                href={`/iad/myprofile/login?r=${encodeURIComponent(router.asPath)}`}
                rel="nofollow"
                marginRight="m"
                testId="loginTopLink"
                onClick={() => {
                    setRedirectUriForEmailVerification(router.asPath)
                    onClick()
                    return callActionEvent('header_click_login', undefined)
                }}
            >
                Einloggen
            </ServerRoutingAnchorLink>
        </Box>
    )
}

const setRedirectUriForEmailVerification = (redirectTarget: string) => {
    if (storageAvailable('localStorage')) {
        localStorage.setItem(REDIRECT_AFTER_VERIFY_EMAIL_STORAGE_KEY, redirectTarget)
    }
}
