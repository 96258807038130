// Cloned from https://github.com/stipsan/smooth-scroll-into-view-if-needed/blob/master/src/index.ts
// and added a `topOffset` property.
// With this we can fix the issue of scrolling to an element where e.g. a fixed header or banner is
// placed at the top of the page.

// But in the next major release of smooth-scroll-into-view-if-needed this should also be supported, see:
// https://github.com/stipsan/smooth-scroll-into-view-if-needed/issues/231#issuecomment-577329996

import scrollIntoView, { CustomBehaviorOptions, StandardBehaviorOptions } from 'scroll-into-view-if-needed'

export type CustomEasing = (t: number) => number

export interface SmoothBehaviorOptions extends StandardBehaviorOptions, Omit<CustomBehaviorOptions, 'behavior'> {
    behavior?: 'smooth'
    duration?: number
    ease?: CustomEasing
    topOffset?: number
}

// Memoize so we're much more friendly to non-dom envs
let memoizedNow: () => number
const now = () => {
    if (!memoizedNow) {
        memoizedNow = 'performance' in window ? performance.now.bind(performance) : Date.now
    }
    return memoizedNow()
}

type Context = {
    scrollable: Element
    method: (x: number, y: number) => void
    startTime: number
    startX: number
    startY: number
    x: number
    y: number
    duration: number
    ease: CustomEasing
    cb: () => void
}
function step(context: Context) {
    const time = now()
    const elapsed = Math.min((time - context.startTime) / context.duration, 1)
    // apply easing to elapsed time
    const value = context.ease(elapsed)

    const currentX = context.startX + (context.x - context.startX) * value
    const currentY = context.startY + (context.y - context.startY) * value

    context.method(currentX, currentY)

    // scroll more if we have not reached our destination
    if (currentX !== context.x || currentY !== context.y) {
        requestAnimationFrame(() => step(context))
    } else {
        // If nothing left to scroll lets fire the callback
        context.cb()
    }
}

function smoothScroll(
    el: Element,
    x: number,
    y: number,
    duration: number = 600,
    ease: CustomEasing = (t) => 1 + --t * t * t * t * t,
    cb: () => void,
) {
    // define scroll context
    const scrollable = el
    const startX = el.scrollLeft
    const startY = el.scrollTop
    const method = (left: number, top: number) => {
        el.scrollLeft = left
        el.scrollTop = top
    }

    // scroll looping over a frame if needed
    step({
        scrollable: scrollable,
        method,
        startTime: now(),
        startX: startX,
        startY: startY,
        x,
        y,
        duration,
        ease,
        cb,
    })
}

const shouldSmoothScroll = <T extends {}>(
    options: SmoothBehaviorOptions | CustomBehaviorOptions<T> | StandardBehaviorOptions,
): options is T => {
    return (options && !options.behavior) || options.behavior === 'smooth'
}

function scroll(target: Element, options?: SmoothBehaviorOptions): Promise<unknown>
function scroll<T extends {}>(target: Element, options: CustomBehaviorOptions<T>): T
function scroll(target: Element, options: StandardBehaviorOptions): void
function scroll<T extends {}>(target: Element, options?: SmoothBehaviorOptions | CustomBehaviorOptions<T> | StandardBehaviorOptions) {
    const overrides = options || {}
    if (shouldSmoothScroll<SmoothBehaviorOptions>(overrides)) {
        return scrollIntoView<Promise<{ el: Element; left: number[]; top: number[] }[]>>(target, {
            block: overrides.block,
            inline: overrides.inline,
            scrollMode: overrides.scrollMode,
            boundary: overrides.boundary,
            behavior: (actions) =>
                Promise.all(
                    actions.reduce((results: Promise<{ el: Element; left: number[]; top: number[] }>[], { el, left, top: top }) => {
                        const topWithOffset = top - (overrides.topOffset ?? 0)
                        const startLeft = el.scrollLeft
                        const startTop = el.scrollTop
                        if (startLeft === left && startTop === topWithOffset) {
                            return results
                        }

                        return [
                            ...results,
                            new Promise<{ el: Element; left: number[]; top: number[] }>((resolve) => {
                                return smoothScroll(el, topWithOffset, topWithOffset, overrides.duration, overrides.ease, () => {
                                    resolve({
                                        el,
                                        left: [startLeft, left],
                                        top: [startTop, topWithOffset],
                                    })
                                })
                            }),
                        ]
                    }, []),
                ),
        })
    }

    return Promise.resolve(scrollIntoView<T>(target, options as CustomBehaviorOptions<T>))
}

// re-assign here makes the flowtype generation work
export const smoothScrollIntoViewWithOffset = scroll
