import React, { FunctionComponent, useContext } from 'react'
import { css } from 'styled-components'
import { RenderSlotId } from '@wh/common/digital-advertising/config'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { werbungPlaceholderEmptyAfter } from '@wh/common/digital-advertising/components/werbungPlaceholderEmptyAfter'
import { werbungBeforeCss } from '../werbungBeforeCss'

export const LeaderboardMediumRenderSlot: FunctionComponent = () => {
    const advertisingState = useContext(AdvertisingStateContext)
    const isClosedAdSlot = (advertisingState.pageModifications.closedAdSlots || []).includes(RenderSlotId.LEADERBOARD_MEDIUM)
    const headerAndContentSeparator = advertisingState.pageModifications.borders?.['header-and-content-separator']

    return (
        <div
            id={RenderSlotId.LEADERBOARD_MEDIUM}
            aria-hidden="true"
            css={css`
                flex: 1 1 auto;
                justify-content: center;
                align-items: center;
                width: 100%;
                /* we cannot set min-height instead of height, as that would break in IE11,  */
                height: 90px;
                overflow: hidden;

                position: relative;
                z-index: ${(p) => p.theme.zIndices.content};

                display: none;
                ${(p) => p.theme.media.only.tablet} {
                    display: ${isClosedAdSlot ? 'none' : 'flex'};
                    box-shadow: ${headerAndContentSeparator
                        ? `inset 0px 0px 0px ${headerAndContentSeparator.width}px ${headerAndContentSeparator.color}`
                        : null};
                }

                ${(p) => p.theme.media.print} {
                    display: none;
                }

                iframe {
                    /* avoids extra spacing at the bottom */
                    display: block;
                }

                /* defines :after */
                ${werbungPlaceholderEmptyAfter(90)}
                ${werbungBeforeCss}
            `}
        />
    )
}
