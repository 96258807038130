import React, { FunctionComponent, useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import { isClient } from '@wh/common/chapter/lib/commonHelpers'
import { Box } from '@wh-components/core/Box/Box'
import ArrowLeftThick from '@wh-components/icons/ArrowLeftThick'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'

const getHashFromLocation = () => (isClient() ? window.location.hash.substring(1) : '')

interface StoryblokCarAuctionsBacklinkBlok extends StoryblokComponentType<string> {
    mainTabId: string
    linkText: string
}

interface Props extends StoryblokDefaultProps<StoryblokCarAuctionsBacklinkBlok> {}

export const StoryblokCarAuctionsBacklink: FunctionComponent<Props> = ({ blok }) => {
    // This is a very specific component for the car auctions landing page,
    // intended as a workaround for the WH app webview to avoid unintentional closing of the webview modal
    const theme = useTheme()
    const [hash, setHash] = useState(getHashFromLocation())

    useEffect(() => {
        const handleHashChange = () => {
            setHash(getHashFromLocation())
        }
        addEventListener('hashchange', handleHashChange, false)
        return () => {
            removeEventListener('hashchange', handleHashChange, false)
        }
    })

    const isMainTabVisible = hash === '' || hash === blok.mainTabId

    return (
        <StyledContainer {...storyblokEditable(blok as unknown as SbBlokData)}>
            <Box fontSize="m" marginTop={theme.space.l} marginBottom={theme.space.l} display={isMainTabVisible ? 'none' : 'block'}>
                <a href="#">
                    <ArrowLeftThick color="palette.primary.main" marginRight={theme.space.s} />
                    <span color="palette.primary.main">{blok.linkText}</span>
                </a>
            </Box>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}px) {
        display: none;
    }
`
