import { Request } from 'express'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { fetcher } from '../../../chapter/api/fetcher'

export const getRelevantDmpSegmentsForJobs = (request?: Request): Promise<{ dmpSegmentIds: number[] }> => {
    return fetcher<{ dmpSegmentIds: number[] }>('/interceptor/recommendations/dmpsegments', {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}
