import React, { useContext } from 'react'
import { DebugFlagContext } from '@wh/common/chapter/components/DebugFlagProvider/DebugFlagProvider'
import Script from 'next/script'
import { isUat } from '../../lib/config/runtimeConfig'

/** we only use this on test envs, so we do not need a didomi cookie consent */
export const ClarityTrackingUat = () => {
    const debugFlags = useContext(DebugFlagContext)
    const renderHotjar = debugFlags.clarityMode !== 'disable' && isUat()

    return renderHotjar ? (
        <Script
            id="clarity-script"
            type="text/javascript"
            strategy="beforeInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                            (function(c,l,a,r,i,t,y){
                                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                            })(window, document, "clarity", "script", "nww5kyt7xi");
                        `,
            }}
        />
    ) : null
}
