// INFO: for full site brandings we need to be able to make the full background clickable
// but since the advertising background div is placed absolutely, our content would swallow all click events
// therefore we disable all click events and only selectively enable it for our content specifically
// WARNING: if we forget to turn pointer-events on for any of our content, it will not be clickable
import { createGlobalStyle } from 'styled-components'
import { IE11OnlySelector } from '@wh-components/core/utilities/system'

export const GlobalAdvertisingStyle = createGlobalStyle`
  /* IE11 workaround - for other browsers it is not necessary to deactivate pointer events on the 'html' element */
  /* we do not want to apply this for other browsers, as it would break scrolling on the background for Firefox<=64 in case the background iframe is not visible */
  ${IE11OnlySelector} {
    html {
        pointer-events: none;
    }
  }

  body {
    pointer-events: none;

    & > *:not(#__next) {
        pointer-events: auto;
    }

    .Toastify {
        pointer-events: auto;
    }
  }
`
