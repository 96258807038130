import { useContext, useEffect, useRef } from 'react'
import { handleTrackingLibrariesLoaded } from '@wh/common/chapter/lib/tagging/tagging'
import { loadExternalScriptAsync } from '@wh/common/chapter/lib/externalScriptLoader'
import { DebugFlagContext } from '../DebugFlagProvider/DebugFlagProvider'
import { XITI_CONFIG, XITI_SITE_ID, XitiTagConstructor } from '@wh/common/chapter/lib/tagging/taggingXiti'
import { DOMAIN_SERVICE_NAME, OEWA_SITE } from '../../lib/tagging/taggingOewa'

declare global {
    interface Window {
        ATInternet: {
            Tracker: {
                Tag: XitiTagConstructor
            }
        }
    }
}

export const TrackingLibraryLoader = () => {
    const debugFlags = useContext(DebugFlagContext)
    const loadedRef = useRef(false)

    useEffect(() => {
        // in dev mode due to react strict mode, the useEffect closure would be executed twice, loading all libs twice
        if (loadedRef.current) {
            return
        }
        loadedRef.current = true

        if (debugFlags.trackingMode === 'disable') {
            return
        }

        const oewaIOMmLibLoader = loadExternalScriptAsync(
            `//${DOMAIN_SERVICE_NAME}/iomm/latest/bootstrap/stub.js`,
            'oewaIOMmLibLoader',
            'anonymous',
        )
            .then(() => {
                if (window.IOMm) {
                    window.IOMm('configure', { st: `${OEWA_SITE}`, dn: `${DOMAIN_SERVICE_NAME}`, cn: 'at', dc: 'web', dg: false, mh: 5 })
                }
                loadExternalScriptAsync(`//${DOMAIN_SERVICE_NAME}/iomm/latest/manager/base/es6/bundle.js`, 'IOMmBundle', 'anonymous').catch(
                    () => {
                        // ignore error
                    },
                )
            })
            .catch(() => {
                // ignore error
            })
        const xitiLibLoader = loadExternalScriptAsync(`//tag.aticdn.net/${XITI_SITE_ID}/smarttag.js`)
            .then(() => {
                window.xitiTracker = new window.ATInternet.Tracker.Tag(XITI_CONFIG)
            })
            .catch(() => {
                // ignore error
            })

        Promise.all([oewaIOMmLibLoader, xitiLibLoader]).then(() => {
            handleTrackingLibrariesLoaded()
        })
    }, [debugFlags.trackingMode])

    return null
}
