// check next.config.js and this issue if confused
// https://github.com/zeit/next.js/issues/1852
import * as Sentry from '@sentry/node'
import { setSentryWasInitialized, commonSentryConfig } from '@wh/common/chapter/components/Sentry/sentry'
import { getContext, isServer } from '@wh/common/chapter/lib/commonHelpers'

// ratio for how many users sentry should be activated on the client side (0...none, 1...all)
const SENTRY_CLIENT_ACTIVE_RATIO = 1
const isSentryActive = isServer() ? true : Math.floor(Math.random() / SENTRY_CLIENT_ACTIVE_RATIO) === 0

export const initSentry = () => {
    if (!isSentryActive) {
        return
    }

    const context = getContext()
    if (context && context !== 'local-docker') {
        Sentry.init(
            commonSentryConfig('https://dc022847d5aa4350875cd8f76f1697d3@o558032.ingest.sentry.io/5691106', {
                additionalAllowUrls: [/https?:\/\/bbx-search-[0-9]+\.dynamic-envs\.willhaben\.at/],
            }),
        )

        const scope = Sentry.getCurrentScope()
        scope.setTag('ssr', isServer().toString())

        setSentryWasInitialized()
    }
}
