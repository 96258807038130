import { useEffect } from 'react'

export const PerformanceApi = () => {
    useEffect(() => {
        // we want to measure adloading times with 'pageLoad' as entrypoint.
        // For SSG sites, we define the start of the pageLoad here
        if (typeof window.performance.mark === 'function') {
            window.performance.mark('pageLoadSsg')
        }
    })

    return null
}
