/**
 * returns an object with key/value pairs of all headers.
 * for headers that appear multiple times (e.g. `header1:"value1"` and `header1:"value2"`), its values are wrapped into an array (`header1:["value1", "value2"])
 */
export const headerObjectFromResponse = (response: Response | undefined) => {
    return Array.from(response?.headers.entries() ?? []).reduce(
        (result, [currentKey, currentValue]) => {
            const oldValue = result[currentKey]
            if (oldValue) {
                // wrap values of multiple headers with the same key in an array
                if (Array.isArray(oldValue)) {
                    result[currentKey] = [...oldValue, currentValue]
                } else {
                    result[currentKey] = [oldValue, currentValue]
                }
            } else {
                result[currentKey] = currentValue
            }
            return result
        },
        {} as Record<string, string | string[]>,
    )
}
