import { getUserInfo } from '../api/userApiClient'
import { NextRequest } from '../types/nextJS'
import { UserData, UserProfileData } from '../types/user'

export const mapUserInfoToProfileData = (userInfo: UserData | undefined): UserProfileData | undefined =>
    userInfo
        ? {
              ...userInfo?.userData,
              contextLinkList: { ...userInfo?.contextLinkList },
          }
        : undefined

export const getProfileData = async (request?: NextRequest) => mapUserInfoToProfileData(await getUserInfo(request).catch(() => undefined))
