import { RefObject } from 'react'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'

export const useIsInView = <T extends Element, U extends Element | undefined>(
    ref: RefObject<T>,
    offset = '0px',
    scrollRootRef: RefObject<U> | undefined = undefined,
    triggerOnce = true,
) =>
    useIntersectionObserver(
        ref,
        {
            triggerOnce: triggerOnce,
            rootMargin: offset,
            root: scrollRootRef?.current,
        },
        [],
        undefined,
    )
