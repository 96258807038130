import { css } from 'styled-components'

export const badgeCss = css`
    content: 'WERBUNG';
    background: hsla(0, 0%, 100%, 0.7);
    border-radius: 1rem;
    padding: 0.1rem 0.5rem;
    font-size: ${(p) => p.theme.fontSizes.xs};
    color: ${(p) => p.theme.colors.palette.elephant};
`
export const werbungBeforeCss = css`
    &:not(:empty)::before {
        position: absolute;
        left: ${(p) => p.theme.space.xs}px;
        bottom: ${(p) => p.theme.space.xs}px;
        z-index: ${(p) => p.theme.zIndices.overlayAd};
        ${badgeCss}
    }
`
export const werbungBeforeCssSitemutation = css`
    &:not(:empty)::before {
        position: absolute;
        left: ${(p) => p.theme.space.xs}px;
        top: ${(p) => p.theme.space.xs}px;
        z-index: ${(p) => p.theme.zIndices.stickyContent};
        ${badgeCss}
    }
`
export const werbungBeforeCssAdDetailSmall = css`
    &:not(:empty)::before {
        position: absolute;
        left: ${(p) => p.theme.space.m}px;
        bottom: ${(p) => p.theme.space.xs}px;
        z-index: ${(p) => p.theme.zIndices.overlayAd};
        ${badgeCss}
    }
`
export const werbungBeforeSkyCss = css`
    &:not(:empty)::before {
        position: absolute;
        margin-left: -${(p) => p.theme.space.m}px;
        top: ${(p) => p.theme.space.xl}px;
        transform: rotate(-90deg);
        z-index: ${(p) => p.theme.zIndices.overlayAd};
        ${badgeCss}
    }
`
export const werbungBeforeFilterCss = css`
    &:not(:empty)::before {
        position: absolute;
        left: ${(p) => p.theme.space.xs}px;
        bottom: -${(p) => p.theme.space.l}px;
        z-index: ${(p) => p.theme.zIndices.overlayAd};
        ${badgeCss}
    }
`
