import { TaggingData, TmsData } from '@wh/common/chapter/types/taggingData'
import {
    ActionEventExtraParameters,
    PageParameters,
    TaggingActionEvent,
    TaggingPage,
    TaggingPageWithPageParameters,
    TaggingSelfPromotionEvent,
} from './taggingTypes'
import { trackOewaPageView } from './taggingOewa'
import { trackPulseClickEvent, trackPulsePageView } from './taggingPulse'
import { trackXitiClickEvent, trackXitiPageView } from './taggingXiti'

type ViewData = Record<string, string | undefined>

interface LinkData extends ViewData {}

interface ClickData extends LinkData {
    xiti_click_type: string
}

interface SelfPromotionData extends LinkData {}

type TempStoredEvent = TempStoredEventTypeView | TempStoredEventTypeLink
type TempStoredEventTypeView = {
    type: 'view'
    eventName: TaggingPage
    viewData: ViewData
}
type TempStoredEventTypeLink = {
    type: 'link'
    eventName: TaggingActionEvent | TaggingSelfPromotionEvent
    linkData: LinkData
}

declare global {
    interface Window {
        trackingLoaded?: boolean
        tracking_wh_temp_event_store?: TempStoredEvent[]
    }
}

export const handleTrackingLibrariesLoaded = () => {
    const tempEvents = window.tracking_wh_temp_event_store || []
    tempEvents.forEach((e) => {
        switch (e.type) {
            case 'view':
                executeViewEvent(e.eventName, e.viewData)
                break
            case 'link':
                executeLinkEvent(e.eventName, e.linkData)
                break
        }
    })
    window.tracking_wh_temp_event_store = undefined
    window.trackingLoaded = true
}

export function callPageViewWithPageParameters(taggingPageWithParameters: TaggingPageWithPageParameters, taggingData: TaggingData) {
    switch (taggingPageWithParameters.page) {
        case 'contact_seller_confirmation_dealer_motor': {
            const pageParameters: PageParameters = {
                xiti_f1: taggingPageWithParameters.selectedContactSuggestions.includes(1) ? 'test-drive' : 'empty',
                xiti_f2: taggingPageWithParameters.selectedContactSuggestions.includes(2) ? 'more-information' : 'empty',
                xiti_f3: taggingPageWithParameters.selectedContactSuggestions.includes(3) ? 'still-available' : 'empty',
                xiti_f4: taggingPageWithParameters.selectedContactSuggestions.includes(4) ? 'trade-possible' : 'empty',
                xiti_f5: taggingPageWithParameters.hasFilledOutMessageField ? 'populated' : 'empty',
            }
            return callPageView('contact_seller_confirmation', { taggingData, pageParameters })
        }
        case 'contact_seller_confirmation_dealer_estate': {
            const pageParameters: PageParameters = {
                xiti_f1: taggingPageWithParameters.selectedContactSuggestions.includes(5) ? 'still-available' : 'empty',
                xiti_f2: taggingPageWithParameters.selectedContactSuggestions.includes(6) ? 'possible-viewing' : 'empty',
                xiti_f3: taggingPageWithParameters.selectedContactSuggestions.includes(7) ? 'call-back' : 'empty',
                xiti_f4: taggingPageWithParameters.selectedContactSuggestions.includes(8) ? 'more-information' : 'empty',
                xiti_f5: taggingPageWithParameters.hasFilledOutMessageField ? 'populated' : 'empty',
            }
            return callPageView('contact_seller_confirmation', { taggingData, pageParameters })
        }
        case 'contact_seller_confirmation_dealer_bap': {
            const pageParameters: PageParameters = {
                xiti_f5: taggingPageWithParameters.hasFilledOutMessageField ? 'populated' : 'empty',
            }
            return callPageView('contact_seller_confirmation', { taggingData, pageParameters })
        }
    }
}

export function callPageView(
    eventName: TaggingPage,
    parameters:
        | {
              taggingData: TaggingData
              pageParameters?: PageParameters
          }
        | {
              tmsData: TmsData & { user_uuid: string | undefined }
              pageParameters?: PageParameters
          },
) {
    // we expect TaggingData from the api to contain the user_uuid, therefore we silence the type error here by setting it to undefined explicitly
    const tmsData = 'tmsData' in parameters ? parameters.tmsData : { user_uuid: undefined, ...parameters.taggingData.tmsDataValues.tmsData }
    const pulseData = 'taggingData' in parameters ? JSON.stringify(parameters.taggingData.pulseData) : undefined

    const viewData: ViewData = {
        // tmsData might contain event_name and other "wrong" data, therefore we set it first, and then overwrite it below
        ...tmsData,
        ...parameters.pageParameters,
        pulse_data: pulseData,
        event_name: undefined,
    }

    if (!window.trackingLoaded) {
        // tracking libraries might not yet be loaded => store event
        window.tracking_wh_temp_event_store = window.tracking_wh_temp_event_store || []
        window.tracking_wh_temp_event_store.push({ type: 'view', eventName, viewData })
        return Promise.resolve()
    }

    // safety net in case handleTrackingLibrariesLoaded was not called after tracking libraries finished loading
    if (typeof window.tracking_wh_temp_event_store !== 'undefined') {
        // error: window.trackingLoaded is set, but handleTrackingLibrariesLoaded was not called
        handleTrackingLibrariesLoaded()
    }

    return executeViewEvent(eventName, viewData)
}

const executeViewEvent = async (eventName: TaggingPage, viewData: ViewData) => {
    const oewaPromise = trackOewaPageView(eventName, viewData).catch(() => {
        // ignore error
    })
    const xitiPromise = trackXitiPageView(eventName, viewData).catch(() => {
        // ignore error
    })
    const pulsePromise = trackPulsePageView(eventName, viewData).catch(() => {
        // ignore error
    })

    await Promise.all([oewaPromise, xitiPromise, pulsePromise])
}

export function callActionEvent(
    eventName: TaggingActionEvent,
    taggingData: TaggingData | undefined,
    extraParameters?: ActionEventExtraParameters,
): Promise<void> {
    const extraClickData: Omit<ClickData, keyof LinkData> = {
        xiti_click_type: 'action',
    }
    return callLinkEvent(eventName, { ...extraClickData, ...extraParameters }, taggingData)
}

export const callSelfPromotionEvent = (
    eventName: TaggingSelfPromotionEvent,
    taggingData: TaggingData | undefined,
    extraParameters?: ActionEventExtraParameters,
): Promise<void> => callLinkEvent(eventName, extraParameters, taggingData)

function callLinkEvent(
    eventName: TaggingActionEvent | TaggingSelfPromotionEvent,
    additionalLinkData: Record<string, unknown> | undefined,
    taggingData?: TaggingData,
): Promise<void> {
    const pulseData = taggingData && 'pulseData' in taggingData ? JSON.stringify(taggingData.pulseData) : undefined
    const linkData: LinkData = {
        // tmsData might contain event_name and other "wrong" data, therefore we set it first, and then overwrite it below
        ...taggingData?.tmsDataValues.tmsData,
        ...additionalLinkData,
        pulse_data: pulseData,
        event_name: undefined,
    }

    if (!window.trackingLoaded) {
        // tracking libraries might not yet be loaded => store event
        window.tracking_wh_temp_event_store = window.tracking_wh_temp_event_store || []
        window.tracking_wh_temp_event_store.push({ type: 'link', eventName, linkData })
        return Promise.resolve()
    }

    // safety net in case handleTrackingLibrariesLoaded was not called after tracking libraries finished loading
    if (typeof window.tracking_wh_temp_event_store !== 'undefined') {
        // error: window.trackingLoaded is set, but handleTrackingLibrariesLoaded was not called
        handleTrackingLibrariesLoaded()
    }

    return executeLinkEvent(eventName, linkData)
}

const executeLinkEvent = async (eventName: TaggingActionEvent | TaggingSelfPromotionEvent, linkData: ClickData | SelfPromotionData) => {
    const xitiPromise = trackXitiClickEvent(eventName, linkData).catch(() => {
        // ignore error
    })
    const pulsePromise = trackPulseClickEvent(eventName, linkData).catch(() => {
        // ignore error
    })

    await Promise.all([xitiPromise, pulsePromise])
}
