/* eslint-disable max-lines */
import { Box } from '@wh-components/core/Box/Box'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { Text } from '@wh-components/core/Text/Text'
import PictureSearchIcon from '@wh-components/icons/Camera'
import NewIcon from '@wh-components/icons/CatNewconstruction'
import EmployerIcon from '@wh-components/icons/Employer'
import FavoriteIcon from '@wh-components/icons/Favorite'
import MessagingIcon from '@wh-components/icons/Messaging'
import MyAdsIcon from '@wh-components/icons/Myads'
import ProfileIcon from '@wh-components/icons/Profile'
import SearchagentIcon from '@wh-components/icons/Searchagent'
import SearchHistoryIcon from '@wh-components/icons/Searchhistory'
import PersonalizationIcon from '@wh-components/icons/Personalization'
import TenantIcon from '@wh-components/icons/Tenant'
import { ClientRoutingAnchorLink, ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { AdInsertionLink } from '@wh/common/chapter/components/Header/AdInsertionLink'
import { MenuLinks } from '@wh/common/chapter/components/Header/menuLinks'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import appstore_header_badgeX2 from '@wh/common/chapter/static_hashed/img/badges/appstore_badge_white_header_x2.png'
import playstore_header_badgeX2 from '@wh/common/chapter/static_hashed/img/badges/playstore_badge_white_header_x2.png'
import React, { Fragment, FunctionComponent } from 'react'
import { css } from 'styled-components'
import { staticRelativeCanonicals } from '../../hocs/staticRelativeCanonicals'
import { OPEN_IMAGE_SEARCH_STORAGE_KEY } from '../../lib/localStorage'
import { storageAvailable } from '../../lib/storageAvailable'
import { verticals } from '../../types/verticals'
import PayLiveryIcon from '@wh-components/icons/Paylivery'

interface HeaderMenuProps {
    closeMenu: () => void
    vertical?: verticals
    showEditTenant?: boolean
    showMyJobAds?: boolean
    showPersonalization: boolean
    showMyTransactions: boolean
}

export const HeaderMenu: FunctionComponent<HeaderMenuProps> = ({
    closeMenu,
    vertical,
    showEditTenant,
    showMyJobAds,
    showPersonalization,
    showMyTransactions,
}) => (
    <Box height="100%" overflowY="scroll" tabIndex={-1}>
        <Box height={36} display="flex" alignItems="center" paddingHorizontal="m" backgroundColor="palette.polarbear">
            <Text fontSize="s" fontWeight="bold">
                Kategorien
            </Text>
        </Box>
        <MenuLinks onClick={closeMenu} isDeckView={false} />
        <Box paddingHorizontal="m" paddingVertical="s" backgroundColor="palette.polarbear">
            <AdInsertionLink onClick={closeMenu} height={40} width="100%" vertical={vertical} taggingEvent="header_click_insertAd">
                {vertical === verticals.JOB ? 'Jobanzeige aufgeben' : 'Neue Anzeige aufgeben'}
            </AdInsertionLink>
        </Box>
        <Box>
            <Box borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/myprofile/myadverts"
                    color="palette.verydarkgrey"
                    display="flex"
                    alignItems="center"
                    padding="m"
                    testId="header_click_myAds"
                    onClick={() => {
                        closeMenu()
                        return callActionEvent('header_click_myAds', undefined)
                    }}
                >
                    <MyAdsIcon size="small" marginRight="m" />
                    <Text fontSize="l">Meine Anzeigen</Text>
                </ClientRoutingAnchorLink>
            </Box>

            {showMyJobAds && (
                <Fragment>
                    <Box borderBottom="babyseal">
                        <ClientRoutingAnchorLink
                            type="anchor"
                            href="/iad/myprofile/myadverts"
                            color="palette.verydarkgrey"
                            display="flex"
                            alignItems="center"
                            paddingVertical="sm"
                            paddingLeft={52}
                            paddingRight="m"
                            testId="header_click_myAds"
                            onClick={() => {
                                closeMenu()
                                return callActionEvent('header_click_myAds', undefined)
                            }}
                        >
                            <Text fontSize="s">Meine Anzeigen</Text>
                        </ClientRoutingAnchorLink>
                    </Box>
                    <Box borderBottom="babyseal">
                        <ServerRoutingAnchorLink
                            type="anchor"
                            href="/jobs/arbeitgeber/meinjob#!/anzeigen"
                            color="palette.verydarkgrey"
                            display="flex"
                            alignItems="center"
                            paddingVertical="sm"
                            paddingLeft={52}
                            paddingRight="m"
                            testId="header_click_myJobAds"
                            onClick={() => {
                                closeMenu()
                                return callActionEvent('header_click_myJobAds', undefined)
                            }}
                        >
                            <Text fontSize="s">Meine Job Anzeigen</Text>
                        </ServerRoutingAnchorLink>
                    </Box>
                </Fragment>
            )}
            {showMyTransactions && (
                <Box borderBottom="babyseal">
                    <ClientRoutingAnchorLink
                        type="anchor"
                        href="/iad/myprofile/mytransactions/seller"
                        color="palette.verydarkgrey"
                        display="flex"
                        alignItems="center"
                        padding="m"
                        testId="header_click_editUser"
                        onClick={() => {
                            closeMenu()
                            return callActionEvent('header_click_myTransactions', undefined)
                        }}
                    >
                        <PayLiveryIcon size="small" marginRight="m" />
                        <Text fontSize="l">Meine Transaktionen</Text>
                        <NewIcon
                            size="small"
                            marginLeft="m"
                            css={css`
                                fill: ${(p) => p.theme.colors.palette.accent.secondary.main};
                            `}
                        />
                    </ClientRoutingAnchorLink>
                </Box>
            )}

            <Box borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/myprofile/myfindings"
                    color="palette.verydarkgrey"
                    display="flex"
                    alignItems="center"
                    padding="m"
                    testId="header_click_myFindings"
                    onClick={() => {
                        closeMenu()
                        return callActionEvent('header_click_myFindings', undefined)
                    }}
                >
                    <FavoriteIcon size="small" marginRight="m" />
                    <Text fontSize="l">Merkliste</Text>
                </ClientRoutingAnchorLink>
            </Box>
            <Box borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/myprofile/mysearches"
                    color="palette.verydarkgrey"
                    display="flex"
                    alignItems="center"
                    padding="m"
                    testId="header_click_mySearches"
                    onClick={() => {
                        closeMenu()
                        return callActionEvent('header_click_mySearches', undefined)
                    }}
                >
                    <SearchagentIcon size="small" marginRight="m" />
                    <Text fontSize="l">Suchagent</Text>
                </ClientRoutingAnchorLink>
            </Box>
            <Box borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/myprofile/mysearches"
                    color="palette.verydarkgrey"
                    display="flex"
                    alignItems="center"
                    paddingVertical="sm"
                    paddingLeft={52}
                    paddingRight="m"
                    testId="header_click_mySearches"
                    onClick={() => {
                        closeMenu()
                        return callActionEvent('header_click_mySearches', undefined)
                    }}
                >
                    <Text fontSize="s">Suchagent</Text>
                </ClientRoutingAnchorLink>
            </Box>
            <Box borderBottom="babyseal">
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="/jobs/meinjob/suchagenten"
                    color="palette.verydarkgrey"
                    display="flex"
                    alignItems="center"
                    paddingVertical="sm"
                    paddingLeft={52}
                    paddingRight="m"
                    testId="header_click_myJobSearchagents"
                    onClick={() => {
                        closeMenu()
                    }}
                >
                    <Text fontSize="s">Job Suchagent</Text>
                </ServerRoutingAnchorLink>
            </Box>
            <Box borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/myprofile/mylastsearches"
                    color="palette.verydarkgrey"
                    display="flex"
                    alignItems="center"
                    padding="m"
                    testId="header_click_myLastSearches"
                    onClick={() => {
                        closeMenu()
                        return callActionEvent('header_click_myLastSearches', undefined)
                    }}
                >
                    <SearchHistoryIcon size="small" marginRight="m" />
                    <Text fontSize="l">Suchverlauf</Text>
                </ClientRoutingAnchorLink>
            </Box>
            <Box borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/myprofile/chat"
                    color="palette.verydarkgrey"
                    display="flex"
                    alignItems="center"
                    padding="m"
                    testId="header_click_chat"
                    onClick={() => {
                        closeMenu()
                        return callActionEvent('header_click_chat', undefined)
                    }}
                >
                    <MessagingIcon size="small" marginRight="m" />
                    <Text fontSize="l">Nachrichten</Text>
                </ClientRoutingAnchorLink>
            </Box>
            {showEditTenant && (
                <Box borderBottom="babyseal">
                    <ClientRoutingAnchorLink
                        type="anchor"
                        href="/iad/myprofile/edittenant"
                        color="palette.verydarkgrey"
                        display="flex"
                        alignItems="center"
                        padding="m"
                        testId="header_click_editTenant"
                        onClick={() => {
                            closeMenu()
                            return callActionEvent('mywillhaben_edittenant', undefined)
                        }}
                    >
                        <TenantIcon size="small" marginRight="m" />
                        <Text fontSize="l">Mietprofil</Text>
                    </ClientRoutingAnchorLink>
                </Box>
            )}
            {showPersonalization && (
                <Box borderBottom="babyseal">
                    <ClientRoutingAnchorLink
                        type="anchor"
                        href="/iad/myprofile/personalization"
                        color="palette.verydarkgrey"
                        display="flex"
                        alignItems="center"
                        padding="m"
                        testId="header_click_personalization"
                        onClick={() => {
                            closeMenu()
                            return callActionEvent('header_usermenu_click_personalization', undefined)
                        }}
                    >
                        <PersonalizationIcon size="small" marginRight="m" />
                        <Text fontSize="l">Personalisierung</Text>
                        <NewIcon
                            size="small"
                            marginLeft="m"
                            css={css`
                                fill: ${(p) => p.theme.colors.palette.accent.secondary.main};
                            `}
                        />
                    </ClientRoutingAnchorLink>
                </Box>
            )}
            <Box borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/myprofile/edituser"
                    color="palette.verydarkgrey"
                    display="flex"
                    alignItems="center"
                    padding="m"
                    testId="header_click_editUser"
                    onClick={() => {
                        closeMenu()
                        return callActionEvent('header_click_editUser', undefined)
                    }}
                >
                    <ProfileIcon size="small" marginRight="m" />
                    <Text fontSize="l">Profildaten</Text>
                </ClientRoutingAnchorLink>
            </Box>
            <Box borderBottom="babyseal">
                <ServerRoutingAnchorLink
                    type="anchor"
                    href={staticRelativeCanonicals.BapHome}
                    color="palette.verydarkgrey"
                    display="flex"
                    alignItems="center"
                    padding="m"
                    testId="header_click_ImgSearch"
                    onClick={() => {
                        if (storageAvailable('localStorage')) {
                            localStorage.setItem(OPEN_IMAGE_SEARCH_STORAGE_KEY, '1')
                        }
                        callActionEvent('header_click_ImgSearch', undefined)
                    }}
                >
                    <PictureSearchIcon size="small" marginRight="m" />
                    <Text fontSize="l">Fashion-Cam</Text>
                </ServerRoutingAnchorLink>
            </Box>
            <Box borderBottom="babyseal">
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="/jobs/stelleninserate"
                    color="palette.verydarkgrey"
                    display="flex"
                    alignItems="center"
                    padding="m"
                    testId="header_click_employer"
                >
                    <EmployerIcon size="small" marginRight="m" />
                    <Text fontSize="l">Für Arbeitgeber:innen</Text>
                </ServerRoutingAnchorLink>
            </Box>
        </Box>
        <Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="https://hilfe.willhaben.at/"
                    color="palette.verydarkgrey"
                    testId="burgermenu-help-link"
                >
                    Hilfe und Kontakt
                </ServerRoutingAnchorLink>
            </Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                App kostenlos downloaden
                <Box display="flex" marginTop="s">
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://play.google.com/store/apps/details?id=at.willhaben"
                        target="_blank"
                        rel="nofollow noopener"
                        testId="playstoreLink"
                    >
                        <ResponsiveImage
                            src={playstore_header_badgeX2}
                            alt="Google Play Store Logo"
                            title="Jetzt kostenlos downloaden im Google Play Store"
                            height="30"
                            display="block"
                            loading="lazy"
                        />
                    </ServerRoutingAnchorLink>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://apps.apple.com/at/app/willhaben/id660325616?mt=8"
                        target="_blank"
                        rel="nofollow noopener"
                        marginLeft="s"
                        testId="appstoreLink"
                    >
                        <ResponsiveImage
                            src={appstore_header_badgeX2}
                            alt="Apple App Store Logo"
                            title="Jetzt kostenlos downloaden im Apple App Store"
                            height="30"
                            display="block"
                            loading="lazy"
                        />
                    </ServerRoutingAnchorLink>
                </Box>
            </Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="https://werbung.willhaben.at/"
                    color="palette.verydarkgrey"
                    testId="burgermenu-digitaladvertising-link"
                >
                    Werbung auf willhaben
                </ServerRoutingAnchorLink>
            </Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="https://jobsbei.willhaben.at/"
                    color="palette.verydarkgrey"
                    testId="burgermenu-jobs-link"
                >
                    Jobs bei willhaben
                </ServerRoutingAnchorLink>
            </Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="https://tech.willhaben.at/"
                    color="palette.verydarkgrey"
                    testId="burgermenu-tech-blog-link"
                >
                    Tech Blog
                </ServerRoutingAnchorLink>
            </Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="https://www.willhaben.at/will/blog"
                    color="palette.verydarkgrey"
                    testId="burgermenu-willhaben-blog-link"
                >
                    willhaben Blog
                </ServerRoutingAnchorLink>
            </Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/nutzungsbedingungen"
                    color="palette.verydarkgrey"
                    testId="burgermenu-termsofuse-link"
                    onClick={closeMenu}
                >
                    Nutzungsbedingungen
                </ClientRoutingAnchorLink>
            </Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/impressum"
                    color="palette.verydarkgrey"
                    testId="burgermenu-impress-link"
                    onClick={closeMenu}
                >
                    Impressum
                </ClientRoutingAnchorLink>
            </Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/agb"
                    color="palette.verydarkgrey"
                    testId="burgermenu-termsandconditions-link"
                    onClick={closeMenu}
                >
                    AGB
                </ClientRoutingAnchorLink>
            </Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href="/iad/datenschutzerklaerung"
                    color="palette.verydarkgrey"
                    testId="burgermenu-data-privacy-link"
                    onClick={closeMenu}
                >
                    Datenschutzerklärung
                </ClientRoutingAnchorLink>
            </Box>
            <Box paddingHorizontal="m" paddingVertical="sm" borderBottom="babyseal">
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="https://datenschutz.willhaben.at/"
                    color="palette.verydarkgrey"
                    testId="burgermenu-mydata-link"
                >
                    Meine Daten
                </ServerRoutingAnchorLink>
            </Box>
        </Box>
        <Box height={46} display="flex" alignItems="center" paddingHorizontal="m">
            <Text fontSize="s" color="palette.elephant">
                © {new Date().getFullYear()} willhaben
            </Text>
        </Box>
    </Box>
)
