import React, { FunctionComponent } from 'react'
import { AnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { MenuLinkProps } from './menuLinkTypes'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import ArrowRightIcon from '@wh-components/icons/ArrowRight'

export const MenuLinkDeckView: FunctionComponent<MenuLinkProps> = ({ item, nrOfAdverts, onClick }) => (
    <Box borderBottom="babyseal">
        <AnchorLink
            clientSideRouting={item.clientSideRouting}
            type="anchor"
            href={item.href}
            color="palette.verydarkgrey"
            underline="none"
            display="flex"
            alignItems="center"
            paddingVertical="sm"
            paddingHorizontal="m"
            onClick={onClick}
            testId={String(item.verticalId)}
        >
            {item.icon}
            <Text fontSize="l">{item.text}</Text>
            <Box flexGrow="1" />
            <Text fontSize="s" color="palette.elephant">
                {nrOfAdverts}
            </Text>
            <ArrowRightIcon size="xsmall" marginLeft="s" />
        </AnchorLink>
    </Box>
)
