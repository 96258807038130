export const OPEN_CREATE_SEARCH_AGENT_MODAL_STORAGE_KEY = 'openCreateSearchAgentModal'
export const OPEN_CREATE_JOB_SEARCH_AGENT_MODAL_STORAGE_KEY = 'openCreateJobSearchAgentModal'
export const OPEN_COMPANY_FOLLOW_MODAL_STORAGE_KEY = 'openCompanyFollowModalStorageKey'
export const OPEN_SAVE_AD_MODAL_STORAGE_KEY = 'openSaveAdModal'
export const OPEN_SAVE_JOB_AD_MODAL_STORAGE_KEY = 'openSaveJobAdModal'
export const OPEN_IMAGE_SEARCH_STORAGE_KEY = 'openImageSearchModal'
export const REDIRECT_AFTER_VERIFY_EMAIL_STORAGE_KEY = 'redirectAfterVerifyEmail'
export const SAVED_SEARCH_RESULT_STORAGE_KEY = 'savedSearchResult2'
export const META_TAGS_LOCAL_STORAGE_KEY = 'metatags'
export const OPEN_FOLLOW_USER_MODAL_STORAGE_KEY = 'openFollowUserModal'
export const ENGINE_EFFECT_NAVIGATOR_UNIT = 'engineEffectNavigatorUnit'
export const SEARCH_RESULT_LAST_KEYWORDS = 'searchResultLastKeywords'
export const MYADS_DISMISS_EXPIRY_KEY = 'payliveryMyadsOneClickBannerDismissExpiry'
export const MYCHAT_PAYLIVERY_DEFAULT_DISMISS_EXPIRY_KEY = 'payliveryMyChatOneClickBannerDismissExpiry'
export const MYCHAT_JOBS_SKYSCRAPER_DISMISS_EXPIRY_KEY = 'jobsMyChatSkyScraperOneClickBannerDismissExpiry'
export const MYCHAT_SHIPPING_CAMPAIGN_SKYSCRAPER_DISMISS_EXPIRY_KEY = 'myChatSkypscraperDismissExpiry'
export const MYCHAT_JOBS_CHAT_BANNER_DISMISS_EXPIRY_KEY = 'jobsMyChatOneClickBannerDismissExpiry'
export const MYCHAT_PHISHING_INFO_CHAT_BANNER_DISMISS_EXPIRY_KEY = 'phishingInfoMyChatOneClickBannerDismissExpiry'
export const LOGGED_IN_HEADER_RENDER_COUNT = 'loggedInHeaderRenderCount'
export const GEO_LOCATION_GRANTED = 'geoLocationGranted'
export const JOBS_SAVED_SEARCH_RESULT_STORAGE_KEY = 'jobsSavedSearchResult'
export const PHONE_VERIFICATION_LAST_SUCCESSFUL_ONE_TIME_PASSWORD_REQUEST = 'phoneVerificationLastSuccessfulRequest'
export const EMAIL_RESET_LAST_SUCCESSFUL_ONE_TIME_PASSWORD_REQUEST = 'emailResetByOneTimePasswordLastSuccessfulRequest'
export const DA_CONFIG_LAST_SUCCESSFUL_LOADED_VERSION = 'daConfigLastSuccessfulLoadedVersion'
export const MORTGAGE_CALCULATOR = 'mortgageCalculator'
