import { BbxCookie, BbxCookieName, getUniversalBbxCookie } from '../types/cookies'

export const getCsrfTokenHeader = (): { [key in BbxCookieName]?: string } => {
    const csrfToken = getUniversalBbxCookie(BbxCookie.CSRF_TOKEN, undefined)

    return csrfToken
        ? {
              [BbxCookie.CSRF_TOKEN]: csrfToken,
          }
        : {}
}
