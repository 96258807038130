import { commenceBackgroundAuthenticationFlow } from '../../authentication/backgroundAuthenticationService'
import { isClient, isServer } from '../lib/commonHelpers'
import { getRestApiRoot } from '../lib/config/runtimeConfig'
import { getCsrfTokenHeader } from '../lib/csrf'
import {
    ApiError,
    ApiErrorResponse,
    isApiErrorResponse,
    isJobsApiValidationErrorResponse,
    isSsoErrorResponse,
    mapJobsValidationErrorsToApiErrorResponse,
} from '../lib/errors'
import { BbxCookieName, BbxCookie, BbxRequestCookies, getUniversalBbxCookie } from '../types/cookies'
import { getHeaderValue } from '../types/headers'
import { NextRequest } from '../types/nextJS'
import { headerObjectFromResponse } from './headerObjectFromResponse'

export interface ErrorResponse {
    statusCode?: number
    message: string
}

declare global {
    interface Window {
        forceReload: boolean
    }

    // eslint-disable-next-line no-var
    var currentScreenSize: 'desktop' | 'tablet' | 'phone'
}

export const fetcher = async <Payload = void>(
    url: string,
    { cookies, ...options }: RequestInit & { cookies?: BbxRequestCookies } = {},
    deserializer?: (response: Response) => Promise<Payload>,
    isLoginRetry = false,
): Promise<Payload> => {
    const fullUrl = `${getRestApiRoot()}${url}`
    const response = await fetch(fullUrl, {
        ...options,
        headers: {
            Accept: 'application/json',
            // Content-Type cannot not be set when sending multipart/form-data, otherwise POSTing will fail
            ...(typeof options.body === 'string' ? { 'Content-Type': 'application/json' } : undefined),
            ...(isServer() ? { 'Accept-Encoding': 'gzip,deflate,sdch' } : undefined),
            ...{ ['X-WH-Client']: `api@willhaben.at;responsive_web;server;1.0.0;${globalThis.currentScreenSize}` },
            ...(isServer() ? getCookieHeaderOfNecessaryBbxCookies(cookies) : {}),
            ...(isClient() && options.method !== 'GET' ? getCsrfTokenHeader() : {}),
            ...options.headers,
        },
    }).catch((error: Error) => {
        throw apiErrorFromFetchResponse(error, undefined, undefined, fullUrl)
    })

    if (isClient() && response.status === 401 && !isLoginRetry) {
        await commenceBackgroundAuthenticationFlow({ redirectToLogin: true })

        // will implicitly use the updated cookies and csrf token
        return await fetcher<Payload>(url, { cookies, ...options }, deserializer, true)
    }

    if (!response.ok) {
        // gracefully ignore json deserialization errors for error responses to get a proper ApiError
        const errorJson = (await response.json().catch(() => undefined)) as unknown
        throw apiErrorFromFetchResponse(undefined, response, errorJson, fullUrl)
    }

    if (deserializer) {
        return deserializer(response)
    } else {
        // workaround since `.json()` would throw on empty responses
        // if `text.length` is 0, assume that Payload is `void` since we cannot check typescript generics at runtime
        const json = response.text().then((text) => (text.length ? (JSON.parse(text) as Payload) : (undefined as Payload)))

        return json
    }
}

export const apiErrorFromFetchResponse = (
    error: Error | undefined,
    response: Response | undefined,
    responseJson: unknown,
    url: string,
): ApiError => {
    let errorMessage = `API request failed for ${url ?? 'unknown'}${error ? `: ${error.message}` : ''}`
    if (response) {
        // Request was made but server responded with something other than 2xx
        errorMessage += `\n\tStatus: ${response.status}`
        errorMessage += `\n\tData: ${responseJson}`
    }

    let errorResponse: ApiErrorResponse | undefined
    if (isApiErrorResponse(responseJson)) {
        errorResponse = responseJson
    } else if (isSsoErrorResponse(responseJson)) {
        errorResponse = { title: 'Leider ist ein Fehler aufgetreten', message: responseJson.errorMessage }
    } else if (isJobsApiValidationErrorResponse(responseJson)) {
        errorResponse = mapJobsValidationErrorsToApiErrorResponse(responseJson)
    }

    const headers = headerObjectFromResponse(response)

    return new ApiError(errorMessage, errorResponse, response?.status, headers, error?.name === 'AbortError')
}

// for SSR requests we need to manually inject the some cookies since client cookies are not automatically passed
const getCookieHeaderOfNecessaryBbxCookies = (cookies?: BbxRequestCookies): { cookie?: string } => {
    if (!cookies) {
        return {}
    }

    const cookieNames: BbxCookieName[] = [BbxCookie.IADVISITOR, BbxCookie.APPLICATION_TOKEN, BbxCookie.CSRF_TOKEN, BbxCookie.BBX_JSESSIONID]

    const cookieHeaderValue = cookieNames
        .map((name: BbxCookieName) => cookieToHeaderValue(name, cookies?.[name]))
        .filter((value?: string) => value)
        .join(' ')

    return {
        cookie: cookieHeaderValue,
    }
}

const cookieToHeaderValue = (name: BbxCookieName, value: string | undefined): string | undefined => {
    if (!value) {
        return undefined
    }

    return `${name}=${value};`
}

export const getXForwardForHeader = (request?: NextRequest): { 'X-Forwarded-For': string } | {} => {
    const xForwardedFor = getHeaderValue(request, 'X-Forwarded-For')
    return xForwardedFor
        ? {
              ['X-Forwarded-For']: xForwardedFor,
          }
        : {}
}

/*
 This function is needed for the server-side post request of cdcApiClient and jobsApiClient
 I currently don't understand why it's necessary and why the "getCookieHeaderOfNecessaryBbxCookies"
 in baseApiClientRaw is not sufficient
 */
export const getCsrfHeader = (request?: NextRequest): Record<string, string> => {
    const csrfToken = getUniversalBbxCookie(BbxCookie.CSRF_TOKEN, request)

    return csrfToken
        ? {
              [BbxCookie.CSRF_TOKEN]: csrfToken,
          }
        : {}
}
