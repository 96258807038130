/* eslint-disable max-lines */
import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    PageToAdvertisingMessage,
    PageToDataMessage,
} from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageProtocols'
import {
    hasCorrectAdvertisingProtocol,
    sanitizeAdvertisingToPageMessage,
} from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageDecoder'
import { captureException } from '@wh/common/chapter/components/Sentry/sentry'
import { AdvertisingParametersV2 } from '@wh/common/digital-advertising/types/advertising-parametersV2'
import { useRouter } from 'next/router'
import {
    undeprecatedBackgroundTransparentTarget,
    undeprecatedCloseAdSlotTarget,
    undeprecatedTopStyle,
} from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingStateDeprecationHelper'
import { AdvertisingPageModificationsState } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingPageModificationsState'
import { useSyncDmpSegmentsToCookie } from './useSyncDmpSegmentsToCookie'

export interface AdvertisingState {
    onReset: () => void
    onSetCurrentAdvertisingParameters: (advertisingParametersV2: AdvertisingParametersV2) => void
    onSetCurrentLeaderboardTop: (leaderboardTop: number) => void
    currentLeaderboardTop: number
    pageModifications: AdvertisingPageModificationsState
}

const fallbackLeaderboardTop = 116
const motorSearchEntryAreaBottomDefaultHeight = 250
const motorSearchEntryAreaBottomMaxHeight = 350
const estateSearchEntryAreaBottomDefaultHeight = 250
const estateSearchEntryAreaBottomMaxHeight = 350
const bapSearchEntryAreaBottomDefaultHeight = 250
const bapSearchEntryAreaBottomMaxHeight = 350
const bapSearchEntryPopularCategoriesIframeDefaultHeight = 250
const bapSearchEntryPopularCategoriesIframeMaxHeight = 350

export const AdvertisingStateContext = React.createContext<AdvertisingState>({
    onReset: () => {},
    onSetCurrentAdvertisingParameters: (_) => {},
    onSetCurrentLeaderboardTop: (_) => {},
    currentLeaderboardTop: fallbackLeaderboardTop,
    pageModifications: {},
})

// this is extracted as a constant on purpose, as it will be used in hook deps which are compared by reference, and thus save rerenders
const initialPageModificationState: AdvertisingPageModificationsState = {}

export const AdvertisingStateProvider: FunctionComponent<PropsWithChildren<{}>> = (props) => {
    const [tmpPageModifications, setTmpPageModifications] = useState<AdvertisingPageModificationsState>(initialPageModificationState)
    const [pageModifications, setPageModifications] = useState<AdvertisingPageModificationsState>(initialPageModificationState)
    const [currentAdvertisingParameters, setCurrentAdvertisingParameters] = useState<AdvertisingParametersV2>({})
    const [currentLeaderboardTop, setCurrentLeaderboardTop] = useState<number>(fallbackLeaderboardTop)

    // avoid unnecessary rerenders when currentAdvertisingParameters change
    const currentAdvertisingParametersRef = useRef(currentAdvertisingParameters)
    currentAdvertisingParametersRef.current = currentAdvertisingParameters

    const messageListener = useCallback(
        (event: MessageEvent) => {
            handleMessage(
                event,
                tmpPageModifications,
                setTmpPageModifications,
                setPageModifications,
                currentAdvertisingParametersRef.current,
            )
        },
        [tmpPageModifications, setPageModifications, setTmpPageModifications, currentAdvertisingParametersRef],
    )

    const onReset = useCallback(() => {
        setTmpPageModifications(initialPageModificationState)
        setPageModifications(initialPageModificationState)
    }, [setPageModifications, setTmpPageModifications])

    const router = useRouter()
    const routerRef = useRef(router)
    routerRef.current = router

    // resetting the advertising state on client side navigation is a safety measure to guarantee cleanup (the useDigitalAdvertising hook cleans up too on unmount, but the reset here is a safety net)
    useEffect(() => {
        const handleRouteChange = () => {
            onReset()
        }
        routerRef.current.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            routerRef.current.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [onReset])

    useEffect(() => {
        addEventListener('message', messageListener, false)
        return () => removeEventListener('message', messageListener, false)
    }, [messageListener])

    useSyncDmpSegmentsToCookie()

    const state: AdvertisingState = useMemo(() => {
        return {
            onReset: onReset,
            onSetCurrentAdvertisingParameters: setCurrentAdvertisingParameters,
            onSetCurrentLeaderboardTop: setCurrentLeaderboardTop,
            currentLeaderboardTop: currentLeaderboardTop,
            pageModifications: pageModifications,
        }
    }, [onReset, pageModifications, setCurrentAdvertisingParameters, currentLeaderboardTop, setCurrentLeaderboardTop])

    return <AdvertisingStateContext.Provider value={state}>{props.children}</AdvertisingStateContext.Provider>
}

const handleMessage = (
    event: MessageEvent,
    tmpPageModifications: AdvertisingPageModificationsState,
    setTmpPageModifications: (value: (prevState: AdvertisingPageModificationsState) => AdvertisingPageModificationsState) => void,
    setPageModifications: (value: (prevState: AdvertisingPageModificationsState) => AdvertisingPageModificationsState) => void,
    currentAdvertisingParameters: AdvertisingParametersV2,
) => {
    // validate the event origin to mitigate the possiblilty of XSS attacks
    if (
        ![
            window.location.origin,
            'https://preview.animotion.agency',
            'https://cdn.animotion.agency',
            'https://cdn.publishr.studio',
        ].includes(event.origin)
    ) {
        return
    }

    const adMessage = sanitizeAdvertisingToPageMessage(event.data)

    if (!adMessage) {
        if (hasCorrectAdvertisingProtocol(event.data)) {
            captureException(new Error(`could not parse ad-wh-v1 message: ${JSON.stringify(event.data)}`), undefined, 'warning')
        }
        return
    }

    switch (adMessage.type) {
        case 'apply':
            setPageModifications(() => tmpPageModifications)
            break
        case 'set-header-gradient':
            setTmpPageModifications((previousPageModifications) => {
                return {
                    ...previousPageModifications,
                    headerGradient: true,
                }
            })
            break
        case 'set-background-transparent':
            setTmpPageModifications((previousPageModifications) => {
                return {
                    ...previousPageModifications,
                    // directly setting it causes a second message with fewer targets to make areas non-transparent again
                    backgroundTransparent: undeprecatedBackgroundTransparentTarget(adMessage.target),
                }
            })
            break
        case 'set-background-color':
            setTmpPageModifications((previousPageModifications) => {
                return {
                    ...previousPageModifications,
                    backgroundColors: {
                        ...previousPageModifications.backgroundColors,
                        [adMessage.target]: adMessage.color,
                    },
                }
            })
            break
        case 'set-foreground-color':
            setTmpPageModifications((previousPageModifications) => {
                return {
                    ...previousPageModifications,
                    foregroundColors: {
                        ...previousPageModifications.foregroundColors,
                        [adMessage.target]: adMessage.color,
                    },
                }
            })
            break
        case 'set-border': {
            const active = 'active' in adMessage ? adMessage.active ?? true : true
            if (!active) {
                setTmpPageModifications((previousPageModifications) => {
                    return {
                        ...previousPageModifications,
                        borders: { ...previousPageModifications.borders, [adMessage.target]: undefined },
                    }
                })
            } else {
                switch (adMessage.target) {
                    case 'main-vertical-navigation':
                    case 'tab-active':
                    case 'tab-inactive':
                    case 'tab-hover':
                    case 'motor-search-box':
                    case 'estate-search-box':
                    case 'bap-search-box-parent-container':
                    case 'bap-shop-widget':
                    case 'bap-category-lines':
                    case 'startpage-body':
                    case 'startpage-verticals-widget':
                    case 'startpage-nearby-widget':
                        setTmpPageModifications((previousPageModifications) => {
                            return {
                                ...previousPageModifications,
                                borders: { ...previousPageModifications.borders, [adMessage.target]: adMessage.color },
                            }
                        })
                        break
                    case 'motor-search-box-search-button':
                        setTmpPageModifications((previousPageModifications) => {
                            return {
                                ...previousPageModifications,
                                borders: {
                                    ...previousPageModifications.borders,
                                    'motor-search-box-search-button': active,
                                },
                            }
                        })
                        break
                    case 'estate-search-box-search-button':
                        setTmpPageModifications((previousPageModifications) => {
                            return {
                                ...previousPageModifications,
                                borders: {
                                    ...previousPageModifications.borders,
                                    'estate-search-box-search-button': active,
                                },
                            }
                        })
                        break
                    case 'header-and-content-separator':
                        setTmpPageModifications((previousPageModifications) => {
                            return {
                                ...previousPageModifications,
                                borders: {
                                    ...previousPageModifications.borders,
                                    'header-and-content-separator': { color: adMessage.color, width: adMessage.width },
                                },
                            }
                        })
                        break
                    default:
                        break
                }
            }
            break
        }
        case 'close-ad-slot':
            setTmpPageModifications((previousPageModifications) => {
                return {
                    ...previousPageModifications,
                    closedAdSlots: [...(previousPageModifications.closedAdSlots || []), ...undeprecatedCloseAdSlotTarget(adMessage.target)],
                }
            })
            break
        case 'change-ad-slot-style':
            switch (adMessage.target) {
                case 'apn-large-skyscraper':
                case 'apn-skyscraper':
                    switch (adMessage.style) {
                        case 'sbar':
                            setTmpPageModifications((previousPageModifications) => {
                                return {
                                    ...previousPageModifications,
                                    apnSkyscraperStyle: {
                                        baseStyle: 'sbar',
                                        topStyle: undeprecatedTopStyle(adMessage.topStyle),
                                        leftStyle: adMessage.leftStyle,
                                    },
                                }
                            })
                            break
                        case 'hpa':
                            setTmpPageModifications((previousPageModifications) => {
                                return {
                                    ...previousPageModifications,
                                    apnSkyscraperStyle: {
                                        baseStyle: 'hpa',
                                        topStyle: undeprecatedTopStyle(adMessage.topStyle),
                                        leftStyle: adMessage.leftStyle,
                                    },
                                }
                            })
                            break
                        case 'sky':
                            setTmpPageModifications((previousPageModifications) => {
                                return {
                                    ...previousPageModifications,
                                    apnSkyscraperStyle: {
                                        baseStyle: 'sky',
                                        topStyle: undeprecatedTopStyle(adMessage.topStyle),
                                        leftStyle: adMessage.leftStyle,
                                    },
                                }
                            })
                            break
                        case 'lad':
                            setTmpPageModifications((previousPageModifications) => {
                                return {
                                    ...previousPageModifications,
                                    apnSkyscraperStyle: {
                                        baseStyle: 'lad',
                                        topStyle: undeprecatedTopStyle(adMessage.topStyle),
                                        leftStyle: adMessage.leftStyle,
                                    },
                                }
                            })
                            break
                        case 'custom':
                            setTmpPageModifications((previousPageModifications) => {
                                const rightOrWidth = (() => {
                                    if ('marginRight' in adMessage) {
                                        return { marginRight: adMessage.marginRight }
                                    } else {
                                        return { width: adMessage.width }
                                    }
                                })()
                                const bottomOrHeight = (() => {
                                    if ('marginBottom' in adMessage) {
                                        return { marginBottom: adMessage.marginBottom }
                                    } else {
                                        return { height: adMessage.height }
                                    }
                                })()
                                return {
                                    ...previousPageModifications,
                                    apnSkyscraperStyle: {
                                        baseStyle: 'custom',
                                        position: adMessage.position,
                                        marginLeft: adMessage.marginLeft,
                                        marginTop: adMessage.marginTop,
                                        ...rightOrWidth,
                                        ...bottomOrHeight,
                                    },
                                }
                            })
                            break
                        default:
                            break
                    }
                    break
                default:
                    break
            }
            break
        case 'set-iframe-content':
            switch (adMessage.target) {
                case 'background':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            backgroundIFrame: { src: adMessage.src, position: adMessage.position },
                        }
                    })
                    break
                case 'overlay':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            overlayIFrame: { src: adMessage.src, position: adMessage.position },
                        }
                    })
                    break
                case 'motor-small-leaderboard':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            motorSmallLeaderboardIFrame: {
                                src: adMessage.src,
                            },
                        }
                    })
                    break
                case 'motor-search-box-right':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            motorSearchBoxRightIFrame: { src: adMessage.src },
                        }
                    })
                    break
                case 'motor-search-entry-area-bottom':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            motorSearchEntryAreaBottomIFrame: {
                                type: 'one',
                                src: adMessage.src,
                                height: Math.min(
                                    adMessage.height ?? motorSearchEntryAreaBottomDefaultHeight,
                                    motorSearchEntryAreaBottomMaxHeight,
                                ),
                            },
                        }
                    })
                    break
                case 'motor-search-entry-area-bottom-left':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            motorSearchEntryAreaBottomIFrame: {
                                type: 'two',
                                left: {
                                    src: adMessage.src,
                                    height: Math.min(
                                        adMessage.height ?? motorSearchEntryAreaBottomDefaultHeight,
                                        motorSearchEntryAreaBottomMaxHeight,
                                    ),
                                },
                                right:
                                    previousPageModifications.motorSearchEntryAreaBottomIFrame?.type === 'two'
                                        ? previousPageModifications.motorSearchEntryAreaBottomIFrame.right
                                        : undefined,
                            },
                        }
                    })
                    break
                case 'motor-search-entry-area-bottom-right':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            motorSearchEntryAreaBottomIFrame: {
                                type: 'two',
                                left:
                                    previousPageModifications.motorSearchEntryAreaBottomIFrame?.type === 'two'
                                        ? previousPageModifications.motorSearchEntryAreaBottomIFrame.left
                                        : undefined,
                                right: {
                                    src: adMessage.src,
                                    height: Math.min(
                                        adMessage.height ?? motorSearchEntryAreaBottomDefaultHeight,
                                        motorSearchEntryAreaBottomMaxHeight,
                                    ),
                                },
                            },
                        }
                    })
                    break
                case 'estate-small-leaderboard':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            estateSmallLeaderboardIFrame: {
                                src: adMessage.src,
                            },
                        }
                    })
                    break
                case 'startpage-teaser-large':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            startPageTeaserIFrame: {
                                src: adMessage.src,
                            },
                        }
                    })
                    break
                case 'startpage-teaser-small':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            startPageTeaserIFrameSmall: {
                                src: adMessage.src,
                                height: adMessage.height ?? 0,
                            },
                        }
                    })
                    break
                case 'startpage-leaderboard-small':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            startPageLeaderboardIFrameSmall: {
                                src: adMessage.src,
                                height: adMessage.height ?? 0,
                            },
                        }
                    })
                    break
                case 'startpage-leaderboard':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            startPageLeaderboard: {
                                src: adMessage.src,
                                height: adMessage.height ?? 0,
                            },
                        }
                    })
                    break
                case 'estate-search-box-right':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            estateSearchBoxRightIFrame: { src: adMessage.src },
                        }
                    })
                    break
                case 'estate-search-entry-area-bottom':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            estateSearchEntryAreaBottomIFrame: {
                                type: 'one',
                                src: adMessage.src,
                                height: Math.min(
                                    adMessage.height ?? estateSearchEntryAreaBottomDefaultHeight,
                                    estateSearchEntryAreaBottomMaxHeight,
                                ),
                            },
                        }
                    })
                    break
                case 'estate-search-entry-area-bottom-left':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            estateSearchEntryAreaBottomIFrame: {
                                type: 'two',
                                left: {
                                    src: adMessage.src,
                                    height: Math.min(
                                        adMessage.height ?? estateSearchEntryAreaBottomDefaultHeight,
                                        estateSearchEntryAreaBottomMaxHeight,
                                    ),
                                },
                                right:
                                    previousPageModifications.estateSearchEntryAreaBottomIFrame?.type === 'two'
                                        ? previousPageModifications.estateSearchEntryAreaBottomIFrame.right
                                        : undefined,
                            },
                        }
                    })
                    break
                case 'estate-search-entry-area-bottom-right':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            estateSearchEntryAreaBottomIFrame: {
                                type: 'two',
                                left:
                                    previousPageModifications.estateSearchEntryAreaBottomIFrame?.type === 'two'
                                        ? previousPageModifications.estateSearchEntryAreaBottomIFrame.left
                                        : undefined,
                                right: {
                                    src: adMessage.src,
                                    height: Math.min(
                                        adMessage.height ?? estateSearchEntryAreaBottomDefaultHeight,
                                        estateSearchEntryAreaBottomMaxHeight,
                                    ),
                                },
                            },
                        }
                    })
                    break
                case 'bap-search-box-right':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            bapSearchBoxRightIFrame: { src: adMessage.src },
                        }
                    })
                    break
                case 'bap-search-entry-area-bottom': // iframe below the bap vertical home search box - covers the whole content width - if this is set, neither 'bap-search-entry-area-bottom-left' nor 'bap-search-entry-area-bottom-right' are displayed
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            bapSearchEntryAreaBottomIFrame: {
                                type: 'one',
                                src: adMessage.src,
                                height: Math.min(
                                    adMessage.height ?? bapSearchEntryAreaBottomDefaultHeight,
                                    bapSearchEntryAreaBottomMaxHeight,
                                ),
                            },
                        }
                    })
                    break
                case 'bap-search-entry-area-bottom-left': // left one of two iframes below the bap vertical home search box that are shown next to each other - if this and 'bap-search-entry-area-bottom-right' are set, 'bap-search-entry-area-bottom' is not displayed
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            bapSearchEntryAreaBottomIFrame: {
                                type: 'two',
                                left: {
                                    src: adMessage.src,
                                    height: Math.min(
                                        adMessage.height ?? bapSearchEntryAreaBottomDefaultHeight,
                                        bapSearchEntryAreaBottomMaxHeight,
                                    ),
                                },
                                right:
                                    previousPageModifications.bapSearchEntryAreaBottomIFrame?.type === 'two'
                                        ? previousPageModifications.bapSearchEntryAreaBottomIFrame.right
                                        : undefined,
                            },
                        }
                    })
                    break
                case 'bap-search-entry-area-bottom-right': // right one of two iframes below the bap vertical home search box that are shown next to each other - if this and 'bap-search-entry-area-bottom-left' are set, 'bap-search-entry-area-bottom' is not displayed
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            bapSearchEntryAreaBottomIFrame: {
                                type: 'two',
                                left:
                                    previousPageModifications.bapSearchEntryAreaBottomIFrame?.type === 'two'
                                        ? previousPageModifications.bapSearchEntryAreaBottomIFrame.left
                                        : undefined,
                                right: {
                                    src: adMessage.src,
                                    height: Math.min(
                                        adMessage.height ?? bapSearchEntryAreaBottomDefaultHeight,
                                        bapSearchEntryAreaBottomMaxHeight,
                                    ),
                                },
                            },
                        }
                    })
                    break
                case 'bap-small-leaderboard':
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            bapSmallLeaderboardIFrame: {
                                src: adMessage.src,
                            },
                        }
                    })
                    break
                case 'bap-search-entry-popular-categories': // below the search box, before "Alle Kategorien". Replaces "Beliebte Kategorien" and "Beliebte Mode-Marken", if set. Supports height (default: 250, max: 350).
                    setTmpPageModifications((previousPageModifications) => {
                        return {
                            ...previousPageModifications,
                            bapSearchEntryPopularCategoriesIframe: {
                                src: adMessage.src,
                                height: Math.min(
                                    adMessage.height ?? bapSearchEntryPopularCategoriesIframeDefaultHeight,
                                    bapSearchEntryPopularCategoriesIframeMaxHeight,
                                ),
                            },
                        }
                    })
                    break
                default:
                    break
            }
            break
        case 'request-advertising-parameters': {
            if (!event.source) {
                console.error('event.source is null, cannot send advertising parameter response via postMessage')
                return
            }

            const response: PageToAdvertisingMessage = {
                protocol: 'wh-ad-v1',
                type: 'advertising-parameters-response',
                parameters: currentAdvertisingParameters,
            }
            sendPostMessage(event, response)
            break
        }
        case 'request-data': {
            if (!event.source) {
                console.error('event.source is null, cannot send advertising parameter response via postMessage')
                return
            }

            const response: PageToDataMessage = {
                protocol: 'wh-ad-v1',
                type: 'data-response',
                parameters: {
                    scrollY: window.scrollY,
                },
            }

            sendPostMessage(event, response)
            break
        }
        default:
            // ignore unknown message type
            break
    }
}

const sendPostMessage = (event: MessageEvent, response: PageToAdvertisingMessage | PageToDataMessage) => {
    try {
        ;(event.source as typeof window).postMessage(response, event.origin)
    } catch (e) {
        console.error(`error sending response via postMessage: ${e}`)
    }
}
