import { StoryblokDefaultProps } from '@wh/common/cms/types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { AdvertSummarySkeletons } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummarySkeleton'
import { getStoryblokStory } from '@wh/common/cms/api/storyblokIadProxyClient'
import { isPromiseFulfilledResult } from '@wh/common/chapter/lib/promiseSettledResult'
import { TrendSlider } from '@bbx/search-journey/sub-domains/start-page/components/widget/TrendSlider'
import { StoryblokAdsSource } from '@wh/common/cms/types/StoryblokAdsSource'
import { mapTrendSliderStories } from './mapTrendSliderStories'
import { StoryblokAsset } from '@wh/common/cms/types/built-in/StoryblokAsset'
import { css } from 'styled-components'

export interface StoryblokTrendSlider extends StoryblokComponentType<'trendSliderInContentPage'> {
    trendSliders: string[]
}

export interface StoryblokTrendSliderContent extends StoryblokComponentType<'cdcTrendSliderContent'> {
    title: string
    subTitle: string
    adsSource: StoryblokAdsSource
    taggingId: string
    dmpSegments: number[]
    image: StoryblokAsset
}

interface StoryblokTrendSliderProps extends StoryblokDefaultProps<StoryblokTrendSlider>, BoxProps {}

export const StoryblokTrendSlider: FunctionComponent<StoryblokTrendSliderProps> = ({ blok, story: _story, ...props }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [trendSliderStories, setTrendSliderStories] = useState<StoryblokTrendSliderContent[] | undefined>(undefined)

    useEffect(() => {
        const loadStories = async () => {
            const promises = blok.trendSliders.map((sliderUuid) => getStoryblokStory(sliderUuid))

            const stories = (await Promise.allSettled(promises))
                .filter(isPromiseFulfilledResult)
                .flatMap((promiseResult) => promiseResult?.value?.story?.content as unknown as StoryblokTrendSliderContent)

            setTrendSliderStories(stories)
            setIsLoading(false)
        }

        loadStories()
    }, [blok.trendSliders])

    if (isLoading) {
        return (
            <Box display="flex" flexDirection="column" gap="m">
                {blok.trendSliders.map((_slider, index) => (
                    <AdvertSummarySkeletons
                        variant="portrait"
                        key={index}
                        css={css`
                            ${(p) => p.theme.media.only.phone} {
                                margin-right: -${(p) => p.theme.space.m}px;
                            }
                        `}
                    />
                ))}
            </Box>
        )
    }

    if (!trendSliderStories) {
        return null
    }

    const trendSliders = mapTrendSliderStories(trendSliderStories)

    return (
        <Box display="flex" flexDirection="column" gap="m" {...storyblokEditable(blok as unknown as SbBlokData)} {...props}>
            {trendSliders?.map((trendSlider) => (
                /**
                 * The <Horizontal-Slider has hardcoded the negative margin on the left side because
                 * all our pages where we use it have a padding on the left side and no padding on the right side (on mobile)
                 * The content pages (<StoryblokBlogPost>) have a padding on both sides
                 * To have the scrolling container edge to edge, we need to add the negative margin on the right side here
                 */
                <Box
                    key={trendSlider.taggingId}
                    css={css`
                        ${(p) => p.theme.media.only.phone} {
                            margin-right: -${(p) => p.theme.space.m}px;
                        }
                    `}
                >
                    <TrendSlider trendSlider={trendSlider} isInView={true} source="content-page" page="Contentpage" />
                </Box>
            ))}
        </Box>
    )
}
