export interface ContextLinkList {
    contextLink: ContextLink[]
}

export interface ContextLink {
    description: string
    id: string
    uri: string
    selected?: boolean
    relativePath: string | null
    serviceName: string
}

export const findContextLinkWithId = (id: string, contextLinkList: ContextLinkList | undefined) => {
    if (!contextLinkList?.contextLink) {
        return undefined
    }

    return contextLinkList.contextLink.find((contextLink) => contextLink.id === id)
}
