import React, { FunctionComponent, ReactNode, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import ArrowLeftThick from '@wh-components/icons/ArrowLeftThick'
import ArrowRightThick from '@wh-components/icons/ArrowRightThick'
import DotFull from '@wh-components/icons/DotFull'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { StoryblokCarAuctionsBenefitsItem, StoryblokCarAuctionsBenefitsItemBlok } from './StoryblokCarAuctionsBenefitsItem'

interface StoryblokCarAuctionsBenefitsListBlok extends StoryblokComponentType<string> {
    headerText: string
    introText: string
    items: StoryblokCarAuctionsBenefitsItemBlok[]
}

interface Props extends StoryblokDefaultProps<StoryblokCarAuctionsBenefitsListBlok> {}

export const StoryblokCarAuctionsBenefitsList: FunctionComponent<Props> = ({ blok }) => {
    const theme = useTheme()

    const [activeItemIndex, setActiveItemIndex] = useState(0)
    const decreaseActiveItemIndex = () => {
        const newIndex = activeItemIndex - 1
        setActiveItemIndex(newIndex >= 0 ? newIndex : blok.items.length - 1)
    }
    const increaseActiveItemIndex = () => {
        const newIndex = activeItemIndex + 1
        setActiveItemIndex(newIndex < blok.items.length ? newIndex : 0)
    }

    const getDotColor = (index: number) => (index === activeItemIndex ? 'palette.white' : 'palette.secondary.main')

    return (
        <ListContainer {...storyblokEditable(blok as unknown as SbBlokData)}>
            <Text as="h2" fontWeight="bold" color="palette.white">
                {blok.headerText}
            </Text>
            <Text as="p" color="palette.white" marginTop={theme.space.s} marginBottom={theme.space.l}>
                {blok.introText}
            </Text>
            <SwipeArea onSwipeLeft={increaseActiveItemIndex} onSwipeRight={decreaseActiveItemIndex}>
                <Box display="flex" flexWrap="wrap" gap="m">
                    {blok.items.map((item, index) => (
                        <ListItem key={index} data-is-active={activeItemIndex === index}>
                            <StoryblokCarAuctionsBenefitsItem blok={item} />
                        </ListItem>
                    ))}
                </Box>
            </SwipeArea>
            <Slider>
                <SliderItem key="back" onClick={decreaseActiveItemIndex}>
                    <ArrowLeftThick color="palette.white" marginRight={theme.space.xxxl} size="3rem" />
                </SliderItem>
                {blok.items.map((_, index) => (
                    <SliderItem key={index}>
                        <DotFull color={getDotColor(index)} margin={theme.space.s} />
                    </SliderItem>
                ))}
                <SliderItem key="forward" onClick={increaseActiveItemIndex}>
                    <ArrowRightThick color="palette.white" marginLeft={theme.space.xxxl} size="3rem" />
                </SliderItem>
            </Slider>
        </ListContainer>
    )
}

const ListContainer = styled.div`
    background-color: ${(p) => p.theme.colors.palette.primary.main};
    padding: ${(p) => p.theme.space.m}px;
    border-radius: ${(p) => p.theme.borderRadius}px;
`

const ListItem = styled.div<{ 'data-is-active': boolean }>`
    flex: 0 0 100%;
    display: ${(p) => (p['data-is-active'] ? 'block' : 'none')};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}px) {
        flex: 0 0 calc(25% - ${(p) => Math.ceil(p.theme.space.m * 0.75)}px);
        display: block;
    }
`

const Slider = styled.div`
    margin-top: ${(p) => p.theme.space.xl};
    margin-bottom: ${(p) => p.theme.space.s};
    text-align: center;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}px) {
        display: none;
    }
`

const SliderItem = styled.span`
    vertical-align: middle;
`

interface SwipeAreaProps {
    children: ReactNode
    onSwipeLeft: () => void
    onSwipeRight: () => void
}

const SwipeArea: FunctionComponent<SwipeAreaProps> = (props) => {
    const [touchStart, setTouchStart] = useState<number | null>(null)
    const [touchEnd, setTouchEnd] = useState<number | null>(null)

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50

    const onTouchStart = (e: React.TouchEvent) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e: React.TouchEvent) => {
        setTouchEnd(e.targetTouches[0].clientX)
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) {
            return
        }

        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance

        if (isLeftSwipe) {
            props.onSwipeLeft()
        } else if (isRightSwipe) {
            props.onSwipeRight()
        }
    }

    return (
        <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            {props.children}
        </div>
    )
}
