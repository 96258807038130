import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { CdcAd } from '@bbx/common/types/UserFeed'

export const getSeoUrl = (advertSummary: AdvertSummary) => buildSeoUrl(getAttributeValue(advertSummary.attributes.attribute, 'SEO_URL'))
export const getSeoUrlCdc = (ad: CdcAd) => buildSeoUrl(getAttributeValue(ad.attributes, 'SEO_URL'))

export const buildSeoUrl = (iadPath: string | undefined | null) => {
    if (!iadPath) {
        return ''
    }

    if (!iadPath?.startsWith('/iad')) {
        if (iadPath?.startsWith('/')) {
            return `/iad${iadPath}`
        } else {
            return `/iad/${iadPath}`
        }
    }

    return iadPath
}
