import styled, { css } from 'styled-components'
import { AnchorLink } from '../AnchorLink/AnchorLink'

const selectedMixin = (color: string) => css`
    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        border-left: 6px solid ${color};
        height: 100%;
    }
`

export const StyledAnchorLink = styled(AnchorLink)<{ iconColor: string; iconColorHover: string; selected: boolean }>`
    & svg {
        color: ${(p) => p.iconColor};
    }

    &:hover {
        background-color: ${(p) => p.theme.colors.palette.babyseal};
        text-decoration: none;

        & svg {
            color: ${(p) => p.iconColorHover};
        }
    }

    ${(p) => p.selected && selectedMixin(p.iconColor)}
`

export const VerticalMenuStyle = css`
    background: ${(p) => p.theme.colors.palette.polarbear};
    border-radius: ${(p) => p.theme.borderRadius}px;
    border: 1px solid ${(p) => p.theme.colors.palette.owl};
    .wh-vertical-menu {
        &--header {
            border-bottom: 1px solid ${(p) => p.theme.colors.palette.owl};
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: ${(p) => p.theme.space.m}px;

            &--nouserdata {
                justify-content: flex-start;
                padding: ${(p) => p.theme.space.s}px ${(p) => p.theme.space.l}px;
                color: ${(p) => p.theme.colors.palette.elephant};
            }
        }

        &--item {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: ${(p) => p.theme.space.sm}px ${(p) => p.theme.space.m}px;
            cursor: pointer;
            border-bottom: 1px solid ${(p) => p.theme.colors.palette.owl};
            color: inherit;

            &-text {
                display: flex;
                align-items: baseline;
                justify-content: flex-start;
                width: 100%;

                &-title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &-count {
                    font-size: ${(p) => p.theme.fontSizes.s};
                    color: ${(p) => p.theme.colors.palette.elephant};
                }
            }

            &-icon {
                margin-right: ${(p) => p.theme.space.s}px;
            }
        }

        &--subitem {
            display: flex;
            padding: ${(p) => p.theme.space.sm}px ${(p) => p.theme.space.m}px ${(p) => p.theme.space.sm}px 50px;
            border-bottom: 1px solid ${(p) => p.theme.colors.palette.owl};
            cursor: pointer;
            color: inherit;

            &-selected {
                .wh-vertical-menu--subitem-text-title {
                    font-weight: ${(p) => p.theme.fontWeights.bold};
                }

                .wh-vertical-menu--item-text {
                    font-weight: ${(p) => p.theme.fontWeights.bold};
                }
            }

            &-text {
                width: 100%;
                display: flex;
                align-items: baseline;
                justify-content: flex-start;
                &-title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &-selected,
            &:hover {
                background-color: ${(p) => p.theme.colors.palette.babyseal};
                text-decoration: none;
            }
        }

        &--footer {
            padding: ${(p) => p.theme.space.m}px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            color: ${(p) => p.theme.colors.palette.elephant};

            &--nouserdata {
                padding: ${(p) => p.theme.space.s}px 0;
            }
        }
    }
`
