import { sanitize } from './sanitizeFunctions'
/* eslint-disable max-lines */
import { getXitiLevel2Id } from './taggingGetXitiLevel2Id'

/*
  Xiti selfpromotions are kinds of events used to track when and if an element comes into viewport or was loaded
  In xiti this feature is tailored for digital-advertisements
  https://developers.atinternet-solutions.com/as2-tagging-en/javascript-en/campaigns-javascript-en/on-site-ads-javascript-en
 */
interface SelfPromotionData {
    adId: string // not a willhaben adId - must be created in XITI by alex grießer or gregor
    productId: string // can be any string
    format: string // can be any string
}

export const xitiClickMap = (
    vertical_id: string | undefined,
    trendLabel: string,
    category: string,
    params: Readonly<Record<string, string | undefined>>,
): Record<
    string,
    | {
          click?: string | undefined
          s2?: string | undefined
          xiti_selfpromotion_impression?: SelfPromotionData | undefined
          xiti_selfpromotion_click?: SelfPromotionData | undefined
      }
    | undefined
> => {
    const xitiLevel2Id = getXitiLevel2Id(vertical_id, params.isHomepage)

    const page = params.page
    const widgetType = params.taggingWidgetType
    const taggingId = params.taggingId

    return {
        send_confirm: { click: 'SendConfirm', s2: '5' },
        send_error: { click: 'SendError', s2: '5' },
        mywillhaben_myprofile_save: { click: 'MyProfile::Save', s2: '10' },
        mywillhaben_myprofile_seller_profile: { click: 'MyProfile::SellerProfile', s2: '10' },
        mywillhaben_myprofile_privacy_policy: { click: 'MyProfile::PrivacyPolicy', s2: '10' },
        mywillhaben_edittenant: { click: 'MyWillhaben::MyTenantProfile', s2: '10' },
        mywillhaben_edittenant_save: { click: 'MyWillhaben::MyTenantProfile::Save', s2: '10' },
        mywillhaben_edittenant_shadowmap: { click: 'MyWillhaben::MyTenantProfile::Tenantoverview::Shadowmap', s2: '10' },
        mywillhaben_lessor_lessoroverview_messagelink_click: { click: 'MyWillhaben::Lessor::MyLessoroverview::Messaginglink', s2: '10' },
        mywillhaben_lessor_lessoroverview_showtenantprofile_click: {
            click: 'MyWillhaben::Lessor::MyLessoroverview::ShowTenantProfile',
            s2: '10',
        },
        mywillhaben_lessor_lessoroverview_requesttenantprofile_click: {
            click: 'MyWillhaben::Lessor::MyLessoroverview::RequestTenantProfile',
            s2: '10',
        },
        mywillhaben_tenantoverview_ad_click: { click: 'MyWillhaben::MyTenantProfile::Tenantoverview::Ad', s2: '10' },
        mywillhaben_tenantoverview_messagelink_click: { click: 'MyWillhaben::MyTenantProfile::Tenantoverview::Messaginglink', s2: '10' },
        mywillhaben_tenantoverview_revokeaccess_click: { click: 'MyWillhaben::MyTenantProfile::Tenantoverview::Revokeaccess', s2: '10' },
        mywillhaben_tenantoverview_grantaccess_click: {
            click: 'MyWillhaben::MyTenantProfile::Tenantoverview::ShareTenantProfile',
            s2: xitiLevel2Id,
        },
        mywillhaben_tenantoverview_show_click: { click: 'MyWillhaben::MyTenantProfile::ShowTenantoverview', s2: '10' },
        login_success: { click: 'Login::SendConfirm', s2: '10' },
        login_error: { click: 'Login::SendError', s2: '10' },
        register_success: { click: 'Registration::SendConfirm', s2: '10' },
        register_error: { click: 'Registration::SendError', s2: '10' },
        my_search_agents_detail: { click: 'MySearchAgents::Open::SearchResult_List', s2: '10' },
        my_search_agents_edit_click: { click: 'MySearchAgents::Edit', s2: '10' },
        my_search_agents_deactivate: { click: 'MySearchAgents::InactiveOn', s2: '10' },
        my_search_agents_activate: { click: 'MySearchAgents::ActiveOn', s2: '10' },
        my_search_agents_delete: { click: 'MySearchAgents::Delete', s2: '10' },
        my_search_agents_save: { click: 'MySearchAgents_Edit::Save', s2: '10' },
        my_search_agents_change_criteria: { click: 'MySearchAgents_Edit::ChangeCriteria', s2: '10' },
        upselling_purchase_click: { click: 'Upselling::Purchase', s2: '4' },
        renew_ad_renew_click: { click: 'RenewAd::Publish', s2: '10' },
        my_ads_click_open_context_menu: { click: 'MyAds::Edit', s2: '10' },
        my_ads_click_delete_ad: { click: 'MyAds::Delete', s2: '10' },
        my_ads_click_lessoroverview: { click: 'MyAds::MyLessoroverview', s2: '10' },
        my_ads_click_share_ad: { click: 'MyAds::Share', s2: '10' },
        my_ads_click_activate_bulkselect: { click: 'MyAds::BulkEdit', s2: '10' },
        my_ads_click_search_field: { click: 'MyAds::Search', s2: '10' },
        my_ads_click_change_sort: {
            click: `MyAds::Sort::${sanitize(params.myads_sort)}`,
            s2: '10',
        },
        my_ads_click_change_status_filter: { click: `MyAds::Filter::${sanitize(params.myads_status)}`, s2: '10' },
        my_ads_click_upselling: { click: 'MyAds::Upselling', s2: '10' },
        my_ads_click_finalize: { click: 'MyAds::Resume', s2: '10' },
        my_ads_click_activate: { click: 'MyAds::Activate', s2: '10' },
        my_ads_click_republish: { click: 'MyAds::Resubmit', s2: '10' },
        my_ads_contextmenu_click_edit_images: { click: 'MyAds::Edit::Image', s2: '10' },
        my_ads_contextmenu_click_edit: { click: 'MyAds::Edit::Description', s2: '10' },
        my_ads_contextmenu_click_edit_delivery: { click: 'MyAds::Edit::Delivery', s2: '10' },
        my_ads_contextmenu_click_activate: { click: 'MyAds::Edit::Activate', s2: '10' },
        my_ads_contextmenu_click_deactivate: { click: 'MyAds::Edit::Deactivate', s2: '10' },
        my_ads_contextmenu_click_toggle_availability: { click: 'MyAds::Edit::ToggleAvailability', s2: '10' },
        my_ads_contextmenu_click_finalize: { click: 'MyAds::Edit::Resume', s2: '10' },
        my_ads_contextmenu_click_republish: { click: 'MyAds::Edit::Resubmit', s2: '10' },
        my_ads_contextmenu_click_upselling: { click: 'MyAds::Edit::Upselling', s2: '10' },
        my_ads_contextmenu_click_carauction: { click: 'MyAds::Edit::CarAuction', s2: '10' },
        my_ads_contextmenu_click_delete: { click: 'MyAds::Edit::Delete', s2: '10' },
        my_ads_contextmenu_click_request_bill: { click: 'MyAds::Edit::RequestInvoice', s2: '10' },
        my_ads_click_goto_aza: { click: 'MyAds::EmptyState::CreateAd', s2: '10' },
        my_saved_ads_click_renew: { click: 'MySavedAds::Save::Renewed', s2: '10' },
        my_saved_ads_click_mortgage: { click: 'MySavedAds::Link::Mortgage', s2: '10' },
        my_saved_ads_click_sorting_DATE_ADDED: { click: 'MySavedAds::Sort::Date', s2: '10' },
        my_saved_ads_click_sorting_MARKET: { click: 'MySavedAds::Sort::AdType', s2: '10' },
        my_saved_ads_click_sorting_PRICE_DESC: { click: 'MySavedAds::Sort::PriceDescending', s2: '10' },
        my_saved_ads_click_sorting_PRICE_ASC: { click: 'MySavedAds::Sort::PriceAscending', s2: '10' },
        ad_insertion_vertical_selection_private_bap_click: { click: 'AdInsertion::VerticalSelection::CreatePrivateBAPAd', s2: '4' },
        ad_insertion_vertical_selection_commercial_bap_click: { click: 'AdInsertion::VerticalSelection::CreateCommercialBAPAd', s2: '4' },
        ad_insertion_vertical_selection_boat_click: { click: 'AdInsertion::VerticalSelection::CreateBoatAd', s2: '4' },
        ad_insertion_vertical_selection_private_boat_click: {
            click: 'AdInsertion::VerticalSelection::CreateBoatAd::SelectPrivateBoatAd',
            s2: '4',
        },
        ad_insertion_vertical_selection_commercial_boat_click: {
            click: 'AdInsertion::VerticalSelection::CreateBoatAd::SelectCommercialBoatAd',
            s2: '4',
        },
        ad_insertion_vertical_selection_motor_click: { click: 'AdInsertion::VerticalSelection::SelectCarsMotor', s2: '4' },
        ad_insertion_vertical_selection_estate_click: { click: 'AdInsertion::VerticalSelection::SelectRealEstate', s2: '4' },
        ad_insertion_vertical_selection_jobs_click: { click: 'AdInsertion::VerticalSelection::SelectJobs', s2: '4' },
        ad_insertion_estate_product_selection_house_click: { click: 'AdInsertion::ProductSelection::Select_House', s2: '2' },
        ad_insertion_estate_product_selection_flat_click: { click: 'AdInsertion::ProductSelection::Select_Appartement', s2: '2' },
        ad_insertion_estate_product_selection_plot_click: { click: 'AdInsertion::ProductSelection::Select_Plot', s2: '2' },
        ad_insertion_estate_product_selection_commercial_click: {
            click: 'AdInsertion::ProductSelection::Select_CommercialProperty',
            s2: '2',
        },
        ad_insertion_estate_product_selection_holiday_click: { click: 'AdInsertion::ProductSelection::Select_VacationProperty', s2: '2' },
        ad_insertion_estate_product_selection_misc_click: { click: 'AdInsertion::ProductSelection::Select_OtherProperty', s2: '2' },
        ad_insertion_estate_product_selection_house_sell_click: {
            click: 'AdInsertion::ProductSelection::Select_House::Select_Sell',
            s2: '2',
        },
        ad_insertion_estate_product_selection_house_rent_click: {
            click: 'AdInsertion::ProductSelection::Select_House::Select_Rent',
            s2: '2',
        },
        ad_insertion_estate_product_selection_flat_sell_click: {
            click: 'AdInsertion::ProductSelection::Select_Appartement::Select_Sell',
            s2: '2',
        },
        ad_insertion_estate_product_selection_flat_rent_click: {
            click: 'AdInsertion::ProductSelection::Select_Appartement::Select_Rent',
            s2: '2',
        },
        ad_insertion_estate_product_selection_plot_sell_click: {
            click: 'AdInsertion::ProductSelection::Select_Plot::Select_Sell',
            s2: '2',
        },
        ad_insertion_estate_product_selection_plot_lease_click: {
            click: 'AdInsertion::ProductSelection::Select_Plot::Select_Lease',
            s2: '2',
        },
        ad_insertion_estate_product_selection_commercial_sell_click: {
            click: 'AdInsertion::ProductSelection::Select_CommercialProperty::Select_Sell',
            s2: '2',
        },
        ad_insertion_estate_product_selection_commercial_rent_click: {
            click: 'AdInsertion::ProductSelection::Select_CommercialProperty::Select_Rent',
            s2: '2',
        },
        ad_insertion_estate_product_selection_holiday_sell_click: {
            click: 'AdInsertion::ProductSelection::Select_VacationProperty::Select_Sell',
            s2: '2',
        },
        ad_insertion_estate_product_selection_holiday_rent_click: {
            click: 'AdInsertion::ProductSelection::Select_VacationProperty::Select_Rent',
            s2: '2',
        },
        ad_insertion_estate_product_selection_misc_sell_click: {
            click: 'AdInsertion::ProductSelection::Select_OtherProperty::Select_Sell',
            s2: '2',
        },
        ad_insertion_estate_product_selection_misc_rent_click: {
            click: 'AdInsertion::ProductSelection::Select_OtherProperty::Select_Rent',
            s2: '2',
        },
        ad_insertion_motor_product_selection_car_click: { click: 'AdInsertion::ProductSelection::Select_Car', s2: '3' },
        ad_insertion_motor_product_selection_mc_click: { click: 'AdInsertion::ProductSelection::Select_Motorbike', s2: '3' },
        ad_insertion_motor_product_selection_truck_click: { click: 'AdInsertion::ProductSelection::Select_Pickup', s2: '3' },
        ad_insertion_motor_product_selection_caravan_click: { click: 'AdInsertion::ProductSelection::Select_Caravan', s2: '3' },
        ad_insertion_motor_product_selection_spareparts_click: { click: 'AdInsertion::ProductSelection::Select_Spareparts', s2: '3' },
        ad_insertion_motor_product_selection_car_turbo_click: { click: 'AdInsertion::ProductSelection::Select_Car::Select_Turbo', s2: '3' },
        ad_insertion_motor_product_selection_car_basic_click: { click: 'AdInsertion::ProductSelection::Select_Car::Select_Basic', s2: '3' },
        ad_insertion_motor_product_selection_mc_turbo_click: {
            click: 'AdInsertion::ProductSelection::Select_Motorbike::Select_Turbo',
            s2: '3',
        },
        ad_insertion_motor_product_selection_mc_basic_click: {
            click: 'AdInsertion::ProductSelection::Select_Motorbike::Select_Basic',
            s2: '3',
        },
        ad_insertion_motor_product_selection_truck_basic_click: {
            click: 'AdInsertion::ProductSelection::Select_Pickup::Select_Basic',
            s2: '3',
        },
        ad_insertion_motor_product_selection_truck_plus_click: {
            click: 'AdInsertion::ProductSelection::Select_Pickup::Select_Plus',
            s2: '3',
        },
        ad_insertion_motor_product_selection_caravan_basic_click: {
            click: 'AdInsertion::ProductSelection::Select_Caravan::Select_Basic',
            s2: '3',
        },
        ad_insertion_motor_product_selection_caravan_plus_click: {
            click: 'AdInsertion::ProductSelection::Select_Caravan::Select_Plus',
            s2: '3',
        },
        ad_insertion_motor_product_selection_spareparts_private_click: {
            click: 'AdInsertion::ProductSelection::Select_Spareparts::Select_Private',
            s2: '3',
        },
        ad_insertion_motor_product_selection_spareparts_commercial_click: {
            click: 'AdInsertion::ProductSelection::Select_Spareparts::Select_Commercial',
            s2: '3',
        },
        ad_insertion_eurotax_page_eurotax_code_submitted: {
            click: 'AdInsertion::Eurotax::Eurotax_Code_Confirm',
            s2: '3',
        },
        ad_insertion_eurotax_page_eurotax_model_submitted: {
            click: 'AdInsertion::Eurotax::Eurotax_List_Confirm',
            s2: '3',
        },
        ad_insertion_eurotax_page_without_selection_submitted: {
            click: 'AdInsertion::Eurotax::No_Eurotax',
            s2: '3',
        },
        ad_insertion_form_filterable_attribute_modal_select_unfiltered: {
            click: 'AdInsertion::Form::SearchMultiSelect::Submit',
            s2: xitiLevel2Id,
        },
        ad_insertion_form_filterable_attribute_modal_select_filtered: {
            click: 'AdInsertion::Form::SearchMultiSelect::SubmitWithFilteredValue',
            s2: xitiLevel2Id,
        },
        ad_insertion_form_rent_appartement_price_finder: {
            click: 'AdInsertion::Form::Rent_Appartement::PriceFinder',
            s2: '2',
        },
        ad_insertion_form_rent_house_price_finder: {
            click: 'AdInsertion::Form::Rent_House::PriceFinder',
            s2: '2',
        },
        ad_insertion_upselling_selected: { click: 'AdInsertion::Upsellings::SelectUpselling', s2: xitiLevel2Id },
        ad_insertion_upselling_deselected: { click: 'AdInsertion::Upsellings::DeselectUpselling', s2: xitiLevel2Id },
        ad_insertion_confirmation_create_ad_click: { click: 'AdInsertion::Confirmation::CreateAd', s2: xitiLevel2Id },
        ad_insertion_confirmation_myads_click: { click: 'AdInsertion::Confirmation::MyAds', s2: xitiLevel2Id },
        ad_insertion_confirmation_addetail_click: { click: 'AdInsertion::Confirmation::OpenAdDetail', s2: xitiLevel2Id },
        ad_insertion_confirmation_share_click: { click: 'AdInsertion::Confirmation::Share', s2: xitiLevel2Id },
        ad_insertion_confirmation_share_webshareapi_click: { click: 'AdInsertion::Confirmation::Share::WebShareApi', s2: xitiLevel2Id },
        ad_insertion_confirmation_edit_click: { click: 'AdInsertion::Confirmation::Edit', s2: xitiLevel2Id },
        ad_insertion_confirmation_edit_description_click: { click: 'AdInsertion::Confirmation::Edit::Description', s2: xitiLevel2Id },
        ad_insertion_confirmation_edit_upselling_click: { click: 'AdInsertion::Confirmation::Edit::Upselling', s2: xitiLevel2Id },
        ad_insertion_confirmation_edit_deactivate_click: { click: 'AdInsertion::Confirmation::Edit::Deactivate', s2: xitiLevel2Id },
        ad_insertion_confirmation_edit_invoice_click: { click: 'AdInsertion::Confirmation::Edit::RequestInvoice', s2: xitiLevel2Id },
        ad_insertion_confirmation_edit_delivery_click: { click: 'AdInsertion::Confirmation::Edit::Delivery', s2: xitiLevel2Id },
        ad_insertion_product_selection_select_appartment_price_finder_click: {
            click: 'AdInsertion::ProductSelection::Select_Appartement:Price_finder',
            s2: '2',
        },
        ad_insertion_product_selection_select_house_price_finder_click: {
            click: 'AdInsertion::ProductSelection::Select_House:Price_finder',
            s2: '2',
        },
        my_personalization_edit_click: { click: `MyWillhaben::MyPersonalization::${sanitize(params.category)}::Edit`, s2: '10' },
        my_personalization_reset_click: { click: `MyWillhaben::MyPersonalization::${sanitize(params.category)}::Reset`, s2: '10' },
        my_personalization_save_click: { click: `MyWillhaben::MyPersonalization::${sanitize(params.category)}::Save`, s2: '10' },
        my_personalization_modal_save_click: { click: `MyWillhaben::MyPersonalization::Unsaved::Save`, s2: '10' },
        my_personalization_modal_discard_click: { click: `MyWillhaben::MyPersonalization::Unsaved::Discard`, s2: '10' },
        my_personalization_toggle_on_click: {
            click: `MyWillhaben::MyPersonalization::${sanitize(params.category)}::ToggleOn`,
            s2: '10',
        },
        my_personalization_toggle_off_click: {
            click: `MyWillhaben::MyPersonalization::${sanitize(params.category)}::ToggleOff`,
            s2: '10',
        },
        header_click_logo: { click: 'Menu::Logo', s2: '11' },
        header_click_myLastSearches: { click: 'Menu::MyLastSearches', s2: '11' },
        header_click_messages: { click: 'Menu::Messages', s2: '11' },
        header_click_login: { click: 'Menu::Login', s2: '11' },
        header_click_register: { click: 'Menu::Register', s2: '11' },
        header_click_myWillhaben: { click: 'Menu::MyWillhaben', s2: '11' },
        header_click_insertAd: { click: 'Menu::InsertAd', s2: '11' },
        header_click_marketplace: { click: 'Menu::Generalist', s2: '11' },
        header_click_realestate: { click: 'Menu::RealEstate', s2: '11' },
        header_click_motor: { click: 'Menu::Motor', s2: '11' },
        header_click_jobs: { click: 'Menu::Jobs', s2: '11' },
        header_click_vertical_links_marketplace_icon: { click: 'Menu::Header::Generalist::Icon', s2: '11' },
        header_click_vertical_links_marketplace: { click: 'Menu::Header::Generalist', s2: '11' },
        header_click_vertical_links_realestate: { click: 'Menu::Header::RealEstate', s2: '11' },
        header_click_vertical_links_motor: { click: 'Menu::Header::Motor', s2: '11' },
        header_click_vertical_links_jobs: { click: 'Menu::Header::Jobs', s2: '11' },
        header_click_ImgSearch: { click: 'Menu::ImgSearch', s2: '11' },
        header_click_myAds: { click: 'Menu::MyAds', s2: '11' },
        header_click_myTransactions: { click: 'Menu::MyTransactions', s2: '11' },
        header_click_myJobAds: { click: 'Menu::MyJobAds', s2: '11' },
        header_click_mySearches: { click: 'Menu::MySearchAgents', s2: '11' },
        header_click_myFindings: { click: 'Menu::MySavedAds', s2: '11' },
        header_click_chat: { click: 'Menu::MyMessages', s2: '11' },
        header_click_editUser: { click: 'Menu::MyProfile', s2: '11' },
        header_usermenu_click_myAdverts: { click: 'Menu::MyWillhaben::MyAds', s2: '11' },
        header_usermenu_click_myJobAdverts: { click: 'Menu::MyWillhaben::MyJobAds', s2: '11' },
        header_usermenu_click_mySearches: { click: 'Menu::MyWillhaben::MySearchAgents', s2: '11' },
        header_usermenu_click_myFindings: { click: 'Menu::MyWillhaben::MySavedAds', s2: '11' },
        header_usermenu_click_myLastSearches: { click: 'Menu::MyWillhaben::MyLastSearches', s2: '11' },
        header_usermenu_click_chat: { click: 'Menu::MyWillhaben::MyMessages', s2: '11' },
        header_usermenu_click_jobsProfile: { click: 'Menu::MyWillhaben::MyJob', s2: '11' },
        header_usermenu_click_editUser: { click: 'Menu::MyWillhaben::MyProfile', s2: '11' },
        header_usermenu_click_personalization: { click: 'Menu::MyWillhaben::MyPersonalization', s2: '11' },
        header_usermenu_click_mytransactions: { click: 'Menu::MyWillhaben::MyTransactions', s2: '11' },
        header_usermenu_click_help: { click: 'Menu::MyWillhaben::Help', s2: '11' },
        header_usermenu_click_logout: { click: 'Menu::MyWillhaben::Logout', s2: '11' },
        jobs_employer_header_usermenu_overview_click: { click: 'Menu::Overview', s2: '1' },
        jobs_employer_header_usermenu_jobads_click: { click: 'Menu::JobAds', s2: '1' },
        jobs_employer_header_usermenu_companyprofile_click: { click: 'Menu::CompanyProfile', s2: '1' },
        addetail_sustainiability_box_info_link_click: {
            click: `AdDetail::Nachhaltigkeit::${category ?? 'Sonstige'}`,
            s2: xitiLevel2Id,
        },
        addetail_previous_ad_click: { click: 'AdDetail::Navigation::Previous', s2: xitiLevel2Id },
        addetail_next_ad_click: { click: 'AdDetail::Navigation::Next', s2: xitiLevel2Id },
        addetail_favorite_ad_click_save: { click: 'AdDetail::SaveAd', s2: xitiLevel2Id },
        addetail_favorite_ad_click_unsave: { click: 'AdDetail::UnsaveAd', s2: xitiLevel2Id },
        addetail_image_gallery_similarads_icon_click: { click: 'ImgSearch::Similar', s2: xitiLevel2Id },
        addetail_image_gallery_floorplan_click: { click: 'AdDetail::Floorplan', s2: xitiLevel2Id },
        addetail_sticky_header_favorite_ad_click_save: { click: 'AdDetail::Sticky::SaveAd', s2: xitiLevel2Id },
        addetail_sticky_header_favorite_ad_click_unsave: { click: 'AdDetail::Sticky::UnsaveAd', s2: xitiLevel2Id },
        addetail_share_webshareapi_click: { click: 'AdDetail::Share::WebShareApi', s2: xitiLevel2Id },
        addetail_share_email_click: { click: 'AdDetail::Share::Email', s2: xitiLevel2Id },
        addetail_sticky_header_share_email_click: { click: 'AdDetail::Sticky::Share::Email', s2: xitiLevel2Id },
        addetail_share_whatsapp_click: { click: 'AdDetail::Share::Whatsapp', s2: xitiLevel2Id },
        addetail_sticky_header_share_whatsapp_click: { click: 'AdDetail::Sticky::Share::Whatsapp', s2: xitiLevel2Id },
        addetail_share_facebook_click: { click: 'AdDetail::Share::Facebook', s2: xitiLevel2Id },
        addetail_sticky_header_share_facebook_click: { click: 'AdDetail::Sticky::Share::Facebook', s2: xitiLevel2Id },
        addetail_share_twitter_click: { click: 'AdDetail::Share::Twitter', s2: xitiLevel2Id },
        addetail_sticky_header_share_twitter_click: { click: 'AdDetail::Sticky::Share::Twitter', s2: xitiLevel2Id },
        addetail_share_link_click: { click: 'AdDetail::Share::Link', s2: xitiLevel2Id },
        addetail_sticky_header_share_link_click: { click: 'AdDetail::Sticky::Share::Link', s2: xitiLevel2Id },
        seller_profile_share_email_click: { click: 'Seller::SellerProfile::Share::Email', s2: '10' },
        seller_profile_share_whatsapp_click: { click: 'Seller::SellerProfile::Share::Whatsapp', s2: '10' },
        seller_profile_share_facebook_click: { click: 'Seller::SellerProfile::Share::Facebook', s2: '10' },
        seller_profile_share_twitter_click: { click: 'Seller::SellerProfile::Share::Twitter', s2: '10' },
        seller_profile_share_link_click: { click: 'Seller::SellerProfile::Share::Link', s2: '10' },
        seller_profile_follow_user_click: { click: 'Seller::SellerProfile::Yes', s2: '10' },
        seller_profile_unfollow_user_click: { click: 'Seller::SellerProfile::No', s2: '10' },
        seller_profile_report_user_click: { click: 'Seller::SellerProfile::ReportImage', s2: '10' },
        addetail_print_click: { click: 'AdDetail::Print', s2: xitiLevel2Id },
        addetail_sticky_print_click: { click: 'AdDetail::Sticky::Print', s2: xitiLevel2Id },
        addetail_dealer_website_click: {
            click: { '3': 'AdDetail::DealerWebsite', '2': 'AdDetail::BrokerWebsite' }[vertical_id as '2' | '3'] || '',
            s2: xitiLevel2Id,
        },
        addetail_contact_seller_top_click: { click: 'AdDetail::ContactSeller', s2: xitiLevel2Id },
        addetail_sticky_header_contact_seller_click: { click: 'AdDetail::Sticky::ContactSeller', s2: xitiLevel2Id },
        addetail_order_now_click: { click: 'AdDetail::OrderNow', s2: xitiLevel2Id },
        addetail_sticky_header_order_now_click: { click: 'AdDetail::Sticky::OrderNow', s2: xitiLevel2Id },
        addetail_sticky_offer_click: { click: 'AdDetail::Sticky::Offer', s2: xitiLevel2Id },
        addetail_privatetop_offer_click: { click: 'AdDetail::PrivateTop::Offer', s2: xitiLevel2Id },
        addetail_contactform_offer_click: { click: 'AdDetail::ContactForm::Offer', s2: xitiLevel2Id },
        addetail_sticky_buynow_click: { click: 'AdDetail::Sticky::BuyNow', s2: xitiLevel2Id },
        addetail_privatetop_buynow_click: { click: 'AdDetail::PrivateTop::BuyNow', s2: xitiLevel2Id },
        addetail_contactform_buynow_click: { click: 'AdDetail::ContactForm::BuyNow', s2: xitiLevel2Id },
        addetail_contact_seller_expand_address_fields_click: {
            click: 'AdDetail::ShowAdressInputfields',
            s2: xitiLevel2Id,
        },
        addetail_show_phone_number_click: { click: 'AdDetail::ShowPhoneNumber', s2: xitiLevel2Id },
        addetail_more_ads_click: { click: 'AdDetail::SellerProfile', s2: xitiLevel2Id },
        addetail_show_opening_hours_click: { click: 'AdDetail::ShowOpeningHours', s2: xitiLevel2Id },
        addetail_expand_description_click: { click: 'AdDetail::ShowDescription', s2: xitiLevel2Id },
        addetail_expand_location_click: {
            click: 'Addetail::ShowLocationDescription',
            s2: xitiLevel2Id,
        },
        addetail_warranty_click: { click: 'AdDetail::Warranty', s2: xitiLevel2Id },
        addetail_defects_liability_click: { click: 'AdDetail::DefectsLiability', s2: xitiLevel2Id },
        addetail_warranty_logo_click: { click: 'AdDetail::Warranty::Logo', s2: xitiLevel2Id },
        addetail_dealer_profile_click: { click: 'AdDetail::ShowDealerProfile', s2: xitiLevel2Id },
        addetail_map_click: { click: 'AdDetail::ShowMap', s2: xitiLevel2Id },
        addetail_send_email_click: { click: 'AdDetail::SendEmail', s2: xitiLevel2Id },
        addetail_send_message_click: { click: 'AdDetail::SendMessage', s2: xitiLevel2Id },
        addetail_call_phone_click: { click: 'AdDetail::Call', s2: xitiLevel2Id },
        addetail_virtual_tour_click: { click: 'AdDetail::360Tour', s2: xitiLevel2Id },
        addetail_unit_click: { click: 'AdDetail::Unit::Link', s2: xitiLevel2Id },
        addetail_to_nc_project_click: { click: 'AdDetail::NCProject::Link', s2: xitiLevel2Id },
        addetail_mortgage_calculator_loaded: { click: 'AdDetail::MortgageCalculator::Loaded', s2: xitiLevel2Id },
        addetail_mortgage_calculator_viewed: { click: 'AdDetail::MortgageCalculator::Viewed', s2: xitiLevel2Id },
        addetail_mortgage_calculator_first_interaction: { click: 'AdDetail::MortgageCalculator::Interacted', s2: xitiLevel2Id },
        addetail_mortgage_calculator_submit: { click: 'AdDetail::MortgageCalculator::Submitted', s2: xitiLevel2Id },
        addetail_jobs_widget_ad_click: { click: 'AdDetail::JobsWidget::Ad', s2: xitiLevel2Id },
        addetail_jobs_widget_show_all_click: { click: 'AdDetail::JobsWidget::ShowAll', s2: xitiLevel2Id },
        addetail_jobs_widget_show_all_button_click: { click: 'AdDetail::JobsWidget::ShowAlLButton', s2: xitiLevel2Id },
        search_result_list_keyword_search: { click: 'ResultList::KeywordSearch', s2: xitiLevel2Id },
        search_result_list_reset_click_navigators: {
            click: 'ResultList::RemoveAttribute::Filter',
            s2: xitiLevel2Id,
        },
        search_result_list_reset_click_top: {
            click: 'ResultList::RemoveAttribute::Top',
            s2: xitiLevel2Id,
        },
        search_result_list_ad_click_save: { click: 'ResultList::SaveAd', s2: xitiLevel2Id },
        search_result_list_ad_click_unsave: { click: 'ResultList::UnsaveAd', s2: xitiLevel2Id },
        search_result_list_show_all_makes: {
            click: 'ResultList::Filter::ShowAll::Make',
            s2: xitiLevel2Id,
        },
        search_result_list_show_all_equipments: {
            click: 'ResultList::Filter::ShowAll::Extras',
            s2: xitiLevel2Id,
        },
        search_result_list_show_all_regions: {
            click: 'ResultList::Filter::ShowAll::Regions',
            s2: xitiLevel2Id,
        },
        search_result_list_filter_small_click: { click: 'ResultList::Filter', s2: xitiLevel2Id },
        search_result_list_filter_object_types_click: {
            click: 'ResultList::Filter::Object_types',
            s2: xitiLevel2Id,
        },
        search_result_list_filter_autocomplete_click: {
            click: 'ResultList::Filter::Autocomplete',
            s2: xitiLevel2Id,
        },
        search_result_list_search_agent_click_top: {
            click: 'ResultList::SearchAgent::Top',
            s2: xitiLevel2Id,
        },
        search_result_list_search_agent_click_sticky: {
            click: 'ResultList::SearchAgent::Sticky',
            s2: xitiLevel2Id,
        },
        search_result_list_search_agent_click_sticky_small: {
            click: 'ResultList::SearchAgent::Sticky::Small',
            s2: xitiLevel2Id,
        },
        search_result_list_search_agent_click_empty_result: {
            click: 'ResultList::SearchAgent::EmptyResult',
            s2: xitiLevel2Id,
        },
        search_result_list_search_agent_click_bottom: {
            click: 'ResultList::SearchAgent::Bottom',
            s2: xitiLevel2Id,
        },
        search_result_list_sortorder: { click: 'ResultList::Sortorder', s2: xitiLevel2Id },
        search_result_list_sortorder_nearme: {
            click: 'ResultList::Sortorder::NearMe',
            s2: xitiLevel2Id,
        },
        search_result_list_list_view_click: {
            click: 'ResultList::ListView',
            s2: xitiLevel2Id,
        },
        search_result_list_grid_view_click: {
            click: 'ResultList::GridView',
            s2: xitiLevel2Id,
        },
        search_result_list_category_suggestion: {
            click: 'ResultList::CategorySuggestion',
            s2: xitiLevel2Id,
        },
        search_result_list_paylivery_toggle: {
            click: 'ResultList::Filter::PayliveryToggle',
            s2: xitiLevel2Id,
        },
        search_result_list_paylivery_checkbox: {
            click: 'ResultList::Filter::PayliveryCheckbox',
            s2: xitiLevel2Id,
        },
        search_result_list_multiselect: {
            click: 'ResultList::Filter::MultiSelect',
            s2: xitiLevel2Id,
        },
        search_result_list_category: {
            click: 'ResultList::Filter::Category',
            s2: xitiLevel2Id,
        },
        search_result_list_bubbles: {
            click: `ResultList::Filter::Bubbles::${sanitize(params.label)}`,
            s2: xitiLevel2Id,
        },
        search_result_list_catless_open: {
            click: 'ResultList::Filter::Catless::Open',
            s2: xitiLevel2Id,
        },
        search_result_list_catless_search: {
            click: 'ResultList::Filter::Catless::Search',
            s2: xitiLevel2Id,
        },
        search_result_list_keyword_search_search: {
            click: 'ResultList::KeywordSearch:Search',
            s2: xitiLevel2Id,
        },
        search_result_list_keyword_search_autocomplete: {
            click: 'ResultList::KeywordSearch:Autocomplete',
            s2: xitiLevel2Id,
        },
        search_result_list_keyword_search_last: {
            click: 'ResultList::KeywordSearch:LastSearch',
            s2: xitiLevel2Id,
        },
        search_result_list_sortorder_nearme_button: {
            click: 'Resultlist::Sortorder::NearMe::Button',
            s2: xitiLevel2Id,
        },
        search_result_list_sortorder_filter: { click: 'ResultList::Sortorder::Filter', s2: xitiLevel2Id },
        search_result_list_sortorder_filter_nearme: {
            click: 'ResultList::Sortorder::Filter::NearMe',
            s2: xitiLevel2Id,
        },
        search_result_list_search_multi_select_submit: {
            click: 'ResultList::Filter::SearchMultiSelect::Submit',
            s2: xitiLevel2Id,
        },
        search_result_list_search_multi_select_submit_with_filter: {
            click: 'ResultList::Filter::SearchMultiSelect::SubmitWithFilteredValue',
            s2: xitiLevel2Id,
        },
        search_result_list_mortgage_calculator_viewed: { click: 'ResultList::MortgageCalculator::Viewed', s2: xitiLevel2Id },
        search_result_list_mortgage_calculator_click: { click: 'ResultList::MortgageCalculator::Clicked', s2: xitiLevel2Id },
        search_result_list_mortgage_calculator_submit: { click: 'ResultList::MortgageCalculator::Submitted', s2: xitiLevel2Id },
        vertical_home_tab_car_click: { click: 'MotorStartpage::Cars', s2: xitiLevel2Id },
        vertical_home_tab_mc_click: { click: 'MotorStartpage::Motorbikes', s2: xitiLevel2Id },
        vertical_home_tab_truck_click: { click: 'MotorStartpage::Trucks', s2: xitiLevel2Id },
        vertical_home_tab_caravan_click: { click: 'MotorStartpage::Caravans', s2: xitiLevel2Id },
        vertical_home_tab_real_estate_click: { click: 'RealEstateStartpage::RealEstate', s2: xitiLevel2Id },
        vertical_home_tab_mortgages_click: { click: 'RealEstateStartpage::Mortgages', s2: xitiLevel2Id },
        search_result_list_personalization_filter_onboarding_box_click: {
            click: 'ResultList::Filter::PersonalizationOnboarding',
            s2: xitiLevel2Id,
        },
        vertical_home_search_box_search_button_click: {
            click: 'MotorStartpage::ViewResults',
            s2: xitiLevel2Id,
        },
        vertical_home_search_box_detail_search_click: {
            click: 'MotorStartpage::MoreOptions',
            s2: xitiLevel2Id,
        },
        vertical_home_search_box_condition_new_activate: {
            click: 'MotorStartpage::NewOption::ToggleOn',
            s2: xitiLevel2Id,
        },
        vertical_home_search_box_condition_new_deactivate: {
            click: 'MotorStartpage::NewOption::ToggleOff',
            s2: xitiLevel2Id,
        },
        vertical_home_spareparts_click: { click: 'MotorStartpage::SpareParts', s2: xitiLevel2Id },
        storyblok_click: { click: `${sanitize(params.prefix)}::${sanitize(params.taggingId)}`, s2: '5' },
        vertical_home_dealers_click: { click: 'MotorStartpage::Dealers', s2: xitiLevel2Id },
        vertical_home_ad_insertion_button_click: {
            click: 'MotorStartpage::AdInsertion',
            s2: xitiLevel2Id,
        },
        vertical_home_topad_click: { click: 'MotorStartpage::TopAd', s2: xitiLevel2Id },
        vertical_home_trend_click: {
            click: `MotorStartpage::Trends::${sanitize(trendLabel)}`,
            s2: xitiLevel2Id,
        },
        vertical_home_popular_car_make_click: {
            click: 'MotorStartpage::PopularMakes::Cars',
            s2: xitiLevel2Id,
        },
        vertical_home_popular_mc_make_click: {
            click: 'MotorStartpage::PopularMakes::Motorbikes',
            s2: xitiLevel2Id,
        },
        vertical_home_last_searches_click: {
            click: 'MotorStartpage::MyLastSearches',
            s2: xitiLevel2Id,
        },
        vertical_home_last_searches_search_click: {
            click: 'MotorStartpage::MyLastSearches::SearchResult',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_new_constructions: {
            click: 'RealEstateStartpage::all_newconstruction_types',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_all_object_types: {
            click: 'RealEstateStartpage::all_object_types',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_house_for_sale: {
            click: 'RealEstateStartpage::house_for_sale',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_house_for_rent: {
            click: 'RealEstateStartpage::house_for_rent',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_flat_for_sale: {
            click: 'RealEstateStartpage::flat_for_sale',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_flat_for_rent: {
            click: 'RealEstateStartpage::flat_for_rent',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_plot: {
            click: 'RealEstateStartpage::plot',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_commercial_property_for_sale: {
            click: 'RealEstateStartpage::commercial_property_for_sale',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_commercial_property_for_rent: {
            click: 'RealEstateStartpage::commercial_property_for_rent',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_vacation_property_for_sale: {
            click: 'RealEstateStartpage::vacation_property_for_sale',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_vacation_property_for_rent: {
            click: 'RealEstateStartpage::vacation_property_for_rent',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_other_objects: {
            click: 'RealEstateStartpage::other_objects',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_nc_flats: {
            click: 'RealEstateStartpage::NewConstructionFlats::DDL',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_dropdown_nc_houses: {
            click: 'RealEstateStartpage::NewConstructionHouses::DDL',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_search_box_search_button_click: {
            click: 'RealEstateStartpage::ViewResults',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_search_box_detail_search_click: {
            click: 'RealEstateStartpage::MoreOptions',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_mortgage_budget_calculator_interaction: {
            click: 'RealEstateStartpage::BudgetCalculator::Interaction',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_mortgage_budget_calculator_submit: {
            click: 'RealEstateStartpage::BudgetCalculator::Submit',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_ad_insertion_button_click: {
            click: 'RealEstateStartpage::AdInsertion',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_brokers_click: {
            click: 'RealEstateStartpage::Brokers',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_topad_click: {
            click: 'RealEstateStartpage::TopAd',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_link_vomit_buy_click: {
            click: 'RealEstateStartpage::DirectLink::Buy',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_link_vomit_rent_click: {
            click: 'RealEstateStartpage::DirectLink::Rent',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_link_vomit_mortgages_calculator_click: {
            click: 'RealEstateStartpage::DirectLink::MortgagesCalculator',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_link_vomit_mortgages_budget_calculator_click: {
            click: 'RealEstateStartpage::DirectLink::MortgagesBudgetCalculator',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_link_vomit_mortgages_refinance_click: {
            click: 'RealEstateStartpage::DirectLink::MortgagesRefinance',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_link_vomit_mortgages_request_offer_click: {
            click: 'RealEstateStartpage::DirectLink::MortgagesRequestOffer',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_last_searches_click: {
            click: 'RealEstateStartpage::MyLastSearches',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_last_searches_search_click: {
            click: 'RealEstateStartpage::MyLastSearches::SearchResult',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_show_all_regions_click: {
            click: 'RealEstateStartpage::ShowAll::Regions',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_autocomplete_click: {
            click: 'RealEstateStartpage::Autocomplete',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_nc_flats_click: {
            click: 'RealEstateStartpage::NewConstructionFlatsDirect',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_nc_houses_click: {
            click: 'RealEstateStartpage::NewConstructionHousesDirect',
            s2: xitiLevel2Id,
        },
        real_estate_vertical_home_bubble_click: {
            click: `RealEstateStartpage::Bubbles::${sanitize(params.taggingId)}`,
            s2: xitiLevel2Id,
        },
        price_finder_results_edit: {
            click: `PriceFinder::Results::Edit`,
            s2: xitiLevel2Id,
        },
        price_finder_input_submit: {
            click: `PriceFinder::Submit`,
            s2: xitiLevel2Id,
        },
        price_finder_results_restart: {
            click: `PriceFinder::Results::Restart`,
            s2: xitiLevel2Id,
        },
        adservices_textlink_promotion_click: {
            click: `AdDetail::Adservices::Promotion`,
            s2: xitiLevel2Id,
        },
        bap_vertical_home_webshop_info_click: {
            click: 'MarketplaceStartpage::InfoWebShop',
            s2: xitiLevel2Id,
        },
        bap_vertical_home_webshop_search_click: {
            click: 'MarketplaceStartpage::WebShops',
            s2: xitiLevel2Id,
        },
        vertical_home_paylivery_toggle: {
            click: 'MarketplaceStartpage::PayliveryToggle',
            s2: xitiLevel2Id,
        },
        vertical_home_image_search_icon: {
            click: 'MarketplaceStartpage::ImageSearchIcon',
            s2: xitiLevel2Id,
        },
        browse_paylivery_toggle: {
            click: 'MarketplaceBrowsePage::PayliveryToggle',
            s2: xitiLevel2Id,
        },
        vertical_home_keyword_search_search: {
            click: 'MarketplaceStartpage::KeywordSearch:Search',
            s2: xitiLevel2Id,
        },
        vertical_home_keyword_search_autocomplete: {
            click: 'MarketplaceStartpage::KeywordSearch:Autocomplete',
            s2: xitiLevel2Id,
        },
        vertical_home_keyword_search_last: {
            click: 'MarketplaceStartpage::KeywordSearch:LastSearch',
            s2: xitiLevel2Id,
        },
        bap_vertical_home_topad_click: {
            click: 'MarketplaceStartpage::TopAd',
            s2: xitiLevel2Id,
        },
        bap_vertical_home_trend_click: {
            click: `MarketplaceStartpage::Trends::${sanitize(trendLabel)}`,
            s2: xitiLevel2Id,
        },
        bap_vertical_home_ad_insertion_button_click: {
            click: 'MarketplaceStartpage::AdInsertion',
            s2: xitiLevel2Id,
        },
        browse_trend_click: {
            click: `MarketplaceBrowsePage::Trends::${sanitize(trendLabel)}`,
            s2: xitiLevel2Id,
        },
        browse_keyword_search_search: {
            click: 'MarketplaceBrowsePage::KeywordSearch:Search',
            s2: xitiLevel2Id,
        },
        browse_keyword_search_autocomplete: {
            click: 'MarketplaceBrowsePage::KeywordSearch:Autocomplete',
            s2: xitiLevel2Id,
        },
        browse_keyword_search_last: {
            click: 'MarketplaceBrowsePage::KeywordSearch:LastSearch',
            s2: xitiLevel2Id,
        },
        vertical_home_nearme_click: {
            click: 'MarketplaceStartpage::NearMe',
            s2: xitiLevel2Id,
        },
        vertical_home_giveaway_click: {
            click: 'MarketplaceStartpage::GiveAway',
            s2: xitiLevel2Id,
        },
        vertical_home_allads_click: {
            click: 'MarketplaceStartpage::AllAds',
            s2: xitiLevel2Id,
        },
        vertical_home_category_click: {
            click: `MarketplaceStartpage::${category || 'Sonstige'}`,
            s2: xitiLevel2Id,
        },
        vertical_home_popular_category_click: {
            click: `MarketplaceStartpage::PopularCategory::${sanitize(params.popularCategory)}`,
            s2: xitiLevel2Id,
        },
        vertical_home_make_click: {
            click: `MarketplaceStartpage::Make::${sanitize(params.make)}`,
            s2: xitiLevel2Id,
        },
        browse_breadcrumb_click: {
            click: `MarketplaceBrowsePage::Breadcrumb::${category || 'Sonstige'}`,
            s2: xitiLevel2Id,
        },
        browse_show_all_ads_click: {
            click: `MarketplaceBrowsePage::ShowAllAds::${category || 'Sonstige'}`,
            s2: xitiLevel2Id,
        },
        browse_show_all_ads_button_click: {
            click: `MarketplaceBrowsePage::ShowAllAdsButton::${category || 'Sonstige'}`,
            s2: xitiLevel2Id,
        },
        browse_category_click: {
            click: `MarketplaceBrowsePage::${category || 'Sonstige'}`,
            s2: xitiLevel2Id,
        },
        browse_ad_insertion_button_click: {
            click: 'MarketplaceBrowsePage::AdInsertion',
            s2: xitiLevel2Id,
        },
        browse_topad_click: {
            click: 'MarketplaceBrowsePage::TopAd',
            s2: xitiLevel2Id,
        },
        detail_search_container_search_agent_click: {
            click: `DetailSearch::${category || 'Sonstige'}::ActivateSearchAgent`,
            s2: xitiLevel2Id,
        },
        detail_search_search_button_click: {
            click: `DetailSearch::${category || 'Sonstige'}::Search`,
            s2: xitiLevel2Id,
        },

        jobs_navigation_employer_link_header: { click: 'navigationclickMenu::Employerheader', s2: '11' },

        jobs_my_searchagents_open_searchagent: { click: 'MyJob::MySearchAgents::Open::SearchResultList', s2: '10' },
        jobs_my_searchagents_edit_click: { click: 'MyJob::MySearchAgents::Edit', s2: '10' },
        jobs_my_searchagents_edit_submit_click: { click: 'MyJob::MySearchAgents::Edit::Save', s2: '10' },
        jobs_my_searchagents_email_deactivate: { click: 'MyJob::MySearchAgents::InactiveOn', s2: '10' },
        jobs_my_searchagents_email_activate: { click: 'MyJob::MySearchAgents::ActiveOn', s2: '10' },
        jobs_my_searchagents_delete_click: { click: 'MyJob::MySearchAgents::Delete', s2: '10' },
        jobs_my_searchagents_searchemptylist: { click: 'MyJob::MySearchAgents::SearchEmptyList', s2: '10' },

        jobs_my_savedads_open_ad: { click: 'MyJob::MySavedAds::Open::AdDetail', s2: '10' },
        jobs_my_savedads_delete_click: { click: 'MyJob::MySavedAds::Delete', s2: '10' },
        jobs_my_savedads_comment_add_click: { click: 'MyJob::MySavedAds::AddComment', s2: '10' },
        jobs_my_savedads_comment_submit_click: { click: 'MyJob::MySavedAds::AddComment::Save', s2: '10' },
        jobs_my_savedads_comment_delete_click: { click: 'MyJob::MySavedAds::DeleteComment', s2: '10' },
        jobs_my_savedads_searchemptylist: { click: 'MyJob::MySavedAds::SearchEmptyList', s2: '10' },

        jobs_addetail_follow_company_click: { click: 'AdDetail::FollowCompany', s2: '1' },
        jobs_addetail_unfollow_company_click: { click: 'AdDetail::UnfollowCompany', s2: '1' },
        jobs_addetail_savead_save_click: { click: 'AdDetail::SaveAd', s2: '1' },
        jobs_addetail_savead_unsave_click: { click: 'AdDetail::UnsaveAd', s2: '1' },
        jobs_addetail_company_profile_top_click: { click: 'AdDetail::OpenCompanyprofileTop', s2: '1' },
        jobs_addetail_company_profile_bottom_click: { click: 'AdDetail::OpenCompanyprofileBottom', s2: '1' },
        jobs_addetail_application_internal_send_click: { click: 'AdDetail::SendApply_Intern', s2: '1' },
        jobs_addetail_application_external_open_click: { click: 'AdDetail::SendApply_Extern', s2: '1' },
        jobs_addetail_share_webshareapi_click: { click: 'AdDetail::Share::WebShareApi', s2: '1' },
        jobs_addetail_share_email_click: { click: 'AdDetail::Share::Email', s2: '1' },
        jobs_addetail_share_whatsapp_click: { click: 'AdDetail::Share::Whatsapp', s2: '1' },
        jobs_addetail_share_facebook_click: { click: 'AdDetail::Share::Facebook', s2: '1' },
        jobs_addetail_share_twitter_click: { click: 'AdDetail::Share::Twitter', s2: '1' },
        jobs_addetail_share_linkedin_click: { click: 'AdDetail::Share::LinkedIn', s2: '1' },
        jobs_addetail_share_link_click: { click: 'AdDetail::Share::Link', s2: '1' },
        jobs_addetail_print_click: { click: 'AdDetail::Print', s2: '1' },
        jobs_addetail_breadcrumbs_navigation_click: { click: 'AdDetail::Breadcrumb', s2: '1' },
        jobs_addetail_similarad_click: { click: 'AdDetail::SimilarAds::Ad', s2: '1' },
        jobs_addetail_similarad_showalltext_click: { click: 'AdDetail::SimilarAds::ShowAll', s2: '1' },
        jobs_addetail_similarad_showallbutton_click: { click: 'AdDetail::SimilarAds::ShowAllButton', s2: '1' },
        jobs_addetail_back_to_result_list_click: { click: 'AdDetail::Navigation::BacktoResults', s2: '1' },
        jobs_addetail_previous_click: { click: 'AdDetail::Navigation::Previous', s2: '1' },
        jobs_addetail_next_click: { click: 'AdDetail::Navigation::Next', s2: '1' },
        jobs_addetail_loginhintbanner_loginregister_click: { click: 'AdDetail::LoginBanner', s2: '1' },
        jobs_addetail_loginhintbanner_close_click: { click: 'AdDetail::LoginBanner::Close', s2: '1' },
        // new application form
        jobs_addetail_unfolds_contact_data_fields_click: { click: 'AdDetail::ApplicationContactData::Expand', s2: '1' },
        jobs_addetail_folds_contact_data_fields_click: { click: 'AdDetail::ApplicationContactData::Collapse', s2: '1' },
        jobs_addetail_save_application_contact_data_toggle: { click: 'AdDetail::ApplicationContactData::Save', s2: '1' },
        jobs_addetail_unsave_application_contact_data_toogle: { click: 'AdDetail::ApplicationContactData::Unsave', s2: '1' },
        jobs_addetail_upload_cv_button: { click: 'AdDetail::ApplicationCV::Upload', s2: '1' },
        jobs_addetail_delete_cv_button: { click: 'AdDetail::ApplicationCV::Delete', s2: '1' },
        jobs_addetail_attachments_button: { click: 'AdDetail::ApplicationOtherDoc::Upload', s2: '1' },
        jobs_addetail_delete_attachments_button: { click: 'AdDetail::ApplicationOtherDoc::Delete', s2: '1' },
        jobs_addetail_add_message_button: { click: 'AdDetail::ApplicationPersonalMessage', s2: '1' },
        jobs_addetail_send_copy_checkbox: { click: 'AdDetail::ApplicationSendCopy', s2: '1' },

        jobs_companysearch_breadcrumbs_navigation_click: { click: 'JobCompanySearch::Breadcrumb', s2: '1' },
        jobs_companysearch_companyprofile_click: { click: 'JobCompanySearch::JobCompanyProfile', s2: '1' },
        jobs_companysearch_keyword_search: { click: 'JobCompanySearch::KeywordSearch::Search', s2: '1' },
        jobs_companysearch_filter_other: { click: 'JobCompanySearch::Filter::Other', s2: '1' },
        jobs_companysearch_loginhintbanner_loginregister_click: { click: 'JobCompanySearch::LoginBanner', s2: '1' },
        jobs_companysearch_loginhintbanner_close_click: { click: 'JobCompanySearch::LoginBanner::Close', s2: '1' },

        jobs_companyprofile_websitelink_click: { click: 'JobCompanyProfile::ExternalWebsite', s2: '1' },
        jobs_companyprofile_share_webshareapi_click: { click: 'JobCompanyProfile::Share::WebShareApi', s2: '1' },
        jobs_companyprofile_share_email_click: { click: 'JobCompanyProfile::Share::Email', s2: '1' },
        jobs_companyprofile_share_whatsapp_click: { click: 'JobCompanyProfile::Share::Whatsapp', s2: '1' },
        jobs_companyprofile_share_facebook_click: { click: 'JobCompanyProfile::Share::Facebook', s2: '1' },
        jobs_companyprofile_share_twitter_click: { click: 'JobCompanyProfile::Share::Twitter', s2: '1' },
        jobs_companyprofile_share_linkedin_click: { click: 'JobCompanyProfile::Share::LinkedIn', s2: '1' },
        jobs_companyprofile_share_link_click: { click: 'JobCompanyProfile::Share::Link', s2: '1' },
        jobs_companyprofile_follow_company_click: { click: 'JobCompanyProfile::Follow', s2: '1' },
        jobs_companyprofile_unfollow_company_click: { click: 'JobCompanyProfile::Unfollow', s2: '1' },
        jobs_companyprofile_contact_click: { click: 'JobCompanyProfile::Contact', s2: '1' },
        jobs_companyprofile_addetail_click: { click: 'JobCompanyProfile::AdDetail', s2: '1' },
        jobs_companyprofile_addetail_newbadge_click: { click: 'JobCompanyProfile::NewBadge', s2: '1' },
        jobs_companyprofile_firm_click: { click: 'JobCompanyProfile::Firm', s2: '1' },
        jobs_companyprofile_topjob_click: { click: 'JobCompanyProfile::TopJob', s2: '1' },
        jobs_companyprofile_navigationbubble_about_click: { click: 'JobCompanyProfile::NavigationBubble::About', s2: '1' },
        jobs_companyprofile_navigationbubble_contact_click: { click: 'JobCompanyProfile::NavigationBubble::Contact', s2: '1' },
        jobs_companyprofile_navigationbubble_currentjobs_click: { click: 'JobCompanyProfile::NavigationBubble::Jobs', s2: '1' },
        jobs_companyprofile_savead_click: { click: 'JobCompanyProfile::SaveAd', s2: '1' },
        jobs_companyprofile_unsavead_click: { click: 'JobCompanyProfile::UnsaveAd', s2: '1' },
        jobs_companyprofile_resultlist_ad_insertion_click: { click: 'JobCompanyProfile::AdInsertion', s2: '1' },
        jobs_companyprofile_breadcrumb_click: { click: 'JobCompanyProfile::Breadcrumb', s2: '1' },
        jobs_companyprofile_keyword_search: { click: 'JobCompanyProfile::KeywordSearch::Search', s2: '1' },
        jobs_companyprofile_keyword_autocomplete: { click: 'JobCompanyProfile::KeywordSearch::Autocomplete', s2: '1' },
        jobs_companyprofile_location_search: { click: 'JobCompanyProfile::LocationSearch::Search', s2: '1' },
        jobs_companyprofile_radiussearch_search_click: {
            click: `JobCompanyProfile::RadiusSearch::${sanitize(params.radius_value)}`,
            s2: '1',
        },
        jobs_companyprofile_show_all_regions: { click: 'JobCompanyProfile::ShowAll::Filter::Regions', s2: '1' },
        jobs_companyprofile_show_all_categories: { click: 'JobCompanyProfile::Filter::ShowAll::Categories', s2: '1' },
        jobs_companyprofile_position_click: { click: 'JobCompanyProfile::Filter::Position', s2: '1' },
        jobs_companyprofile_employmentmode_click: { click: 'JobCompanyProfile::Filter::Employmentmode', s2: '1' },
        jobs_companyprofile_loginhintbanner_loginregister_click: { click: 'JobCompanyProfile::LoginBanner', s2: '1' },
        jobs_companyprofile_loginhintbanner_close_click: { click: 'JobCompanyProfile::LoginBanner::Close', s2: '1' },

        jobs_resultlist_keywordsearch_search_click: { click: 'ResultListJob::KeywordSearch::Search', s2: '1' },
        jobs_resultlist_keywordsearch_autocomplete_click: { click: 'ResultListJob::KeywordSearch::Autocomplete', s2: '1' },
        jobs_resultlist_keywordsearch_last_search_click: { click: 'ResultListJob::KeywordSearch::LastSearch', s2: '1' },
        jobs_resultlist_locationsearch_search_click: { click: 'ResultListJob::LocationSearch::Search', s2: '1' },
        jobs_resultlist_locationsearch_autocomplete_click: { click: 'ResultListJob::LocationSearch::Autocomplete', s2: '1' },
        jobs_resultlist_locationsearch_last_search_click: { click: 'ResultListJob::LocationSearch::LastSearch', s2: '1' },
        jobs_resultlist_radiussearch_search_click: {
            click: `ResultListJob::RadiusSearch::${sanitize(params.radius_value)}`,
            s2: '1',
        },
        jobs_resultlist_filter_timelimit_click: {
            click: `ResultListJob::Filter::Date::${sanitize(params.timeLimitValue)}`,
            s2: '1',
        },
        jobs_resultlist_filter_show_all_categories: { click: 'ResultListJob::Filter::ShowAll::Categories', s2: '1' },
        jobs_resultlist_filter_employmentmode_click: { click: 'ResultListJob::Filter::Employmentmode', s2: '1' },
        jobs_resultlist_filter_position_click: { click: 'ResultListJob::Filter::Position', s2: '1' },
        jobs_resultlist_filter_companytype_click: { click: 'ResultListJob::Filter::Companytype', s2: '1' },
        jobs_resultlist_filter_show_all_regions: { click: 'ResultListJob::Filter::ShowAll::Regions', s2: '1' },
        jobs_resultlist_removeattribute_filter_click: { click: 'ResultListJob::RemoveAttribute::Filter', s2: '1' },
        jobs_resultlist_removeattribute_top_click: { click: 'ResultListJob::RemoveAttribute::Top', s2: '1' },
        jobs_resultlist_removeattribute_left_click: { click: 'ResultListJob::RemoveAttribute::Left', s2: '1' },
        jobs_resultlist_savead_click: { click: 'ResultListJob::SaveAd', s2: '1' },
        jobs_resultlist_unsavead_click: { click: 'ResultListJob::UnsaveAd', s2: '1' },
        jobs_resultlist_breadcrumb_click: { click: 'ResultListJob::Breadcrumb', s2: '1' },
        jobs_resultlist_resetfilter_click: { click: 'ResultListJob::ResetFilter', s2: '1' },
        jobs_resultlist_searchagent_top_click: { click: 'ResultListJob::SearchAgent::Top', s2: '1' },
        jobs_resultlist_searchagent_sticky_click: { click: 'ResultListJob::SearchAgent::Sticky', s2: '1' },
        jobs_resultlist_searchagent_bottom_click: { click: 'ResultListJob::SearchAgent::Bottom', s2: '1' },
        jobs_resultlist_searchagent_emptyresult_click: { click: 'ResultListJob::SearchAgent::EmptyResult', s2: '1' },
        jobs_resultlist_searchagent_popup_create_click: { click: 'ResultListJob::SearchAgentPopUp::Activate', s2: '1' },
        jobs_resultlist_searchagent_popup_close_click: { click: 'ResultListJob::SearchAgentPopUp::Close', s2: '1' },
        jobs_resultlist_addetail_click: { click: 'ResultListJob::AdDetail', s2: '1' },
        jobs_resultlist_addetail_newbadge_click: { click: 'ResultListJob::NewBadge', s2: '1' },
        jobs_resultlist_firm_click: { click: 'ResultListJob::Firm', s2: '1' },
        jobs_resultlist_topjob_click: { click: 'ResultListJob::TopJob', s2: '1' },
        jobs_resultlist_linkvomit_click: { click: `ResultListJob::LinkVomit::${trendLabel}`, s2: '1' },
        jobs_resultlist_ad_insertion_click: { click: 'ResultListJob::AdInsertation', s2: '1' },
        jobs_resultlist_filter_bubble_click: { click: 'ResultListJob::Filter::Bubbles', s2: '1' },
        jobs_resultlist_category_suggestion_click: { click: 'ResultListJob::CategorySuggestion', s2: '1' },
        jobs_resultlist_loginhintbanner_loginregister_click: { click: 'ResultListJob::LoginBanner', s2: '1' },
        jobs_resultlist_loginhintbanner_close_click: { click: 'ResultListJob::LoginBanner::Close', s2: '1' },

        jobs_employer_buy_premium_bundle_1: { click: 'Buy::premiumbundle::1', s2: '1' },
        jobs_employer_buy_premium_bundle_3: { click: 'Buy::premiumbundle::3', s2: '1' },
        jobs_employer_buy_premium_bundle_5: { click: 'Buy::premiumbundle::5', s2: '1' },
        jobs_employer_buy_standard_bundle_1: { click: 'Buy::standardbundle::1', s2: '1' },
        jobs_employer_buy_standard_bundle_3: { click: 'Buy::standardbundle::3', s2: '1' },
        jobs_employer_buy_standard_bundle_5: { click: 'Buy::standardbundle::5', s2: '1' },
        jobs_employer_buy_basic_bundle_1: { click: 'Buy::basicbundle::1', s2: '1' },
        jobs_employer_buy_basic_bundle_3: { click: 'Buy::basicbundle::3', s2: '1' },
        jobs_employer_buy_basic_bundle_5: { click: 'Buy::basicbundle::5', s2: '1' },
        jobs_employer_new_customer_bundle_button: { click: 'Employer::SelfService::Buy::NewCustomerBundle ', s2: '1' },

        jobs_employer_menu_to_dashboard: { click: 'Menu::ToDashboard', s2: '1' },
        jobs_employer_menu_logo: { click: 'Menu::Logo', s2: '1' },
        jobs_employer_open_support_form: { click: 'OpenSupportForm', s2: '1' },
        jobs_employer_call_support: { click: 'CallSupport', s2: '1' },
        jobs_employer_self_service_landingpage_adinsertion: { click: 'Employer::SelfSerivce::Landingpage::AdInsertion', s2: '1' },
        jobs_employer_self_service_landingpage_tab: { click: 'Employer::SelfService::Landingpage::Tab', s2: '1' },
        jobs_employer_self_service_landingpage_ad_insertion: { click: 'Employer::SelfSerivce::Landingpage::AdInsertion', s2: '1' },
        jobs_employer_self_service_landingpage_more_information: { click: 'Employer::SelfService::Landingpage::MoreInformation', s2: '1' },
        jobs_employer_self_service_landingpage_referral_button: { click: 'Employer::SelfService::Landingpage::Referral', s2: '1' },

        jobs_employer_full_service_open_products_contact: { click: 'Employer::FullService::OpenProductContact', s2: '1' },
        jobs_employer_full_service_job_boost: { click: 'Employer::FullService::Landingpage::JobBoost', s2: '1' },

        jobs_employer_full_service_fact_sheet: { click: 'Employer::FullService::Landingpage::FactsheetDownload', s2: '1' },
        jobs_employer_full_service_call_support: { click: 'CallSupport', s2: '1' },
        jobs_employer_full_service_open_support_form: { click: 'OpenSupportForm', s2: '1' },
        jobs_employer_full_service_more_information: { click: 'Employer::FullService::Landingpage::MoreInformation', s2: '1' },
        jobs_employer_full_service_tab: { click: 'Employer::FullService::Landingpage::Tab', s2: '1' },

        jobs_employer_branding_page_tab: { click: 'Employer::EmployerBranding::Landingpage::Tab', s2: '1' },
        jobs_employer_branding_page_job_boost_recruiting_communication: {
            click: 'Employer::EmployerBranding::Landingpage::RecruitingCommunication',
            s2: '1',
        },
        jobs_employer_branding_page_our_price_list_employer_communication: {
            click: 'Employer::EmployerBranding::Landingpage::EmployerCommunication',
            s2: '1',
        },
        jobs_employer_branding_page_to_the_apprenticeship_weeks_campaign: {
            click: 'Employer::EmployerBranding::Landingpage::Campaign',
            s2: '1',
        },
        jobs_employer_branding_all_case_studies: {
            click: 'Employer::EmployerBranding::Landingpage::CaseStudies',
            s2: '1',
        },
        jobs_employer_downloads_landingpage_download_button: {
            click: 'Employer::Downloads::Landingpage::Download',
            s2: '1',
        },
        jobs_employer_downloads_landingpage_download_tab: {
            click: 'Employer::Downloads::Landingpage::Tab',
            s2: '1',
        },
        jobs_employer_adinsertion_textgeneration_click: {
            click: 'MyEmployer::JobAdInsertion::AITextGeneration',
            s2: '1',
        },
        jobs_employer_adinsertion_textgeneration_repeat: {
            click: 'MyEmployer::JobAdInsertion::AITextGeneration::Repeat',
            s2: '1',
        },

        jobs_employer_adinsertion_willhaben_logo_click: {
            click: 'MyEmployer::JobAdInsertion::Logo',
            s2: '1',
        },
        jobs_employer_adinsertion_navigation_overview_click: {
            click: 'MyEmployer::JobAdinsertion::MyOverview',
            s2: '1',
        },
        jobs_employer_adinsertion_navigation_ads_click: {
            click: 'MyEmployer::JobAdInsertion::MyAds',
            s2: '1',
        },
        jobs_employer_adinsertion_navigation_company_profile_click: {
            click: 'MyEmployer::JobAdInsertion::MyCompanyProfile',
            s2: '1',
        },
        jobs_employer_adinsertion_navigation_new_ad_click: {
            click: 'MyEmployer::JobAdInsertion::AdInsertion',
            s2: '1',
        },
        jobs_employer_adinsertion_bundle_product_click: {
            click: 'MyEmployer::JobAdInsertion::Product::Bundle',
            s2: '1',
        },
        jobs_employer_adinsertion_fullservice_product_click: {
            click: 'MyEmployer::JobAdInsertion::Product::FullService',
            s2: '1',
        },
        jobs_employer_adinsertion_advert_template_click: {
            click: 'MyEmployer::JobAdInsertion::Product::Template',
            s2: '1',
        },
        jobs_employer_adinsertion_image_upload_click: {
            click: 'MyEmployer::JobAdInsertion::ImageUpload',
            s2: '1',
        },
        jobs_employer_adinsertion_job_title_input_click: {
            click: 'MyEmployer::JobAdInsertion::Jobtitle',
            s2: '1',
        },
        jobs_employer_adinsertion_job_subtitle_click: {
            click: 'MyEmployer::JobAdInsertion::JobSubtitle',
            s2: '1',
        },
        jobs_employer_adinsertion_category_click: {
            click: 'MyEmployer::JobAdInsertion::Category',
            s2: '1',
        },
        jobs_employer_adinsertion_category_suggestion_click: {
            click: 'MyEmployer::JobAdInsertion::Category::Suggestion',
            s2: '1',
        },
        jobs_employer_adinsertion_remove_category_suggestion_click: {
            click: 'MyEmployer::JobAdInsertion::Category::RemoveAttribute',
            s2: '1',
        },
        jobs_employer_adinsertion_location_input_click: {
            click: 'MyEmployer::JobAdinsertion::Location',
            s2: '1',
        },
        jobs_employer_adinsertion_remove_location_click: {
            click: 'MyEmployer::JobAdInsertion::Location::RemoveAttribute',
            s2: '1',
        },
        jobs_employer_adinsertion_country_click: {
            click: 'MyEmployer::JobAdInsertion::Country',
            s2: '1',
        },
        jobs_employer_adinsertion_salary_input_click: {
            click: 'MyEmployer::JobAdInsertion::Salary',
            s2: '1',
        },
        jobs_employer_adinsertion_salary_bubble_monthly_click: {
            click: 'MyEmployer::JobAdInsertion::Salary::Monthly',
            s2: '1',
        },
        jobs_employer_adinsertion_salary_bubble_yearly_click: {
            click: 'MyEmployer::JobAdInsertion::Salary::Yearly',
            s2: '1',
        },
        jobs_employer_adinsertion_salary_bubble_hourly_click: {
            click: 'MyEmployer::JobAdInsertion::Salary::Hourly',
            s2: '1',
        },
        jobs_employer_adinsertion_salary_overpay_checkbox_click: {
            click: 'MyEmployer::JobAdInsertion::Salary::Overpayment',
            s2: '1',
        },
        jobs_employer_adinsertion_salary_tips_link_click: {
            click: 'MyEmployer::JobAdInsertion::Salary::Tips	',
            s2: '1',
        },
        jobs_employer_adinsertion_position_bubble_click: {
            click: 'MyEmployer::JobAdInsertion::Position',
            s2: '1',
        },
        jobs_employer_adinsertion_job_start_bubble_click: {
            click: 'MyEmployer::JobAdInsertion::JobStart',
            s2: '1',
        },
        jobs_employer_adinsertion_home_office_bubble_click: {
            click: 'MyEmployer::JobAdInsertion::HomeOffice',
            s2: '1',
        },
        jobs_employer_adinsertion_experience_bubble_click: {
            click: 'MyEmployer::JobAdInsertion::Experience',
            s2: '1',
        },

        jobs_employer_adinsertion_employmentmode_checkbox_click: {
            click: 'MyEmployer::JobAdInsertion::EmploymentMode',
            s2: '1',
        },
        jobs_employer_adinsertion_add_foreign_languages_click: {
            click: 'MyEmployer::JobAdInsertion::Language',
            s2: '1',
        },
        jobs_employer_adinsertion_remove_foreign_languages_click: {
            click: 'MyEmployer::JobAdInsertion::Language::RemoveAttribute',
            s2: '1',
        },
        jobs_employer_adinsertion_add_more_foreign_languages_click: {
            click: 'MyEmployer::JobAdInsertion::Language::ShowAll',
            s2: '1',
        },
        jobs_employer_adinsertion_keywords_click: {
            click: 'MyEmployer::JobAdInsertion::AdText::Tags',
            s2: '1',
        },
        jobs_employer_adinsertion_reset_contact_person_data_click: {
            click: 'MyEmployer::JobAdInsertion::Contact::Reset',
            s2: '1',
        },
        jobs_employer_adinsertion_contact_person_gender_click: {
            click: 'MyEmployer::JobAdInsertion::Contact::Gender',
            s2: '1',
        },
        jobs_employer_adinsertion_contact_person_title_click: {
            click: 'MyEmployer::JobAdInsertion::Contact::Title',
            s2: '1',
        },
        jobs_employer_adinsertion_contact_person_firstname_click: {
            click: 'MyEmployer::JobAdinsertion::Contact::FirstName',
            s2: '1',
        },
        jobs_employer_adinsertion_contact_person_lastname_click: {
            click: 'MyEmployer::JobAdInsertion::Contact::LastName',
            s2: '1',
        },
        jobs_employer_adinsertion_contact_person_mail_input_click: {
            click: 'MyEmployer::JobAdInsertion::Contact::Email',
            s2: '1',
        },
        jobs_employer_adinsertion_reference_id_click: {
            click: 'MyEmployer::JobAdInsertion::Contact::ReferenceID',
            s2: '1',
        },
        jobs_employer_adinsertion_preview_button_click: {
            click: 'MyEmployer::JobAdInsertion::Preview',
            s2: '1',
        },
        jobs_employer_adinsertion_save_button_click: {
            click: ' MyEmployer::JobAdInsertion::Draft::Save',
            s2: '1',
        },
        jobs_employer_adinsertion_publish_button_click: {
            click: 'MyEmployer::JobAdInsertion::Publish',
            s2: '1',
        },
        jobs_employer_adinsertion_by_mail_bubble_click: {
            click: 'MyEmployer::JobAdInsertion::Contact::ViaEmail',
            s2: '1',
        },
        jobs_employer_adinsertion_by_application_form_bubble_click: {
            click: 'MyEmployer::JobAdInsertion::Contact::ViaApplicationForm',
            s2: '1',
        },
        jobs_employer_adinsertion_jobstitle_tipp_click: {
            click: 'MyEmployer::JobAdInsertion::Tipp::Jobtitle',
            s2: '1',
        },
        jobs_employer_ats_goto_ad_overview_click: {
            click: 'ToAdOverview',
            s2: '1',
        },
        jobs_employer_ats_open_application_click: {
            click: 'OpenApplication',
            s2: '1',
        },
        jobs_employer_ats_sendmessage_to_applicant_click: {
            click: 'SendMessageToApplicant',
            s2: '1',
        },
        jobs_employer_ats_call_applicant_click: {
            click: 'CallApplicant',
            s2: '1',
        },
        jobs_employer_ats_application_attachment_click: {
            click: 'ApplicationAttachment',
            s2: '1',
        },
        jobs_employer_ats_download_attachment_click: {
            click: 'DownloadAttachment',
            s2: '1',
        },

        ad_widget_ad_click: {
            click: taggingId ? `${page}::${widgetType}::${taggingId}::Ad` : `${page}::${widgetType}::Ad`,
            s2: xitiLevel2Id,
        },
        ad_widget_show_all_click: {
            click: taggingId ? `${page}::${widgetType}::${taggingId}::ShowAll` : `${page}::${widgetType}::ShowAll`,
            s2: xitiLevel2Id,
        },
        ad_widget_show_all_button_click: {
            click: taggingId ? `${page}::${widgetType}::${taggingId}::ShowAllButton` : `${page}::${widgetType}::ShowAllButton`,
            s2: xitiLevel2Id,
        },
        startpage_myfolder_widget_show_similar_ads_button_click: {
            click: `${page}::${widgetType}::ShowSimilarAds`,
            s2: xitiLevel2Id,
        },
        ad_widget_click_interaction: {
            xiti_selfpromotion_click: {
                adId: '10',
                productId: page!,
                format: widgetType!,
            },
        },
        ad_widget_in_view: {
            xiti_selfpromotion_impression: {
                adId: '10',
                productId: page!,
                format: widgetType!,
            },
        },
        startpage_nearby_request_location: {
            click: `Startpage::NearbyWidget::RequestLocationButton`,
            s2: xitiLevel2Id,
        },
        startpage_seller_ad_insertion: {
            click: `Startpage::SellerWidget::AdInsertion`,
            s2: xitiLevel2Id,
        },
        startpage_welcome_ad_insertion: {
            click: `Startpage::WelcomeWidget::AdInsertion`,
            s2: xitiLevel2Id,
        },
        startpage_welcome_login: {
            click: `Startpage::WelcomeWidget::Login`,
            s2: xitiLevel2Id,
        },
        startpage_error_search: {
            click: `Startpage::Error::Search`,
            s2: xitiLevel2Id,
        },
        startpage_hide_jobs_widget_click: {
            click: `Startpage::JobsWidget::Hide`,
            s2: xitiLevel2Id,
        },
        startpage_dismiss_last_search_click: {
            click: `Startpage::LastSearchesWidget::Dismiss`,
            s2: xitiLevel2Id,
        },
        startpage_trend_click: {
            click: `Startpage::Trends::${sanitize(trendLabel)}`,
            s2: xitiLevel2Id,
        },
        startpage_teaser_click: {
            click: `Startpage::Teaser::${params.adIndex}::${params.label}`,
            s2: xitiLevel2Id,
        },
        startpage_popular_category_click: {
            click: `Startpage::PopularCategories::${sanitize(params.popular_category_label)}`,
            s2: xitiLevel2Id,
        },
        startpage_bubble_list_click: {
            click: `Startpage::BubbleList::${sanitize(params.list_label)}::${sanitize(params.item_label)}`,
            s2: xitiLevel2Id,
        },
        startpage_seller_widget_click: {
            click: `Startpage::SellerWidget`,
            s2: xitiLevel2Id,
        },
        startpage_vertical_click: {
            click: `Startpage::Vertical::${sanitize(params.vertical_label)}`,
            s2: xitiLevel2Id,
        },
        startpage_sticky_vertical_click: {
            click: `Menu::Header::${sanitize(params.vertical_label)}`,
            s2: xitiLevel2Id,
        },
        startpage_trend_ad_slider_sujet_click: {
            click: `Startpage::TrendSlider::SujetClick::${taggingId}`,
            s2: xitiLevel2Id,
        },
        search_result_list_hide_jobs_widget_click: {
            click: `ResultList::JobsWidget::Hide`,
            s2: xitiLevel2Id,
        },
        jobs_startpage_keywordsearch_autocomplete_click: { click: 'JobStartpage::KeywordSearch::Autocomplete', s2: '1' },
        jobs_startpage_keywordsearch_autocomplete_search: { click: 'JobStartpage::KeywordSearch::Search', s2: '1' },
        jobs_startpage_keywordsearch_last_search_click: { click: 'JobStartpage::KeywordSearch::LastSearch', s2: '1' },
        jobs_startpage_locationsearch_autocomplete_click: { click: 'JobStartpage::LocationSearch::Autocomplete', s2: '1' },
        jobs_startpage_locationsearch_last_search_click: { click: 'JobStartpage::LocationSearch::LastSearch', s2: '1' },
        jobs_startpage_radiussearch_search_click: {
            click: `JobStartpage::RadiusSearch::${sanitize(params.radius_value)}`,
            s2: '1',
        },
        jobs_startpage_filter_show_all_categories: { click: 'JobStartpage::Filter::ShowAll::Categories', s2: '1' },
        jobs_startpage_filter_show_all_employmentmode: {
            click: 'JobStartpage::Filter::ShowAll::Employmentmode',
            s2: '1',
        },
        jobs_startpage_filter_show_all_position: { click: 'JobStartpage::Filter::ShowAll::Position', s2: '1' },
        jobs_startpage_lastsearch_click: { click: 'JobStartpage::LastSearch::Box', s2: '1' },
        jobs_startpage_topjob_click: { click: 'JobStartpage::TopJob', s2: '1' },
        jobs_startpage_lastviewedads_click: { click: 'JobStartpage::LastViewedAds::Ad', s2: '1' },
        jobs_startpage_adinsertion_click: { click: 'JobStartpage::AdInsertion', s2: '1' },
        jobs_startpage_trend_click: { click: `JobStartpage::Trends::${trendLabel}`, s2: '1' },
        jobs_startpage_morefilter_click: { click: 'JobStartpage::MoreFilter', s2: '1' },
        jobs_startpage_companysearch_click: { click: 'JobStartpage::FirmIndex', s2: '1' },
        jobs_startpage_operationarea_widget_click: {
            click: `Entrypoint::Startseite::Berufsfeld::${trendLabel}`,
            s2: '1',
        },
        jobs_startpage_region_widget_click: { click: `Entrypoint::Startseite::Region::${trendLabel}`, s2: '1' },
        jobs_startpage_linkvomit_click: { click: `JobStartpage::LinkVomit::${trendLabel}`, s2: '1' },
        jobs_startpage_foremployers_click: { click: 'JobStartpage::ForEmployers', s2: '1' },
        jobs_startpage_quicklinks_savedads_click: { click: 'JobStartpage::QuickLinks::SavedAds', s2: '1' },
        jobs_startpage_quicklinks_searchagents_click: { click: 'JobStartpage::QuickLinks::Searchagents', s2: '1' },
        jobs_startpage_loginhintbanner_loginregister_click: { click: 'JobStartpage::LoginBanner', s2: '1' },
        jobs_startpage_loginhintbanner_close_click: { click: 'JobStartpage::LoginBanner::Close', s2: '1' },

        estate_addetail_rental_share_create_tenant_profile_b2c: { click: 'AdDetail::ContactSeller::CreateTenantProfile::B2C', s2: '2' },
        estate_addetail_rental_share_create_tenant_profile_c2c: { click: 'AdDetail::ContactSeller::CreateTenantProfile::C2C', s2: '2' },

        estate_addetail_rental_share_tenant_profile_b2c: { click: 'AdDetail::ContactSeller::ShareTenantProfile::B2C', s2: '2' },
        estate_addetail_rental_share_tenant_profile_c2c: { click: 'AdDetail::ContactSeller::ShareTenantProfile::C2C', s2: '2' },

        estate_addetail_shadowmap: { click: 'AdDetail::Shadowmap', s2: '10' },

        content_page_srl_ad_click: {
            click: `ContentPage::SearchResultList::${taggingId}::Ad`,
            s2: xitiLevel2Id,
        },
        content_page_srl_show_all: {
            click: `ContentPage::SearchResultList::${taggingId}::ShowAll`,
            s2: xitiLevel2Id,
        },
        mytransactions_overview_filter_click: {
            click: `MyTransactions::${sanitize(params.mytransactions_target)}::Filter::${sanitize(params.mytransactions_filter)}`,
            s2: '10',
        },
        mytransactions_overview_empty_state_action_click: {
            click: `MyTransactions::${sanitize(params.mytransactions_target)}::EmptyState::${sanitize(params.mytransactions_empty_state_action)}`,
            s2: '10',
        },
        mytransactions_detailpage_show_modal: {
            click: `MyTransactions::${sanitize(params.mytransactions_target)}::DetailPage`,
            s2: '10',
        },
        mytransactions_detailpage_link_click: {
            click: `MyTransactions::${sanitize(params.mytransactions_target)}::DetailPage::${sanitize(params.mytransactions_detail_link)}`,
            s2: '10',
        },
    }
}
