import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { AdvertStatus } from '@wh/common/chapter/types/advertStatus'
import { CdcAdvertStatus } from '@bbx/common/types/UserFeed'

export const adIsActive = (advertStatus: AdvertStatus | CdcAdvertStatus) =>
    advertStatus.id === adStatusFlag.ACTIVE ||
    advertStatus.id === adStatusFlag.GIVEN_AWAY ||
    advertStatus.id === adStatusFlag.RENTED ||
    advertStatus.id === adStatusFlag.RESERVED

export const adDetailIsAccessible = (advertStatus: AdvertStatus | CdcAdvertStatus) =>
    adIsActive(advertStatus) || advertStatus.id === adStatusFlag.SOLD

export const adIsGivenAwayOrRentedOrSold = (advertStatus: AdvertStatus | CdcAdvertStatus) =>
    advertStatus.id === adStatusFlag.GIVEN_AWAY || advertStatus.id === adStatusFlag.RENTED || advertStatus.id === adStatusFlag.SOLD
