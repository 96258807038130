import { useCallback, useEffect, useState } from 'react'
import {
    getWhitelistedCookiesFromRequestOrDocument,
    WhitelistedCookieKey,
} from '@wh/common/chapter/components/CookieProvider/CookieProvider'

const DAYS_IN_MS = 1000 * 60 * 60 * 24
const expiresInOneYear = new Date(new Date().getTime() + DAYS_IN_MS * 365)

export const useCookieState = <T extends string | undefined>(
    cookieKey: WhitelistedCookieKey,
    initialState: T,
): [T, (newValue: T, expires?: Date) => void] => {
    const [cookieValue, setCookieValue] = useState<T>(initialState)

    useEffect(() => {
        const cookies = getWhitelistedCookiesFromRequestOrDocument(undefined, document)
        // @ts-ignore
        setCookieValue(cookies[cookieKey] ?? initialState)
    }, [cookieKey, initialState])

    const setCookieAndState = useCallback(
        (newValue: T, expires?: Date) => {
            document.cookie = `${cookieKey}=${newValue};path=/;expires=${(expires ?? expiresInOneYear).toUTCString()}`
            setCookieValue(newValue)
        },
        [cookieKey],
    )
    return [cookieValue, setCookieAndState]
}
