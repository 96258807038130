import { DebugFlagContext } from '@wh/common/chapter/components/DebugFlagProvider/DebugFlagProvider'
import { useDidomi } from '@wh/common/chapter/components/Didomi/useDidomi'
import { FeatureToggle, useFeatureToggle } from '@wh/common/chapter/components/FeatureToggleProvider/FeatureToggleProvider'
import { useTimeout } from '@wh/common/chapter/hooks/useTimeout'
import Head from 'next/head'
import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react'

declare global {
    interface Window {
        uz_feed?: unknown
        uz_voc?: unknown
        _uzactfeed?: unknown
    }
}

interface FeatureToggleUserZoomProps {
    interceptCode: string
    toggle: FeatureToggle
}

export const FeatureToggleUserZoom: FunctionComponent<FeatureToggleUserZoomProps> = ({ interceptCode, toggle }) => {
    const featureToggle = useFeatureToggle(toggle)
    return <UserZoom interceptCode={interceptCode} enabled={featureToggle} />
}

interface UserZoomProps {
    interceptCode: string
    enabled?: boolean
}

export const UserZoom: FunctionComponent<UserZoomProps> = ({ interceptCode, enabled }) => {
    const debugFlags = useContext(DebugFlagContext)
    const debugFlagsUserzoomModeRef = useRef(debugFlags.userzoomMode)
    debugFlagsUserzoomModeRef.current = debugFlags.userzoomMode
    const { isConsentGiven } = useDidomi('UserZoom')
    const [timeoutElapsed, setTimeoutElapsed] = useState(false)
    const renderUserZoom = timeoutElapsed && debugFlags.userzoomMode !== 'disable' && enabled && isConsentGiven

    useTimeout(() => {
        setTimeoutElapsed(true)
    }, 2000)

    useEffect(() => {
        return () => {
            if (debugFlagsUserzoomModeRef.current === 'disable') {
                return
            }

            // clean up userzoom on client-side-routing to a page that does not contain this component

            const removeElement = (e: Element | null) => {
                try {
                    e?.parentNode?.removeChild?.(e)
                } catch (_error) {
                    // ignore silently
                }
            }

            removeElement(document.querySelector("script[src*='userzoom.com'][src*='uz_feed_us.js']"))
            removeElement(document.querySelector("link[rel='stylesheet'][href*='userzoom.com'][href*='feedback.css']"))
            removeElement(document.querySelector(`script[src*='userzoom.com'][src$='voc.js']`))
            removeElement(document.querySelector('#uz_ft'))

            window.uz_feed = undefined
            window.uz_voc = undefined
            window._uzactfeed = undefined
        }
    }, [])

    return renderUserZoom ? (
        <Head>
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    // needsLibLoading fixes client-side-routing issues where the uz_feed.js library is loaded again, but is wrongly initialized and breaks
                    __html: `
                            var needsLibLoading = window._uzactfeed ? false : true
                            _uzactfeed = window._uzactfeed || [];
                            _uzactfeed.push(['_start']);
                            if (needsLibLoading) {
                                (function() {
                                     var uz = document.createElement('script'); uz.type = 'text/javascript'; uz.defer = true; uz.charset = 'utf-8';
                                     uz.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn4.userzoom.com/files/js/${interceptCode}.js?t=uz_feed&cuid=BB07E2106332E2119DA70022196C4538';
                                     var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(uz, s);
                                })();
                            }
                            `,
                }}
            />
        </Head>
    ) : null
}
