import React, { FunctionComponent } from 'react'
import { VerticalId, verticalIdMap, VerticalShortName } from '@wh/common/chapter/types/verticals'
import { AdType, AdTypeId, adTypeIdMap, inverseAdTypeMap } from '@wh/common/chapter/types/AdType'

import PlaceholderBap from '@wh-components/icons/PlaceholderBap'
import PlaceholderCar from '@wh-components/icons/PlaceholderCar'
import PlaceholderCaravan from '@wh-components/icons/PlaceholderCaravan'
import PlaceholderFlat from '@wh-components/icons/PlaceholderFlat'
import PlaceholderGarage from '@wh-components/icons/PlaceholderGarage'
import PlaceholderHoliday from '@wh-components/icons/PlaceholderHoliday'
import PlaceholderHouse from '@wh-components/icons/PlaceholderHouse'
import PlaceholderJob from '@wh-components/icons/PlaceholderJob'
import PlaceholderMotorbike from '@wh-components/icons/PlaceholderMotorbike'
import PlaceholderOffice from '@wh-components/icons/PlaceholderOffice'
import PlaceholderPlot from '@wh-components/icons/PlaceholderPlot'
import PlaceholderTruck from '@wh-components/icons/PlaceholderTruck'
import { SpaceProps } from '@wh-components/system/space'
import PlaceholderJobCompany from '@wh-components/icons/PlaceholderJobCompany'

export interface PlaceholderProps {
    adType: AdType | AdTypeId | number | undefined
    vertical?: VerticalShortName | VerticalId
    isHouse?: boolean
    size?: number | string
}

export const PlaceholderImage: FunctionComponent<PlaceholderProps & SpaceProps> = ({
    vertical,
    adType,
    isHouse,
    size = '100%',
    ...props
}) => {
    const adTypeId = typeof adType === 'string' ? inverseAdTypeMap[adType] : adType
    const verticalId = typeof vertical === 'string' ? verticalIdMap[vertical] : vertical

    return (
        placeholderIconTypeFromAdType(adTypeId, isHouse, size, props) ??
        placeholderIconTypeFromVertical(verticalId, size, props) ?? <PlaceholderBap size={size} color="palette.primary.main" {...props} />
    )
}

const placeholderIconTypeFromAdType = (
    adTypeId: number | undefined,
    isHouse: boolean = true,
    size: number | string,
    spaceProps: SpaceProps,
) => {
    switch (adTypeId) {
        case adTypeIdMap.BAP_FREE:
        case adTypeIdMap.BAP_WEBSTORE_OBJECT:
        case adTypeIdMap.BAP_SALESOBJECT_PRO:
            return <PlaceholderBap size={size} color="palette.primary.main" {...spaceProps} />
        case adTypeIdMap.MOTOR_CAR:
            return <PlaceholderCar size={size} color="palette.primary.main" {...spaceProps} />
        case adTypeIdMap.MOTOR_CARAVAN:
            return <PlaceholderCaravan size={size} color="palette.primary.main" {...spaceProps} />
        case adTypeIdMap.MOTOR_MC:
            return <PlaceholderMotorbike size={size} color="palette.primary.main" {...spaceProps} />
        case adTypeIdMap.MOTOR_VAN_TRUCK:
            return <PlaceholderTruck size={size} color="palette.primary.main" {...spaceProps} />
        case adTypeIdMap.ESTATE_SALE:
        case adTypeIdMap.ESTATE_RENT:
            return isHouse ? (
                <PlaceholderHouse size={size} color="palette.primary.main" {...spaceProps} />
            ) : (
                <PlaceholderFlat size={size} color="palette.primary.main" {...spaceProps} />
            )
        case adTypeIdMap.ESTATE_BUSINESS_SALE:
        case adTypeIdMap.ESTATE_BUSINESS_RENT:
            return <PlaceholderOffice size={size} color="palette.primary.main" {...spaceProps} />
        case adTypeIdMap.ESTATE_LEISURE_SALE:
        case adTypeIdMap.ESTATE_LEISURE_RENT:
            return <PlaceholderHoliday size={size} color="palette.primary.main" {...spaceProps} />
        case adTypeIdMap.ESTATE_PLOT_SALE:
            return <PlaceholderPlot size={size} color="palette.primary.main" {...spaceProps} />
        case adTypeIdMap.ESTATE_MISC:
            return <PlaceholderGarage size={size} color="palette.primary.main" {...spaceProps} />
        case adTypeIdMap.JOB_JOB:
            return <PlaceholderJob size={size} color="palette.primary.main" {...spaceProps} />
        case adTypeIdMap.JOB_COMPANY:
            return <PlaceholderJobCompany size={size} color="palette.primary.main" {...spaceProps} />
        default:
            return null
    }
}

const placeholderIconTypeFromVertical = (verticalId: VerticalId | undefined, size: number | string, spaceProps: SpaceProps) => {
    switch (verticalId) {
        case verticalIdMap.BAP:
            return <PlaceholderBap size={size} color="palette.primary.main" {...spaceProps} />
        case verticalIdMap.ESTATE:
            return <PlaceholderHouse size={size} color="palette.primary.main" {...spaceProps} />
        case verticalIdMap.MOTOR:
            return <PlaceholderCar size={size} color="palette.primary.main" {...spaceProps} />
        case verticalIdMap.JOB:
            return <PlaceholderJob size={size} color="palette.primary.main" {...spaceProps} />
        default:
            return null
    }
}
