import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import React, { FunctionComponent } from 'react'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'

export interface StoryblokBlogpostFeaturedRow extends StoryblokComponentType<string> {
    blogposts: SbBlokData[]
}

interface Props extends StoryblokDefaultProps<StoryblokBlogpostFeaturedRow> {}

export const StoryblokBlogpostFeaturedRow: FunctionComponent<Props> = ({ blok }) => {
    return (
        <Box
            display="grid"
            gridTemplateRows={{ phone: '236px 236px', tablet: '236px' }}
            gridTemplateColumns={{ phone: '1fr 1fr', tablet: '1fr 1fr 1fr 1fr' }}
            gap="s"
            {...storyblokEditable(blok as unknown as SbBlokData)}
        >
            {blok.blogposts.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </Box>
    )
}
