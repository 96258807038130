import { LOGGED_IN_HEADER_RENDER_COUNT } from './localStorage'
import { storageAvailable } from './storageAvailable'

export const incrementShouldShowEnterBirthYearVisitCount = () => {
    if (storageAvailable('localStorage')) {
        if (Number(localStorage.getItem(LOGGED_IN_HEADER_RENDER_COUNT)) <= 3) {
            localStorage.setItem(
                LOGGED_IN_HEADER_RENDER_COUNT,
                (Number(localStorage.getItem(LOGGED_IN_HEADER_RENDER_COUNT)) + 1).toString(),
            )
        }
    }
}

export const resetShouldShowEnterBirthYearVisitCount = () => {
    if (storageAvailable('localStorage')) {
        localStorage.setItem(LOGGED_IN_HEADER_RENDER_COUNT, '0')
    }
}
