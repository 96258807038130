import { createInstance, enums, logging, OptimizelyDecideOption, OptimizelyProvider } from '@optimizely/react-sdk'
import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react'
import UAParser from 'ua-parser-js'
import { isClient, isServer } from '../../lib/commonHelpers'
import { isLocal } from '../../lib/config/runtimeConfig'
import { CookieContext } from '../CookieProvider/CookieProvider'
import { DebugFlagContext } from '../DebugFlagProvider/DebugFlagProvider'
import { isUserLoggedIn, useProfileData } from '../GlobalStateProvider/GlobalStateProvider'
import { captureException } from '../Sentry/sentry'

const defaultOptimizelyLogger = logging.createLogger({
    logLevel: isLocal() ? enums.LOG_LEVEL.NOTSET : enums.LOG_LEVEL.ERROR,
})

export const CustomOptimizelyProvider: FunctionComponent<PropsWithChildren<{ optimizelyDatafile?: string; datafileUrl: string }>> = (
    props,
) => {
    const cookies = useContext(CookieContext)

    // for not logged-in users, we will use the IADVISITOR
    // for logged in users the user uuid
    // for SSG pages, initially the profileData is initially `unsure` which means it is being loaded. in that case we'll set the optimizelyUser to `undefined` which means optimizely is initially disabled.
    // optimizely will not send any data to optimizely servers unless a user id is available
    const [profileData] = useProfileData()

    const getOptimizelyUser = () => {
        if (profileData === 'unsure' || !cookies.IADVISITOR) {
            return undefined
        }

        if (isUserLoggedIn(profileData)) {
            return { id: profileData.uuid }
        } else {
            return { id: cookies.IADVISITOR }
        }
    }

    const optimizelyUser = getOptimizelyUser()

    const [optimizely] = useState(() =>
        createInstance({
            datafile: props.optimizelyDatafile,
            // the sdk key is not necessary here since the node api wraps the optimizely api
            sdkKey: isSSG(props.optimizelyDatafile) ? 'not-necessary' : undefined,
            datafileOptions: isSSG(props.optimizelyDatafile)
                ? {
                      urlTemplate: props.datafileUrl,
                      autoUpdate: false,
                  }
                : undefined,
            logger: {
                log: (level, message) => {
                    // ignore error message during static next.js build
                    if (!message.includes('Error fetching datafile: Unsupported protocol: null')) {
                        defaultOptimizelyLogger.log(level, message)
                    }

                    if (
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                        level === enums.LOG_LEVEL.ERROR &&
                        !message.includes('.replace is not a function') &&
                        !message.includes('Optimizely object is not valid. Failing track.') &&
                        !message.includes('EventProcessor: The quota has been exceeded.') &&
                        !message.includes('Error fetching datafile: Request error') &&
                        !message.includes(
                            "EventProcessor: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
                        ) &&
                        !message.includes('Error initializing client. The core client or user promise(s) rejected.') &&
                        !message.includes('DatafileManager: Request timed out') &&
                        !message.includes('EventProcessor: The operation is insecure.') &&
                        !message.includes('EventProcessor: QuotaExceededError')
                    ) {
                        if (isClient()) {
                            const browser = new UAParser(navigator.userAgent).getBrowser()
                            if (browser.name?.toLowerCase() === 'firefox' && browser.version?.startsWith('106')) {
                                // there is a bug in firefox 106 that causes recursion errors
                                return
                            }
                        }

                        // we currently have A LOT (1.4M/day) optimizely errors which eats up our sentry budget - completely disabling it would make it invisible, so we can reduce sampling to 1 of every 10000 errors to still help us see the issue
                        if (Math.random() * 10000 <= 1) {
                            captureException(new Error(`Optimizely Error: ${message}`))
                        }
                    }
                },
            },
            // set logLevel here instead additionally to the config of defaultOptimizelyLogger to make use of an early return in the OptimizelyLogger implementation and reduce the possibility of this error to happen: https://github.com/optimizely/javascript-sdk/issues/719
            logLevel: isLocal() ? enums.LOG_LEVEL.NOTSET : enums.LOG_LEVEL.ERROR,
            // during SSR there is no need to send decide events because they will be repeated during hydration anyways
            defaultDecideOptions: isServer() ? [OptimizelyDecideOption.DISABLE_DECISION_EVENT] : undefined,
            eventBatchSize: 10,
            eventFlushInterval: 1000,
        }),
    )

    const debugFlags = useContext(DebugFlagContext)
    const isDebugFlagEnabled = debugFlags.optimizelyMode === 'enable'

    useEffect(() => {
        return () => {
            // close optimizely when unmounting so the event queue will be sent immediately and not wait until the browser tab is closed
            void optimizely.close()
        }
    }, [optimizely])

    return (
        <OptimizelyProvider
            optimizely={optimizely}
            isServerSide={!isSSG(props.optimizelyDatafile)}
            // only set user after user consent is known and given to prevent optimizely sending any data to optimizely servers before that
            user={isDebugFlagEnabled ? optimizelyUser : undefined}
            children={props.children}
        />
    )
}

const isSSG = (optimizelyDatafile?: string) => !optimizelyDatafile
