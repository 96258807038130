import { MetaTagInfo } from '@wh/common/chapter/api/tagging/metaTagCache'
import { ANDROID, FACEBOOK, MICROSOFT, OPEN_GRAPH, TWITTER } from '@wh/common/chapter/lib/config/constants-seo'
import { SeoMetaData } from '@wh/common/chapter/types/SeoMetaData'
import { NextSeo } from 'next-seo'
import { MetaTag } from 'next-seo/lib/types'
import React, { FunctionComponent } from 'react'
import { getWillhabenDomain } from '../../lib/config/runtimeConfig'

interface SeoMetaTagsForSeoMetaDataProps {
    seoMetaData: SeoMetaData | null
    ogSiteImageUrl?: string
    dontSetDefaultOgSiteImageDimensions?: boolean
    noindex?: boolean
    nofollow?: boolean
}

export const SeoMetaTagsForSeoMetaData: FunctionComponent<SeoMetaTagsForSeoMetaDataProps> = (props) => {
    const seoMetaData: SeoMetaData = props.seoMetaData || {
        canonicalUrl: '',
        description: OPEN_GRAPH.DESCRIPTION,
        keywords: '',
        title: OPEN_GRAPH.TITLE,
    }

    return (
        <SeoMetaTags
            title={unescapeSpecialCharsToAvoidDoubleEscaping(seoMetaData.title)}
            description={seoMetaData.description}
            keywords={seoMetaData.keywords}
            canonicalUrl={seoMetaData.canonicalUrl}
            ogMeta={{
                ogUrl: seoMetaData.canonicalUrl,
                ogTitle: unescapeSpecialCharsToAvoidDoubleEscaping(seoMetaData.title),
                ogDescription: seoMetaData.description,
                ogSiteImageUrl: props.ogSiteImageUrl || OPEN_GRAPH.IMAGE,
                ogSiteImageAlt: seoMetaData.title,
                ogSiteImageWidth: props.dontSetDefaultOgSiteImageDimensions ? undefined : OPEN_GRAPH.IMAGE_WIDTH,
                ogSiteImageHeight: props.dontSetDefaultOgSiteImageDimensions ? undefined : OPEN_GRAPH.IMAGE_HEIGHT,
            }}
            noindex={props.noindex}
            nofollow={props.nofollow}
        />
    )
}

const unescapeSpecialCharsToAvoidDoubleEscaping = (text: string) => {
    return text
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/&quot;/g, '"')
}

interface SeoMetaTagsForSeoMetaTagsInfoProps {
    metaTagInfo: Pick<MetaTagInfo, 'title' | 'metaDescription' | 'metaKeywords'>
    canonicalUrl: string | undefined
    noindex: boolean
    nofollow: boolean
}

export const SeoMetaTagsForSeoMetaTagsInfo: FunctionComponent<SeoMetaTagsForSeoMetaTagsInfoProps> = (props) => (
    <SeoMetaTags
        title={props.metaTagInfo.title}
        description={props.metaTagInfo.metaDescription}
        keywords={props.metaTagInfo.metaKeywords}
        canonicalUrl={props.canonicalUrl ?? ''}
        ogMeta={{
            ogUrl: props.canonicalUrl ?? '',
            ogTitle: props.metaTagInfo.title,
            ogDescription: props.metaTagInfo.metaDescription,
            ogSiteImageUrl: OPEN_GRAPH.IMAGE,
            ogSiteImageAlt: OPEN_GRAPH.IMAGE_ALT,
            ogSiteImageWidth: OPEN_GRAPH.IMAGE_WIDTH,
            ogSiteImageHeight: OPEN_GRAPH.IMAGE_HEIGHT,
        }}
        noindex={props.noindex}
        nofollow={props.nofollow}
    />
)

interface SeoMetaTagsProps {
    title: string
    description: string
    keywords: string
    canonicalUrl: string
    ogMeta: {
        ogUrl: string
        ogTitle: string
        ogDescription: string
        ogSiteImageUrl: string
        ogSiteImageAlt: string
        ogSiteImageWidth?: number
        ogSiteImageHeight?: number
    }
    noindex?: boolean
    nofollow?: boolean
}

export const SeoMetaTags: FunctionComponent<SeoMetaTagsProps> = (props) => {
    const additionalAppLinkMetaTags: MetaTag[] = props.canonicalUrl
        ? [
              {
                  name: 'apple-itunes-app',
                  content: `app-id=660325616, app-argument=${props.canonicalUrl}`,
              },
              {
                  name: 'google-play-app',
                  content: `app-id=at.willhaben, willhaben-onelink=${createOnelinkUrlFromCanonical(props.canonicalUrl)}`,
              },
          ]
        : []

    return (
        <NextSeo
            title={props.title}
            description={props.description}
            canonical={props.canonicalUrl}
            noindex={props.noindex}
            nofollow={props.nofollow}
            additionalMetaTags={[
                {
                    name: 'keywords',
                    content: props.keywords,
                },
                {
                    property: 'DC.title',
                    content: props.title,
                },
                {
                    name: 'apple-mobile-web-app-capable',
                    content: 'yes',
                },
                {
                    name: 'apple-mobile-web-app-title',
                    content: OPEN_GRAPH.NAME,
                },
                {
                    name: 'application-name',
                    content: OPEN_GRAPH.NAME,
                },
                {
                    name: 'theme-color',
                    content: ANDROID.THEME_COLOR,
                },
                {
                    name: 'msapplication-TileColor',
                    content: MICROSOFT.TILE_COLOR,
                },
                {
                    name: 'msapplication-square150x150logo',
                    content: MICROSOFT.TILE_IMAGE_URL,
                },
                {
                    name: 'msapplication-config',
                    content: MICROSOFT.APPLICATION_CONFIG,
                },
                {
                    name: 'format-detection',
                    content: 'telephone=no, address=no, email=no',
                },
                {
                    name: 'dcterms.rights',
                    content:
                        'Legal Notice: All rights are reserved. The usage of contents of this website and/or of website data for the purpose of text and data mining in the meaning of § 42h (6) of the Austrian Copyright Act is not permitted for the training of AI (artificial intelligence) models and/or AI based technologies. willhaben internet service GmbH & Co KG may change this Legal Notice at any time at its sole discretion.',
                },
                {
                    name: 'tdm-reservation',
                    content: '1',
                },
                ...additionalAppLinkMetaTags,
            ]}
            openGraph={{
                type: OPEN_GRAPH.TYPE,
                url: props.ogMeta.ogUrl,
                title: props.ogMeta.ogTitle,
                description: props.ogMeta.ogDescription,
                locale: OPEN_GRAPH.LOCALE,
                images: [
                    {
                        url: getSeoImageUrl(props.ogMeta.ogSiteImageUrl),
                        width: props.ogMeta.ogSiteImageWidth,
                        height: props.ogMeta.ogSiteImageHeight,
                        alt: props.ogMeta.ogSiteImageAlt,
                    },
                ],
                site_name: OPEN_GRAPH.SITE_NAME,
            }}
            facebook={{
                appId: FACEBOOK.APP_ID,
            }}
            twitter={{
                handle: TWITTER.SITE,
                site: TWITTER.SITE,
                cardType: TWITTER.CARD,
            }}
        />
    )
}

const getSeoImageUrl = (url: string) => {
    if (url.startsWith && !url.startsWith('http://') && !url.startsWith('https://')) {
        return `${getWillhabenDomain()}${url}`
    }
    return url
}

const appBannerOnelinkBaseUrl = 'https://app.adjust.com/196bvcrd?deep_link='

const createOnelinkUrlFromCanonical = (canonical: string) => {
    const encodedCanonical = encodeURIComponent(canonical)
    return `${appBannerOnelinkBaseUrl}${encodedCanonical}`
}
