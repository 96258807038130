import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { theme } from '@wh-components/core/theme'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import React, { Fragment, FunctionComponent, ReactNode } from 'react'
import { storyblokResolveLink } from '../../lib/storyblokResolveLink'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'

interface StoryblokHeaderLinkImageBlok extends StoryblokComponentType<string> {
    image: StoryblokAsset
    imageMobile: StoryblokAsset
    link: StoryblokLink
    imageHeight: string
    position: 'left' | 'center' | 'right'
}

interface Props extends StoryblokDefaultProps<StoryblokHeaderLinkImageBlok> {}

const MAX_IMAGE_HEIGHT = 450

export const StoryblokHeaderLinkImage: FunctionComponent<Props> = ({ blok }) => {
    const { link, image, imageHeight, imageMobile, position = 'center' } = blok

    const imageMobileSet = !!imageMobile?.filename

    let storyblokImageExtension = '/m/'
    let storyblokImageExtension2x = '/m/'

    if (imageHeight && +imageHeight < MAX_IMAGE_HEIGHT) {
        // The base image is multiplied by two since it will always be scaled a little bit, because the element dimensions don't correspond with actual image size
        // The additional image size ensures better image quality and since storyblok offers automatic webp detection, the images won't get too big
        storyblokImageExtension += `0x${Math.floor(+imageHeight * 1.5)}`
        storyblokImageExtension2x += `0x${+imageHeight * 3}`
    } else {
        storyblokImageExtension += `0x${MAX_IMAGE_HEIGHT}`
        storyblokImageExtension2x += `0x${MAX_IMAGE_HEIGHT * 2}`
    }

    const img = (
        <ResponsiveImage
            srcSet={`${imageMobileSet ? imageMobile.filename : image.filename}${storyblokImageExtension} 1x, ${
                imageMobileSet ? imageMobile.filename : image.filename
            }${storyblokImageExtension2x} 2x`}
            src={`${imageMobileSet ? imageMobile.filename : image.filename}${storyblokImageExtension}`}
            height={imageHeight ?? 'auto'}
            cssWidth="100%"
            objectFit="cover"
            alt={image.alt}
            objectPosition={position}
        />
    )

    const resolvedLink = storyblokResolveLink(link)

    return (
        <Box
            {...storyblokEditable(blok as unknown as SbBlokData)}
            width="100%"
            borderRadius="m"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            backgroundColor={{ tablet: 'palette.primary.main' }}
            lineHeight={0}
        >
            <LinkWrapper link={resolvedLink}>
                {imageMobileSet ? (
                    <picture>
                        <source
                            srcSet={`${image.filename}${storyblokImageExtension} 1x, ${image.filename}${storyblokImageExtension2x} 2x`}
                            media={`(min-width: ${theme.breakpoints.tablet}px)`}
                        />
                        {img}
                    </picture>
                ) : (
                    img
                )}
            </LinkWrapper>
        </Box>
    )
}

const LinkWrapper: FunctionComponent<{ link: string; children: ReactNode }> = ({ link, children }) => {
    if (link) {
        return (
            <ServerRoutingAnchorLink type="anchor" href={link}>
                {children}
            </ServerRoutingAnchorLink>
        )
    }

    return <Fragment>{children}</Fragment>
}
