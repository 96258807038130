import {
    PromiseFulfilledResultWithAdditionalValue,
    PromiseRejectedResultWithValue,
    PromiseSettledResultWithValue,
} from '@wh/adin/src/domains/ad-management/components/ad-insertion/ad-insertion-form/FormComponents/ImagePicker/MyPromiseRejectedResult'

export const isPromiseFulfilledResult = <T>(
    promiseSettledResult: PromiseSettledResult<T>,
): promiseSettledResult is PromiseFulfilledResult<T> => promiseSettledResult.status === 'fulfilled'

export const isPromiseRejectedResult = (
    promiseSettledResult: PromiseSettledResult<unknown>,
): promiseSettledResult is PromiseRejectedResult => promiseSettledResult.status === 'rejected'

export const isPromiseRejectedResultWithValue = <T, T2>(
    promiseSettledResult: PromiseSettledResultWithValue<T, T2>,
): promiseSettledResult is PromiseRejectedResultWithValue<T2> => promiseSettledResult.status === 'rejected'

export const isPromiseFulfilledResultWithAdditionalValue = <T, T2>(
    promiseSettledResult: PromiseSettledResultWithValue<T, T2>,
): promiseSettledResult is PromiseFulfilledResultWithAdditionalValue<T, T2> => promiseSettledResult.status === 'fulfilled'
