import { ExpressNextContextWithUserData, ResponseError } from '@wh/common/chapter/types/nextJS'
// check next.config.js and this issue if confused
// https://github.com/zeit/next.js/issues/1852
import * as Sentry from '@sentry/node'
import type { Integration } from '@sentry/core/build/types'
import { ErrorEvent, EventHint } from '@sentry/core/build/types'
import { ErrorInfo } from 'react'
import { dedupeIntegration, extraErrorDataIntegration } from '@sentry/browser'
import { getContext, isServer } from '@wh/common/chapter/lib/commonHelpers'

const universalIntegrations = [
    extraErrorDataIntegration(),
    dedupeIntegration(),
    Sentry.inboundFiltersIntegration(),
    Sentry.functionToStringIntegration(),
]

const clientIntegrations = () => [
    // @ts-ignore this integration is only available in @sentry/browser, but we only import @sentry/node and switch the import at compile-time in next.config.js
    Sentry.breadcrumbsIntegration() as Integration,
    // @ts-ignore this integration is only available in @sentry/browser, but we only import @sentry/node and switch the import at compile-time in next.config.js
    Sentry.httpContextIntegration() as Integration,
]

const ignoreErrors = [
    /Network request failed/gi,
    /Failed to fetch/gi,
    /NetworkError/gi,
    /withrealtime\/messaging/gi,
    /checkUnread/gi,
    /object expected/gi,
    /object doesn't support this action/gi,
    /network error/gi,
    /freed script/gi,
    /Der Download für die/gi,
    /Cannot read property 'innerHTML'/gi,
    /Non-Error promise rejection captured with keys:/gi,
    /Non-Error exception captured with keys: message, name, statusCode/gi,
    /Object captured as exception with keys: message, name, statusCode/gi,
    /Unable to get property 'removeChild'/gi,
    /Cannot read property 'removeChild' of null/gi,
    /InvalidStateError/gi,
    /Fehler bei "fetch"/gi,
    /Abort fetching component for route/gi,
    /Loading chunk/gi,
    /Permission denied to access property "document" on cross-origin object/gi,
    /undefined is not an object (evaluating 'e.split')/gi,
    /Cannot read property 'split' of undefined/gi,
    /can't redefine non-configurable property "userAgent"/gi,
    /Non-Error promise rejection captured with keys: [object has no keys]/gi,
    /API request/gi,
    /Failed to execute 'removeChild' on 'Node'/gi,
    /Failed to execute 'insertBefore' on 'Node'/gi,
    /The object can not be found here/gi,
]

export interface ExtraSentryConfig {
    additionalAllowUrls?: (RegExp | string)[]
}

export const commonSentryConfig = (dsn: string, extraSentryConfig?: ExtraSentryConfig): Parameters<typeof Sentry.init>[0] => ({
    dsn: dsn,
    release: process.env.SENTRY_RELEASE,
    environment: getContext(),
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    defaultIntegrations: isServer() ? universalIntegrations : [...universalIntegrations, ...clientIntegrations()],
    // @ts-ignore
    allowUrls: isServer()
        ? undefined
        : [/https?:\/\/((www-dev|www-uat|www-st|www)\.)?willhaben\.at/, ...(extraSentryConfig?.additionalAllowUrls || [])],
    // filter common network and messaging errors
    ignoreErrors: ignoreErrors,
    beforeSend: beforeSend,
    // workaround since autoSessionTracking is only available in @sentry/browser and not @sentry/node, and we switch sdks in next.config.js dynamically
    ...{ autoSessionTracking: false },
})

const beforeSend = (event: ErrorEvent, hint: EventHint) => {
    if (typeof window !== 'undefined' && /headless/i.test(window.navigator.userAgent)) {
        return null
    }

    const errorMessage = getErrorMessage(hint)
    if (errorMessage) {
        event.fingerprint = [errorMessage]
    }

    return event
}

const getErrorMessage = (hint?: EventHint): string | undefined => {
    const error = hint?.originalException
    if (error instanceof Error) {
        return error.message
    } else if (typeof error === 'string') {
        return error
    }

    return undefined
}

let isSentryActive = false

export const setSentryWasInitialized = () => {
    isSentryActive = true
}

const enrichSentryScope = (err: unknown, extra?: ExpressNextContextWithUserData | ErrorInfo, level: Sentry.SeverityLevel = 'error') => {
    const scope = Sentry.getCurrentScope()
    if (extra) {
        // when error was reported in componentDidThrow
        if (isErrorInfo(extra)) {
            scope.setExtra('componentStack', extra.componentStack)
            // when error was reported in getInitialProps either on server or client
        } else {
            // we still need to check if res or req exist as they are optional
            if (extra.res) {
                scope.setExtra('statusCode', extra.res.statusCode)
            }
            // req only exists on server therefor srr is  true
            if (extra.req) {
                scope.setTag('url', extra.req.url)
                scope.setExtra('method', extra.req.method)
                scope.setExtra('headers', extra.req.headers)
                scope.setExtra('params', extra.req.params)
                scope.setExtra('query', extra.req.query)
                scope.setExtra('pathname', extra.req.path)
            } else {
                scope.setExtra('query', extra.query)
                scope.setExtra('pathname', extra.pathname)
            }
        }
    }

    if (level) {
        scope.setLevel(level)
    }

    if (typeof window !== 'undefined') {
        scope.setTag('url', window.location.href)
        scope.setExtra('query', window.location.search)
    }

    if ((err as ResponseError).statusCode) {
        scope.setExtra('errorStatusCode', (err as ResponseError).statusCode)
    }
}

const isErrorInfo = (arg: unknown): arg is ErrorInfo => {
    return typeof (arg as ErrorInfo).componentStack !== 'undefined'
}

export const captureException = (
    err: unknown,
    extra?: ExpressNextContextWithUserData | ErrorInfo,
    level: Sentry.SeverityLevel = 'error',
) => {
    if (!isSentryActive) {
        return
    }
    if (getContext() === undefined) {
        return
    }
    enrichSentryScope(err, extra, level)
    Sentry.captureException(err)
}
