import React, { FunctionComponent, PropsWithChildren, useState } from 'react'

interface EnterBirthYearProps {
    shouldShowEnterBirthYear: boolean
    setEnterBirthYear: (enter: boolean) => void
}

export const EnterBirthYearContext = React.createContext<EnterBirthYearProps>({
    shouldShowEnterBirthYear: false,
    setEnterBirthYear: () => {},
})

export interface EnterBirthYearProviderProps extends PropsWithChildren {
    shouldShowEnterBirthYear?: boolean
    setEnterBirthYear?: (enter: boolean) => void
}

export const EnterBirthYearProvider: FunctionComponent<EnterBirthYearProviderProps> = (props) => {
    const setShouldShowEnterBirthYear = (enter: boolean) => {
        setState({ ...state, shouldShowEnterBirthYear: enter })
    }

    const initState = {
        shouldShowEnterBirthYear: false,
        setEnterBirthYear: setShouldShowEnterBirthYear,
    }

    const [state, setState] = useState(initState)
    return <EnterBirthYearContext.Provider value={state}>{props.children}</EnterBirthYearContext.Provider>
}
