import { UserAgentContext } from '@wh/common/chapter/components/UserAgentProvider/UserAgentProvider'
import { useContext } from 'react'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import {
    Value,
    isValueObject,
    ResponsiveGuaranteedValueWithSSRFallback,
    ResponsiveGuaranteedValue,
    ValueObject,
} from '@wh-components/system'

export const useUserAgent = () => useContext(UserAgentContext)

export const useIsBot = () => useUserAgent().isBot

/** returns the screen size from the user agent on SSR, and from the window width on CSR */
export const useScreenSize = () => {
    const userAgentContext = useUserAgent()

    const screenSize = useResponsiveValue(
        {
            desktop: 'desktop',
            tablet: 'tablet',
            phone: 'phone',
        } as const,
        userAgentContext.screenSize,
    )

    return screenSize
}

type ExtractResponsiveValueOrNever<T> = T extends ResponsiveGuaranteedValue<infer V> ? V : never
type ResponsiveReturnValue<T> = T extends ResponsiveGuaranteedValue<infer V> ? ResponsiveGuaranteedValueWithSSRFallback<V> : T

export const useCreateResponsiveGuaranteedValueWithSSRFallback = <V extends ResponsiveGuaranteedValue<Value> | undefined>(
    value: V,
): ResponsiveReturnValue<V> => {
    const { screenSize } = useUserAgent()

    // these workarounds for TypeScript are necessary because of the usage of `infer`
    const nonUndefinedValue = value as unknown as ResponsiveGuaranteedValue<ExtractResponsiveValueOrNever<V>>
    if (typeof value === 'undefined') {
        return undefined as ResponsiveReturnValue<V>
    } else if (isValueObject(nonUndefinedValue)) {
        return {
            ssr: ssrFallback(nonUndefinedValue, screenSize),
            csr: nonUndefinedValue,
        } as unknown as ResponsiveReturnValue<V>
    } else {
        return value as ResponsiveReturnValue<V>
    }
}

const ssrFallback = <T extends Value>(
    value: ValueObject<T> & {
        phone: T
    },
    screenSize: 'desktop' | 'tablet' | 'phone',
): T => {
    switch (screenSize) {
        case 'phone':
            return value.phone
        case 'tablet':
            return value.tablet ?? value.phone
        case 'desktop':
            return value.desktop ?? value.tablet ?? value.phone
    }
}
