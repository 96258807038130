/// <reference types="@wh/common/chapter/types/pulse.d.ts" />

import { promiseTimeout, TRACKING_WORKAROUND_DELAY } from './taggingPromiseTimeout'
import { createImpliedPostEventPayload, createPulseLinkEventPayload } from '@wh/common/chapter/lib/tagging/taggingPulseLinkEvent'
import { TaggingActionEvent, TaggingPage, TaggingSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { createPulseViewEventPayload, PageSpeedMetric, transformPerformanceMetrics } from './taggingPulseTransformations'
import { defaultPulseTracker } from './PulseTracker'
import { PulseFormParams } from './taggingPulseTypes'
import Tracker from '@spt-tracking/pulse-sdk'
import { getDebugFlags } from '../../components/DebugFlagProvider/DebugFlagProvider'

export const trackPulseClickEvent = (
    eventName: TaggingActionEvent | TaggingSelfPromotionEvent,
    params: Readonly<Record<string, string | undefined>>,
) => {
    if (typeof window === 'undefined' || getDebugFlags(window.location.href).trackingMode === 'disable') {
        // skip tagging
        return Promise.resolve()
    }

    const payload = createPulseLinkEventPayload(eventName, params)
    if (payload) {
        defaultPulseTracker.track('trackerEvent', payload)
    }

    return Promise.resolve()
}

export const trackPulsePageView = (
    eventName: TaggingPage,
    params: Readonly<
        Record<string, string | number | Record<string, unknown> | Array<string | number | Record<string, unknown>> | undefined> &
            PulseFormParams
    >,
) => {
    if (typeof window === 'undefined' || getDebugFlags(window.location.href).trackingMode === 'disable') {
        // skip tagging
        return Promise.resolve()
    }

    if (ignorePageViewEvent(eventName)) {
        // skip tagging
        return Promise.resolve()
    }

    const eventPayload = createPulseViewEventPayload(eventName, params)
    defaultPulseTracker.trackPageView(eventPayload)

    const impliedPostEvent = createImpliedPostEventPayload(eventName, params)
    if (impliedPostEvent) {
        defaultPulseTracker.track('trackerEvent', impliedPostEvent)
    }

    pageSpeedMetricQueue.startTrackingIfNeeded()

    // since the track functions do not return a Promise, but we need to wait for tagging to be finished before routing in some cases, we introduce an artificial delay
    return promiseTimeout(TRACKING_WORKAROUND_DELAY)
}

const ignorePageViewEvent = (eventName: TaggingPage) => {
    if (eventName === 'jobs_addetail_successful_application') {
        return true
    }
    return false
}

export const trackPulseWebVitals = (params: PageSpeedMetric) => {
    if (typeof window === 'undefined' || getDebugFlags(window.location.href).trackingMode === 'disable') {
        // skip tagging
        return
    }
    const eventPayload = transformPerformanceMetrics(params)
    performanceMetricPulseTracker.track('trackerEvent', eventPayload)
}

// we use a separate tracker for performance metrics, so we don't implicitly reuse parts of the last view event's payload
export const performanceMetricPulseTracker = new Tracker('willhabenat')

class PageSpeedMetricQueue {
    private eventListenersReady = false
    private queue: PageSpeedMetric[] = []

    // this should only be called after each pulse page view so the event can be associated with the page correctly
    startTrackingIfNeeded(): void {
        this.eventListenersReady = true
        this.flushQueue()
    }

    // we need to stop tracking on routeChangeStart so the web vitals of the next page are correctly associated with the next pulse page view
    stopTracking(): void {
        this.eventListenersReady = false
    }

    track(metric: PageSpeedMetric): void {
        if (this.eventListenersReady) {
            trackPulseWebVitals(metric)
        } else {
            this.queue.push(metric)
        }
    }

    private flushQueue(): void {
        this.queue.forEach((m) => this.track(m))
        this.queue = []
    }
}

export const pageSpeedMetricQueue = new PageSpeedMetricQueue()
