import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { TestProps } from '@wh-components/core/common'
import { Alert } from '@wh-components/core/Alert/Alert'
import SuccessIcon from '@wh-components/icons/AlertSuccess'
import { SpaceProps } from '@wh-components/system/space'

interface SuccessAlertProps extends TestProps, SpaceProps, PropsWithChildren {
    title?: string
    message?: ReactNode
}

export const SuccessAlert: FunctionComponent<SuccessAlertProps> = ({ title, message, testId, children, ...spaceProps }) => (
    <Alert title={title} variant="success" Icon={SuccessIcon} testId={testId || 'alert-success'} {...spaceProps}>
        {children || message}
    </Alert>
)
