import Paylivery from '@wh-components/icons/Paylivery'
import React, { Fragment, FunctionComponent, ReactNode } from 'react'
import { css } from 'styled-components'

/**
 * Replaces occurences of the text Paylivery with Paylivery-Icon + "Paylivery". Case doesn't matter.
 * The string can appear multiple times. Only works if the children are a single string.
 */
export const PayLiveryIconText: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    if (typeof children !== 'string') {
        return <Fragment>{children}</Fragment>
    }

    const payliveryRegex = /paylivery/gi

    const matchArray = []

    for (let match = payliveryRegex.exec(children); match !== null; match = payliveryRegex.exec(children)) {
        matchArray.push(match.index)
    }

    matchArray.reverse()

    let childrenString = children
    const modifiedChildren: ReactNode[] = []

    for (const index of matchArray) {
        modifiedChildren.unshift(childrenString.substring(index + 'paylivery'.length))
        const iconText = childrenString.substring(index, index + 'paylivery'.length)
        modifiedChildren.unshift(
            <Fragment key={index}>
                {index !== 0 && <Fragment>&nbsp;</Fragment>}
                <span
                    css={css`
                        white-space: nowrap;
                    `}
                >
                    <Paylivery
                        color="white"
                        size="24px"
                        css={css`
                            vertical-align: middle;
                        `}
                        aria-hidden={true}
                    />
                    {iconText}
                </span>
            </Fragment>,
        )
        childrenString = childrenString.substring(0, index)
    }

    modifiedChildren.unshift(childrenString)

    return <Fragment>{modifiedChildren}</Fragment>
}
