import { getXitiLevel2Id } from './taggingGetXitiLevel2Id'

export const xitiViewMap = (
    vertical_id: string | undefined,
    privateOrProfessionalString: string,
    startPageTag: string,
    genericPageName?: string,
    adId?: string,
): Record<string, { page: string; s2: string | undefined } | undefined> => {
    const xitiLevel2Id = getXitiLevel2Id(vertical_id)

    return {
        homepage: { page: 'Homepage', s2: '7' },
        contact_contact: { page: 'Contact::Contact', s2: '5' },
        terms_conditions_general: { page: 'GeneralTerms', s2: '5' },
        terms_conditions_payment_and_delivery: { page: 'TermsPaymentAndDelivery', s2: '5' },
        terms_conditions_c4y: { page: 'TermsC4Y', s2: '5' },
        ad_rules: { page: 'AdRules', s2: '5' },
        privacy_policy: { page: 'PrivacyPolicy', s2: '5' },
        imprint: { page: 'Imprint', s2: '5' },
        terms_conditions: { page: 'TermsOfUse', s2: '5' },
        press_about_willhaben: { page: 'Press::AboutWillhaben', s2: '5' },
        security_hints_overview: { page: 'SecurityHints::Overview', s2: '5' },
        security_hints_buy: { page: 'SecurityHints::Buy', s2: '5' },
        security_hints_account: { page: 'SecurityHints::Account', s2: '5' },
        security_hints_sell: { page: 'SecurityHints::Sell', s2: '5' },
        security_hints_puppy: { page: 'SecurityHints::Puppy', s2: '5' },
        mywillhaben_myprofile: { page: 'MyWillhaben::MyProfile', s2: '10' },
        mywillhaben_changelogindata: { page: 'MyWillhaben::MyProfile::Edit', s2: '10' },
        mywillhaben_lessoroverview: { page: 'MyWillhaben::Lessor::MyLessoroverview', s2: '10' },
        mywillhaben_tenantoverview: { page: 'MyWillhaben::MyTenantProfile::Tenantoverview', s2: '10' },
        ad_insertion_vertical_selection: { page: 'AdInsertion::VerticalSelection', s2: '4' },
        ad_insertion_estate_product_selection: { page: 'AdInsertion::ProductSelection', s2: '2' },
        ad_insertion_motor_product_selection: { page: 'AdInsertion::ProductSelection', s2: '3' },
        ad_insertion_modelsearch: { page: 'AdInsertion::Eurotax', s2: xitiLevel2Id },
        ad_insertion_form: { page: 'AdInsertion::Form', s2: xitiLevel2Id },
        ad_insertion_form_edit: { page: 'AdInsertion::Form_Edit', s2: xitiLevel2Id },
        ad_insertion_delivery: { page: 'AdInsertion::Delivery', s2: xitiLevel2Id },
        ad_insertion_delivery_edit: { page: 'AdInsertion::Edit_Delivery', s2: xitiLevel2Id },
        ad_insertion_upsellings: { page: 'AdInsertion::Upsellings', s2: xitiLevel2Id },
        ad_insertion_summary: { page: 'AdInsertion::BillingOverview', s2: xitiLevel2Id },
        ad_insertion_confirmation: { page: 'AdInsertion::Confirmation', s2: xitiLevel2Id },
        ad_insertion_confirmation_upselling: { page: `AI::${privateOrProfessionalString}::Upselling_Confirmation`, s2: xitiLevel2Id },
        my_search_agents: { page: 'MyWillhaben::MySearchAgents', s2: '10' },
        my_search_agents_create: { page: 'MyWillhaben::MySearchAgents_Create', s2: '10' },
        my_search_agents_edit: { page: 'MyWillhaben::MySearchAgents_Edit', s2: '10' },
        my_search_agents_edit_modal: { page: 'MyWillhaben::MySearchAgents_Edit_Overlay', s2: '10' },
        my_personalization: { page: 'MyWillhaben::MyPersonalization', s2: '10' },
        my_last_searches: { page: 'MyWillhaben::MyLastSearches', s2: '10' },
        my_last_searches_item_click: { page: 'MyLastSearches::Open::SearchResult_List', s2: '10' },
        price_finder: { page: 'PriceFinder', s2: '2' },
        price_finder_results: { page: 'PriceFinder::Results', s2: '2' },
        price_finder_results_empty: { page: 'PriceFinder::Results::Empty', s2: '2' },
        upselling: { page: `AI::${privateOrProfessionalString}::Upselling`, s2: '4' },
        checkout: { page: `AI::${privateOrProfessionalString}::Checkout`, s2: '4' },
        billrequest: { page: 'MyWillhaben::MyAds::RequestInvoice', s2: '10' },
        invoices: { page: 'MyWillhaben::MyAds::Invoices', s2: '10' },
        renew_ad_page: { page: 'MyWillhaben::MyAds::RenewAd', s2: '10' },
        my_ads: { page: 'MyWillhaben::MyAds', s2: '10' },
        my_saved_ads: { page: 'MyWillhaben::MySavedAds', s2: '10' },
        chat_view: { page: 'MyWillhaben::Messages', s2: '10' },
        my_transactions_seller: { page: 'MyWillhaben::Seller::MyTransactionsOverview', s2: '10' },
        my_transactions_buyer: { page: 'MyWillhaben::Buyer::MyTransactionsOverview', s2: '10' },
        adview: { page: 'AdDetail', s2: xitiLevel2Id },
        adview_nc: { page: 'AdDetail', s2: xitiLevel2Id },
        report_ad: { page: 'AdDetail::AdReport', s2: xitiLevel2Id },
        adservices: { page: `AdDetail::AdServices::${genericPageName}`, s2: xitiLevel2Id },
        mapobject: { page: 'AdMap', s2: xitiLevel2Id },
        contact_seller_confirmation: { page: 'MessageConfirmation', s2: xitiLevel2Id },
        search_result_list: { page: 'ResultList', s2: xitiLevel2Id },
        auto_dealer_list: { page: 'DealerList', s2: xitiLevel2Id },
        bap_dealer_list: { page: 'DealerList', s2: xitiLevel2Id },
        bap_seo_listlandingpage_view: { page: `ResultList::LLP`, s2: xitiLevel2Id },
        detail_search: { page: 'DetailSearch', s2: xitiLevel2Id },
        vertical_home: { page: startPageTag, s2: xitiLevel2Id },
        bap_giveaway_home: { page: 'GiveAwayStartpage', s2: xitiLevel2Id },
        vertical_browse: { page: 'MarketplaceBrowsePage', s2: xitiLevel2Id },
        vertical_browse_giveaway: { page: 'MarketplaceGiveAwayBrowsePage', s2: xitiLevel2Id },
        seller_profile: { page: 'Seller::SellerProfile', s2: '10' },
        dealer_profile: { page: 'Seller::DealerProfile', s2: xitiLevel2Id },
        jobs_my_searchagents: { page: 'MyJob::MySearchAgents', s2: '10' },
        jobs_my_savedads: { page: 'MyJob::MySavedAds', s2: '10' },
        jobs_addetail: { page: 'AdDetail', s2: '1' },
        jobs_addetail_successful_application: { page: `AdDetail::ApplicationConfirmation::${adId ?? ''}`, s2: '1' },
        jobs_resultlist: { page: 'ResultListJob', s2: '1' },
        jobs_startpage: { page: 'JobStartpage', s2: '1' },
        jobs_companyprofile_pageview: { page: 'JobCompanyProfile', s2: '1' },
        jobs_companysearch: { page: 'JobCompanySearch', s2: '1' },
        jobs_report_ad: { page: 'AdDetail::AdReport', s2: '1' },
        jobs_self_service: { page: 'Employer::SelfService', s2: '1' },
        jobs_full_service: { page: 'Employer::FullService', s2: '1' },
        jobs_employer_branding: { page: 'Employer::EmployerBranding', s2: '1' },
        jobs_employer_downloads: { page: 'Employer::Downloads', s2: '1' },
        jobs_report_company: { page: 'JobCompanyProfile::CompanyReport', s2: '1' },
        storyblok_page: { page: genericPageName ?? '', s2: '5' },
        jobs_employer_adinsertion: { page: 'MyEmployer::JobAdInsertion', s2: '1' },
        jobs_bundle_page: { page: 'Employer::MyJob::AI::BundlePurchase', s2: '1' },
        jobs_application_overview_page: { page: 'MyEmployer::ApplicationOverview', s2: '1' },
        jobs_application_detail_page: { page: 'MyEmployer::ApplicationDetail', s2: '1' },
        jobs_application_attachment_page: { page: 'MyEmployer::ApplicationAttachment', s2: '1' },
    }
}
