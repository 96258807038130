import React, { FunctionComponent } from 'react'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import styled from 'styled-components'
import { storyblokEditable } from '@storyblok/react'

export const StoryblokTable: FunctionComponent<StoryblokDefaultProps> = ({ blok }) => {
    const table = blok.table as typeof blok.table & {
        thead: Array<{ _uid: string; value: string }>
        tbody: Array<{ _uid: string; value: string; body: Array<{ _uid: string; value: string }> }>
    }

    return (
        <CmsTable {...storyblokEditable(blok)}>
            <thead>
                <tr>
                    {table.thead.map((thead) => {
                        return <th key={thead._uid}>{thead.value}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {table.tbody.map((tr) => {
                    return (
                        <tr key={tr._uid}>
                            {tr.body.map((td) => {
                                return <td key={td._uid}>{td.value}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </CmsTable>
    )
}

const CmsTable = styled.table`
    width: 100%;

    & thead {
        text-align: left;
    }
`
