import { useCallback, useContext } from 'react'
import { CookieContext, WhitelistedCookieKey } from '@wh/common/chapter/components/CookieProvider/CookieProvider'

export const useSSRCompatibleCookieStore = (
    cookieKey: WhitelistedCookieKey,
    fallback: string | undefined,
): [string | undefined, (newValue: string, expires: Date) => void] => {
    const cookieValue = useContext(CookieContext)[cookieKey] ?? fallback
    const setCookie = useCallback(
        (newValue: string, expires: Date) => {
            document.cookie = `${cookieKey}=${newValue};path=/;expires=${expires.toUTCString()}`
        },
        [cookieKey],
    )
    return [cookieValue, setCookie]
}
