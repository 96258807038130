import { Request as ExpressRequest, Response as ExpressResponse } from 'express'
import { BbxCookie, BbxRequestCookies } from '@wh/common/chapter/types/cookies'
import { NextResponse } from '@wh/common/chapter/types/nextJS'

export const getSetCookieHeadersValuesFromResponse = (response: Response) => {
    const setCookieHeaderValues: string[] = []
    response.headers.forEach((value, key) => {
        if (key.toLowerCase() === 'set-cookie') {
            setCookieHeaderValues.push(value)
        }
    })
    return setCookieHeaderValues
}

export const addBbxCookieToNextResponse = (cookie: BbxCookie, value: string, maxAge: number, response: NextResponse) => {
    const newCookie = `${cookie}=${value}; max-age=${maxAge}; Secure`
    const existingCookies = response.getHeader('set-cookie')
    response.setHeader('set-cookie', getMergedCookieHeaders(existingCookies, newCookie))
}

export const addCookieHeadersToServerResponse = (cookieHeaders: string | string[] | undefined, response: ExpressResponse) => {
    if (cookieHeaders) {
        const existingCookies = response.getHeader('set-cookie')
        response.set('set-cookie', getMergedCookieHeaders(existingCookies, cookieHeaders))
    }
}

export const convertCookieHeadersAndAddCookiesToServerRequest = (cookieHeaders: string | string[] | undefined, request: ExpressRequest) => {
    if (cookieHeaders) {
        const cookieHeaderList: string[] = Array.isArray(cookieHeaders) ? cookieHeaders : [cookieHeaders]

        cookieHeaderList
            .map((header: string) => cookieHeaderToCookie(header))
            .filter((cookie: BbxRequestCookies) => !!cookie)
            .forEach((cookie: BbxRequestCookies) => {
                request.cookies = {
                    ...cookie,
                    ...request.cookies,
                }
            })
    }
}

const cookieHeaderToCookie = (cookieHeader: string): BbxRequestCookies => {
    const matchResult = /^([^=;]*)=([^; ]*)/i.exec(cookieHeader)
    if (matchResult && matchResult.length >= 3) {
        return {
            [matchResult[1]]: matchResult[2],
        }
    }
    return {}
}

const getMergedCookieHeaders = (existingCookies: string | number | string[] | undefined, apiCookieHeaders: string | string[]) => {
    const newCookies: string[] = []

    pushCookieHeaders(newCookies, existingCookies)
    pushCookieHeaders(newCookies, apiCookieHeaders)
    return newCookies
}

const pushCookieHeaders = (result: string[], cookieHeaders: string | number | string[] | undefined) => {
    if (!cookieHeaders) {
        return
    }

    if (cookieHeaders) {
        if (Array.isArray(cookieHeaders)) {
            cookieHeaders.forEach((cookieHeader: string) => {
                result.push(cookieHeader)
            })
        } else {
            result.push(cookieHeaders as string)
        }
    }
}
