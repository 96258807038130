import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { useMemo } from 'react'

const EMPTY_TAGGING_DATA: TaggingData = {
    neustarTaggingData: undefined,
    pulseData: undefined,
    taggingNames: {
        nameValuePair: [],
    },
    tmsDataValues: { tmsData: {} },
}

// adds isHomepage to taggingData for xiti
export const useFeedEmptyTaggingData = (): TaggingData => {
    const taggingData = useEmptyTaggingData()

    return useMemo(() => {
        return {
            ...taggingData,
            tmsDataValues: { ...taggingData.tmsDataValues, isHomepage: 'true' },
        }
    }, [taggingData])
}

// adds user uuid to tagging data if the user is logged in
export const useEmptyTaggingData = (): TaggingData => {
    const [profileData] = useProfileData()

    return useMemo(() => {
        if (!isUserLoggedIn(profileData)) {
            return EMPTY_TAGGING_DATA
        } else {
            return {
                ...EMPTY_TAGGING_DATA,
                tmsDataValues: { tmsData: { user_uuid: profileData.uuid } },
            }
        }
    }, [profileData])
}
