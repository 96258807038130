import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * An async-utility hook that accepts a callback function and a delay time (in milliseconds), then repeats the
 * execution of the given function by the defined milliseconds.
 * from https://github.com/beautifulinteractions/beautiful-react-hooks/blob/master/src/useInterval.js
 */
export const useInterval = (fn: () => void, delay: number): [boolean, () => void] => {
    const timeout = useRef<number | undefined>()
    const callback = useRef(fn)
    const [isCleared, setIsCleared] = useState(false)

    // the clear method
    const clear = useCallback(() => {
        if (timeout.current) {
            setIsCleared(true)
            clearInterval(timeout.current)
        }
    }, [])

    // if the provided function changes, change its reference
    useEffect(() => {
        if (typeof fn === 'function') {
            callback.current = fn
        }
    }, [fn])

    // when the milliseconds change, reset the timeout
    useEffect(() => {
        if (typeof delay === 'number') {
            timeout.current = window.setInterval(() => {
                callback.current()
            }, delay)
        }

        // cleanup previous interval
        return clear
    }, [delay, clear])

    // when component unmount clear the timeout
    useEffect(
        () => () => {
            clear()
        },
        [clear],
    )

    return [isCleared, clear]
}
