/** Returns an object without all keys with value "undefined" removed */
export const removeUndefined = <T>(obj: { [key: string]: T | undefined }): { [key: string]: T } => {
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'undefined') {
            delete obj[key]
        }
    })
    return obj as { [key: string]: T }
}

/** Returns an object without all keys with value "undefined" or "null" removed */
export const removeNullAndUndefined = <T>(obj: { [key: string]: T | undefined | null } | undefined): { [key: string]: T } | undefined => {
    if (!obj) {
        return undefined
    }

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'undefined' || obj[key] === null) {
            delete obj[key]
        }
    })
    return obj as { [key: string]: T }
}

/** helpful for typesafe down-cast of array using `.filter(isNotUndefined)` */
export const isNotUndefined = <T>(value: T | undefined): value is T => typeof value !== 'undefined'

export const isNotNullOrUndefined = <T>(value: T | null | undefined): value is T => typeof value !== 'undefined' && value !== null

// from https://dev.to/kingdaro/indexing-objects-in-typescript-1cgi
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasKey = <O extends {}>(obj: O, key: keyof any): key is keyof O => {
    return key in obj
}

export const intersperse = <T, U>(originalArray: T[], elementToInsert: U): Array<T | U> =>
    originalArray.reduce(
        (result, element, index) => {
            if (index > 0) {
                result.push(elementToInsert)
            }
            result.push(element)
            return result
        },
        [] as Array<T | U>,
    )

export const intersperseWithIndexes = <T, U>(
    originalArray: T[],
    elementsToInsert: { insertBeforeIndex: number; element: U }[],
): Array<T | U> => {
    const sortedElementsToInsert = elementsToInsert.sort((a, b) => a.insertBeforeIndex - b.insertBeforeIndex)

    const result: Array<T | U> = originalArray.reduce(
        (previousValue, currentValue, currentIndex) => {
            while (sortedElementsToInsert.length > 0 && sortedElementsToInsert[0].insertBeforeIndex <= currentIndex) {
                previousValue.push(sortedElementsToInsert[0].element)
                sortedElementsToInsert.shift()
            }
            previousValue.push(currentValue)
            return previousValue
        },
        [] as Array<T | U>,
    )

    // append remaining elements
    return result.concat(sortedElementsToInsert.map((e) => e.element))
}

export const areSetsEqual = <A, B>(a: Set<A>, b: Set<B>) => a.size === b.size && b.size === new Set([...a, ...b]).size

/** use with reduce: `.reduce(mergeObjects, {})` */
export const mergeObjects = <T extends {}, U>(result: T, currentValue: U) => {
    result = { ...result, ...currentValue }
    return result
}

/**
 * create a new array with all elements matching the callbackFn until it does not match any more.
 * **includes** the first element not matching the callbackFn.
 */
export const takeWhileInclusive = <T>(originalArray: T[], callbackFn: (element: T) => boolean): T[] => {
    const firstNonMatchingIndex = originalArray.findIndex((element) => !callbackFn(element))
    return firstNonMatchingIndex === -1 ? [...originalArray] : originalArray.slice(0, firstNonMatchingIndex + 1)
}

/**
 * create a new array with all elements matching the callbackFn until it does not match any more.
 * **excludes** the first element not matching the callbackFn.
 */
export const takeWhileExclusive = <T>(originalArray: T[], callbackFn: (element: T) => boolean): T[] => {
    const firstNonMatchingIndex = originalArray.findIndex((element) => !callbackFn(element))
    return firstNonMatchingIndex === -1 ? [...originalArray] : originalArray.slice(0, firstNonMatchingIndex)
}
