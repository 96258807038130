const validVendors = [
    'amazon',
    'google',
    'c:schibsted-MQPXaqyh',
    'c:optimizely-9AgGsXi9',
    'c:oewa-XAQmGSgn',
    'c:firebase-aa58e9WU',
    'c:pulse-xWsGpqS4',
    'c:didomi',
    'c:schibsted-P2ipxGte',
    'c:crashlytic-XCJSji5d',
    'c:fabric-9wgPpqwL',
    'c:braze-VVT8RM4n',
    'c:hotjar',
    'c:xiti-VHZZHM9i',
    'c:appsflyer-MHBdMy2G',
    'c:userzoom',
    'c:microsoft-analytics',
    'c:googleana-4TXnJigR',
    'c:xxxlutzk-m9fQkPtL',
    'c:optionale-bnAEyZxy',
    'c:microsoft',
    'c:adjust-9kwy8cz3',
]
export const filterDidomiConsent = () => {
    try {
        const name = 'didomi_token'
        let didomiToken = localStorage.getItem(name)
        const didomiJSON = didomiToken ? JSON.parse(atob(didomiToken)) : undefined
        if (didomiJSON && (didomiJSON.vendors?.enabled?.length > 25 || didomiJSON.vendors?.disabled?.length > 25)) {
            if (didomiJSON.vendors.enabled) {
                didomiJSON.vendors.enabled = didomiJSON.vendors.enabled.filter((vendor: string) => validVendors.includes(vendor))
            }
            if (didomiJSON.vendors.disabled) {
                didomiJSON.vendors.disabled = didomiJSON.vendors.disabled.filter((vendor: string) => validVendors.includes(vendor))
            }
            didomiToken = btoa(JSON.stringify(didomiJSON))
            localStorage.setItem(name, didomiToken)
            document.cookie = `${name}=${didomiToken}; domain=.willhaben.at; path=/; expires=Thu, 20 Mar 2025 00:00:00 UTC;`
        }
    } catch (e) {
        console.error('Error cleaning up Didomi token ', e)
    }
}
