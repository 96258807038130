import Cookie from 'js-cookie'
import { NextRequest } from './nextJS'

export type BbxCookieName =
    | BbxCookie.CSRF_TOKEN
    | BbxCookie.APPLICATION_TOKEN
    | BbxCookie.IADVISITOR
    | BbxCookie.BBX_JSESSIONID
    | BbxCookie.X_XSRF_TOKEN
    | 'bbxResultListViewMode'
    | 'WH_AUTO'
    | 'bbxDmpSegments'
    | 'bbxHideJobsStartpageWidget'
    | 'bbxHideJobsResultListWidget'
    | 'bbxHideJobsResultListWidget_RE_Car'
    | 'bbxMyAdsEnabled'
    | 'bbxLastViewed'
    | 'bbxP10NItemRecommendations'
    | 'bbxMortgageCalculator'

export type BbxRequestCookies = { [name in BbxCookieName]?: string }

export enum BbxCookie {
    CSRF_TOKEN = 'x-bbx-csrf-token',
    APPLICATION_TOKEN = 'x-bbx-application-token',
    IADVISITOR = 'IADVISITOR',
    BBX_JSESSIONID = 'BBX_JSESSIONID',
    X_XSRF_TOKEN = 'X-XSRF-TOKEN',
}

export const LogoutCookies = [BbxCookie.CSRF_TOKEN, BbxCookie.BBX_JSESSIONID, 'IADPX2', 'JSESSIONID', 'JOBS_SESSIONID']

export const getUniversalBbxCookie = (cookieName: BbxCookieName, request: NextRequest | undefined): string | undefined => {
    if (typeof window === 'undefined' && typeof request === 'undefined') {
        console.error('Cannot get cookies on server without request')
        return undefined
    }

    if (request) {
        return getBbxCookieFromRequest(cookieName, request)
    } else {
        return getBbxCookieFromBrowser(cookieName)
    }
}

export const getBbxCookieFromRequest = (cookieName: BbxCookieName, request: NextRequest | undefined) => {
    const cookies = getBbxCookiesFromRequest(request)
    return cookies ? cookies[cookieName] : undefined
}

export const getBbxCookieFromBrowser = (cookieName: BbxCookieName) => {
    return Cookie.get(cookieName)
}

export const getBbxCookiesFromRequest = (request?: NextRequest): BbxRequestCookies | undefined => {
    if (!request?.cookies) {
        return undefined
    }

    return request.cookies as BbxRequestCookies
}
