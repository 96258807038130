import { ButtonProps } from '@wh-components/core/Button/Button'
import PlusIcon from '@wh-components/icons/Plus'
import { FlexItemProps } from '@wh-components/system/flexbox'
import { LayoutProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { TaggingActionEvent } from '../../lib/tagging/taggingTypes'
import { verticals } from '../../types/verticals'

interface AdInsertionLinkProps extends ButtonProps, SpaceProps, LayoutProps, PropsWithChildren, FlexItemProps {
    onClick?: () => void
    vertical?: verticals
    taggingEvent: TaggingActionEvent
}

export const AdInsertionLink: FunctionComponent<AdInsertionLinkProps> = ({ onClick, vertical, taggingEvent, children, ...props }) => {
    const isJobs = vertical === verticals.JOB

    return (
        <ClientRoutingAnchorLink
            type="button"
            href={isJobs ? '/jobs/stelleninserate' : '/iad/anzeigenaufgabe'}
            size="xsmall"
            color="secondary"
            variant="solid"
            Icon={PlusIcon}
            testId="header-new-ad-link"
            aria-label={isJobs ? 'Neue Jobanzeige aufgeben' : 'Neue Anzeige aufgeben'}
            {...props}
            onClick={() => {
                onClick?.()
                return callActionEvent(taggingEvent, undefined)
            }}
        >
            {children}
        </ClientRoutingAnchorLink>
    )
}
