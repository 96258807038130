import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { SkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import { HorizontalSlider, SliderAdImageVariant } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { SpaceProps } from '@wh-components/system/space'

export const AdvertSummarySkeletons: FunctionComponent<
    { numberOfAdsToDisplay?: number; variant?: SliderAdImageVariant; hideHeaderSkeleton?: boolean; attributeRows?: number } & SpaceProps
> = ({ numberOfAdsToDisplay = 5, variant = 'landscape', attributeRows, ...props }) => (
    <HorizontalSlider
        isLoading={true}
        testIdPrefix="advert-summary-skeletons"
        numberOfAdsToDisplay={numberOfAdsToDisplay}
        {...props}
        variant={variant}
    >
        {Array.from({ length: numberOfAdsToDisplay }).map((_, index) => (
            <AdvertSummarySkeleton key={index} variant={variant} attributeRows={attributeRows} />
        ))}
    </HorizontalSlider>
)

const widths = ['100%', '80%', '40%', '100%']

export const AdvertSummarySkeleton: FunctionComponent<{ variant?: SliderAdImageVariant; attributeRows?: number }> = ({
    variant = 'landscape',
    attributeRows = 4,
}) => (
    <Box
        display="flex"
        flexShrink={0}
        flexDirection="column"
        marginRight="m"
        css={css`
            &:last-of-type {
                margin-right: 0;
            }
        `}
    >
        <Box
            width="100%"
            marginBottom="s"
            aspectRatio={variant === 'portrait' ? '4/5' : undefined}
            height={variant === 'landscape' ? 131 : undefined}
        >
            <SkeletonLine />
        </Box>
        {Array.from({ length: attributeRows }).map((_, index) => (
            <Box key={index} width={widths[index]} height={20} marginBottom="xs">
                <SkeletonLine />
            </Box>
        ))}
    </Box>
)
