import React, { FunctionComponent } from 'react'
import { InternalRichText } from './InternalRichText'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/react'
import { css } from 'styled-components'

interface StoryblokRichText extends StoryblokComponentType<string> {
    text: SbBlokData
}

interface Props extends StoryblokDefaultProps<StoryblokRichText> {}

export const StoryblokRichText: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <InternalRichText
            blok={blok as unknown as SbBlokData}
            fontSize="sm"
            richTextKey="text"
            story={story}
            css={css`
                & > *:first-child {
                    margin-top: 0;
                }
                & > *:last-child {
                    margin-bottom: 0;
                }
            `}
        />
    )
}
