import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { ContextLinkList } from '@bbx/common/types/contextLinks'

export interface SearchConfigs {
    searchConfig: SearchConfig[]
}

export interface SearchConfig {
    searchId: number
    description: string
    verticalId: number
    searchKey: string
    nrOfAdverts: number
    sortOrderDefault: number
    searchSortOrderList: SearchSortOrderList
    taggingData: TaggingData
    selfLink: string
    parentLink: string
    searchLink: string
    contextLinkList: ContextLinkList
    iconUrlSvg: string
    iconUrlPng: string
    isInternLink: boolean
    quickLaunchNavigators?: unknown
    quicklinks: Quicklink[]
}

export interface SearchSortOrderList {
    searchSortOrder: SearchSortOrder[]
}

export interface SearchSortOrder {
    id: number
    description: string
    searchConfigId: number
}

export interface Quicklink {
    name: string
    count: string
    searchLink: string
    searchApiUri: string
    iconUrlSvg: string
    iconUrlPng: string
}

export const findSearchConfigForSearchId = (searchConfigs: SearchConfigs, searchId: number) =>
    searchConfigs.searchConfig.find((c) => c.searchId === searchId)
