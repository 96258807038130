import { TrendSliderType } from '@bbx/common/types/UserFeed'
import { StoryblokTrendSliderContent } from './StoryblokTrendSlider'
import { concatPathWithQueryParams, removeUrlParameter } from '@wh/common/chapter/lib/urlHelpers'

/**
 * Maps the storyblok-response of a trend-slider story to the format that is returned by CDC and expected by the <TrendSlider>-component
 */
export const mapTrendSliderStories = (trendSliderStories: StoryblokTrendSliderContent[]): TrendSliderType[] => {
    return trendSliderStories.map((trendSliderStory) => mapTrendSliderStory(trendSliderStory))
}

export const mapTrendSliderStory = (trendSliderStory: StoryblokTrendSliderContent): TrendSliderType => {
    const urlStr = trendSliderStory.adsSource.links.searchApiLink.replace('/restapi/v2', '')

    const apiLink = {
        id: 'searchApiLink',
        serviceName: 'iad',
        relativePath: concatPathWithQueryParams(removeUrlParameter(urlStr, 'rows'), { topicId: '1001', rows: '9' }),
    }

    const webLink = {
        id: 'searchWebLink',
        uri: trendSliderStory.adsSource.links.searchWebLink,
    }

    return {
        title: trendSliderStory.title,
        subTitle: trendSliderStory.subTitle,
        taggingId: trendSliderStory.taggingId,
        storyblokImage: { url: trendSliderStory.image?.filename, alt: trendSliderStory.image?.alt },
        pulseMetadata: { listName: `external-widget:trend-slider:${trendSliderStory.taggingId}` },
        contextLinkList: [webLink, apiLink],
    } as TrendSliderType
}
