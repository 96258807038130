import React, { FunctionComponent, RefObject } from 'react'
import { css } from 'styled-components'

export interface ImgSrcSet {
    x1: string
    x2: string
}

type ImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    imgSrcSet: ImgSrcSet
}

export const LazyPicture: FunctionComponent<ImageProps> = ({ imgSrcSet, ...props }) => {
    return <Picture imgSrcSet={imgSrcSet} {...props} loading="lazy" />
}

export const Picture: FunctionComponent<ImageProps & { imgRef?: RefObject<HTMLImageElement> }> = ({
    imgSrcSet,
    imgRef,
    css: cssProp,
    ...props
}) => {
    return (
        <picture>
            <source srcSet={`${imgSrcSet.x1} 1x, ${imgSrcSet.x2} 2x`} />
            <img
                src={imgSrcSet.x1}
                css={css`
                    height: ${props.height};
                    display: block;
                    ${cssProp}
                `}
                ref={imgRef}
                {...props}
            />
        </picture>
    )
}
