import React, { FunctionComponent, useContext, useState } from 'react'
import { DebugFlagContext } from '@wh/common/chapter/components/DebugFlagProvider/DebugFlagProvider'
import { useTimeout } from '@wh/common/chapter/hooks/useTimeout'
import { useDidomi } from '@wh/common/chapter/components/Didomi/useDidomi'
import { FeatureToggle, useFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider'
import Script from 'next/script'

interface FeatureToggleHotjarProps {
    siteId: number
    toggle: FeatureToggle
}

export const FeatureToggleHotjar: FunctionComponent<FeatureToggleHotjarProps> = ({ siteId, toggle }) => {
    const featureToggle = useFeatureToggle(toggle)
    return <Hotjar siteId={siteId} enabled={featureToggle} id={toggle} />
}

interface HotjarProps {
    siteId: number
    enabled?: boolean
    /** Avoid insertion of script when navigating on the client. */
    id: string
}

export const Hotjar: FunctionComponent<HotjarProps> = ({ siteId, enabled, id }) => {
    const debugFlags = useContext(DebugFlagContext)
    const { isConsentGiven } = useDidomi('Hotjar')
    const [timeoutElapsed, setTimeoutElapsed] = useState(false)
    const renderHotjar = timeoutElapsed && debugFlags.hotjarMode !== 'disable' && enabled && isConsentGiven
    const hotjarSnippetVersion = 6

    useTimeout(() => {
        setTimeoutElapsed(true)
    }, 2000)

    return renderHotjar ? (
        <Script
            id={`hotjar-${siteId}-${id}`}
            type="text/javascript"
            dangerouslySetInnerHTML={{
                __html: `
                            (function(h,o,t,j,a,r){
                                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                h._hjSettings={hjid:${siteId},hjsv:${hotjarSnippetVersion}};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                            `,
            }}
        />
    ) : null
}
