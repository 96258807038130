export const getXitiLevel2Id = (vertical_id: string | undefined, isHomepage?: string) => {
    if (isHomepage === 'true') {
        return '7'
    }

    switch (vertical_id) {
        case '2':
            return '2'
        case '3':
            return '3'
        case '5':
            return '4'
    }

    return undefined
}
