import { preprocessPageViewSCVs } from './taggingXitiPreProcessPageViewSCVs'
import { getContext } from '@wh/common/chapter/lib/commonHelpers'
import { removeUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { promiseTimeout, TRACKING_WORKAROUND_DELAY } from '@wh/common/chapter/lib/tagging/taggingPromiseTimeout'
import { TaggingActionEvent, TaggingPage, TaggingSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { xitiViewMap } from '@wh/common/chapter/lib/tagging/taggingXitiMapPageViews'
import { xitiClickMap } from '@wh/common/chapter/lib/tagging/taggingXitiMapEvents'
import { sanitize } from './sanitizeFunctions'

declare global {
    interface Window {
        xitiTracker?: Tag
    }
}

export interface XitiTagConstructor {
    // eslint-disable-next-line @typescript-eslint/prefer-function-type
    new (config?: Partial<XitiConfig>): Tag
}

export declare class Tag {
    constructor(config?: Partial<XitiConfig>)
    page: {
        set: (params: string | Record<string, unknown>) => void
        reset: () => void
    }
    click: {
        set: (params: Record<string, unknown>) => void
    }
    selfPromotion: {
        set: (params: Record<string, unknown>) => void
    }
    customVars: {
        set: (params: Record<string, unknown>) => void
    }
    internalSearch: {
        set: (params: Record<string, unknown>) => void
    }
    identifiedVisitor: {
        set: (params: Record<string, unknown>) => void
        unset: () => void
    }
    dispatch: () => void
}

// https://developers.atinternet-solutions.com/javascript-en/getting-started-javascript-en/tracker-initialisation-javascript-en/#appendix_5
interface XitiConfig {
    site: string
    log: string
    logSSL: string
    domain: string // deprecated, replaced by collectDomain
    secure: string // deprecated
    disableCookie: string
    cookieDomain: string
    cookieSecure: string
    ignoreEmptyChapterValue: string
    pixelPath: string
    preview: string
    collectDomain: string
    collectDomainSSL: string
    sendHitWhenOptOut: string
    forceHttp: string
    maxHitSize: string
    requestMethod: string
}

export const XITI_CONFIG: Partial<XitiConfig> = {
    log: 'logc135',
    logSSL: 'logs152',
    domain: 'xiti.com',
    cookieDomain: 'willhaben.at',
    secure: 'true',
}

const XITI_SITE_ID_PROD = '612451'
const XITI_SITE_ID_DEV = '612450'
export const XITI_SITE_ID = getContext() === 'prod' ? XITI_SITE_ID_PROD : XITI_SITE_ID_DEV

export const trackXitiPageView = (eventName: TaggingPage, params: Readonly<Record<string, string | undefined>>) => {
    const mappedData = mapPageViewEventName(eventName, params)

    if (!mappedData) {
        // no event matched, skip tagging
        return Promise.resolve()
    }

    const combinedParams: Record<string, unknown> = { ...preprocessPageViewSCVs(eventName, params), ...mappedData }

    return trackXitiEvent('view', combinedParams)
}

export const trackXitiClickEvent = (
    eventName: TaggingActionEvent | TaggingSelfPromotionEvent,
    params: Readonly<Record<string, string | undefined>>,
) => {
    const mappedData = mapClickEventName(eventName, params)

    if (!mappedData) {
        // no event matched, skip tagging
        return Promise.resolve()
    }

    const combinedParams: Record<string, unknown> = { ...params, ...mappedData }

    return trackXitiEvent('link', combinedParams)
}

const trackXitiEvent = (eventType: 'view' | 'link', params: Readonly<Record<string, unknown>>) => {
    if (typeof window.xitiTracker === 'undefined') {
        // skip tagging
        return Promise.resolve()
    }

    const page = removeUndefined({
        name: params.xiti_page_chapter_name,
        level2: params.xiti_vertical_id || params.xiti_s2,
    })
    if (eventType === 'view' && typeof page.name !== 'undefined') {
        window.xitiTracker.page.reset()
        window.xitiTracker.page.set(page)
    }

    const cv_site = removeUndefined({
        '1': params.xiti_x1,
        '2': params.xiti_x2,
        '3': params.xiti_x3,
        '4': params.xiti_x4,
        '5': params.xiti_x5,
        '6': params.xiti_x6,
        '7': params.xiti_x7,
        '8': params.xiti_x8,
        '9': params.xiti_x9,
        '10': params.xiti_x10,
        '11': params.xiti_x11,
        '12': params.xiti_x12,
        '13': params.xiti_x13,
    })
    const cv_page = removeUndefined({
        '1': params.xiti_f1,
        '2': params.xiti_f2,
        '3': params.xiti_f3,
        '4': params.xiti_f4,
        '5': params.xiti_f5,
        '6': params.xiti_f6,
        '7': params.xiti_f7,
        '8': params.xiti_f8,
        '9': params.xiti_f9,
        '10': params.xiti_f10,
    })
    if (!isEmptyObject(cv_site) || !isEmptyObject(cv_page)) {
        if (eventType === 'link') {
            window.xitiTracker.page.set('')
        }
        window.xitiTracker.customVars.set({
            site: isEmptyObject(cv_site) ? undefined : cv_site,
            page: isEmptyObject(cv_page) ? undefined : cv_page,
        })
    }

    const click = removeUndefined({
        name: params.xiti_click_chapter_name,
        level2: params.xiti_vertical_id || params.xiti_s2,
        type: params.xiti_click_type,
    })
    if (eventType === 'link' && typeof click.name !== 'undefined') {
        window.xitiTracker.click.set(click)
    }

    const visitor = removeUndefined({
        category: params.xiti_visitor_category,
        id: params.xiti_user_id as string | number | undefined,
    })
    if (!isEmptyObject(visitor)) {
        if (visitor.id) {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            const idNumber = parseInt(`${visitor.id ?? ''}`, 10)
            visitor.id = isNaN(idNumber) ? visitor.id : idNumber
            window.xitiTracker.identifiedVisitor.set(visitor)
        } else if (visitor.unset) {
            window.xitiTracker.identifiedVisitor.unset()
        }
    }

    const promo_click = removeUndefined((params.xiti_selfpromotion_click ?? {}) as Record<string, string>)
    if (!isEmptyObject(promo_click)) {
        window.xitiTracker.selfPromotion.set({ click: promo_click })
    }

    const promo_impression = removeUndefined((params.xiti_selfpromotion_impression ?? {}) as Record<string, string>)
    if (!isEmptyObject(promo_impression)) {
        window.xitiTracker.selfPromotion.set({ impression: promo_impression })
    }

    const intsearch = removeUndefined({
        keyword: params.search_terms,
        resultPageNumber: params.page_number,
    })
    if (!isEmptyObject(intsearch)) {
        window.xitiTracker.internalSearch.set(intsearch)
    }

    window.xitiTracker.dispatch()

    // since the track functions do not return a Promise, but we need to wait for tagging to be finished before routing in some cases, we introduce an artificial delay
    return promiseTimeout(TRACKING_WORKAROUND_DELAY)
}

const isEmptyObject = (object: Record<string, unknown>) => {
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            return false
        }
    }
    return true
}

const mapPageViewEventName = (eventName: TaggingPage, params: Readonly<Record<string, string | undefined>>) => {
    const privateOrProfessionalString = isPrivateAd(params.ad_type_id) ? 'Private' : 'Professional'
    const startPageTag = createStartPageTag(params.vertical_id, params.category_level_id_1)
    const adId = (params.ad_id as string)?.toLowerCase().replace('j', '')
    const map = xitiViewMap(params.vertical_id, privateOrProfessionalString, startPageTag, params.genericPageName, adId)

    const result = map[eventName ?? '']

    if (!result) {
        // ignoring unhandled eventName
        return undefined
    }

    return {
        xiti_page_chapter_name: result.page,
        xiti_s2: result.s2,
    }
}

const mapClickEventName = (
    eventName: TaggingActionEvent | TaggingSelfPromotionEvent,
    params: Readonly<Record<string, string | undefined>>,
) => {
    const trendLabel = params.trend_label || ''
    const category = xitiCategory(params.category_level_id_1) || sanitize(params.category)
    const map = xitiClickMap(params.vertical_id, trendLabel, category ?? '', params)

    const result = map[eventName ?? '']

    if (!result) {
        // ignoring unhandled eventName
        return undefined
    }

    return {
        xiti_click_chapter_name: result.click,
        xiti_s2: result.s2,
        xiti_selfpromotion_impression: result.xiti_selfpromotion_impression,
        xiti_selfpromotion_click: result.xiti_selfpromotion_click,
    }
}

const createStartPageTag = (vertical_id: string | undefined, category_level_id_1: string | undefined) => {
    switch (vertical_id) {
        case '2':
            return 'RealEstateStartpage'
        case '3':
            switch (category_level_id_1) {
                case '2':
                    return 'CarsStartpage'
                case '4':
                    return 'MotorbikeStartpage'
                default:
                    return 'MotorStartpage'
            }
        case '5':
            return 'MarketplaceStartpage'
    }
    return 'Startpage'
}

const isPrivateAd = (ad_type_id: string | undefined) => {
    if (ad_type_id === '69') {
        return false
    }
    return true
}

const xitiCategory = (category_level_id_1: string | undefined) => {
    switch (category_level_id_1) {
        case '2':
            return 'Cars'
        case '4':
            return 'Motorbikes'
        case '52':
            return 'Caravans'
        case '50':
            return 'Trucks'
        case '90':
            return 'all_object_types'
        case '131':
            return 'flat_for_rent'
        case '101':
            return 'flat_for_sale'
        case '132':
            return 'house_for_rent'
        case '102':
            return 'house_for_sale'
        case '42':
            return 'all_newconstruction_types'
        case '32':
            return 'vacation_property_for_rent'
        case '12':
            return 'vacation_property_for_sale'
        case '16':
            return 'commercial_property_for_rent'
        case '15':
            return 'commercial_property_for_sale'
        case '14':
            return 'plot'
        case '35':
            return 'other_objects'
    }
    return ''
}
