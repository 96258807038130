import { JsonDecoder } from 'ts.data.json'
import { ExtractedAdvertisingToPageMessage } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageProtocols'
import { positionFixedOrAbsoluteDecoder } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/decoderHelpers'
import { protocolDecoder } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageDecoderProtocolDecoder'

const setIframeContentDecoder1 = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'set-iframe-content' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('set-iframe-content'),
        target: JsonDecoder.oneOf(
            (['background', 'overlay'] as const).map((s) => JsonDecoder.isExactly(s)),
            'iframe target',
        ),
        src: JsonDecoder.string,
        position: positionFixedOrAbsoluteDecoder,
    },
    'AdvertisingToPageMessage',
)

const setIframeContentDecoder2 = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'set-iframe-content' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('set-iframe-content'),
        target: JsonDecoder.oneOf(
            (
                [
                    'motor-search-box-right',
                    'estate-search-box-right',
                    'bap-search-box-right',
                    'bap-small-leaderboard',
                    'motor-small-leaderboard',
                    'estate-small-leaderboard',
                    'startpage-teaser-large',
                ] as const
            ).map((s) => JsonDecoder.isExactly(s)),
            'iframe target',
        ),
        src: JsonDecoder.string,
    },
    'AdvertisingToPageMessage',
)

const setIframeContentDecoder3 = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'set-iframe-content' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('set-iframe-content'),
        target: JsonDecoder.oneOf(
            (
                [
                    'motor-search-entry-area-bottom',
                    'motor-search-entry-area-bottom-left',
                    'motor-search-entry-area-bottom-right',
                    'estate-search-entry-area-bottom',
                    'estate-search-entry-area-bottom-left',
                    'estate-search-entry-area-bottom-right',
                    'bap-search-entry-area-bottom',
                    'bap-search-entry-area-bottom-left',
                    'bap-search-entry-area-bottom-right',
                    'bap-search-entry-popular-categories',
                    'startpage-teaser-small',
                    'startpage-leaderboard-small',
                    'startpage-leaderboard',
                ] as const
            ).map((s) => JsonDecoder.isExactly(s)),
            'iframe target',
        ),
        src: JsonDecoder.string,
        height: JsonDecoder.optional(JsonDecoder.number),
    },
    'AdvertisingToPageMessage',
)

export const setIframeContentDecoder = JsonDecoder.oneOf(
    [setIframeContentDecoder1, setIframeContentDecoder2, setIframeContentDecoder3],
    'set-iframe-content',
)
