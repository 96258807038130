import { useCallback, useEffect, useState } from 'react'
import { useUserAgent } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { parseUserAgent, UserAgentState } from '@wh/common/chapter/components/UserAgentProvider/UserAgentProvider'
import { useSSRCompatibleCookieStore } from '@wh/common/chapter/hooks/useSSRCompatibleCookieStore'
import Cookie from 'js-cookie'

const DAYS_IN_MS = 1000 * 60 * 60 * 24

const DeprecatedBrowserAlertSnoozeValue = {
    true: 'true',
    false: 'false',
}

const checkIfDeprecatedAlertShouldBeShown = (userAgentState: UserAgentState, isSnoozeActivatedString: string | undefined) =>
    isSnoozeActivatedString === DeprecatedBrowserAlertSnoozeValue.true ? false : calculateBrowserClass(userAgentState) === 'unsupported'

export const useDeprecatedBrowserAlert = () => {
    const userAgentState = useUserAgent()
    // INFO: we need to use cookies and not localstorage because the alert needs to be shown in the SSR result because old browsers might not even be able to run our javascript
    const [isSnoozeActivatedString, setIsSnoozeActivated] = useSSRCompatibleCookieStore(
        'snooze_deprecated_browser_alert',
        DeprecatedBrowserAlertSnoozeValue.false,
    )
    const handleSnoozeDeprecatedBrowserAlert = useCallback(() => {
        setIsSnoozeActivated(DeprecatedBrowserAlertSnoozeValue.true, new Date(new Date().getTime() + DAYS_IN_MS * 14))
    }, [setIsSnoozeActivated])

    const [showDeprecatedBrowserAlertState, setShowDeprecatedBrowserAlertState] = useState(() =>
        checkIfDeprecatedAlertShouldBeShown(userAgentState, isSnoozeActivatedString),
    )

    useEffect(() => {
        const browserUserAgent = parseUserAgent(navigator.userAgent)
        const isSnoozeActivatedCookieString = Cookie.get('snooze_deprecated_browser_alert') ?? DeprecatedBrowserAlertSnoozeValue.false

        const showDeprecatedBrowserAlertClient = checkIfDeprecatedAlertShouldBeShown(browserUserAgent, isSnoozeActivatedCookieString)
        setShowDeprecatedBrowserAlertState(showDeprecatedBrowserAlertClient)
    }, [])

    return { showDeprecatedBrowserAlert: showDeprecatedBrowserAlertState, handleSnoozeDeprecatedBrowserAlert }
}

type BrowserClass = 'supported' | 'undesired' | 'unsupported'

const calculateBrowserClass = (userAgentState: UserAgentState): BrowserClass => {
    const uaParserResult = userAgentState.uaParserResult
    if (!uaParserResult) {
        return 'supported'
    }

    if (uaParserResult.browser.name?.toLowerCase() === 'safari') {
        const browserVersion = splitVersion(uaParserResult.browser.version)
        if (!browserVersion) {
            return 'supported'
        }
        const [major] = browserVersion

        if (major < 12) {
            return 'unsupported'
        }
    } else if (uaParserResult.browser.name?.toLowerCase() === 'mobile safari' || uaParserResult.os.name?.toLowerCase() === 'ios') {
        const osVersion = splitVersion(uaParserResult.os.version)
        if (!osVersion) {
            return 'supported'
        }
        const [major] = osVersion

        if (major < 11) {
            return 'unsupported'
        }
    } else if (uaParserResult.browser.name?.toLowerCase() === 'ie') {
        const browserVersion = splitVersion(uaParserResult.browser.version)
        if (!browserVersion) {
            return 'supported'
        }
        const [major] = browserVersion

        if (major <= 11) {
            return 'unsupported'
        }
    } else if (uaParserResult.browser.name?.toLowerCase() === 'firefox') {
        const browserVersion = splitVersion(uaParserResult.browser.version)
        if (!browserVersion) {
            return 'supported'
        }
        const [major] = browserVersion

        if (major < 57) {
            return 'unsupported'
        }
    } else if (uaParserResult.browser.name?.toLowerCase() === 'opera') {
        const browserVersion = splitVersion(uaParserResult.browser.version)
        if (!browserVersion) {
            return 'supported'
        }
        const [major] = browserVersion

        if (major < 53) {
            return 'unsupported'
        }
    } else if (uaParserResult.os.name?.toLowerCase() === 'android') {
        if (['samsung browser', 'samsung internet'].includes(uaParserResult.browser.name?.toLowerCase() ?? '')) {
            const browserVersion = splitVersion(uaParserResult.browser.version)
            if (!browserVersion) {
                return 'supported'
            }
            const [major] = browserVersion

            if (major < 9) {
                return 'unsupported'
            }
        } else if (uaParserResult.browser.name?.toLowerCase() === 'android browser') {
            return 'unsupported'
        }
    } else if (uaParserResult.browser.name?.toLowerCase() === 'chrome') {
        const browserVersion = splitVersion(uaParserResult.browser.version)
        if (!browserVersion) {
            return 'supported'
        }
        const [major] = browserVersion

        if (major < 66) {
            return 'unsupported'
        }
    }

    return 'supported'
}

const splitVersion = (versionString: string | undefined): [number, number | undefined] | undefined => {
    if (!versionString) {
        return undefined
    }

    const [majorString, minorString] = versionString.split('.')

    if (typeof majorString === 'undefined') {
        return undefined
    }

    const major = parseInt(majorString, 10)

    if (isNaN(major)) {
        return undefined
    }

    if (typeof minorString === 'undefined') {
        return [major, undefined]
    }

    const minor = parseInt(minorString, 10)

    if (isNaN(minor)) {
        return [major, undefined]
    }

    return [major, minor]
}
