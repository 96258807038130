import { rentalRoutes } from '@wh/common/rental/lib/rentalUtils'

export const saveScrollPos = (url: string) => {
    let scrollPos = { x: window.scrollX, y: window.scrollY }
    let key = url

    if (url === '/iad/immobilien/') {
        scrollPos = { x: 0, y: 0 }
    }

    if (url.startsWith(rentalRoutes.creditCheckEntryPoint)) {
        scrollPos = { x: 0, y: 0 }
        key = rentalRoutes.creditCheckEntryPoint
    }

    sessionStorage.setItem(key, JSON.stringify(scrollPos))
}

export const restoreScrollPos = (url: string) => {
    const key = url.startsWith(rentalRoutes.creditCheckEntryPoint) ? rentalRoutes.creditCheckEntryPoint : url
    const scrollPos: string | null = sessionStorage.getItem(key)
    const parsedScrollPos = scrollPos ? (JSON.parse(scrollPos) as { x: number; y: number }) : undefined

    if (parsedScrollPos) {
        window.scrollTo(parsedScrollPos.x, parsedScrollPos.y)
    }
}
