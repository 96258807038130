import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { AttributesItem, getAttributeValue } from '@wh/common/chapter/types/Attributes'

export const isPayliveryAd = (advertDetails: AdvertDetails) =>
    (advertDetails.p2ppOptions?.deliveryOptions.length ?? 0) > 0 && advertDetails.advertStatus.id !== adStatusFlag.SOLD

export const hasPaylivery = (attributes: AttributesItem[]) => getAttributeValue(attributes, 'p2penabled') === 'true'

export const isBuyNowAd = (advertDetails: AdvertDetails) =>
    isPayliveryAd(advertDetails) && !!advertDetails.p2ppOptions?.buyNowInformationDto?.isBuyNowAd
