import { isNotNullOrUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { TeaserAttribute } from '@wh/common/chapter/components/TeaserAttributes'

export const joinTeaserAttributes = (teaserAttributes?: TeaserAttribute[] | null) => {
    return teaserAttributes
        ?.map((teaserAttribute) =>
            [teaserAttribute.prefix, teaserAttribute.value, teaserAttribute.postfix].filter(isNotNullOrUndefined).join(' '),
        )
        .join(', ')
}
