import React, { FunctionComponent, useContext } from 'react'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { css } from 'styled-components'

export const LeaderboardIframe: FunctionComponent = () => {
    const advertisingState = useContext(AdvertisingStateContext)

    const leaderboardIframe = advertisingState.pageModifications.startPageLeaderboard

    const shouldRenderLeaderboardframeBasedOnBreakpoint = useResponsiveValue({ phone: false, tablet: true }, false)
    const shouldRenderLeaderboardframe = shouldRenderLeaderboardframeBasedOnBreakpoint && leaderboardIframe !== undefined

    if (!shouldRenderLeaderboardframe) {
        return null
    }

    return (
        <iframe
            src={leaderboardIframe?.src || ''}
            frameBorder="0"
            title="Werbung"
            aria-hidden="true"
            css={css`
                display: block;
                width: 100%;
                height: ${leaderboardIframe?.height}px;

                ${(p) => p.theme.media.print} {
                    display: none;
                }
            `}
        />
    )
}
