import { css } from 'styled-components'

// workaround for Firefox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=939897
export const printDisplayBlockFirefoxWorkaroundCss = css`
    @supports (-moz-appearance: meterbar) {
        @media print {
            display: block;
        }
    }
`
