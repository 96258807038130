import { isNotUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import type { ISbStoryData } from 'storyblok-js-client'
import { TableOfContentType } from './StoryblokTableOfContent'

export interface CmsStoryContent {
    body: Array<
        | { text: { content: { attrs: { body: { level: string; text: string; anchorId: string } | undefined } | undefined } } | undefined }
        | undefined
    >
}

export const mapCmsContentToToc = (story: ISbStoryData<CmsStoryContent> | undefined): TableOfContentType[] => {
    const flatToc = story?.content.body
        .map((body) => body?.text)
        ?.flatMap((text) => text?.content)
        ?.map((content) => content?.attrs)
        ?.flatMap((content) => content?.body)
        ?.filter(isNotUndefined)
        ?.map((body) => {
            return { level: body.level, text: body.text, anchorId: body.anchorId }
        })

    const nestedToc: TableOfContentType[] = []

    flatToc?.forEach((entry) => {
        const currentLevel = entry.level
        if (currentLevel === '2') {
            nestedToc.push({ subList: [], ...entry })
        }
        if (currentLevel === '3') {
            nestedToc[nestedToc.length - 1]?.subList?.push({ subList: [], ...entry })
        }
        if (currentLevel === '4') {
            const subList = nestedToc[nestedToc.length - 1].subList
            subList?.[subList.length - 1]?.subList?.push(entry)
        }
    })

    return nestedToc
}
