import { AttributeName } from './AttributeName'

export interface AttributesList {
    attribute: AttributesItem[]
}

export interface AttributesItem {
    name: AttributeName
    values: string[]
}

/* no api types */
export interface FormattedAttributesList {
    attribute: FormattedAttributesItem[]
}

export interface FormattedAttributesItem extends AttributesItem {
    formattedValues: string[]
    description?: string
    postfix?: string
    prefix?: string
    renderAsArray?: boolean
}

export interface AttributeGroup {
    id: string
    heading: string
    attribute: FormattedAttributesItem[]
}

export const getFormattedAttributeValue = (attributes: FormattedAttributesItem[], key: AttributeName): string | undefined => {
    return getAttribute(attributes, key)?.formattedValues?.[0]
}

export const getAttributeValue = (attributes: AttributesItem[], key: AttributeName): string | undefined => {
    return getAttributeValues(attributes, key)?.[0]
}

export const getAttributeValues = (attributes: AttributesItem[], key: AttributeName): string[] | undefined => {
    return attributes.find((attribute) => attribute.name === key)?.values
}

export const getAttribute = <T extends AttributesItem>(attributes: T[], key: AttributeName) => {
    return attributes.find((attribute) => attribute.name === key)
}

export const doesAttributeExist = (attributes: FormattedAttributesItem[], key: AttributeName) => !!getAttribute(attributes, key)
