import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { Text } from '@wh-components/core/Text/Text'
import { getSeoSearchResult } from '@wh/search/src/domains/search-journey/common/api/searchApiClient'
import { SearchResult } from '@wh/search/src/domains/search-journey/common/SearchResult'
import { Box } from '@wh-components/core/Box/Box'
import { AdvertSummary } from '@wh/search/src/common/types/ad-detail/AdvertSummary'
import { css, useTheme } from 'styled-components'
import { AdvertSummaryComponent } from '@wh/search/src/domains/search-journey/common/components/AdvertSummary/AdvertSummary'
import { getSeoUrl } from '@wh/search/src/domains/search-journey/common/lib/getSeoUrl'
import { StoryblokSearchLink } from '@wh/common/cms/types/StoryblokSearchLink'
import { StoryblokDefaultProps } from '@wh/common/cms/types/StoryblokComponents'
import { storyblokResolveSearchLink } from '@wh/common/cms/lib/storyblokResolveSearchLink'
import { concatPathWithQueryParams, removeUrlParameter } from '@wh/common/chapter/lib/urlHelpers'
import { adFocusHoverStyles } from '@wh/common/chapter/components/adFocusHoverStyles'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { verticals } from '@wh/common/chapter/types/verticals'
import { useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { useEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { SkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'

interface StoryblokSearhResultList extends StoryblokComponentType<string> {
    searchUrl: StoryblokSearchLink
    numberOfRows: number
    taggingId: string
}

interface Props extends StoryblokDefaultProps<StoryblokSearhResultList> {}

export const StoryblokSearchResultList: FunctionComponent<Props> = ({ blok }) => {
    const taggingExtraParameters = {
        taggingWidgetType: `ContentPage_${blok.taggingId}`,
        source: 'content-page',
        page: 'ContentPage',
    }

    const theme = useTheme()
    const [searchResult, setSearchResult] = useState<SearchResult | undefined>(undefined)
    const [numOfColumns, setNumOfColumns] = useState<number>(4)
    const [urlOfPage, setUrlOfPage] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const taggingData = useEmptyTaggingData()

    useEffect(() => {
        async function loadSearchResult() {
            try {
                const columns = window.innerWidth < theme.breakpoints.tablet ? 2 : 4
                setNumOfColumns(columns)

                const rowsParam = (blok.numberOfRows * columns - 1).toString()

                const pageUrl = storyblokResolveSearchLink(blok.searchUrl)
                setUrlOfPage(pageUrl)

                const searchUrl = concatPathWithQueryParams(removeUrlParameter(pageUrl.replace('/iad', ''), 'rows'), {
                    DISABLE_PP: 'true',
                    rows: rowsParam,
                })
                const result = await getSeoSearchResult(searchUrl)
                setSearchResult(result)
            } finally {
                setIsLoading(false)
            }
        }

        if (blok.searchUrl.type === 'searchLink') {
            loadSearchResult()
        }
    }, [theme, blok])

    const { visibilityTrackingRef } = useWidgetTagging({
        source: 'content-page',
        page: 'ContentPage',
        taggingWidgetType: `SearchResultList_${blok.taggingId}`,
    })

    const onClickShowAll = async () => {
        callActionEvent('content_page_srl_show_all', taggingData, {
            ...taggingExtraParameters,
            taggingId: blok.taggingId,
        })
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, taggingExtraParameters)
    }

    if (blok.searchUrl.type === 'deeplink' || blok.searchUrl.type === 'extern') {
        return (
            <Text>
                <Text display="block" color="palette.signal.red" fontSize="70px" margin="20px" fontWeight="bold">
                    Falscher Link Typ!
                </Text>
                <Text display="block" color="palette.signal.red" fontSize="40px" margin="20px" fontWeight="bold">
                    Es wird nur 'Suchlink' unterstützt.
                </Text>
            </Text>
        )
    } else if (!blok.searchUrl.webLink.includes('kaufen-und-verkaufen')) {
        return (
            <Text display="block" color="palette.signal.red" fontSize="70px" margin="20px" fontWeight="bold">
                Es werden nur Marktplatz Suchlinks unterstützt
            </Text>
        )
    }

    return (
        <Box ref={visibilityTrackingRef} {...storyblokEditable(blok as unknown as SbBlokData)}>
            <Box
                testId="contentpage_srl_wrapper"
                display="flex"
                flex="1 1 auto"
                alignContent="flex-start"
                flexWrap="wrap"
                paddingVertical="s"
                css={css`
                    & > * {
                        width: 50%;

                        ${(p) => p.theme.media.tablet} {
                            width: 25%;
                        }
                    }
                `}
            >
                {isLoading ? (
                    [...Array(blok.numberOfRows * numOfColumns).keys()].map((index: number) => (
                        <Box display="block" padding="s" key={index}>
                            <Box width="100%" marginBottom="s" aspectRatio="4/5">
                                <SkeletonLine />
                            </Box>
                            {index + 1 === blok.numberOfRows * numOfColumns ? undefined : (
                                <Fragment>
                                    <Box width="80%" height={20} marginBottom="xs">
                                        <SkeletonLine />
                                    </Box>
                                    <Box width="40%" height={20} marginBottom="xs">
                                        <SkeletonLine />
                                    </Box>
                                    <Box width="40%" height={20} marginBottom="xs">
                                        <SkeletonLine />
                                    </Box>
                                </Fragment>
                            )}
                        </Box>
                    ))
                ) : (
                    <Fragment>
                        {searchResult?.advertSummaryList.advertSummary.map((advertSummary: AdvertSummary, index: number) => (
                            <Box
                                display="block"
                                padding="s"
                                key={index}
                                css={css`
                                    text-wrap: wrap;
                                    ${adFocusHoverStyles(false, 0)}
                                `}
                            >
                                <AdvertSummaryComponent
                                    attributes={advertSummary.attributes.attribute}
                                    teaserAttributes={advertSummary.teaserAttributes}
                                    onClickTaggingEvent={() => {
                                        callActionEvent('content_page_srl_ad_click', taggingData, {
                                            ...taggingExtraParameters,
                                            taggingId: blok.taggingId,
                                        })
                                        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, taggingExtraParameters)
                                    }}
                                    key={advertSummary.id}
                                    vertical={verticals.BAP}
                                    adTypeId={advertSummary.adTypeId}
                                    description={advertSummary.description}
                                    href={getSeoUrl(advertSummary)}
                                    advertImage={advertSummary.advertImageList?.advertImage?.[0]?.mainImageUrl}
                                    advertStatus={advertSummary.advertStatus}
                                    adId={advertSummary.id}
                                    variant="portrait"
                                />
                            </Box>
                        ))}

                        <Box display="block" paddingHorizontal="s" marginVertical="s">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                paddingHorizontal="m"
                                width="100%"
                                aspectRatio="4/5"
                                css={css`
                                    background-color: ${theme.colors.palette.babyseal};
                                `}
                            >
                                <ServerRoutingAnchorLink
                                    testId="contentpage_srl_link_show_all"
                                    href={urlOfPage}
                                    type="button"
                                    width="100%"
                                    onClick={onClickShowAll}
                                    css={css`
                                        text-align: center;
                                        font-size: ${(p) => p.theme.fontSizes.s};
                                    `}
                                >
                                    Alle anzeigen
                                </ServerRoutingAnchorLink>
                            </Box>
                        </Box>
                    </Fragment>
                )}
            </Box>
        </Box>
    )
}
