import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import icon from '../../static_hashed/img/touch-icons/apple-touch-icon.png'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { Button } from '@wh-components/core/Button/Button'
import FavoriteIcon from '@wh-components/icons/Favorite'
import { useAndroidOnelinkBanner } from './useAndroidOnelinkBanner'
import { Modal } from '@wh-components/core/Modal/Modal'
import { SpaceProps } from '@wh-components/system/space'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'

interface AndroidOnelinkBannerProps {
    allowAndroidBanner: boolean
}

/**
 * App Install Banner for Android.
 * Reads the corresponding url from the google-play-app meta tag's `willhaben-onelink` parameter (see `SeoMetaTags.tsx`).
 * Not used for iOS since we use native Smart Banners there by just providing a apple-itunes-app meta tag.
 * Provides Context for its children to allow them to adapt for the banner height.
 */
export const AndroidOnelinkBanner: FunctionComponent<AndroidOnelinkBannerProps> = (props) => {
    const { showAndroidOnelinkBanner, handleSnoozeAndroidOnelinkBanner, openAndroidOnelink } = useAndroidOnelinkBanner()
    const { render, cssShow } = useTransitionShowHideDelay(showAndroidOnelinkBanner && props.allowAndroidBanner, 1000)
    const appInstalled = useAppInstalled()

    if (!render) {
        return null
    }

    return (
        <Modal isOpen={cssShow} style={{ content: { margin: 16 } }}>
            <Box paddingHorizontal="xs" paddingVertical="m" display="flex" flexDirection="column" alignItems="center">
                <Box display="flex" maxWidth={272}>
                    <Box
                        backgroundImage={`url(${icon})`}
                        backgroundColor="transparent"
                        backgroundSize="cover"
                        flex="0 0 auto"
                        marginRight="sm"
                        width={50}
                        height={50}
                        borderRadius={10}
                        boxShadow="none"
                        css={css`
                            filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
                        `}
                    />
                    <Box flex="1 1 auto" marginRight="m">
                        <Box fontSize={12} fontWeight="normal" color="palette.verydarkgrey">
                            <Text fontWeight="bold">willhaben</Text>
                            <span> - {appInstalled ? 'Öffne' : 'Installiere'} die App und nutze willhaben in vollem Umfang</span>
                        </Box>
                        <Stars marginTop={4} color="#fed921" size={12} />
                    </Box>
                </Box>
                <Button
                    marginTop="l"
                    flex="0 0 auto"
                    minWidth={130}
                    onClick={() => {
                        openAndroidOnelink()
                    }}
                >
                    {appInstalled ? 'Öffnen' : 'Installieren'}
                </Button>
                <ButtonWithLinkStyle
                    marginTop="m"
                    color="palette.elephant"
                    fontWeight="bold"
                    onClick={() => {
                        handleSnoozeAndroidOnelinkBanner()
                    }}
                >
                    Nein, danke!
                </ButtonWithLinkStyle>
            </Box>
        </Modal>
    )
}

// for css transitions to work correctly, it is necessary to first render an element to the dom with the initial value, and only then change it to the target value
// and on hiding, the dom node still has to be rendered until the animation finished
const useTransitionShowHideDelay = (show: boolean, hideDuration: number) => {
    const [state, setState] = useState<'hidden' | 'showingDelay' | 'shown' | 'hidingDelay'>(show ? 'shown' : 'hidden')
    const stateRef = useRef(state)
    stateRef.current = state
    useEffect(() => {
        switch (stateRef.current) {
            case 'hidden':
            case 'hidingDelay':
                if (show) {
                    setState('showingDelay')
                    const handle = setTimeout(() => {
                        setState('shown')
                    }, 100)
                    return () => {
                        clearTimeout(handle)
                    }
                } else {
                    return
                }
            case 'shown':
            case 'showingDelay':
                if (show) {
                    return
                } else {
                    setState('hidingDelay')
                    const handle = setTimeout(() => {
                        setState('hidden')
                    }, hideDuration)
                    return () => {
                        clearTimeout(handle)
                    }
                }
        }
    }, [show, hideDuration])

    return {
        hidden: {
            render: false,
            cssShow: false,
        },
        showingDelay: {
            render: true,
            cssShow: false,
        },
        shown: {
            render: true,
            cssShow: true,
        },
        hidingDelay: {
            render: true,
            cssShow: false,
        },
    }[state]
}

const Stars: FunctionComponent<{ color: string; size: number } & SpaceProps> = ({ color, size, ...layoutProps }) => (
    <Box fontSize={13} lineHeight="1em" fontWeight="bold" letterSpacing={4} {...layoutProps}>
        <FavoriteIcon color={color} size={size} />
        <FavoriteIcon color={color} size={size} />
        <FavoriteIcon color={color} size={size} />
        <FavoriteIcon color={color} size={size} />
        {/* half star */}
        <Box display="inline-block" width={size / 2} height={size} overflow="hidden">
            <FavoriteIcon color={color} size={size} />
        </Box>
    </Box>
)

declare global {
    interface Navigator {
        getInstalledRelatedApps?: () => Promise<unknown[]>
    }
}

const useAppInstalled = () => {
    const [appIsInstalled, setAppIsInstalled] = useState(false)

    useEffect(() => {
        ;(async () => {
            if (navigator.getInstalledRelatedApps) {
                const installedApps = await navigator.getInstalledRelatedApps()
                setAppIsInstalled(installedApps.length > 0)
            }
        })()
    }, [])

    return appIsInstalled
}
