import { promiseTimeout, TRACKING_WORKAROUND_DELAY } from '@wh/common/chapter/lib/tagging/taggingPromiseTimeout'
import { TaggingPage } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { replaceUmlauts, sanitize } from './sanitizeFunctions'

declare global {
    interface Window {
        IOMm?: (...args: IOMmArgs) => void
    }
}

type IOMmArgs = ['configure', OewaIOMmConfigParams] | ['pageview', OewaIOMmPageviewParams]

interface OewaIOMmConfigParams {
    st: string
    dn: string
    cn: string
    mh: number // needed for activating ÖWA for production
    dc?: string // distribution channel [web|hyb|app] defaults to web
    dg?: boolean // debug flag defaults to false
}
interface OewaIOMmPageviewParams {
    cp: string // category path - identical to former ÖWA IOLib cp parameter but for all environments
    co?: string
    pt?: string
}

export const OEWA_SITE = 'at_w_atwillhab'
const MOEWA_BREAKPOINT = 969
export const DOMAIN_SERVICE_NAME = 'data-e81f9554e5.willhaben.at'

export const trackOewaPageView = (eventName: TaggingPage, params: Readonly<Record<string, string | undefined>>) => {
    if (!(typeof window.IOMm === 'function')) {
        // skip tagging
        return Promise.resolve()
    }

    const mappedParams = mapEventName(eventName, params)

    if (!mappedParams?.oewa_categorypath_sktg) {
        // skipping tag as not all necessary parameters were provided
        return Promise.resolve()
    }

    const { oewa_categorypath_sktg, oewa_categorypath_pageid } = mappedParams

    const oewaIOMmPageviewParams: OewaIOMmPageviewParams = { cp: oewa_categorypath_sktg || '' }
    const width = window.innerWidth
    let isMobile = false
    if (typeof width === 'number' && MOEWA_BREAKPOINT && width <= MOEWA_BREAKPOINT) {
        isMobile = true
    }
    if (isMobile) {
        oewaIOMmPageviewParams.cp = `${oewaIOMmPageviewParams.cp}/moewa`
    }
    if (oewa_categorypath_pageid) {
        oewaIOMmPageviewParams.cp = `${oewaIOMmPageviewParams.cp}/${oewa_categorypath_pageid}`
    }

    window.IOMm('pageview', oewaIOMmPageviewParams)

    // since the track functions do not return a Promise, but we need to wait for tagging to be finished before routing in some cases, we introduce an artificial delay
    return promiseTimeout(TRACKING_WORKAROUND_DELAY)
}

const mapEventName = (eventName: TaggingPage, params: Readonly<Record<string, string | undefined>>) => {
    const vertical = oewaVerticalName(params.vertical_id)
    const category = oewaCategory(params.category_level_id_1, params.ad_type_id, params.category_level_1)
    const startPageId = createStartPageTag(params.vertical_id, params.category_level_id_1)
    const verticalSKTG = createVerticalSKTG(params.vertical_id)
    const region_level_2_without_umlauts = replaceUmlauts(params.region_level_2 || '')
    const eventPrefix = eventName === 'mapobject' ? '/KA' : '/DA'
    const adServicesPageId = (params.ad_type || 'Adservices')
        .concat('/')
        .concat(params.genericPageName || 'Adservices')
        .replace(/ /g, '_')
    const adDetailPageId = createAdDetailPageId(
        params.vertical_id,
        params.make,
        params.model,
        category,
        region_level_2_without_umlauts,
        eventPrefix,
    )
    const adDetailContactSellerConfirmationPageId = createAdDetailContactSellerConfirmationPageId(
        params.vertical_id,
        params.make,
        params.model,
        category,
        region_level_2_without_umlauts,
    )

    const map: Record<string, { sktg: string; pageid?: string } | undefined> = {
        homepage: { sktg: 'Service/Rubrikenmaerkte/RubrikenmaerkteUeberblick' },
        contact_contact: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        terms_conditions_general: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        terms_conditions_payment_and_delivery: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        ad_rules: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        privacy_policy: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        imprint: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        terms_conditions: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        press_about_willhaben: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        security_hints_overview: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        security_hints_buy: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        security_hints_account: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        security_hints_sell: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        security_hints_puppy: { sktg: 'Service/Unternehmenskommunikation/Unternehmenskommunikation' },
        auto_dealer_list: { sktg: 'Service/Rubrikenmaerkte/Automarkt/Firmen/Bundesland' },
        bap_dealer_list: { sktg: 'Service/Rubrikenmaerkte/Sonstiges/Shops' },
        my_personalization: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        mywillhaben_myprofile: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        mywillhaben_changelogindata: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        ad_insertion_vertical_selection: { sktg: 'Service/Rubrikenmaerkte/Sonstiges/Anzeigenaufgabe', pageid: 'Uebersicht' },
        ad_insertion_estate_product_selection: { sktg: 'Service/Rubrikenmaerkte/Immobilienmarkt/Anzeigenaufgabe', pageid: 'Uebersicht' },
        ad_insertion_motor_product_selection: { sktg: 'Service/Rubrikenmaerkte/Automarkt/Anzeigenaufgabe', pageid: 'Uebersicht' },
        ad_insertion_modelsearch: { sktg: `${verticalSKTG}/Anzeigenaufgabe`, pageid: 'Erstellen' },
        ad_insertion_form: { sktg: `${verticalSKTG}/Anzeigenaufgabe`, pageid: 'Erstellen' },
        ad_insertion_form_edit: { sktg: `${verticalSKTG}/Anzeigenaufgabe`, pageid: 'Erstellen' },
        ad_insertion_delivery: { sktg: `${verticalSKTG}/Anzeigenaufgabe`, pageid: 'Erstellen' },
        ad_insertion_delivery_edit: { sktg: `${verticalSKTG}/Anzeigenaufgabe`, pageid: 'Erstellen' },
        ad_insertion_upsellings: { sktg: `${verticalSKTG}/Anzeigenaufgabe`, pageid: 'Erstellen' },
        ad_insertion_summary: { sktg: `${verticalSKTG}/Anzeigenaufgabe`, pageid: 'Zahlungsuebersicht' },
        ad_insertion_confirmation: { sktg: 'Service/Rubrikenmaerkte/Sonstiges', pageid: 'Anzeigenaufgabe' },
        my_search_agents: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        my_search_agents_create: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        my_search_agents_edit: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        my_search_agents_edit_modal: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        upselling: { sktg: 'Service/Rubrikenmaerkte/'.concat(vertical), pageid: 'Anzeigenaufgabe' },
        checkout: { sktg: 'Service/Rubrikenmaerkte/'.concat(vertical), pageid: 'Anzeigenaufgabe' },
        billrequest: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        invoices: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        renew_ad_page: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        my_ads: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        my_saved_ads: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        chat_view: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'Meinwillhaben' },
        seller_profile: { sktg: `${verticalSKTG}/Alle/TL` },
        dealer_profile: { sktg: `${verticalSKTG}/Alle/TL` },
        adview: { sktg: verticalSKTG, pageid: adDetailPageId },
        mapobject: { sktg: verticalSKTG, pageid: adDetailPageId },
        adview_nc: { sktg: verticalSKTG, pageid: adDetailPageId },
        contact_seller_confirmation: { sktg: verticalSKTG, pageid: adDetailContactSellerConfirmationPageId },
        search_result_list: { sktg: verticalSKTG, pageid: (category || 'Sonstige').concat('/TL') },
        detail_search: { sktg: verticalSKTG, pageid: (category || 'Sonstige').concat('/Suche') },
        vertical_home: { sktg: verticalSKTG, pageid: startPageId },
        bap_giveaway_home: { sktg: 'Service/Rubrikenmaerkte/Sonstiges', pageid: 'Verschenken' },
        vertical_browse: { sktg: verticalSKTG, pageid: startPageId },
        vertical_browse_giveaway: { sktg: 'Service/Rubrikenmaerkte/Sonstiges', pageid: 'Verschenken' },
        jobs_my_searchagents: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'MyJob' },
        jobs_my_savedads: { sktg: 'Service/Sonstiges/Sonstiges', pageid: 'MyJob' },
        jobs_addetail: { sktg: 'Service/Rubrikenmaerkte/Stellenanzeige/Arbeitnehmer/Jobsuche/Ergebnisliste/Detailansicht' }, // TODO: add pageid??
        jobs_resultlist: { sktg: 'Service/Rubrikenmaerkte/Stellenanzeige/Arbeitnehmer/Jobsuche/Ergebnisliste' }, // TODO: add pageid??
        jobs_startpage: { sktg: 'Service/Rubrikenmaerkte/Stellenanzeige/Arbeitnehmer/Jobsuche' }, // TODO: add pageid??
        jobs_companysearch: { sktg: 'Service/Rubrikenmaerkte/Stellenanzeige/Arbeitnehmer/Firmensuche' },
        jobs_companyprofile: { sktg: 'Service/Rubrikenmaerkte/Stellenanzeige/Arbeitnehmer/Firmensuche/Firmendetail' },
        'will::immotipps': { sktg: 'Service/Rubrikenmaerkte/Immobilienmarkt', pageid: 'Immotipps' },
        adservices: { sktg: verticalSKTG, pageid: adServicesPageId },
    }

    let mapKey: string = eventName

    if (eventName === 'storyblok_page' && params.genericPageName) {
        mapKey = params.genericPageName
    }

    const result = map[mapKey ?? '']
    if (!result) {
        // ignoring unhandled eventName
        return undefined
    }

    return {
        ...params,
        oewa_categorypath_sktg: result.sktg,
        oewa_categorypath_pageid: result.pageid,
    }
}

const oewaVerticalName = (verticalId: string | undefined) => {
    switch (verticalId) {
        case '1':
            return 'Jobs'
        case '2':
            return 'Immobilienmarkt'
        case '3':
            return 'Automarkt'
        case '5':
            return 'Sonstiges'
    }
    return 'Sonstiges'
}

const oewaCategory = (category_level_id_1: string | undefined, ad_type_id: string | undefined, category_level_1: string | undefined) => {
    // Special handling for New Construction projects
    if (ad_type_id === '16') {
        return 'Neubauprojekt'
    }

    switch (category_level_id_1) {
        case '2':
            return 'Auto'
        case '4':
            return 'Motorraeder'
        case '52':
            return 'Wohnmobile'
        case '50':
            return 'Nutzfahrzeuge'
        case '131':
            return 'Wohnungen_zu_vermieten'
        case '101':
            return 'Wohnungen_zu_verkaufen'
        case '132':
            return 'Haeuser_zu_vermieten'
        case '102':
            return 'Haeuser_zu_verkaufen'
        case '32':
            return 'Ferienimmobilien_zu_vermieten'
        case '12':
            return 'Ferienimmobilien_zu_verkaufen'
        case '16':
            return 'Gewerbeobjekte_zu_vermieten'
        case '15':
            return 'Gewerbeobjekte_zu_verkaufen'
        case '14':
            return 'Grundstuecke'
    }

    return sanitize(category_level_1)
}

const createStartPageTag = (vertical_id: string | undefined, category_level_id_1: string | undefined) => {
    switch (vertical_id) {
        case '2':
            return 'Home'
        case '3':
            switch (category_level_id_1) {
                case '2':
                    return 'Auto'
                case '4':
                    return 'Motorraeder'
            }
            break
        case '5':
            return 'Home'
    }
    return 'Home'
}

const createVerticalSKTG = (vertical_id: string | undefined) => {
    switch (vertical_id) {
        case '2':
            return 'Service/Rubrikenmaerkte/Immobilienmarkt'
        case '3':
            return 'Service/Rubrikenmaerkte/Automarkt'
        case '5':
            return 'Service/Rubrikenmaerkte/Sonstiges'
    }
    return 'Service/Rubrikenmaerkte/Sonstiges'
}

const createAdDetailPageId = (
    vertical_id: string | undefined,
    make: string | undefined,
    model: string | undefined,
    category: string | undefined,
    region_level_2: string | undefined,
    prefix: string,
) => {
    switch (vertical_id) {
        case '2':
            return (category || 'Sonstige')
                .concat(prefix)
                .concat('/')
                .concat(region_level_2 ?? '')
        case '3':
            return 'Auto'
                .concat(prefix)
                .concat('/')
                .concat(make || '', '/', model || '')
        case '5':
            return (category || 'Sonstige').concat(prefix)
    }
    return ''
}

const createAdDetailContactSellerConfirmationPageId = (
    vertical_id: string | undefined,
    make: string | undefined,
    model: string | undefined,
    category: string | undefined,
    region_level_2: string | undefined,
) => {
    switch (vertical_id) {
        case '2':
            return (category || 'Sonstige').concat('/K_G/').concat(region_level_2 ?? '')
        case '3':
            return 'Auto/K_G/'.concat(make || '', '/', model || '')
        case '5':
            return (category || 'Sonstige').concat('/K_G/')
    }
    return ''
}
