import { LOGGED_IN_HEADER_RENDER_COUNT } from '@wh/common/chapter/lib/localStorage'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { isClient } from './commonHelpers'
import { UserProfileData } from '../types/user'
import { findContextLinkWithId } from '../types/contextLinks'

export const shouldShowEnterBirthYearHint = (birthYear: string) => {
    if (!isClient()) {
        return false
    }
    if (birthYear) {
        return false
    }
    if (storageAvailable('localStorage')) {
        return Number(localStorage.getItem(LOGGED_IN_HEADER_RENDER_COUNT)) <= 3
    }
    return true
}

export const requiresDac7Action = (profileData?: UserProfileData | null) => {
    return (
        profileData && profileData.dac7NotificationAttempt !== 'COMPLETED' && findContextLinkWithId('dac7form', profileData.contextLinkList)
    )
}
