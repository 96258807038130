import { PayliveryBadge } from '../../../chapter/components/PayliveryBadge/PayliveryBadge'
import { storyblokResolveLink } from '../../lib/storyblokResolveLink'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { StoryblokComponentType, SbBlokData } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { LazyPicture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import { isNotUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { Fragment, FunctionComponent, ReactNode } from 'react'
import { css } from 'styled-components'
import { storyblokTransformSlugToTag } from '../../lib/storyblokTransformSlugToTag'

export interface StoryblokTextWithPictureAndLinksBlok extends StoryblokComponentType<string> {
    heading: string
    text: string
    image: StoryblokAsset
    imageLink: StoryblokLink
    imageTaggingId?: string
    link1: StoryblokLink
    link2: StoryblokLink
    link1_label?: string
    link2_label?: string
    link1TaggingId?: string
    link2TaggingId?: string
    imageHeight: number
    amount?: number
    showPayliveryBadge?: boolean
    imageBackgroundColor: string
    imageHoverColor: string
}

interface Props extends StoryblokDefaultProps<StoryblokTextWithPictureAndLinksBlok> {
    taggingData?: TaggingData
    taggingPrefix?: string
}

export const StoryblokTextWithPictureAndLinks: FunctionComponent<Props> = ({ blok, story, taggingData, taggingPrefix, ...props }) => {
    const {
        heading,
        text,
        image,
        imageLink,
        imageTaggingId,
        link1,
        link2,
        link1_label,
        link2_label,
        link1TaggingId,
        link2TaggingId,
        imageBackgroundColor,
        imageHoverColor,
        showPayliveryBadge,
    } = blok

    const resolvedLink1 = storyblokResolveLink(link1)
    const resolvedLink2 = storyblokResolveLink(link2)

    const links = [
        resolvedLink1 ? { link: resolvedLink1, label: link1_label, taggingId: link1TaggingId, target: link1.target } : undefined,
        resolvedLink2 ? { link: resolvedLink2, label: link2_label, taggingId: link2TaggingId, target: link2.target } : undefined,
    ].filter(isNotUndefined)

    const prefix = taggingPrefix ?? storyblokTransformSlugToTag(story?.full_slug ?? '')

    return (
        <Box
            display="flex"
            flexDirection="column"
            flex={{ tablet: 1 }}
            width={{ phone: 'calc(50% - 8px)', tablet: 'auto' }}
            {...storyblokEditable(blok as unknown as SbBlokData)}
            {...props}
        >
            <Box height={{ phone: 120, tablet: 165 }} position="relative">
                {showPayliveryBadge && (
                    <Box position="absolute" margin="xs">
                        <PayliveryBadge id={heading} />
                    </Box>
                )}
                <LinkWrapper
                    link={storyblokResolveLink(imageLink)}
                    taggingData={taggingData}
                    taggingPrefix={prefix}
                    taggingId={imageTaggingId}
                    target={imageLink.target}
                >
                    <LazyPicture
                        height="100%"
                        width="100%"
                        imgSrcSet={{ x1: `${image.filename}/m/0x165`, x2: `${image.filename}/m/0x330` }}
                        alt={image.alt}
                        css={css`
                            object-fit: cover;
                            background-color: ${(p) => p.theme.colors.palette.babyseal};
                            background-color: ${imageBackgroundColor};
                            transition: 0.4s;
                            ${imageHoverColor ? `&:hover {background-color: ${imageHoverColor};}` : ''}
                        `}
                    />
                </LinkWrapper>
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={1} paddingTop="s" paddingBottom="m">
                <Text fontWeight="bold" marginBottom="xs">
                    {heading}
                </Text>
                <Text fontSize="s" marginBottom="s">
                    {text}
                </Text>
                <Box display="flex" flexWrap="wrap">
                    {links.map((link, index2) => (
                        <Box key={index2} display="flex">
                            <ServerRoutingAnchorLink
                                display="inline"
                                type="anchor"
                                href={link.link}
                                onClick={() => {
                                    if (prefix && link.taggingId) {
                                        callActionEvent('storyblok_click', taggingData, {
                                            prefix: prefix,
                                            taggingId: link.taggingId,
                                        })
                                    }
                                }}
                                underline="none"
                                target={link.target}
                            >
                                <Text fontSize="s" fontWeight="bold">
                                    {link.label}
                                </Text>
                            </ServerRoutingAnchorLink>
                            {index2 < links.length - 1 && (
                                <Text color="palette.koala" marginHorizontal="s">
                                    |
                                </Text>
                            )}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

const LinkWrapper: FunctionComponent<{
    link: string
    taggingData?: TaggingData
    taggingPrefix: string
    taggingId?: string
    target?: string
    children: ReactNode
}> = ({ link, taggingData, taggingPrefix, taggingId, target, children }) => {
    if (link) {
        return (
            <ServerRoutingAnchorLink
                type="anchor"
                href={link}
                onClick={() => {
                    if (taggingPrefix && taggingId) {
                        callActionEvent('storyblok_click', taggingData, { prefix: taggingPrefix, taggingId: taggingId })
                    }
                }}
                underline="none"
                target={target}
            >
                {children}
            </ServerRoutingAnchorLink>
        )
    }

    return <Fragment>{children}</Fragment>
}
