import { ExtractedAdvertisingToPageMessage } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageProtocols'
import { AdvertisingPageModificationsState } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingPageModificationsState'

export const undeprecatedBackgroundTransparentTarget = (
    target: ExtractedAdvertisingToPageMessage<{ type: 'set-background-transparent' }>['target'],
) => {
    return target.reduce(
        (previous, current) => {
            if (current === 'apn-leaderboard') {
                return previous.concat(['apn-large-leaderboard', 'apn-medium-leaderboard'])
            } else if (current === 'apn-leaderboard-right-separator') {
                return previous.concat(['apn-large-leaderboard-right-separator'])
            } else {
                return previous.concat([current])
            }
        },
        [] as NonNullable<AdvertisingPageModificationsState['backgroundTransparent']>,
    )
}

export const undeprecatedCloseAdSlotTarget = (
    target: ExtractedAdvertisingToPageMessage<{ type: 'close-ad-slot' }>['target'],
): NonNullable<AdvertisingPageModificationsState['closedAdSlots']> => {
    switch (target) {
        case 'apn-skyscraper':
            return ['apn-large-skyscraper']
        case 'apn-leaderboard':
            return ['apn-large-leaderboard', 'apn-medium-leaderboard']
        case 'apn-tablet-leaderboard':
            return ['apn-medium-leaderboard']
        case 'apn-content-ad':
            return ['apn-large-ad-detail']
        case 'apn-tablet-ad-detail-content-ad':
            return ['apn-medium-ad-detail']
        case 'apn-ad-detail-content-ad':
            return ['apn-small-ad-detail']
        case 'apn-ad-detail':
            return ['apn-small-ad-detail']
        case 'apn-filter-ad':
            return ['apn-large-filter-ad', 'apn-medium-filter-ad']
        case 'apn-channel-branding':
            return ['apn-large-channel-branding']
        case 'apn-bottom-ad':
            return ['apn-large-bottom-ad']
        case 'apn-topic-worlds':
            return ['apn-small-topic-worlds']
        case 'apn-fake-ad-1':
            return ['apn-large-result-list-1']
        case 'apn-fake-ad-2':
            return ['apn-large-result-list-2']
        case 'apn-fake-ad-3':
            return ['apn-large-result-list-3']
        case 'apn-tablet-result-list-1':
            return ['apn-medium-result-list-1']
        case 'apn-tablet-result-list-2':
            return ['apn-medium-result-list-2']
        case 'apn-result-list-1':
            return ['apn-small-result-list-1']
        case 'apn-result-list-2':
            return ['apn-small-result-list-2']
        case 'apn-result-list-3':
            return ['apn-small-result-list-3']
        case 'apn-aza-1':
            return ['apn-large-aza-1']
        case 'apn-aza-2':
            return []
        case 'apn-home-special-format':
            return ['apn-large-home-special-format']
        case 'apn-message-sent':
            return []
        case 'apn-scrolling-position-1':
            return []
        case 'apn-scrolling-position-2':
            return []

        default:
            return [target]
    }
}

export const undeprecatedTopStyle = (topStyle: 'page-top' | 'apn-large-leaderboard-top' | 'apn-leaderboard-top' | undefined) => {
    if (topStyle === 'apn-leaderboard-top') {
        return 'apn-large-leaderboard-top'
    } else {
        return topStyle
    }
}
