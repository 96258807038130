import { css } from 'styled-components'

/* Expanding clickable area on touch devices
 *  needs a position:relative parent */
export const increaseClickableArea = (pixel: number) => css`
    @media (pointer: coarse) {
        &::after {
            content: '';
            position: absolute;
            top: -${pixel}px;
            right: -${pixel}px;
            bottom: -${pixel}px;
            left: -${pixel}px;
        }
    }
`
