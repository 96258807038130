import React, { FunctionComponent, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { UAParser } from 'ua-parser-js'

export interface UserAgentState {
    userAgent: string
    isBot: boolean
    screenSize: 'desktop' | 'tablet' | 'phone'
    uaParserResult?: UAParser.IResult
}

const defaultValue: UserAgentState = {
    userAgent: '',
    isBot: false,
    screenSize: 'desktop',
}

export const UserAgentContext = React.createContext<UserAgentState>(defaultValue)

interface UserAgentProviderProps extends PropsWithChildren {
    userAgent?: string
}

export const UserAgentProvider: FunctionComponent<UserAgentProviderProps> = ({ userAgent, children }) => {
    const [userAgentState, setUserAgentState] = useState(userAgent)

    useEffect(() => {
        setUserAgentState(navigator.userAgent)
    }, [setUserAgentState])

    const state = useMemo(() => (userAgentState ? parseUserAgent(userAgentState) : undefined), [userAgentState])

    return <UserAgentContext.Provider value={state || defaultValue}>{children}</UserAgentContext.Provider>
}

export const parseUserAgent = (userAgent: string): UserAgentState => {
    const uaParser = new UAParser()
    uaParser.setUA(userAgent)

    return {
        userAgent,
        isBot: isBot(userAgent),
        screenSize: getScreenSize(uaParser),
        uaParserResult: uaParser.getResult(),
    }
}

const isBot = (userAgent: string): boolean => botUserAgents.some((botUserAgent) => userAgent.toLowerCase().indexOf(botUserAgent) > -1)

// For SEO / dynamic rendering we care about Google only! So Google's crawlers should be identified as a relevant bot.
export const botUserAgents = ['googlebot', 'google-structured-data-testing-tool', 'adsbot-google', 'mediapartners-google']

export const getScreenSize = (uaParser: UAParser) => {
    if (uaParser.getDevice().type === 'mobile') {
        return 'phone'
    }

    if (uaParser.getDevice().type === 'tablet') {
        return 'tablet'
    }

    return 'desktop'
}
