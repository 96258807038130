export const loadExternalScriptAsync = (src: string, id?: string, crossOrigin?: string) => {
    return new Promise((resolve, reject) => {
        if (id && document.getElementById(id)) {
            // already created <script> tag
            reject('script already loaded')
            return
        }
        const scriptTag = document.createElement('script')
        scriptTag.src = src
        scriptTag.async = true
        if (id) {
            scriptTag.id = id
        }
        if (crossOrigin) {
            scriptTag.setAttribute('crossOrigin', crossOrigin)
        }
        scriptTag.addEventListener('load', () => {
            resolve(void 0)
        })
        scriptTag.addEventListener('error', (e) => {
            reject(e)
        })
        document.body.appendChild(scriptTag)
    })
}
