import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Request } from 'express'
import { parse as parseCookie } from 'cookie'
import { isObject } from '@wh-components/core/utilities/system'

const whitelistedCookieKeys = [
    'IADVISITOR',
    'snooze_deprecated_browser_alert',
    'snooze_android_onelink_banner',
    'bbxResultListViewMode',
    'bbxHideJobsStartpageWidget',
    'bbxHideJobsResultListWidget',
    'bbxHideJobsResultListWidget_RE_Car',
    'bbxLastViewed',
    'bbxMortgageCalculator',
    'user-preferences-myads2',
] as const

export type WhitelistedCookieKey = (typeof whitelistedCookieKeys)[number]

export type WhitelistedCookies = { [k in WhitelistedCookieKey]?: string }

const COOKIE_PROVIDER_DEFAULT = {}

export const CookieContext = React.createContext<WhitelistedCookies>(COOKIE_PROVIDER_DEFAULT)

export interface CookieProviderProps extends PropsWithChildren {
    cookies?: WhitelistedCookies
}

export const CookieProvider: FunctionComponent<CookieProviderProps> = (props) => {
    return <CookieContext.Provider value={props.cookies || COOKIE_PROVIDER_DEFAULT}>{props.children}</CookieContext.Provider>
}

// INFO: this function currently would result in different values for json cookies (cookie values that start with `j:`), because our server-side cookie parser decodes those, and client-side we don't - since we don't use any such cookies currently, we don't need this at the moment
export const getWhitelistedCookiesFromRequestOrDocument = (
    req: Request | undefined,
    document: Document | undefined,
): WhitelistedCookies => {
    let unfilteredCookies: { [key in string]?: unknown }
    if (typeof document !== 'undefined') {
        unfilteredCookies = parseCookie(document.cookie)
    } else if (req && isObject(req.cookies)) {
        unfilteredCookies = req.cookies
    } else {
        return {}
    }

    const filteredCookies = whitelistedCookieKeys.reduce((cookies, whitelistedCookieKey) => {
        const c = unfilteredCookies[whitelistedCookieKey]
        if (typeof c === 'string') {
            cookies[whitelistedCookieKey] = c
        }
        return cookies
    }, {} as WhitelistedCookies)
    return filteredCookies
}
