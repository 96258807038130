import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { VerticalId } from '@wh/common/chapter/types/verticals'
import { findSearchConfigForSearchId, SearchConfigs } from '../SearchConfigs'
import { SearchId } from '@bbx/common/api/dtos/search-id'
import { UnPromisify } from '@bbx/common/types/unpromisify'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { concatPathWithQueryParams, encodeURIifNecessary } from '@wh/common/chapter/lib/urlHelpers'
import { ParsedUrlQuery } from 'querystring'
import { fetcher } from '@wh/common/chapter/api/fetcher'
import { urlSearchParamsFromObject } from '@wh/common/chapter/api/urlSearchParams'
import { NextRequest } from '@wh/common/chapter/types/nextJS'

export const getSearchResult = (
    verticalId: number,
    searchId: number,
    additionalParams?: Record<string, string | string[]>,
    request?: NextRequest,
): Promise<SearchResult> => {
    return fetcher<SearchResult>(
        `/iad/search/atz/${verticalId}/${searchId}?${additionalParams ? urlSearchParamsFromObject(additionalParams) : ''}`,
        {
            credentials: 'include',
            cookies: getBbxCookiesFromRequest(request),
        },
    )
}

export const getSeoSearchResult = (path: string, request?: NextRequest): Promise<SearchResult> => {
    return fetcher<SearchResult>(`/iad/search/atz/seo${path}`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getAlertSearchResult = (query: ParsedUrlQuery, request?: NextRequest): Promise<SearchResult> => {
    return fetcher<SearchResult>(`/iad/search/atz/alert?${urlSearchParamsFromObject(query)}`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const fetchFromContextLink = <T>(
    contextLink: { serviceName: string; relativePath: string | null },
    additionalParams?: Record<string, string | string[]>,
    abortSignal?: AbortSignal,
    headers?: Record<string, string>,
): Promise<T> => {
    return fetcher<T>(
        concatPathWithQueryParams(`/${contextLink.serviceName}${encodeURIifNecessary(contextLink.relativePath!)}`, additionalParams ?? {}),
        {
            credentials: 'include',
            signal: abortSignal,
            headers: headers,
        },
    )
}

export type AutoMotorAdTypeCounters = UnPromisify<ReturnType<typeof getAutoMotorAdTypeCounters>>

export const getAutoMotorAdTypeCounters = () => {
    return fetcher<SearchConfigs>('/iad/searchconfig/3', {
        credentials: 'include',
    }).then((searchConfigs) => ({
        cars: findSearchConfigForSearchId(searchConfigs, SearchId.Cars)?.nrOfAdverts ?? 0,
        motorbikes: findSearchConfigForSearchId(searchConfigs, SearchId.Motorcycles)?.nrOfAdverts ?? 0,
        trucks: findSearchConfigForSearchId(searchConfigs, SearchId.Trucks)?.nrOfAdverts ?? 0,
        caravans: findSearchConfigForSearchId(searchConfigs, SearchId.Caravans)?.nrOfAdverts ?? 0,
    }))
}

export const getSeoSearchTerms = (verticalId: VerticalId, limit: number = 16): Promise<SeoSearchTerms> => {
    return fetcher(`/iad/seoSearchTerms/${verticalId}/${limit}`, {
        credentials: 'include',
    })
}
