import { TaggingPage } from './taggingTypes'

export const preprocessPageViewSCVs = (
    eventName: TaggingPage,
    params: Readonly<Record<string, string | undefined>>,
): Record<string, string | undefined> => {
    const modifiedParams = { ...params }
    if (typeof params.is_private !== 'undefined') {
        modifiedParams.xiti_x1 = params.is_private === 'true' ? '1' : '2'
    }
    if (eventName === 'adview_nc') {
        modifiedParams.xiti_x4 = '11'
    }
    modifiedParams.xiti_x5 = params.region_level_id_2
    modifiedParams.xiti_x6 = params.ad_id
    modifiedParams.xiti_x7 = 'willhaben'
    modifiedParams.xiti_x8 = {
        '1': 'Jobs',
        '2': 'Immobilien',
        '3': 'AutoMotor',
        '5': 'Marktplatz',
    }[params.vertical_id as '1' | '2' | '3' | '5']
    modifiedParams.xiti_x13 = '1'
    if (params.vertical_id === '5') {
        modifiedParams.xiti_x9 = params.category_level_id_1
        modifiedParams.xiti_x10 = params.category_level_id_2
        modifiedParams.xiti_x11 = params.category_level_id_3
        if (params.category_level_max) {
            const category_level_max = `category_level_id_${params.category_level_max}`
            modifiedParams.xiti_x12 = params[category_level_max]
        }
    } else if (params.vertical_id === '3') {
        const adTypeId = params.ad_type_id
        if (adTypeId) {
            modifiedParams.xiti_x2 = `100${adTypeId}`
        }
        const multipleMakes = !isNumberString(params.make_id)
        if (multipleMakes) {
            modifiedParams.xiti_x3 = '1'
        } else {
            if (params.make_id && params.category_level_id_1 === '52') {
                modifiedParams.xiti_x3 = `333${params.make_id}`
            } else if (params.make_id && params.category_level_id_1 === '50') {
                modifiedParams.xiti_x3 = `222${params.make_id}`
            } else {
                modifiedParams.xiti_x3 = params.make_id
            }
        }
    } else if (params.vertical_id === '2') {
        if (params.category_level_id_1 === '90') {
            modifiedParams.xiti_x2 = '1'
        } else if (params.category_level_id_1 === '101') {
            modifiedParams.xiti_x2 = '1'
        } else if (params.category_level_id_1 === '102') {
            modifiedParams.xiti_x2 = '1'
        } else if (params.category_level_id_1 === '131') {
            modifiedParams.xiti_x2 = '2'
        } else if (params.category_level_id_1 === '132') {
            modifiedParams.xiti_x2 = '2'
        } else if (params.category_level_id_1 === '15') {
            modifiedParams.xiti_x2 = '3'
        } else if (params.category_level_id_1 === '16') {
            modifiedParams.xiti_x2 = '4'
        } else if (params.category_level_id_1 === '12') {
            modifiedParams.xiti_x2 = '5'
        } else if (params.category_level_id_1 === '32') {
            modifiedParams.xiti_x2 = '6'
        } else if (params.category_level_id_1 === '14') {
            modifiedParams.xiti_x2 = '8'
        } else if (params.category_level_id_1 === '35') {
            modifiedParams.xiti_x2 = '15'
        } else if (params.category_level_id_1 === '42') {
            modifiedParams.xiti_x2 = '16'

            if (window.location.search.indexOf('PROJECT/TYPE=2146911818') > -1) {
                modifiedParams.xiti_x3 = '99928'
            }

            if (window.location.search.indexOf('PROJECT/TYPE=2146911824') > -1) {
                modifiedParams.xiti_x3 = '99929'
            }

            if (window.location.search.indexOf('PROJECT/TYPE=2146911825') > -1) {
                modifiedParams.xiti_x3 = '99930'
            }
        }
        if (params.property_type && params.property_type.indexOf(';') === -1) {
            modifiedParams.xiti_x3 = `999${params.property_type}`
        } else if (params.property_type_val && !isNaN(+params.property_type_val)) {
            modifiedParams.xiti_x3 = `999${params.property_type_val}`
        }
    }

    return modifiedParams
}

const isNumberString = (numberString: string | undefined) => {
    if (!numberString) {
        return false
    }
    const numberRegex = /^[0-9]+$/i
    return numberRegex.test(numberString.trim())
}
