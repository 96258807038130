import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'

export interface StoryblokCarAuctionsBenefitsItemBlok extends StoryblokComponentType<string> {
    icon?: StoryblokAsset
    title: string
    description: string
}

interface Props extends StoryblokDefaultProps<StoryblokCarAuctionsBenefitsItemBlok> {}

export const StoryblokCarAuctionsBenefitsItem: FunctionComponent<Props> = ({ blok }) => {
    const theme = useTheme()

    return (
        <Item {...storyblokEditable(blok as unknown as SbBlokData)}>
            {blok.icon && (
                <Box key="icon" textAlign="center" margin={theme.space.m}>
                    <Icon src={blok.icon.filename} alt={blok.icon.alt} />
                </Box>
            )}
            <Text key="title" as="p" color="palette.white" fontWeight="bold">
                {blok.title}
            </Text>
            <Text key="description" as="p" color="palette.white">
                {blok.description}
            </Text>
        </Item>
    )
}

const Item = styled.div`
    min-height: calc(200px + 9em);
`

const Icon = styled.img`
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}px) {
        max-width: 160px;
    }
`
