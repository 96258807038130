/* eslint-disable no-case-declarations */
import { StoryblokSearchLink } from '../types/StoryblokSearchLink'

type FallbackSearchLink = {
    searchWebLink: unknown
}

export const storyblokResolveSearchLink = (link?: StoryblokSearchLink | null) => {
    if (!link) {
        return ''
    }

    switch (link.type) {
        case 'extern':
            return link.fullLink
        case 'searchLink':
        case 'deeplink':
            return link.webLink
        default:
            // Older version of the blok schema don't have a type set
            // In that case we just checked if there is a string set for searchWebLink
            const fallback = link as FallbackSearchLink
            if (typeof fallback.searchWebLink === 'string') {
                return fallback.searchWebLink
            }
            return ''
    }
}
