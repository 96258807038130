import React, { FunctionComponent } from 'react'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { StoryblokComponentType } from '@storyblok/js/dist/types/types'

export interface StoryblokOpenDidomiLinkBlok extends StoryblokComponentType<string> {
    label: string
}

export const StoryblokOpenDidomiLink: FunctionComponent<StoryblokDefaultProps<StoryblokOpenDidomiLinkBlok>> = ({ blok }) => {
    return <a href="javascript:Didomi.preferences.show()">{blok.label}</a>
}
