import React, { DetailedHTMLProps, AnchorHTMLAttributes, forwardRef } from 'react'
import styled, { DataAttributes } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { compose, ResponsiveValue } from '@wh-components/system'
import { ButtonProps, ButtonColorType } from '@wh-components/core/Button/Button'
import { TestProps, testIdAttribute, IdProps } from '@wh-components/core/common'
import { SpaceProps, space, spacePropKeys } from '@wh-components/system/space'
import { LayoutProps, layout, WidthProps, layoutPropKeys } from '@wh-components/system/layout'
import { PositionProps, position, positionPropKeys } from '@wh-components/system/position'
import { FlexboxProps, flexbox, flexboxPropKeys } from '@wh-components/system/flexbox'
import { color, colorPropKeys } from '@wh-components/system/color'
import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'

type ReactAnchorProps = Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'ref' | 'type'>
type AnchorType = 'anchor' | 'button'
type UnderlineType = 'always' | 'hover' | 'none'

interface AnchorLinkProps extends ReactAnchorProps, TestProps, IdProps, SpaceProps, LayoutProps, PositionProps, FlexboxProps {
    clientSideRouting: boolean
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void | Promise<void>
    scrollToTop?: boolean
    underline?: UnderlineType
    disabled?: boolean
}

type AnchorSpecificProps = { color?: ResponsiveValue<string>; type: 'anchor' }
type ButtonSpecificProps = ButtonProps & WidthProps & { type: 'button' }
type FullAnchorLinkProps = AnchorLinkProps & (AnchorSpecificProps | ButtonSpecificProps)

const isButton = (type: AnchorType, _color: unknown): _color is ButtonColorType => type === 'button'

export const AnchorLink = forwardRef<HTMLAnchorElement, FullAnchorLinkProps>(
    (
        {
            type,
            clientSideRouting,
            href,
            target,
            rel,
            color: colorProp,
            scrollToTop = true,
            underline = 'hover',
            onClick,
            children,
            ...props
        },
        ref,
    ) =>
        isButton(type, colorProp) ? (
            <LinkWithButtonStyle
                href={validateUrl(href)}
                target={target}
                rel={getRel(rel, target)}
                color={colorProp}
                onClick={(event) => navigateTo(event, validateUrl(href), target, onClick, clientSideRouting, scrollToTop)}
                ref={ref}
                {...props}
            >
                {children}
            </LinkWithButtonStyle>
        ) : (
            <StyledAnchor
                href={validateUrl(href)}
                target={target}
                rel={getRel(rel, target)}
                color={colorProp || 'palette.primary.main'}
                underline={underline}
                onClick={(event) => navigateTo(event, validateUrl(href), target, onClick, clientSideRouting, scrollToTop)}
                ref={ref}
                {...props}
            >
                {children}
            </StyledAnchor>
        ),
)

const cssProps = new Set([...spacePropKeys, ...layoutPropKeys, ...positionPropKeys, ...flexboxPropKeys, ...colorPropKeys])

const StyledAnchor = styled.a
    .withConfig({
        shouldForwardProp: (prop) => !(cssProps as Set<string>).has(prop) && isPropValid(prop),
    })
    .attrs<TestProps & DataAttributes>(testIdAttribute)<{ underline: UnderlineType }>`
    text-decoration: ${(p) => (p.underline !== 'always' ? 'none' : 'underline')};

    /* Visible in Windows high-contrast themes */
    outline-color: transparent;
    outline-width: 3px;
    outline-style: solid;

    &:hover {
        text-decoration: ${(p) => p.underline === 'hover' && 'underline'};
    }
    &:focus {
        box-shadow: rgb(152, 226, 255) 0 0 0 3px;
        border-radius: 4px;
    }

    &:focus:not(:focus-visible) {
        box-shadow: none;
    }

    ${compose(space, layout, position, flexbox, color)}
`

type RoutingAnchorLinkProps = DistributiveOmit<FullAnchorLinkProps, 'clientSideRouting'>

export const ClientRoutingAnchorLink = forwardRef<HTMLAnchorElement, RoutingAnchorLinkProps>(({ children, ...props }, ref) => (
    <AnchorLink {...props} clientSideRouting={true} ref={ref}>
        {children}
    </AnchorLink>
))

export const ServerRoutingAnchorLink = forwardRef<HTMLAnchorElement, RoutingAnchorLinkProps>(({ children, ...props }, ref) => (
    <AnchorLink {...props} clientSideRouting={false} ref={ref}>
        {children}
    </AnchorLink>
))

const navigateTo = (
    event: React.MouseEvent<HTMLAnchorElement>,
    href: string | undefined,
    target: string | undefined,
    onClick: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void | Promise<void>) | undefined,
    clientSideRouting: boolean,
    scrollToTop: boolean,
) => {
    if (href && target !== '_blank' && !event.ctrlKey && !event.metaKey) {
        const onClickPromiseAction = () => {
            const result = onClick?.(event)
            return typeof result === 'undefined' ? Promise.resolve() : result
        }

        event.preventDefault()
        BbxRouter.push({
            href,
            clientSideRouting,
            beforeRouting: onClickPromiseAction,
            options: { scroll: scrollToTop },
        })
    } else {
        onClick?.(event)
    }
}

const validateUrl = (url: string | undefined) => (!url?.startsWith('javascript:') ? url : undefined)

const getRel = (rel: string | undefined, target: string | undefined) => {
    if (rel) {
        return target === '_blank' && !rel.includes('noopener') ? `noopener ${rel}` : rel
    } else {
        return target === '_blank' ? 'noopener' : undefined
    }
}

/**
 * The standard Omit utility from typescript doesn't iterate over union types
 * Source: https://davidgomes.com/pick-omit-over-union-types-in-typescript/
 */
type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never
