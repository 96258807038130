import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import React, { Fragment, FunctionComponent } from 'react'
import { InternalRichText } from './InternalRichText'
import { Box } from '@wh-components/core/Box/Box'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { StoryblokTextWithPicture, StoryblokTextWithPictureBlok } from './StoryBlokTextWithPicture'
import { storyblokEditable } from '@storyblok/react'

export interface StoryblokTextWithPictureListBlok extends StoryblokComponentType<string> {
    heading: string
    items: StoryblokTextWithPictureBlok[]
}

interface Props extends StoryblokDefaultProps<StoryblokTextWithPictureListBlok> {
    taggingData?: TaggingData
    taggingPrefix?: string
}

export const StoryblokTextWithPictureList: FunctionComponent<Props> = ({ blok, taggingData, taggingPrefix, story }) => {
    return (
        <Fragment>
            <InternalRichText blok={blok as unknown as SbBlokData} richTextKey="heading" story={story} />
            <Box
                display="flex"
                flexDirection={{ phone: 'column', desktop: 'row' }}
                gap={{ phone: 's', desktop: 'm' }}
                justifyContent={{ desktop: 'space-between' }}
                {...storyblokEditable(blok as unknown as SbBlokData)}
            >
                {blok.items.map((item, index) => (
                    <StoryblokTextWithPicture blok={item} taggingPrefix={taggingPrefix} taggingData={taggingData} key={index} />
                ))}
            </Box>
        </Fragment>
    )
}
