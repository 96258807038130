export const DEV_MODE = process.env.NODE_ENV !== 'production'
export const LOCAL_EXPRESS_PROXY = process.env.LOCAL_EXPRESS_PROXY === 'true'
export const BBX_APPLICATION_SOURCE = 'bbx'
// Max items per page, currently used for myfindings, myadverts and mysearches
export const BBX_PAGE_SIZE = 50
export const HEADER_NAME_GET_INITAL_PROPS_DURATION_MILLISECONDS = 'gip'

// iAd produces 500s when requesting a page bigger than 999
export const BBX_MAX_PAGE_PARAM = 999

// same as 'name' attribute by formik used in all vertical detail message views
const adContactFullNameInputFieldId = 'fromFullName'
const adContactMailTextareaId = 'mailContent'
export const adContactIdSelectors = [adContactMailTextareaId, adContactFullNameInputFieldId]

export const FIXED_HEADER_HEIGHT_PHONE = 50
