import React, { Dispatch, FunctionComponent, PropsWithChildren, SetStateAction, useCallback, useState } from 'react'
import { ConsentID } from '@didomi/react'

export interface DidomiState {
    isConsentGivenForVendor: (vendor: ConsentID) => boolean
    setReady: Dispatch<SetStateAction<{ ready: boolean }>>
    isReady: boolean
}

const defaultState = {
    isConsentGivenForVendor: (_vendor: ConsentID) => false,
    setReady: () => {},
    isReady: false,
}

export const DidomiContext = React.createContext<DidomiState>(defaultState)

export const DidomiProvider: FunctionComponent<PropsWithChildren<{}>> = (props) => {
    const [isReady, setReady] = useState<{ ready: boolean }>({ ready: false })

    const isConsentGivenForVendor = useCallback(
        (vendor: ConsentID) => {
            return isReady.ready && isConsentGiven(vendor)
        },
        [isReady],
    )

    return (
        <DidomiContext.Provider value={{ setReady, isReady: isReady.ready, isConsentGivenForVendor }}>
            {props.children}
        </DidomiContext.Provider>
    )
}

declare global {
    interface Window {
        didomiState?: {
            didomiVendorsConsent?: string
            didomiVendorsConsentDenied?: string
            didomiPurposesConsentDenied?: string
        }
    }
}

const isConsentGiven = (vendor: ConsentID) => {
    const vendorList = window.didomiState?.didomiVendorsConsent

    if (vendorList) {
        return vendorList.split(',').some((validVendor) => validVendor === vendor)
    }

    return false
}
