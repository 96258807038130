import React, { FunctionComponent, PropsWithChildren, useState } from 'react'
import styled from 'styled-components'
import { SpaceProps } from '@wh-components/system/space'
import { LayoutProps } from '@wh-components/system/layout'
import { FlexItemProps } from '@wh-components/system/flexbox'
import { Box } from '@wh-components/core/Box/Box'
import PlusIcon from '@wh-components/icons/Plus'
import MinusIcon from '@wh-components/icons/Minus'

interface CollapseProps extends SpaceProps, LayoutProps, FlexItemProps, PropsWithChildren {
    title: string
    headingFontSizeDesktop?: string
    /**  id must follow the HTML5 standard meaning they cannot contain space */
    id?: string
}

/**
 * Note: the collapsible feature is only available on smaller sizes than tablet.
 */
export const Collapse: FunctionComponent<CollapseProps> = ({ title, headingFontSizeDesktop, id, children, ...props }) => {
    const [open, setOpen] = useState(false)
    const technicalId = `${id ? id : title.replace(/\s/g, '-')}-collapse`
    const handleId = `${technicalId}-handle`
    const panelId = `${technicalId}-panel`

    return (
        <CollapseItem {...(id && { id })} testId={technicalId} open={open} {...props}>
            <Box display={{ phone: 'flex', tablet: 'none' }}>
                <CollapseButtonHeader
                    open={open}
                    role="button"
                    id={handleId}
                    data-testid={handleId}
                    aria-pressed={open}
                    aria-expanded={open}
                    aria-controls={panelId}
                    onClick={() => setOpen(!open)}
                >
                    <Box marginRight="s" aria-hidden="true">
                        {open ? <MinusIcon size="12" /> : <PlusIcon size={12} />}
                    </Box>
                    {title}
                </CollapseButtonHeader>
            </Box>
            <Box
                display={{ phone: 'none', tablet: 'flex' }}
                marginBottom="s"
                padding="0"
                fontSize={headingFontSizeDesktop ? headingFontSizeDesktop : 's'}
                fontWeight="bold"
                height="auto"
            >
                {title}
            </Box>
            <Box
                id={panelId}
                testId={panelId}
                aria-labelledby={handleId}
                display={{ tablet: 'flex', phone: open ? 'flex' : 'none' }}
                paddingHorizontal={{ phone: 'm', tablet: 0 }}
                fontSize="s"
            >
                {children}
            </Box>
        </CollapseItem>
    )
}

const CollapseItem = styled(Box)<{ open: boolean }>`
    background-color: ${(p) => (p.open ? p.theme.colors.palette.polarbear : 'transparent')};
    border-bottom: 1px solid ${(p) => p.theme.colors.palette.owl};

    ${(p) => p.theme.media.tablet} {
        background-color: transparent;
        border-bottom: none;
    }
`

const CollapseButtonHeader = styled.button<{ open: boolean }>`
    height: 40px;
    width: 100%;
    margin: 0;
    padding: 0 ${(p) => p.theme.space.m}px;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: ${(p) => p.theme.colors.palette.verydarkgrey};
    font-size: ${(p) => p.theme.fontSizes.s};
    font-weight: ${(p) => (p.open ? p.theme.fontWeights.bold : p.theme.fontWeights.regular)};
    line-height: ${(p) => p.theme.lineHeights.paragraph};
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    outline: none;

    &:hover {
        font-weight: ${(p) => p.theme.fontWeights.bold};
    }
`
