import React, { Fragment, FunctionComponent, ReactElement, RefObject, useContext, useEffect, useRef, useState } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { css } from 'styled-components'
import { Button, IconButton } from '@wh-components/core/Button/Button'
import { ResponsiveValue } from '@wh-components/system'
import { SkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import { adFocusHoverStyles } from '../adFocusHoverStyles'
import { theme } from '@wh-components/core/theme'
import { hiddenScrollbar } from '../hiddenScrollbar'
import { CloseButton } from '@wh-components/core/CloseButton/CloseButton'
import { increaseClickableArea } from '../increaseClickableArea'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { Text } from '@wh-components/core/Text/Text'
import ArrowRight from '@wh-components/icons/ArrowRight'
import ArrowLeft from '@wh-components/icons/ArrowLeft'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { useAutoScroller } from './AutoScroll'
import { useScreenSize } from '../UserAgentProvider/useUserAgent'

export type SliderAdImageVariant = 'landscape' | 'portrait'

interface HorizontalSliderProps {
    heading?: React.ReactNode
    subHeading?: React.ReactNode
    teaser?: React.ReactNode
    linkText?: string
    buttonText?: string
    href?: string
    onClick?: () => void
    onClickButton?: () => void
    onClose?: () => void
    onArrowBtnClick?: () => void
    children: ReactElement[]
    testIdPrefix?: string
    numberOfAdsLimit?: number
    numberOfAdsToDisplay?: number
    buttonHeight?: ResponsiveValue<number>
    className?: string
    isLoading?: boolean
    variant?: SliderAdImageVariant
    visibilityTrackingRef?: RefObject<HTMLDivElement>
    autoScrollInterval?: number
    forceShowButton?: boolean
}

export const HorizontalSlider: FunctionComponent<HorizontalSliderProps & SpaceProps> = ({
    heading,
    subHeading,
    teaser,
    linkText,
    buttonText,
    href,
    onClick,
    onClickButton = onClick,
    onClose,
    onArrowBtnClick,
    children,
    numberOfAdsLimit = 9,
    numberOfAdsToDisplay = 5,
    testIdPrefix,
    buttonHeight = ITEM_HEIGHT_ESTATE_AUTO,
    className,
    paddingLeft,
    isLoading = false,
    variant = 'landscape',
    visibilityTrackingRef,
    autoScrollInterval,
    forceShowButton = false,
    ...props
}) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const hoverStyle = advertisingState.pageModifications.backgroundColors?.['startpage-ad-hover']
    const feedColor = advertisingState.pageModifications.backgroundColors?.['startpage-feed']
    const accentColor = advertisingState.pageModifications.foregroundColors?.['startpage-accent-color']
    const buttonBackgroundColor = feedColor ?? theme.colors.palette.babyseal
    const buttonHeightResolved = useResponsiveValue(buttonHeight, ITEM_HEIGHT_ESTATE_AUTO)

    /* to have a consistent width when there are less then e.g. 5 children
     *  100% / 5 -> but we need to exclude the margins between the children
     *  calc(100% - margins) / 5 */
    const itemWidthDesktop = `calc((100% - ${theme.space.s * 2 * (numberOfAdsToDisplay - 1)}px) / ${numberOfAdsToDisplay})`
    const itemWidth = { phone: '37.5%', tablet: '26.5%', desktop: itemWidthDesktop }

    const realNumberOfAds = children.length
    const showMore = realNumberOfAds > numberOfAdsToDisplay

    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const scrollPos = useScrollPosition(scrollContainerRef)

    const screen = useScreenSize()
    const isDesktop = screen === 'desktop'
    const interval = isDesktop ? autoScrollInterval : undefined

    useAutoScroller(interval, scrollPos, () => {
        if (scrollPos === 'right') {
            scrollPrevious(scrollContainerRef, onArrowBtnClick)
        } else {
            scrollNext(scrollContainerRef, onArrowBtnClick)
        }
    })

    return (
        <Box
            testId={testIdPrefix ? `${testIdPrefix}-wrapper` : undefined}
            className={className}
            paddingLeft={paddingLeft}
            ref={visibilityTrackingRef}
            {...props}
        >
            {(isLoading || heading || onClick || href) && (
                <Box display="flex" marginBottom={!subHeading ? 's' : undefined} alignItems="flex-end">
                    {isLoading ? (
                        <Fragment>
                            <Box height={20} width={{ phone: '100px', tablet: '150px' }}>
                                <SkeletonLine />
                            </Box>
                            <Box height={20} width="100px" marginLeft="auto" marginRight={{ phone: 'm', tablet: '0' }}>
                                <SkeletonLine />
                            </Box>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {heading && heading}

                            {linkText && (onClick || href) && (
                                <Box marginLeft="auto" paddingLeft="sm" marginRight={{ phone: 'm', desktop: '0' }}>
                                    {href ? (
                                        <ServerRoutingAnchorLink
                                            marginLeft="auto"
                                            type="anchor"
                                            href={href}
                                            testId={testIdPrefix ? `${testIdPrefix}-slider-link` : undefined}
                                            onClick={onClick}
                                            color={accentColor}
                                        >
                                            <Text
                                                fontSize="s"
                                                fontWeight="bold"
                                                css={css`
                                                    white-space: nowrap;
                                                `}
                                            >
                                                {linkText}
                                            </Text>
                                        </ServerRoutingAnchorLink>
                                    ) : (
                                        <ButtonWithLinkStyle
                                            onClick={onClick}
                                            testId={testIdPrefix ? `${testIdPrefix}-slider-link` : undefined}
                                            color={accentColor}
                                            css={css`
                                                font-size: ${(p) => p.theme.fontSizes.s};
                                                font-weight: ${(p) => p.theme.fontWeights.bold};
                                                white-space: nowrap;
                                            `}
                                        >
                                            {linkText}
                                        </ButtonWithLinkStyle>
                                    )}
                                </Box>
                            )}

                            {onClose && (
                                <CloseButton
                                    position="relative"
                                    marginRight={{ phone: 'm', desktop: '0' }}
                                    marginLeft={{ tablet: 'sm' }}
                                    size="xsmall"
                                    aria-label={`${heading} schließen`}
                                    testId={`${heading}-close-button`}
                                    onClick={onClose}
                                    css={css`
                                        color: ${(p) => p.theme.colors.palette.koala};
                                        transform: scale(0.83) translateY(2px);
                                        outline: 1px solid;
                                        ${increaseClickableArea(8)}
                                    `}
                                />
                            )}
                        </Fragment>
                    )}
                </Box>
            )}

            {subHeading && (
                <Box marginBottom="s" marginRight={{ phone: 'm', desktop: '0' }}>
                    {subHeading}
                </Box>
            )}

            <Box position="relative">
                <Box
                    display="flex"
                    overflow="auto"
                    paddingLeft="m"
                    paddingRight={{ phone: 'm', desktop: '0' }}
                    paddingVertical="xxs" // space for outline
                    css={css`
                        /* enable momentum/inertia scrolling on iOS */
                        -webkit-overflow-scrolling: touch;
                        margin-left: -${(p) => p.theme.space.m}px;

                        ${hiddenScrollbar}
                    `}
                    ref={scrollContainerRef}
                >
                    <Fragment>
                        {teaser}
                        {children.slice(0, numberOfAdsLimit).map((child, key) => {
                            return (
                                <Box
                                    key={key}
                                    marginLeft="s"
                                    marginRight="s"
                                    minWidth={itemWidth}
                                    width={itemWidth}
                                    css={css`
                                        &:first-of-type {
                                            margin-left: 0;
                                        }

                                        &:last-of-type {
                                            margin-right: 0;
                                        }

                                        ${adFocusHoverStyles(false, 0, hoverStyle)}
                                    `}
                                >
                                    {child}
                                </Box>
                            )
                        })}
                    </Fragment>

                    {buttonText && (onClickButton || href) && (
                        <Box
                            display={{ phone: 'flex', desktop: showMore || forceShowButton ? 'flex' : 'none' }}
                            justifyContent="center"
                            alignItems="center"
                            paddingHorizontal="m"
                            minWidth={itemWidth}
                            marginLeft="s"
                            aspectRatio={variant === 'portrait' ? '4/5' : undefined}
                            height={variant === 'portrait' ? 'fit-content' : buttonHeight}
                            css={css`
                                background-color: ${buttonBackgroundColor};
                            `}
                        >
                            <Fragment>
                                {href ? (
                                    <ServerRoutingAnchorLink
                                        href={href}
                                        testId={testIdPrefix ? `${testIdPrefix}-slider-button` : undefined}
                                        type="button"
                                        width="100%"
                                        onClick={onClickButton}
                                        css={css`
                                            text-align: center;
                                            font-size: ${(p) => p.theme.fontSizes.s};
                                        `}
                                    >
                                        {buttonText}
                                    </ServerRoutingAnchorLink>
                                ) : (
                                    <Button
                                        onClick={onClickButton}
                                        testId={testIdPrefix ? `${testIdPrefix}-slider-button` : undefined}
                                        width="100%"
                                        css={css`
                                            font-size: ${(p) => p.theme.fontSizes.s};
                                            font-weight: ${(p) => p.theme.fontWeights.bold};
                                        `}
                                    >
                                        {buttonText}
                                    </Button>
                                )}
                            </Fragment>
                        </Box>
                    )}
                    <ArrowIconButton
                        buttonHeightResolved={buttonHeightResolved}
                        direction="left"
                        left={0}
                        display={{ phone: 'none', desktop: scrollPos !== 'left' ? 'flex' : 'none' }}
                        borderRightRadius="m"
                        variant={variant}
                        onClick={() => scrollPrevious(scrollContainerRef, onArrowBtnClick)}
                    />
                    <ArrowIconButton
                        buttonHeightResolved={buttonHeightResolved}
                        direction="right"
                        right={0}
                        display={{
                            phone: 'none',
                            desktop: scrollPos !== 'right' && showMore ? 'flex' : 'none',
                        }}
                        borderLeftRadius="m"
                        variant={variant}
                        onClick={() => scrollNext(scrollContainerRef, onArrowBtnClick)}
                    />
                </Box>
            </Box>
        </Box>
    )
}

type ScrollDirection = 1 | -1

const scroll = (container: React.RefObject<HTMLDivElement>, onClickCallback: (() => void) | undefined, direction: ScrollDirection) => {
    container.current?.scrollBy({
        left: container.current?.scrollWidth * direction,
        top: 0,
        behavior: 'smooth',
    })

    onClickCallback?.()
}

const scrollNext = (container: React.RefObject<HTMLDivElement>, onClickCallback: (() => void) | undefined) => {
    scroll(container, onClickCallback, 1)
}

const scrollPrevious = (container: React.RefObject<HTMLDivElement>, onClickCallback: (() => void) | undefined) => {
    scroll(container, onClickCallback, -1)
}

const ArrowIconButton: FunctionComponent<
    BoxProps & {
        onClick: () => void
        direction: 'left' | 'right'
        buttonHeightResolved: number
        variant: SliderAdImageVariant
    }
> = ({ onClick, direction, buttonHeightResolved, variant, ...props }) => (
    <Box
        justifyContent="center"
        position="absolute"
        backgroundColor="palette.babyseal"
        opacity={0.7}
        top={variant === 'portrait' ? '85px' : `calc(${buttonHeightResolved}px - 50px + 2px)`} // button height = 50px, + 2px random alignment
        as="section" // hackily set this to section instead of div, so that the last-of-type selector in the children above does not match this
        {...props}
    >
        <IconButton
            onClick={onClick}
            Icon={direction === 'left' ? ArrowLeft : ArrowRight}
            variant="transparent"
            width={30}
            height={50}
            css={css`
                color: ${(p) => p.theme.colors.palette.raccoon};
            `}
        />
    </Box>
)

const useScrollPosition = (ref: RefObject<HTMLDivElement>) => {
    const [scrollPos, setScrollPos] = useState<'left' | 'right' | 'between'>('left')

    useEffect(() => {
        if (!ref.current) {
            return
        }

        const container = ref.current

        const handleScroll = () => {
            const maxScrollWidth = container.scrollWidth - container.clientWidth
            if (container.scrollLeft < 50) {
                setScrollPos('left')
            } else if (container.scrollLeft > maxScrollWidth - 50) {
                setScrollPos('right')
            } else {
                setScrollPos('between')
            }
        }

        container.addEventListener('scroll', handleScroll, false)

        return () => {
            container.removeEventListener('scroll', handleScroll, false)
        }
    }, [ref])

    return scrollPos
}

export const ITEM_HEIGHT_BAP_APPROX = 219
export const ITEM_HEIGHT_ESTATE_AUTO = 131
export const ITEM_HEIGHT_JOBS = 80
