import { StoryblokComponentType } from '@storyblok/js/dist/types/types'
import React, { FunctionComponent } from 'react'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { UserZoom } from '@wh/common/chapter/components/UserZoom/UserZoom'

interface StoryblokUserzoomIntegration extends StoryblokComponentType<string> {
    interceptCode: string
}

interface Props extends StoryblokDefaultProps<StoryblokUserzoomIntegration> {}

export const StoryblokUserzoomIntegration: FunctionComponent<Props> = ({ blok }) => {
    return <UserZoom interceptCode={blok.interceptCode} enabled={true} />
}
