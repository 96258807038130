import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { smoothScrollIntoViewWithOffset } from './smooth-scroll/smooth-scroll-with-offset'
import getConfig from 'next/config'
import { parse as parseCookie } from 'cookie'

export const scrollToTop = (behaviour: 'smooth' | 'auto' = 'smooth') => {
    const element = document.getElementById('top-scroll-anchor')
    if (element) {
        scrollIntoView(element, {
            scrollMode: 'if-needed',
            block: 'start',
            behavior: behaviour,
        })
    }
}

export const scrollDistanceByElement = (element: HTMLElement | null | Window, topDistance: number, leftDistance: number) => {
    if (element) {
        const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style

        if (isSmoothScrollSupported) {
            element.scrollBy({ top: topDistance, left: leftDistance, behavior: 'smooth' })
        } else {
            element.scrollBy(leftDistance, topDistance)
        }
    }
}

export const scrollToElement = (
    element: HTMLElement | null,
    block: ScrollLogicalPosition = 'start',
    scrollMode: 'if-needed' | 'always' = 'if-needed',
    topOffset?: number,
    behaviour: 'smooth' | 'auto' = 'smooth',
): Promise<void> => {
    if (element) {
        if (topOffset) {
            return smoothScrollIntoViewWithOffset(element, {
                scrollMode,
                block,
                behavior: 'smooth',
                topOffset,
            }).then(() => undefined)
        } else {
            const result = scrollIntoView(element, {
                scrollMode,
                block,
                behavior: behaviour,
            }) as Promise<void> | void
            if (typeof result === 'object' && 'then' in result && typeof result.then === 'function') {
                return result
            } else {
                return Promise.resolve()
            }
        }
    }

    return Promise.resolve()
}

// INFO: DO NOT USE FOR CONDITIONAL RENDERING - that breaks hydration
export const isServer = () => {
    return typeof window === 'undefined'
}

// INFO: DO NOT USE FOR CONDITIONAL RENDERING - that breaks hydration
export const isClient = () => {
    return !isServer()
}

type Context = 'prod' | 'uat' | 'st' | 'dev' | 'local-docker' | undefined

// structure of relevant available settings in next.config.js
interface NextConfig {
    publicRuntimeConfig: PublicRuntimeConfig
}

// structure of `publicRuntimeConfig` key in next.config.js
interface PublicRuntimeConfig {
    context?: Context
    app: 'adin' | 'jobs' | 'search'
}

const config = getConfig() as NextConfig | undefined
const publicRuntimeConfig = config?.publicRuntimeConfig

const context = publicRuntimeConfig ? publicRuntimeConfig.context : (process.env.CONTEXT as Context)

export const getContext = () => {
    if (isServer()) {
        return context
    } else {
        const cookies = parseCookie(document.cookie)
        return cookies.context
    }
}

export const getApp = () => publicRuntimeConfig?.app

export const getStartAndEndElements = (page: number, pageSize: number) => {
    // minus 1 since we our page 1 is in fact page 0
    const start = (page - 1) * pageSize
    const end = page * pageSize
    return { start, end }
}

export const clamp = (x: number, lower: number, upper: number) => {
    return Math.min(upper, Math.max(lower, x))
}

export const sortAlphabetically = (a?: string | null, b?: string | null) => (a && b ? a.localeCompare(b, 'de', { sensitivity: 'base' }) : 0)

export const isTruncated = (el: HTMLElement | null): boolean => {
    return el instanceof HTMLElement && el.scrollWidth > el.clientWidth
}

export const isEnterKey = (e: React.KeyboardEvent<HTMLElement>): boolean => {
    return e.key === 'Enter' || e.keyCode === 13
}

export const tryFocus = (idSelectors: string[]) => {
    for (const selector of idSelectors) {
        const element = document.getElementById(selector)
        if (element) {
            element.focus()
            break
        }
    }
}

interface AnyObject {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}

export const getFromKeyPath = (obj: AnyObject, keyPath: string): string | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return keyPath.split('.').reduce((acc, key) => {
        if (acc && Object.prototype.hasOwnProperty.call(acc, key)) {
            return acc[key] as string
        } else {
            return undefined
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, obj as any)
}
