import React from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { SkeletonLine } from '../Skeletons/Skeletons'
import { css } from 'styled-components'

export const UserProfileSkeleton: React.FunctionComponent<{}> = () => {
    return (
        <Box display="flex" alignItems="center" marginLeft={{ phone: '10px', tablet: 0 }}>
            <Box width={{ phone: '32px', tablet: '24px' }} height={{ phone: '32px', tablet: '24px' }} marginRight="xs">
                <SkeletonLine
                    css={css`
                        border-radius: 50%;
                    `}
                />
            </Box>
            <Box
                width={{ phone: '10px', tablet: '100px' }}
                height={{ phone: '10px', tablet: '18px' }}
                marginRight={{ phone: 's', tablet: 0 }}
            >
                <SkeletonLine />
            </Box>
        </Box>
    )
}
