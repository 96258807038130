export const extractFileExtensionFromUrl = (url?: string | null) => {
    if (!url) {
        return null
    }
    const indexLastDot = url.lastIndexOf('.')
    let extension = null
    if (indexLastDot > 0) {
        extension = url.substring(indexLastDot + 1).toLowerCase()
    }
    return extension
}
