import React, { FunctionComponent, Fragment } from 'react'
import styled from 'styled-components'
import { RenderSlotId } from '@wh/common/digital-advertising/config'

const RenderSlot = styled.div`
    display: none;
`

interface DataPixelProps {}

export const DatapixelRenderSlots: FunctionComponent<DataPixelProps> = (_props) => {
    return (
        <Fragment>
            <RenderSlot aria-hidden="true" id={RenderSlotId.DATAPIXEL_LARGE} />
            <RenderSlot aria-hidden="true" id={RenderSlotId.DATAPIXEL_MEDIUM} />
            <RenderSlot aria-hidden="true" id={RenderSlotId.DATAPIXEL_SMALL} />
        </Fragment>
    )
}
