import { ISbStoryData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import dayjs from 'dayjs'
import React, { Fragment, FunctionComponent, useContext } from 'react'
import { ClientRoutingAnchorLink } from '../../../chapter/components/AnchorLink/AnchorLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { StoryblokBlogpostBlok } from '../pagetypes/StoryblokBlogpost'
import { StoryblokContext } from '../StoryblokContextProvider'

export interface StoryblokBlogpostAdditionalEntries extends StoryblokComponentType<string> {
    tag: string
    compact: boolean
}

// `first_published_at` was removed from the type with https://github.com/storyblok/storyblok-js-client/commit/ce3cc10918ea66bd1b8e180e0cb63dd99292d23b
// Since this was a refactoring commit I assume it was wrongfully removed, api documentation still mentions it
declare module '@storyblok/js/dist/types/types' {
    export interface ISbStoryData {
        first_published_at?: string
    }
}

interface Props extends StoryblokDefaultProps<StoryblokBlogpostAdditionalEntries> {}

export const StoryblokBlogpostAdditionalEntries: FunctionComponent<Props> = ({ blok }) => {
    const { tag, compact } = blok
    const storyblokContext = useContext(StoryblokContext)
    const foundStory = storyblokContext.links
        .map((story) => story as ISbStoryData<StoryblokBlogpostBlok>)
        .sort((story1, story2) => {
            const homepagePrio1 = story1.content.homepagePrio
            const homepagePrio2 = story2.content.homepagePrio
            if (homepagePrio1 && !homepagePrio2) {
                return -1
            }
            if (homepagePrio2 && !homepagePrio1) {
                return 1
            }
            if (homepagePrio1 && homepagePrio2) {
                return homepagePrio1 - homepagePrio2
            }
            return dayjs(story2.first_published_at ?? 0).diff(story1.first_published_at ?? 0)
        })
        .filter((story) => story.tag_list.includes(tag))

    return (
        <Fragment>
            {foundStory.map((story) => (
                <Box key={story.id} marginTop={compact ? 'xs' : 'm'} {...storyblokEditable(blok as unknown as SbBlokData)} as="article">
                    <Text fontWeight="bold" as="h3" fontSize="m">
                        <ClientRoutingAnchorLink type="anchor" href={`/${story.full_slug}`} color="palette.verydarkgrey">
                            {story.content.seoTitle}
                        </ClientRoutingAnchorLink>
                    </Text>
                    {!compact && (
                        <Fragment>
                            {!story.content.hideDescription && <Text lineClamp={3}>{story.content.seoDescription}</Text>}
                            <ClientRoutingAnchorLink type="anchor" href={`/${story.full_slug}`} aria-hidden="true" tabIndex={-1}>
                                weiterlesen
                            </ClientRoutingAnchorLink>
                        </Fragment>
                    )}
                </Box>
            ))}
        </Fragment>
    )
}
