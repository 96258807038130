import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import React, { FunctionComponent } from 'react'
import { useTheme } from 'styled-components'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { InternalRichText } from './InternalRichText'

interface StoryblokTextWithPictureFullWidth extends StoryblokComponentType<string> {
    heading: string
    image: StoryblokAsset
    imageMobile?: StoryblokAsset
    height: string
    heightMobile: string
    width: string
}

interface Props extends StoryblokDefaultProps<StoryblokTextWithPictureFullWidth> {}

export const StoryblokTextWithPictureFullWidth: FunctionComponent<Props & BoxProps> = ({ blok, story }) => {
    const theme = useTheme()

    const mobileImageAvailable = !!blok.imageMobile?.filename

    const image = (
        <ResponsiveImage
            srcSet={buildImageSourceSet(mobileImageAvailable ? blok.imageMobile?.filename : blok.image.filename, +blok.height)}
            alt={blok.image.alt}
            cssWidth={{ phone: '100%', tablet: `${blok.width}px` }}
            cssHeight={{ phone: !blok.heightMobile ? `${blok.height}px` : `${blok.heightMobile}px`, tablet: `${blok.height}px` }}
            objectFit="cover"
            display="block"
        />
    )

    return (
        <Box display="flex" flexDirection={{ phone: 'column', tablet: 'row' }} {...storyblokEditable(blok as unknown as SbBlokData)}>
            {mobileImageAvailable ? (
                <picture>
                    <source
                        srcSet={buildImageSourceSet(blok.image.filename, +blok.height)}
                        media={`(min-width: ${theme.breakpoints.tablet}px)`}
                    />
                    {image}
                </picture>
            ) : (
                image
            )}
            <Box display="flex" flexDirection="column" marginLeft={{ tablet: 'l' }} marginTop={{ phone: 'm', tablet: 0 }}>
                <Heading text={blok.heading} level={3} color="palette.primary.main" marginBottom="s" />
                <InternalRichText blok={blok as unknown as SbBlokData} richTextKey="text" story={story} />
            </Box>
        </Box>
    )
}

const buildImageSourceSet = (imageUrl: string | undefined, size: number) => {
    if (!imageUrl) {
        console.error('no imageUrl')
        return ''
    }
    // The base image is multiplied by 1.5 since it will always be scaled a little bit, because the element dimensions don't correspond with actual image size
    // The additional image size ensures better image quality and since storyblok offers automatic webp detection, the images won't get too big
    return `${imageUrl}/m/0x${Math.floor(size * 1.5)} 1x, ${imageUrl}/m/0x${size * 3} 2x`
}
