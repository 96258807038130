import { useContext } from 'react'
import { DidomiContext } from './DidomiContext'
import { ConsentID } from '@didomi/react'

export type DidomiVendor = 'FacebookTrackingPixel' | 'GoogleTrackingPixel' | 'UserZoom' | 'Hotjar'

const vendorMap: Record<DidomiVendor, ConsentID> = {
    FacebookTrackingPixel: 'didomi:facebook',
    GoogleTrackingPixel: 'didomi:google',
    UserZoom: 'c:userzoom',
    Hotjar: 'c:hotjar',
}

export const useDidomi = (vendor: DidomiVendor) => {
    const { isConsentGivenForVendor, isReady } = useContext(DidomiContext)

    return {
        isReady: isReady,
        isConsentGiven: isConsentGivenForVendor(vendorMap[vendor]),
    }
}
