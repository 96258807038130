export enum RenderSlotId {
    DATAPIXEL_LARGE = 'apn-large-datapixel',
    DATAPIXEL_MEDIUM = 'apn-medium-datapixel',
    DATAPIXEL_SMALL = 'apn-small-datapixel',
    SKYSCRAPER_LARGE = 'apn-large-skyscraper',
    LEADERBOARD_LARGE = 'apn-large-leaderboard',
    LEADERBOARD_MEDIUM = 'apn-medium-leaderboard',
    CONTENT_AD_LARGE = 'apn-large-content-ad',
    CONTENT_AD_MEDIUM = 'apn-medium-content-ad',
    CONTENT_AD_SMALL = 'apn-small-content-ad',
    AD_DETAIL_LARGE = 'apn-large-ad-detail',
    AD_DETAIL_MEDIUM = 'apn-medium-ad-detail',
    AD_DETAIL_SMALL = 'apn-small-ad-detail',
    AZA_1_LARGE = 'apn-large-aza-1',
    AZA_1_MEDIUM = 'apn-medium-aza-1',

    RESULTLIST_CHANNEL_BRANDING_LARGE = 'apn-large-channel-branding',
    RESULTLIST_RESULT_LIST_1_LARGE = 'apn-large-result-list-1',
    RESULTLIST_RESULT_LIST_2_LARGE = 'apn-large-result-list-2',
    RESULTLIST_RESULT_LIST_3_LARGE = 'apn-large-result-list-3',
    RESULTLIST_BOTTOM_AD_LARGE = 'apn-large-bottom-ad',
    RESULTLIST_BOTTOM_AD_LARGE_EXPERIMENT = 'apn-large-bottom-ad-experiment',
    RESULTLIST_FILTER_AD_LARGE = 'apn-large-filter-ad',
    RESULTLIST_RESULT_LIST_1_MEDIUM = 'apn-medium-result-list-1',
    RESULTLIST_RESULT_LIST_2_MEDIUM = 'apn-medium-result-list-2',
    RESULTLIST_RESULT_LIST_3_MEDIUM = 'apn-medium-result-list-3',
    RESULTLIST_RESULT_LIST_1_SMALL = 'apn-small-result-list-1',
    RESULTLIST_RESULT_LIST_2_SMALL = 'apn-small-result-list-2',
    RESULTLIST_TOPIC_WORLDS_SMALL = 'apn-small-topic-worlds',
    RESULTLIST_RESULT_LIST_3_SMALL = 'apn-small-result-list-3',
    RESULTLIST_RESULT_LIST_SCROLL_TOP_SMALL = 'apn-small-result-list-scrolling-1',
    RESULTLIST_RESULT_LIST_SCROLL_BOTTOM_SMALL = 'apn-small-result-list-scrolling-2',
}

export type LayoutSize = 'PHONE' | 'TABLET' | 'DESKTOP_WITHOUT_SKYSCRAPER' | 'DESKTOP_WITH_SKYSCRAPER'

export type RenderSlotIdConfig = { [K in LayoutSize]: RenderSlotId[] }

export const DatapixelRenderSlotIds: { [K in LayoutSize]: RenderSlotId } = {
    ['PHONE']: RenderSlotId.DATAPIXEL_SMALL,
    ['TABLET']: RenderSlotId.DATAPIXEL_MEDIUM,
    ['DESKTOP_WITHOUT_SKYSCRAPER']: RenderSlotId.DATAPIXEL_LARGE,
    ['DESKTOP_WITH_SKYSCRAPER']: RenderSlotId.DATAPIXEL_LARGE,
}

export const renderSlotLayoutSizeMapping: Record<RenderSlotId, 'phone' | 'tablet' | 'desktop'> = {
    [RenderSlotId.DATAPIXEL_LARGE]: 'desktop',
    [RenderSlotId.DATAPIXEL_MEDIUM]: 'tablet',
    [RenderSlotId.DATAPIXEL_SMALL]: 'phone',
    [RenderSlotId.SKYSCRAPER_LARGE]: 'desktop',
    [RenderSlotId.LEADERBOARD_LARGE]: 'desktop',
    [RenderSlotId.LEADERBOARD_MEDIUM]: 'tablet',
    [RenderSlotId.CONTENT_AD_LARGE]: 'desktop',
    [RenderSlotId.CONTENT_AD_MEDIUM]: 'tablet',
    [RenderSlotId.CONTENT_AD_SMALL]: 'phone',
    [RenderSlotId.AD_DETAIL_LARGE]: 'desktop',
    [RenderSlotId.AD_DETAIL_MEDIUM]: 'tablet',
    [RenderSlotId.AD_DETAIL_SMALL]: 'phone',
    [RenderSlotId.AZA_1_LARGE]: 'desktop',
    [RenderSlotId.AZA_1_MEDIUM]: 'tablet',
    [RenderSlotId.RESULTLIST_CHANNEL_BRANDING_LARGE]: 'desktop',
    [RenderSlotId.RESULTLIST_RESULT_LIST_1_LARGE]: 'desktop',
    [RenderSlotId.RESULTLIST_RESULT_LIST_2_LARGE]: 'desktop',
    [RenderSlotId.RESULTLIST_RESULT_LIST_3_LARGE]: 'desktop',
    [RenderSlotId.RESULTLIST_BOTTOM_AD_LARGE]: 'desktop',
    [RenderSlotId.RESULTLIST_BOTTOM_AD_LARGE_EXPERIMENT]: 'desktop',
    [RenderSlotId.RESULTLIST_FILTER_AD_LARGE]: 'desktop',
    [RenderSlotId.RESULTLIST_RESULT_LIST_1_MEDIUM]: 'tablet',
    [RenderSlotId.RESULTLIST_RESULT_LIST_2_MEDIUM]: 'tablet',
    [RenderSlotId.RESULTLIST_RESULT_LIST_3_MEDIUM]: 'tablet',
    [RenderSlotId.RESULTLIST_RESULT_LIST_1_SMALL]: 'phone',
    [RenderSlotId.RESULTLIST_RESULT_LIST_2_SMALL]: 'phone',
    [RenderSlotId.RESULTLIST_TOPIC_WORLDS_SMALL]: 'phone',
    [RenderSlotId.RESULTLIST_RESULT_LIST_3_SMALL]: 'phone',
    [RenderSlotId.RESULTLIST_RESULT_LIST_SCROLL_TOP_SMALL]: 'phone',
    [RenderSlotId.RESULTLIST_RESULT_LIST_SCROLL_BOTTOM_SMALL]: 'phone',
}

export const renderSlotShowAdvertisingStringMapping: Record<RenderSlotId, boolean> = {
    [RenderSlotId.DATAPIXEL_LARGE]: false,
    [RenderSlotId.DATAPIXEL_MEDIUM]: false,
    [RenderSlotId.DATAPIXEL_SMALL]: false,
    [RenderSlotId.SKYSCRAPER_LARGE]: false,
    [RenderSlotId.LEADERBOARD_LARGE]: false,
    [RenderSlotId.LEADERBOARD_MEDIUM]: false,
    [RenderSlotId.CONTENT_AD_LARGE]: false,
    [RenderSlotId.CONTENT_AD_MEDIUM]: false,
    [RenderSlotId.CONTENT_AD_SMALL]: false,
    [RenderSlotId.AD_DETAIL_LARGE]: false,
    [RenderSlotId.AD_DETAIL_MEDIUM]: false,
    [RenderSlotId.AD_DETAIL_SMALL]: false,
    [RenderSlotId.AZA_1_LARGE]: true,
    [RenderSlotId.AZA_1_MEDIUM]: true,
    [RenderSlotId.RESULTLIST_CHANNEL_BRANDING_LARGE]: true,
    [RenderSlotId.RESULTLIST_RESULT_LIST_1_LARGE]: true,
    [RenderSlotId.RESULTLIST_RESULT_LIST_2_LARGE]: true,
    [RenderSlotId.RESULTLIST_RESULT_LIST_3_LARGE]: true,
    [RenderSlotId.RESULTLIST_BOTTOM_AD_LARGE]: true,
    [RenderSlotId.RESULTLIST_BOTTOM_AD_LARGE_EXPERIMENT]: true,
    [RenderSlotId.RESULTLIST_FILTER_AD_LARGE]: false,
    [RenderSlotId.RESULTLIST_RESULT_LIST_1_MEDIUM]: true,
    [RenderSlotId.RESULTLIST_RESULT_LIST_2_MEDIUM]: true,
    [RenderSlotId.RESULTLIST_RESULT_LIST_3_MEDIUM]: true,
    [RenderSlotId.RESULTLIST_RESULT_LIST_1_SMALL]: true,
    [RenderSlotId.RESULTLIST_RESULT_LIST_2_SMALL]: true,
    [RenderSlotId.RESULTLIST_TOPIC_WORLDS_SMALL]: false,
    [RenderSlotId.RESULTLIST_RESULT_LIST_3_SMALL]: true,
    [RenderSlotId.RESULTLIST_RESULT_LIST_SCROLL_TOP_SMALL]: true,
    [RenderSlotId.RESULTLIST_RESULT_LIST_SCROLL_BOTTOM_SMALL]: true,
}

export const minSearchResultsOnPageForDesktopResultListFilterAd = 3

export type ResultListRenderSlotConfig = {
    renderSlotId: RenderSlotId
    showBeforeAdIndex: number
    showBeforeAdIndexDesktop?: number
    showBeforeAdIndexGridView: number
    showBeforeAdIndexGridViewDesktop?: number
    showStartingFromPage: number
    showUntilPage?: number
    minSearchResultsOnPage: number | undefined
    loadingHeight?: number
}[]

const resultListRenderSlotConfig: ResultListRenderSlotConfig = [
    {
        renderSlotId: RenderSlotId.RESULTLIST_CHANNEL_BRANDING_LARGE,
        showBeforeAdIndex: 0,
        showBeforeAdIndexDesktop: 26,
        showBeforeAdIndexGridView: 0,
        showBeforeAdIndexGridViewDesktop: 24,
        showStartingFromPage: 1,
        minSearchResultsOnPage: 1,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_1_LARGE,
        showBeforeAdIndex: 2,
        showBeforeAdIndexGridView: 6,
        showStartingFromPage: 1,
        minSearchResultsOnPage: 3,
        loadingHeight: 250,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_2_LARGE,
        showBeforeAdIndex: 12,
        showBeforeAdIndexGridView: 12,
        showStartingFromPage: 1,
        minSearchResultsOnPage: 13,
        loadingHeight: 250,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_3_LARGE,
        showBeforeAdIndex: 23,
        showBeforeAdIndexDesktop: 22,
        showBeforeAdIndexGridView: 18,
        showStartingFromPage: 1,
        minSearchResultsOnPage: 24,
        loadingHeight: 250,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_BOTTOM_AD_LARGE,
        showBeforeAdIndex: Number.MAX_SAFE_INTEGER,
        showBeforeAdIndexGridView: Number.MAX_SAFE_INTEGER,
        showStartingFromPage: 1,
        minSearchResultsOnPage: 1,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_1_MEDIUM,
        showBeforeAdIndex: 2,
        showBeforeAdIndexGridView: 6,
        showStartingFromPage: 1,
        minSearchResultsOnPage: 3,
        loadingHeight: 90,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_2_MEDIUM,
        showBeforeAdIndex: 12,
        showBeforeAdIndexGridView: 12,
        showStartingFromPage: 1,
        minSearchResultsOnPage: 13,
        loadingHeight: 90,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_3_MEDIUM,
        showBeforeAdIndex: 23,
        showBeforeAdIndexGridView: 18,
        showStartingFromPage: 1,
        minSearchResultsOnPage: 24,
        loadingHeight: 90,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_1_SMALL,
        showBeforeAdIndex: 2,
        showBeforeAdIndexGridView: 4,
        showStartingFromPage: 1,
        showUntilPage: 1,
        minSearchResultsOnPage: 3,
        loadingHeight: 250,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_2_SMALL,
        showBeforeAdIndex: 12,
        showBeforeAdIndexGridView: 12,
        showStartingFromPage: 1,
        showUntilPage: 1,
        minSearchResultsOnPage: 13,
        loadingHeight: 250,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_SCROLL_TOP_SMALL,
        showBeforeAdIndex: 2,
        showBeforeAdIndexGridView: 4,
        showStartingFromPage: 2,
        minSearchResultsOnPage: 3,
        loadingHeight: 250,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_TOPIC_WORLDS_SMALL,
        showBeforeAdIndex: 16,
        showBeforeAdIndexGridView: 16,
        showStartingFromPage: 1,
        minSearchResultsOnPage: 17,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_3_SMALL,
        showBeforeAdIndex: 22,
        showBeforeAdIndexGridView: 20,
        showStartingFromPage: 1,
        showUntilPage: 1,
        minSearchResultsOnPage: 23,
        loadingHeight: 250,
    },
    {
        renderSlotId: RenderSlotId.RESULTLIST_RESULT_LIST_SCROLL_BOTTOM_SMALL,
        showBeforeAdIndex: 22,
        showBeforeAdIndexGridView: 20,
        showStartingFromPage: 2,
        minSearchResultsOnPage: 23,
        loadingHeight: 250,
    },
]

export const resultListRenderSlotConfigForGivenSearchResult = (page: number, searchResultsOnCurrentPage: number) =>
    resultListRenderSlotConfig.filter(
        (config) =>
            config.showStartingFromPage <= page &&
            (typeof config.showUntilPage === 'undefined' || config.showUntilPage >= page) &&
            (typeof config.minSearchResultsOnPage === 'undefined' || config.minSearchResultsOnPage <= searchResultsOnCurrentPage),
    )

const baseRenderSlotIdConfig: RenderSlotIdConfig = {
    PHONE: [],
    TABLET: [RenderSlotId.LEADERBOARD_MEDIUM],
    DESKTOP_WITHOUT_SKYSCRAPER: [RenderSlotId.LEADERBOARD_LARGE],
    DESKTOP_WITH_SKYSCRAPER: [RenderSlotId.LEADERBOARD_LARGE, RenderSlotId.SKYSCRAPER_LARGE],
}

export const calculateResultListAdverstisingSlotIdConfigForSearchResult = (
    page: number,
    searchResultsOnCurrentPage: number,
): RenderSlotIdConfig => {
    const phoneResultListRenderSlots = calculateResultListAdvertisingSlots('phone', page, searchResultsOnCurrentPage)
    const desktopExtraRenderSlots =
        searchResultsOnCurrentPage >= minSearchResultsOnPageForDesktopResultListFilterAd ? [RenderSlotId.RESULTLIST_FILTER_AD_LARGE] : []
    const tabletResultListRenderSlots = calculateResultListAdvertisingSlots('tablet', page, searchResultsOnCurrentPage)
    const desktopResultListRenderSlots = calculateResultListAdvertisingSlots('desktop', page, searchResultsOnCurrentPage)
    return {
        PHONE: [...baseRenderSlotIdConfig.PHONE, ...phoneResultListRenderSlots],
        TABLET: [...baseRenderSlotIdConfig.TABLET, ...tabletResultListRenderSlots],
        DESKTOP_WITHOUT_SKYSCRAPER: [
            ...baseRenderSlotIdConfig.DESKTOP_WITHOUT_SKYSCRAPER,
            ...desktopResultListRenderSlots,
            ...desktopExtraRenderSlots,
        ],
        DESKTOP_WITH_SKYSCRAPER: [
            ...baseRenderSlotIdConfig.DESKTOP_WITH_SKYSCRAPER,
            ...desktopResultListRenderSlots,
            ...desktopExtraRenderSlots,
        ],
    }
}

const calculateResultListAdvertisingSlots = (
    layoutSize: 'phone' | 'tablet' | 'desktop',
    page: number,
    searchResultsOnCurrentPage: number,
): RenderSlotId[] =>
    resultListRenderSlotConfigForGivenSearchResult(page, searchResultsOnCurrentPage)
        .filter((c) => renderSlotLayoutSizeMapping[c.renderSlotId] === layoutSize)
        .map((c) => c.renderSlotId)
