import React, { Fragment, FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import classNames from 'classnames'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '../../lib/tagging/tagging'
import { TaggingActionEvent } from '../../lib/tagging/taggingTypes'
import { useRouter } from 'next/router'

interface MyAdsLinksProps {
    isHighlighted?: boolean
}

export const MyAdsLinks: FunctionComponent<MyAdsLinksProps> = ({ isHighlighted }) => {
    const router = useRouter()
    return (
        <Fragment>
            <MyAdsLink
                testId="verticalMenuSubitem-myadverts-bbx"
                title="Meine Anzeigen"
                href="/iad/myprofile/myadverts"
                active={isHighlighted && !router.pathname.includes('meinjob')}
                taggingEventName="header_usermenu_click_myAdverts"
            />
            <MyAdsLink
                testId="verticalMenuSubitem-myadverts-jobs"
                title="Meine Job Anzeigen"
                href="/jobs/arbeitgeber/meinjob#!/anzeigen"
                active={isHighlighted && router.pathname.includes('meinjob')}
                taggingEventName="header_usermenu_click_myJobAdverts"
            />
        </Fragment>
    )
}

interface MyAdsLinkProps {
    title: string
    href: string
    testId: string
    active?: boolean
    taggingEventName: TaggingActionEvent
}

const MyAdsLink: FunctionComponent<MyAdsLinkProps> = ({ title, href, active = false, testId, taggingEventName }) => (
    <ClientRoutingAnchorLink
        type="anchor"
        className={classNames({
            'wh-vertical-menu--subitem': true,
            'wh-vertical-menu--subitem-selected': active,
        })}
        testId={testId}
        href={href}
        onClick={() => {
            return callActionEvent(taggingEventName, undefined)
        }}
    >
        <div className="wh-vertical-menu--subitem-text">
            <Box className="wh-vertical-menu--subitem-text-title" marginRight="s">
                <Text fontSize="s">{title}</Text>
            </Box>
        </div>
    </ClientRoutingAnchorLink>
)
