import { AnonymousUserData, MessagingCounterData, UserData, UserSearchAgentCount, UserStatistics } from '@wh/common/chapter/types/user'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { NextRequest } from '../types/nextJS'
import { fetcher } from './fetcher'

export const getUserInfo = (request?: NextRequest): Promise<UserData> => {
    return fetcher<UserData>('/userprofile-service/userprofile/me', {
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getUnreadMessages = (uuid: string): Promise<MessagingCounterData> => {
    return fetcher<MessagingCounterData>(`/mcp/api/users/${uuid}/counter`, {
        credentials: 'include',
    })
}

export const getUserStatistics = (request?: NextRequest): Promise<UserStatistics> => {
    return fetcher<UserStatistics>(`/iad/user/statistics`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getFreshUserStatistics = (request?: NextRequest): Promise<UserStatistics> => {
    return fetcher<UserStatistics>(`/iad/user/statistics`, {
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getSearchAgentCount = (request?: NextRequest): Promise<UserSearchAgentCount> => {
    return fetcher<UserSearchAgentCount>(`/iad/alert/user/activeCount`, {
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getUUIDForVisitorUUID = (visitorId: string, request?: NextRequest): Promise<AnonymousUserData> => {
    return fetcher<AnonymousUserData>(`/iad/user/anonymous/${visitorId}`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}
