const fileRegex = /^https?:\/\/.+\/f\/\d+\/\d+x\d+\/*.*\.\w{1,5}$/
const dimensionRegex = /^\d+x\d+$/

export type StoryblokImageDimension = { width: number; height: number }

export const storyblokExtractDimensionsFromImageUrl = (url?: string): StoryblokImageDimension | null => {
    if (!url || !fileRegex.test(url)) {
        return null
    }

    for (const segment of url.split('/')) {
        if (dimensionRegex.test(segment)) {
            const splitDimensions = segment.split('x')
            return {
                width: +splitDimensions[0],
                height: +splitDimensions[1],
            }
        }
    }

    return null
}
