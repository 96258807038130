export type AdType =
    | 'BapFree'
    | 'BapWebstoreobject'
    | 'BapSalesobjectPro'
    | 'MotorCar'
    | 'MotorMc'
    | 'MotorVanTruck'
    | 'MotorCaravan'
    | 'EstateSale'
    | 'EstateRent'
    | 'EstateBusinessSale'
    | 'EstateBusinessRent'
    | 'EstateLeisureSale'
    | 'EstateLeisureRent'
    | 'EstatePlotSale'
    | 'EstateMisc'
    | 'EstateNewConstruction'
    | 'JobJob'
    | 'JobCompany'

export const inverseAdTypeMap: Record<AdType, AdTypeId> = {
    BapFree: 67,
    BapWebstoreobject: 68,
    BapSalesobjectPro: 69,
    MotorCar: 20,
    MotorMc: 21,
    MotorVanTruck: 25,
    MotorCaravan: 26,
    EstateSale: 1,
    EstateRent: 2,
    EstateBusinessSale: 3,
    EstateBusinessRent: 4,
    EstateLeisureSale: 5,
    EstateLeisureRent: 6,
    EstatePlotSale: 8,
    EstateMisc: 15,
    EstateNewConstruction: 16,
    JobJob: 401,
    JobCompany: 402,
}

export type AdTypeId = 67 | 68 | 69 | 20 | 21 | 25 | 26 | 1 | 2 | 3 | 4 | 5 | 6 | 8 | 15 | 401 | 402 | 16 | 0

export const inverseAdTypeIdMap: Record<number, AdType> = {
    67: 'BapFree',
    68: 'BapWebstoreobject',
    69: 'BapSalesobjectPro',
    20: 'MotorCar',
    21: 'MotorMc',
    25: 'MotorVanTruck',
    26: 'MotorCaravan',
    1: 'EstateSale',
    2: 'EstateRent',
    3: 'EstateBusinessSale',
    4: 'EstateBusinessRent',
    5: 'EstateLeisureSale',
    6: 'EstateLeisureRent',
    8: 'EstatePlotSale',
    15: 'EstateMisc',
    16: 'EstateNewConstruction',
    401: 'JobJob',
    402: 'JobCompany',
}

export const adTypeIdMap = {
    BAP_FREE: 67,
    BAP_WEBSTORE_OBJECT: 68,
    BAP_SALESOBJECT_PRO: 69,
    MOTOR_CAR: 20,
    MOTOR_MC: 21,
    MOTOR_VAN_TRUCK: 25,
    MOTOR_CARAVAN: 26,
    ESTATE_SALE: 1,
    ESTATE_RENT: 2,
    ESTATE_BUSINESS_SALE: 3,
    ESTATE_BUSINESS_RENT: 4,
    ESTATE_LEISURE_SALE: 5,
    ESTATE_LEISURE_RENT: 6,
    ESTATE_PLOT_SALE: 8,
    ESTATE_MISC: 15,
    ESTATE_NEW_CONSTRUCTION: 16,
    JOB_JOB: 401,
    JOB_COMPANY: 402,
}
