import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Tab, Tabs } from '@wh-components/core/Tabs/Tabs'
import { isClient } from '@wh/common/chapter/lib/commonHelpers'
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

const useExtractUrlsFromTabItems = (tabItems: StoryblokTabItem[]) =>
    useMemo(
        () =>
            tabItems.map((item, index) => {
                if (!item.url || item.url.trim() === '') {
                    return `tab-${index}`
                }
                return item.url
            }),
        [tabItems],
    )

const useTabNavigation = (tabItems: StoryblokTabItem[]) => {
    const [selected, setSelected] = useState(0)
    const initialTabSetRef = useRef(false)

    const urls = useExtractUrlsFromTabItems(tabItems)

    const setSelectedFromHash = useCallback(() => {
        const hash = isClient() ? window.location.hash.substring(1) : ''
        const initialIndex = urls.findIndex((url) => hash === url)
        setSelected(initialIndex)
    }, [urls])

    useEffect(() => {
        if (initialTabSetRef.current) {
            return
        }
        initialTabSetRef.current = true
        setSelectedFromHash()
    }, [setSelectedFromHash])

    useEffect(() => {
        const handleHashChange = () => {
            setSelectedFromHash()
        }
        addEventListener('hashchange', handleHashChange, false)
        return () => {
            removeEventListener('hashchange', handleHashChange, false)
        }
    }, [setSelectedFromHash])

    const handleSelect = (index: number) => {
        setSelected((oldIndex) => {
            if (oldIndex === index) {
                return oldIndex
            }

            window.location.hash = urls[index]
            return index
        })
    }

    return [selected, handleSelect] as const
}

interface StoryblokTabItem extends StoryblokComponentType<string> {
    label: string
    children: SbBlokData[]
    url: string
}

interface StoryblokTabs extends StoryblokComponentType<string> {
    tabSize: 'xsmall' | 'small' | 'medium' | 'large'
    items: StoryblokTabItem[]
}

interface Props extends StoryblokDefaultProps<StoryblokTabs> {}

export const StoryblokTabs: FunctionComponent<Props> = ({ blok, story }) => {
    const [selected, setSelected] = useTabNavigation(blok.items)

    return (
        <Tabs
            size={blok.tabSize}
            renderAllPanels={true}
            selected={selected}
            onSelect={setSelected}
            {...storyblokEditable(blok as unknown as SbBlokData)}
        >
            {blok.items.map((tab, index) => (
                <Tab key={index} label={tab.label} marginTop="m">
                    {tab.children.map((nestedBlok: SbBlokData) => {
                        return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
                    })}
                </Tab>
            ))}
        </Tabs>
    )
}
