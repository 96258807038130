import { VerticalList } from '@wh/common/chapter/types/verticals'
import {
    addCookieHeadersToServerResponse,
    convertCookieHeadersAndAddCookiesToServerRequest,
    getSetCookieHeadersValuesFromResponse,
} from '@wh/common/chapter/lib/cookies'
import { Request as ExpressRequest, Response as ExpressResponse } from 'express'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { fetcher } from './fetcher'

export const getVerticalListAndStoreCookieResponse = async (
    request?: ExpressRequest,
    response?: ExpressResponse,
): Promise<VerticalList> => {
    const { verticalList, responseCookies } = await getVerticalList<{
        verticalList: VerticalList
        responseCookies: string[]
    }>(request, async (verticalListResponse) => ({
        verticalList: await verticalListResponse.json(),
        // `getSetCookie` would be more convenient, but browser support is not there yet
        responseCookies: getSetCookieHeadersValuesFromResponse(verticalListResponse),
    }))

    // pass set-cookie headers from api response to page response
    // during next.js build of static pages, response is available, but response.set is not
    if (request && response && responseCookies.length > 0 && 'set' in response) {
        try {
            addCookieHeadersToServerResponse(responseCookies, response)
            convertCookieHeadersAndAddCookiesToServerRequest(responseCookies, request)
        } catch (error) {
            console.log('Error on passing on cookies from the API to the server response', error)
        }
    }

    return verticalList
}

export const getVerticalList = async <R = VerticalList>(request?: ExpressRequest, deserializer?: (response: Response) => Promise<R>) => {
    return fetcher<R>('/iad/vertical', { credentials: 'include', cookies: getBbxCookiesFromRequest(request) }, deserializer)
}
