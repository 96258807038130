import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { StoryblokComponentType, SbBlokData } from '@storyblok/js/dist/types/types'
import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { AnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Box } from '@wh-components/core/Box/Box'
import { LazyPicture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import { Text } from '@wh-components/core/Text/Text'
import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { storyblokResolveLink } from '../../lib/storyblokResolveLink'
import { storyblokEditable } from '@storyblok/react'
import { storyblokTransformSlugToTag } from '../../lib/storyblokTransformSlugToTag'

export interface StoryblokTextWithPictureBlok extends StoryblokComponentType<string> {
    heading: string
    text: string
    image: StoryblokAsset
    link: StoryblokLink
    imageHeight: number
    amount?: number
    taggingId?: string
}

interface Props extends StoryblokDefaultProps<StoryblokTextWithPictureBlok> {
    taggingData?: TaggingData
    taggingPrefix?: string
}

export const StoryblokTextWithPicture: FunctionComponent<Props> = ({ blok, story, taggingData, taggingPrefix, ...props }) => {
    const { heading, text, image, link, imageHeight, amount, taggingId } = blok
    const numberArray = amount ? String(amount).split('') : undefined

    if (link.linktype === 'story') {
        return null
    }

    const prefix = taggingPrefix ?? storyblokTransformSlugToTag(story?.full_slug ?? '')

    return (
        <AnchorLink
            type="anchor"
            href={storyblokResolveLink(link)}
            clientSideRouting={false}
            color="palette.verydarkgrey"
            underline="none"
            onClick={() => {
                if (prefix && taggingId) {
                    callActionEvent('storyblok_click', taggingData, { prefix: prefix, taggingId: taggingId })
                }
            }}
            aria-label={`${image.alt} anzeigen`}
            flex={1}
            {...storyblokEditable(blok as unknown as SbBlokData)}
            {...props}
        >
            <Box display="flex" flexDirection={{ desktop: 'column' }} height="100%">
                <Box position="relative" borderLeftRadius={{ phone: 'm', desktop: 0 }} overflow="hidden">
                    <Box height={{ phone: 80, desktop: `${imageHeight}px` }} width={{ phone: 80, desktop: '100%' }}>
                        <LazyPicture
                            height="100%"
                            width="100%"
                            imgSrcSet={{ x1: `${image.filename}/m/0x${imageHeight}`, x2: `${image.filename}/m/0x${imageHeight * 2}` }}
                            alt={image.alt}
                            css={css`
                                object-fit: cover;
                                background-color: ${(p) => p.theme.colors.palette.babyseal};
                            `}
                        />
                    </Box>
                    {numberArray && (
                        <Box position="absolute" bottom="s" left="s" display={{ phone: 'none', desktop: 'flex' }} flexDirection="column">
                            <Text color="palette.white" fontWeight="bold" fontSize="xs" marginBottom="xxs">
                                MEHR ALS
                            </Text>
                            <Box display={{ phone: 'none', desktop: 'flex' }}>
                                {numberArray?.map((number, key) => <NumberBlock key={key}>{number}</NumberBlock>)}
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box
                    flexGrow={1}
                    padding="s"
                    border="owl"
                    borderTop={{ desktop: 0 }}
                    borderLeft={{ phone: 0, desktop: 'owl' }}
                    borderRightRadius={{ phone: 'm', desktop: 0 }}
                    borderBottomRadius={{ desktop: 'm' }}
                >
                    <Text as="p" fontWeight="bold">
                        {heading}
                    </Text>
                    <Text fontSize="s" lineClamp={2}>
                        {text}
                    </Text>
                </Box>
            </Box>
        </AnchorLink>
    )
}

const NumberBlock = styled.span`
    display: inline-block;
    padding: 5px 4px;
    border-radius: ${(p) => p.theme.borderRadius}px;
    background-color: ${(p) => p.theme.colors.palette.primary.main};
    color: ${(p) => p.theme.colors.palette.white};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    line-height: 1;
    user-select: none;

    &:not(:first-of-type) {
        margin-left: 1px;
    }

    &:nth-last-child(3n) {
        margin-left: 3px;
    }
`
