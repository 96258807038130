import { storyblokExtractDimensionsFromImageUrl } from '../../lib/storyblokExtractDimensionsFromImageUrl'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Picture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'

interface StoryblokAssetDownload extends StoryblokComponentType<string> {
    previewImage: StoryblokAsset
    asset: StoryblokAsset
    text: string
}

interface Props extends StoryblokDefaultProps<StoryblokAssetDownload> {}

export const StoryblokAssetDownload: FunctionComponent<Props> = ({ blok }) => {
    const dimensions = storyblokExtractDimensionsFromImageUrl(blok.previewImage.filename)

    return (
        <Box {...storyblokEditable(blok as unknown as SbBlokData)}>
            <ServerRoutingAnchorLink
                type="anchor"
                href={blok.asset.filename}
                target="_blank"
                rel="noopener noreferrer"
                display="block"
                marginBottom="xs"
            >
                <Picture
                    imgSrcSet={{ x1: `${blok.previewImage.filename}/m/300x0`, x2: `${blok.previewImage.filename}/m/600x0` }}
                    alt={blok.previewImage.alt}
                    css={css`
                        width: 100%;
                        aspect-ratio: ${dimensions?.width} / ${dimensions?.height};
                    `}
                />
            </ServerRoutingAnchorLink>
            <ServerRoutingAnchorLink
                type="anchor"
                href={blok.asset.filename}
                target="_blank"
                rel="noopener noreferrer"
                color="palette.verydarkgrey"
            >
                {blok.text}
            </ServerRoutingAnchorLink>
        </Box>
    )
}
