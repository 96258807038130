import { AdvertisingState } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { theme } from '@wh-components/core/theme'

type SkyscraperStyle = NonNullable<AdvertisingState['pageModifications']['apnSkyscraperStyle']>

const skyscraperStyles: Record<
    'sbar' | 'hpa' | 'sky' | 'lad',
    {
        position: 'absolute' | 'fixed'
        top: 'page-top' | 'apn-large-leaderboard-top'
        left: 'stick-to-content' | 'default-spacing'
        width: '300px' | '160px' | undefined
        height: '100%' | '600px' | undefined
        bottom: '0' | undefined
        right: '0' | undefined
    }
> = {
    sbar: {
        position: 'fixed',
        top: 'page-top',
        left: 'default-spacing',
        width: undefined,
        height: '100%',
        bottom: undefined,
        right: '0',
    },
    hpa: {
        position: 'fixed',
        top: 'apn-large-leaderboard-top',
        left: 'default-spacing',
        width: '300px',
        height: '600px',
        bottom: undefined,
        right: undefined,
    },
    sky: {
        position: 'fixed',
        top: 'apn-large-leaderboard-top',
        left: 'default-spacing',
        width: '160px',
        height: '600px',
        bottom: undefined,
        right: undefined,
    },
    // the lad additionally right-aligns the content of the apn-leaderboard
    lad: {
        position: 'fixed',
        top: 'apn-large-leaderboard-top',
        left: 'stick-to-content',
        width: undefined,
        height: undefined,
        bottom: '0',
        right: '0',
    },
}

const isIllegalWidth = (skyscraperStyle: SkyscraperStyle) =>
    skyscraperStyle.baseStyle === 'custom' &&
    skyscraperStyle.position === 'fixed' &&
    'width' in skyscraperStyle &&
    typeof skyscraperStyle.width === 'string' &&
    skyscraperStyle.width.includes('%')

export const position = (skyscraperStyle: SkyscraperStyle): string => {
    if (skyscraperStyle.baseStyle === 'custom') {
        return skyscraperStyle.position
    }

    return skyscraperStyles[skyscraperStyle.baseStyle].position
}

export const top = (skyscraperStyle: SkyscraperStyle, currentLeaderboardTop: number): string | undefined => {
    if (skyscraperStyle.baseStyle === 'custom') {
        return cssLengthStringFromStringOrNumber(skyscraperStyle.marginTop)
    }

    switch (skyscraperStyle.topStyle || skyscraperStyles[skyscraperStyle.baseStyle].top) {
        case 'page-top':
            return '0px'
        case 'apn-large-leaderboard-top':
            return `${currentLeaderboardTop}px`
    }

    return undefined
}

export const left = (skyscraperStyle: SkyscraperStyle): string | undefined => {
    if (skyscraperStyle.baseStyle === 'custom') {
        switch (skyscraperStyle.position) {
            case 'absolute':
                return cssLengthStringFromStringOrNumber(skyscraperStyle.marginLeft)
            case 'fixed':
                return `calc(${contentWidth()}px + ${cssLengthStringFromStringOrNumber(skyscraperStyle.marginLeft)})`
            default:
                return `calc(${contentWidth()}px + ${cssLengthStringFromStringOrNumber(skyscraperStyle.marginLeft)})`
        }
    }

    switch (skyscraperStyle.leftStyle || skyscraperStyles[skyscraperStyle.baseStyle].left) {
        case 'default-spacing':
            return `calc(${contentWidth()}px + 10px)`
        case 'stick-to-content':
            return `${contentWidth()}px`
    }

    return undefined
}

export const right = (skyscraperStyle: SkyscraperStyle): string | undefined => {
    if (skyscraperStyle.baseStyle === 'custom') {
        if ('width' in skyscraperStyle) {
            if (isIllegalWidth(skyscraperStyle)) {
                return '0px'
            } else {
                return undefined
            }
        } else {
            return cssLengthStringFromStringOrNumber(skyscraperStyle.marginRight)
        }
    }

    return skyscraperStyles[skyscraperStyle.baseStyle].right
}

export const bottom = (skyscraperStyle: SkyscraperStyle): string | undefined => {
    if (skyscraperStyle.baseStyle === 'custom') {
        if ('height' in skyscraperStyle) {
            return undefined
        } else {
            return cssLengthStringFromStringOrNumber(skyscraperStyle.marginBottom)
        }
    }

    return skyscraperStyles[skyscraperStyle.baseStyle].bottom
}

const contentWidth = () => theme.breakpoints.desktop

export const width = (skyscraperStyle: SkyscraperStyle): string | undefined => {
    if (skyscraperStyle.baseStyle === 'custom') {
        if (!('width' in skyscraperStyle) || isIllegalWidth(skyscraperStyle)) {
            return undefined
        } else {
            return cssLengthStringFromStringOrNumber(skyscraperStyle.width)
        }
    }

    return skyscraperStyles[skyscraperStyle.baseStyle].width
}

export const height = (skyscraperStyle: SkyscraperStyle): string | undefined => {
    if (skyscraperStyle.baseStyle === 'custom') {
        if ('height' in skyscraperStyle) {
            return cssLengthStringFromStringOrNumber(skyscraperStyle.height)
        } else {
            return undefined
        }
    }

    return skyscraperStyles[skyscraperStyle.baseStyle].height
}

const cssLengthStringFromStringOrNumber = (cssValue: string | number) => {
    if (typeof cssValue === 'number') {
        return `${cssValue}px`
    } else {
        return cssValue
    }
}
