import React, { FunctionComponent, useContext } from 'react'
import { css } from 'styled-components'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'

export const OverlayIframe: FunctionComponent<{}> = () => {
    const advertisingState = useContext(AdvertisingStateContext)
    const shouldRenderOverlayIframe = advertisingState.pageModifications.overlayIFrame?.src

    if (!shouldRenderOverlayIframe) {
        return null
    }

    return (
        <iframe
            src={advertisingState.pageModifications.overlayIFrame?.src || ''}
            frameBorder="0"
            title="Werbung"
            aria-hidden="true"
            css={css`
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                position: ${advertisingState.pageModifications.overlayIFrame?.position || 'absolute'};
                z-index: ${(p) => p.theme.zIndices.overlayAd};
                pointer-events: none;

                ${(p) => p.theme.media.print} {
                    display: none;
                }
            `}
        />
    )
}
