import React, { FunctionComponent, PropsWithChildren } from 'react'

export const SSGContext = React.createContext<boolean>(true)

interface SSGProviderProps extends PropsWithChildren {
    isSSG: boolean
}

export const SSGProvider: FunctionComponent<SSGProviderProps> = ({ isSSG, children }) => {
    return <SSGContext.Provider value={isSSG}>{children}</SSGContext.Provider>
}
