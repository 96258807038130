import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'
import { Text } from '@wh-components/core/Text/Text'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'

export const BubbleList = styled.ul`
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${(p) => p.theme.space.xs}px;
`

type BubbleListItemProps = PropsWithChildren & {
    url: string
    onClick?: () => void
    testId?: string
}

export const BubbleListItem: FunctionComponent<BubbleListItemProps> = ({ url, onClick, testId, children }) => {
    return (
        <li>
            <LinkWithButtonStyle href={url} onClick={onClick} size="xsmall" color="complimentary" variant="outline" testId={testId}>
                <Text truncate={true} fontSize="s" color="palette.raccoon" fontWeight="regular">
                    {children}
                </Text>
            </LinkWithButtonStyle>
        </li>
    )
}
