import { extractFileExtensionFromUrl } from '../../../chapter/lib/extractFileExtensionFromUrl'
import { storyblokResolveLink } from '../../lib/storyblokResolveLink'
import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import dayjs from 'dayjs'
import React, { Fragment, FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

interface StoryblokBlogListItem extends StoryblokComponentType<string> {
    text: string
    url: StoryblokLink
    /**
     * Empty string if not set
     */
    date: string
}

interface StoryblokBlogList extends StoryblokComponentType<string> {
    items: StoryblokBlogListItem[]
}

interface Props extends StoryblokDefaultProps<StoryblokBlogList> {}

const reactNodeArrayJoin = (array: ReactNode[], renderSeparator: (index: number) => ReactNode) =>
    array.flatMap((value, index) => (index !== array.length - 1 ? [value, renderSeparator(index)] : value))

export const StoryblokBlogList: FunctionComponent<Props> = ({ blok }) => {
    return (
        <section {...storyblokEditable(blok as unknown as SbBlokData)}>
            {blok.items.map((item, index) => {
                if (item.url.linktype === 'story') {
                    return null
                }

                const url = storyblokResolveLink(item.url)

                /**
                 * Elements that should be rendered under the text link.
                 * Each item should be separated by a '|' character
                 */
                const subItems: ReactNode[] = []

                if (item.date) {
                    subItems.push(<Fragment key={`${item._uid}_date`}>{dayjs(item.date).format('DD.MM.YYYY')}</Fragment>)
                }

                // if the link points to an asset, add a link that points to the same link and contains the file extension as text
                if (item.url.linktype === 'asset') {
                    const extension = extractFileExtensionFromUrl(url)
                    if (extension !== null) {
                        subItems.push(
                            <ServerRoutingAnchorLink
                                key={`${item._uid}_file`}
                                type="anchor"
                                href={url}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                ({extension})
                            </ServerRoutingAnchorLink>,
                        )
                    }
                }

                return (
                    <BlogListItem
                        key={item._uid}
                        last={index === blok.items.length - 1}
                        {...storyblokEditable(item as unknown as SbBlokData)}
                    >
                        <BlogListItemLink type="anchor" href={url} rel="noopener noreferrer" target="_blank">
                            {item.text}
                        </BlogListItemLink>
                        <BlogListItemSubtitle>
                            {reactNodeArrayJoin(subItems, (subIndex) => (
                                <Fragment key={`${item._uid}_separator-${subIndex}`}>&nbsp;|&nbsp;</Fragment>
                            ))}
                        </BlogListItemSubtitle>
                    </BlogListItem>
                )
            })}
        </section>
    )
}

const BlogListItem = styled.div<{ last?: boolean }>`
    padding: 0 ${(p) => p.theme.space.m}px ${(p) => p.theme.space.m}px ${(p) => p.theme.space.m}px;
    margin-bottom: ${(p) => p.theme.space.m}px;
    border-bottom: ${(p) => (p.last ? undefined : `1px solid ${p.theme.colors.palette.owl}`)};
`

const BlogListItemLink = styled(ServerRoutingAnchorLink)`
    color: ${(p) => p.theme.colors.palette.verydarkgrey};
    font-weight: bold;
    display: block;
    margin-bottom: ${(p) => p.theme.space.xs}px;
`

const BlogListItemSubtitle = styled.div`
    color: ${(p) => p.theme.colors.palette.elephant};
    font-size: ${(p) => p.theme.fontSizes.s};
`
