import { DidomiSDK } from '@didomi/react'
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import { DebugFlagContext } from '../DebugFlagProvider/DebugFlagProvider'
import { UserAgentContext } from '../UserAgentProvider/UserAgentProvider'
import { DidomiContext } from './DidomiContext'
import { filterDidomiConsent } from './filterDidomiToken'

export const DidomiLoader = () => {
    const { setReady } = useContext(DidomiContext)
    const debugFlags = useContext(DebugFlagContext)

    const { userAgent } = useContext(UserAgentContext)

    useEffect(() => {
        filterDidomiConsent()
    }, [])

    return debugFlags.cmpMode === 'disable' || !userAgent ? null : (
        <DidomiSDK
            apiKey="89dbb2ef-5543-413b-b23c-99389df3609a"
            config={config}
            onReady={() => setReady({ ready: true })}
            onConsentChanged={() => onConsentChanged(setReady)}
        />
    )
}

const onConsentChanged = (setReady: Dispatch<SetStateAction<{ ready: boolean }>>) => {
    if (window.didomiState?.didomiVendorsConsentDenied !== '' || window.didomiState?.didomiPurposesConsentDenied !== '') {
        window.location.reload()
    }
    setReady({ ready: true })
}

const config = {
    app: {
        apiKey: '89dbb2ef-5543-413b-b23c-99389df3609a',
    },
    notice: {
        content: {
            dismiss: {
                de: 'Cookies akzeptieren',
            },
            learnMore: {
                de: 'Erweiterte Einstellungen',
            },
        },
    },
    preferences: {
        content: {
            authorizeVendors: {
                de: 'Annehmen',
            },
            blockVendors: {
                de: 'Ablehnen',
            },
            textVendors: {
                de: 'Du kannst f\u00fcr jeden aufgef\u00fchrten Partner Deine Zustimmungspr\u00e4ferenzen festlegen. Klicke auf einen Partnernamen, um dich \u00fcber Branche und Datenerhebung sowie -verwendung zu informieren.',
            },
        },
    },
    integrations: {
        vendors: {
            // Setup the integration with Google (ask Didomi to share consent with Google tags)
            google: {
                enable: true,
                refresh: false, // Tell the SDK to never load/refresh ads and only share consent with Google
            },
            // google consent mode
            gcm: {
                enable: true,
                setDefaultStatus: true,
                defaultStatus: {
                    analytics: false,
                    ad: false,
                },
            },
        },
    },
    cookies: {
        storageSources: {
            cookies: true,
            localStorage: true,
        },
    },
    user: {
        bots: {
            consentRequired: false,
            types: ['crawlers', 'performance'],
            extraUserAgents: [],
        },
    },
}

export const DidomiStyles = createGlobalStyle`
    #didomi-host .didomi-notice-popup .didomi-popup-notice-text  {
        max-width: 100% !important;
    }

    .didomi-popup-view {
        width: 100%;
    }
`
