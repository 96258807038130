import { css } from 'styled-components'

export const werbungPlaceholderEmptyAfter = (placeholderHeight: number) => css`
    &:empty:after {
        content: 'WERBUNG';
        text-align: center;
        font-size: ${(p) => p.theme.fontSizes.l};
        font-weight: ${(p) => p.theme.fontWeights.bold};
        color: ${(p) => p.theme.colors.palette.owl};
        background-color: ${(p) => p.theme.colors.palette.babyseal};
        width: 100%;
        height: ${placeholderHeight}px;
        padding-top: ${placeholderHeight / 2 - 12}px;
        box-sizing: border-box;
    }
`
