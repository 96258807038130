import { HorizontalSlider } from '../../../chapter/components/HorizontalSlider/HorizontalSlider'
import { storyblokCallActionEvent } from '../../lib/storyblokCallActionEvent'
import { storyblokResolveSearchLink } from '../../lib/storyblokResolveSearchLink'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokSearchLink } from '../../types/StoryblokSearchLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Picture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'

interface StoryblokPictureWithBlueTextBottomItem extends StoryblokComponentType<string> {
    label: string
    image: StoryblokAsset
    url: StoryblokSearchLink
    taggingId: string
}

export interface StoryblokPictureWithBlueTextBottom extends StoryblokComponentType<'pictureWithBlueTextBottom'> {
    label: string
    items: StoryblokPictureWithBlueTextBottomItem[]
    taggingId: string
}

interface Props extends StoryblokDefaultProps<StoryblokPictureWithBlueTextBottom> {
    onClickOverride?: (item: StoryblokPictureWithBlueTextBottomItem) => void
    testId?: string
    categoriesForegroundColor?: string
}

/**
 * Originated from the bap start page.
 *
 * Despite its name this component displays a list of categories.
 */
export const StoryblokPictureWithBlueTextBottom: FunctionComponent<Props> = ({
    blok,
    story,
    onClickOverride,
    testId,
    categoriesForegroundColor,
}) => {
    return (
        <Box {...storyblokEditable(blok as unknown as SbBlokData)}>
            {blok.label && (
                <Heading
                    text={blok.label}
                    level={2}
                    fontSize="m"
                    marginBottom="s"
                    testId={testId ? `${testId}-heading` : undefined}
                    color={categoriesForegroundColor ?? 'palette.raccoon'}
                    css={css`
                        text-transform: uppercase;
                    `}
                />
            )}
            <HorizontalSlider
                testIdPrefix={testId}
                css={css`
                    transform: translateZ(-11px);
                    ${(p) => p.theme.media.only.phone} {
                        margin-right: -${(p) => p.theme.space.m}px;
                    }
                `}
            >
                {blok.items.map((category, index) => (
                    <ClientRoutingAnchorLink
                        key={index}
                        type="anchor"
                        href={storyblokResolveSearchLink(category.url)}
                        onClick={() => {
                            if (onClickOverride) {
                                onClickOverride(category)
                            } else {
                                storyblokCallActionEvent(
                                    `${blok.taggingId ? blok.taggingId : blok.label}::${
                                        category.taggingId ? category.taggingId : category.label
                                    }`,
                                    story?.full_slug ?? '',
                                )
                            }
                        }}
                        display="flex"
                        flexDirection="column"
                        overflow="hidden"
                        css={css`
                            border-radius: ${(p) => p.theme.borderRadii.m}px;
                        `}
                        testId={testId ? `${testId}-entry-${index}` : undefined}
                    >
                        <Picture
                            width="100%"
                            imgSrcSet={{
                                x1: `${category.image.filename}/m/120x104`,
                                x2: `${category.image.filename}/m/240x208`,
                            }}
                            css={css`
                                aspect-ratio: 120 / 104;
                            `}
                            alt={category.image.alt}
                        />
                        <Box
                            backgroundColor="palette.primary.main"
                            height="30px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Text color="palette.white">{category.label}</Text>
                        </Box>
                    </ClientRoutingAnchorLink>
                ))}
            </HorizontalSlider>
        </Box>
    )
}
