// adapted from https://github.com/asyarb/use-intersection-observer

import { DependencyList, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

const IS_BROWSER = typeof window !== 'undefined'

export const useIntersectionObserver = (
    ref: RefObject<Element>,
    options: IntersectionObserverInit & { triggerOnce: boolean; initialIsInView?: boolean } = {
        triggerOnce: true,
        threshold: 0,
        initialIsInView: false,
    },
    deps?: DependencyList, // deps can be used to reset an observer that has triggerOnce activated
    callback?: (entry: IntersectionObserverEntry) => void,
    // use { intersectionRatio < ratio | intersectionRatio > ratio } in the callback to check if the ref is in view or not
    // if you use 'triggerOnce: true', 'isIntersecting' is not needed
): [boolean] => {
    const [inView, setInView] = useState(options.initialIsInView ?? false)

    const handleIntersect = useCallback(
        (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            if (entries.length === 0) {
                return
            }

            if (options.triggerOnce) {
                const firstIntersectingEntry = entries.find((e) => e.isIntersecting)

                if (typeof firstIntersectingEntry === 'undefined') {
                    setInView(false)
                } else {
                    callback?.(firstIntersectingEntry)
                    observer.disconnect()
                    setInView(true)
                }
            } else {
                // INFO: there might be multiple elements in the entries array when the browser was busy doing other things - in that case, we drop all entries but the last one to avoid unnecessary rerenders (will probably be obsolete when concurrent mode arrives) - client code should not depend on the callback being called for every single entry in this case, rather consider using triggerOnce
                const lastEntry = entries[entries.length - 1]
                callback?.(lastEntry)
                setInView(lastEntry.isIntersecting)
            }
        },
        [callback, options.triggerOnce],
    )

    // because of useState, the callback function is never updated, therefore we need to use a stable callback that never changes by using an empty dependency array
    const handleIntersectRef = useRef(handleIntersect)
    handleIntersectRef.current = handleIntersect
    const handleIntersectStable = useCallback(
        (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => handleIntersectRef.current(entries, observer),
        [],
    )

    // INFO because of shallow deps comparison, we are not using options directly in the deps array to avoid unnecessary execution of the useMemo hook, and furthermore rerenderings and bugs with triggerOnce
    const intersectObs = useMemo(
        () =>
            IS_BROWSER
                ? new IntersectionObserver(handleIntersectStable, {
                      root: options.root,
                      rootMargin: options.rootMargin,
                      threshold: options.threshold,
                  })
                : null,
        // options.threshold can be an array, therefore using JSON.stringify() to avoid useMemo firing when receiving two different arrays with the same content
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleIntersectStable, options.root, options.rootMargin, JSON.stringify(options.threshold)],
    )

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const externalDeps = useMemo(() => new Object(), deps ?? [])

    // we are not using ref.current directly in this useEffect, see INFO below
    const elementBeforeRender = ref.current
    useEffect(() => {
        if (!intersectObs) {
            return
        }

        if (elementBeforeRender) {
            intersectObs.observe(elementBeforeRender)
        }

        return () => intersectObs.disconnect()
    }, [intersectObs, externalDeps, elementBeforeRender])

    // INFO it can happen that in the last render of useIntersectionObserver the ref element is not yet set (e.g. when using a loading state and the ref is set on a div that only appears when loading completed).
    // in this case, the useIntersectionObserver would be executed, but the useEffect from above would not be executed, because none of its dependencies changed - so we need to make sure that we correctly start observing in that case.
    // instead of using a "callback ref", we decided to rerender in that case since a callback ref would make it harder to use the useIntersectionObserver hook in a component that receives the ref to observe from its parent.
    const [, setRerenderCount] = useState(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (elementBeforeRender !== ref.current) {
            // trigger rerendering to correctly start observing the changed ref element
            setRerenderCount((oldCount) => oldCount + 1)
        }
    }) // this useEffect must not have a dependency list, so it can detect the last render of useIntersectionObserver and check if ref.current was changed in that run to trigger a rerender

    return [inView]
}
