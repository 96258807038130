import { Vertical } from '@wh/common/chapter/types/verticals'
import { UserData, UserProfileData, UserStatistics } from '@wh/common/chapter/types/user'
import React, { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react'
import { EnterBirthYearProvider } from '../EnterBirthYearProvider/EnterBirthYearProvider'

interface GlobalStateProviderProps extends PropsWithChildren {
    initialVerticalsInfo?: Vertical[]
    initialProfileData?: UserProfileState
}
type SetStateType<T> = React.Dispatch<React.SetStateAction<T>>
type SetStateTypeInitial<T> = SetStateType<T> | null

// --- Verticals stuff
export type VerticalInfoState = Vertical[] | undefined
const VerticalInfoContext = createContext<[VerticalInfoState, SetStateTypeInitial<VerticalInfoState>]>([undefined, null])
type UseVerticalsInfo = [VerticalInfoState, SetStateType<VerticalInfoState>]

// --- User stuff
export const isUserLoggedIn = (userData: UserProfileState): userData is UserProfileData => {
    return userData !== 'unsure' && !!userData?.uuid
}

export const isUserLoggedOut = (userData: UserProfileState): userData is undefined => {
    return typeof userData === 'undefined'
}

export type UserProfileState = UserProfileData | undefined | null | 'unsure'
const UserProfileContext = createContext<[UserProfileState, SetStateTypeInitial<UserProfileState>]>(['unsure', null])
type UseProfileData = [UserProfileState, SetStateType<UserProfileState>]

export type UserStatsState = UserStatistics | undefined
const UserStatsContext = createContext<[UserStatsState, SetStateTypeInitial<UserStatsState>]>([undefined, null])
type UseUserStats = [UserStatsState, SetStateType<UserStatsState>]

export type SearchAgentCountState = number
const SearchAgentCountContext = createContext<[SearchAgentCountState, SetStateTypeInitial<SearchAgentCountState>]>([-1, null])
type UseSearchAgentCount = [SearchAgentCountState, SetStateType<SearchAgentCountState>]

export type UnreadMessagesState = number
const UnreadMessagesContext = createContext<[UnreadMessagesState, SetStateTypeInitial<UnreadMessagesState>]>([0, null])
type UseUnreadMessagesCount = [UnreadMessagesState, SetStateType<UnreadMessagesState>]

export const GlobalStateProvider: FunctionComponent<GlobalStateProviderProps> = ({
    initialVerticalsInfo,
    initialProfileData,
    children,
}) => {
    const [verticalsInfo, setVerticalsInfo] = React.useState<VerticalInfoState>(initialVerticalsInfo)
    const verticalInfoContextValue = React.useMemo<UseVerticalsInfo>(() => [verticalsInfo, setVerticalsInfo], [verticalsInfo])

    const [profileData, setProfileData] = React.useState<UserProfileState>(initialProfileData)
    const profileDataContextValue = React.useMemo<UseProfileData>(() => [profileData, setProfileData], [profileData])

    const [userStats, setUserStats] = React.useState<UserStatsState>()
    const userStatsContextValue = React.useMemo<UseUserStats>(() => [userStats, setUserStats], [userStats])

    const [searchAgentStats, setSearchAgentStats] = React.useState<SearchAgentCountState>(-1)
    const searchAgentCountContextValue = React.useMemo<UseSearchAgentCount>(
        () => [searchAgentStats, setSearchAgentStats],
        [searchAgentStats],
    )

    const [unreadMessages, setUnreadMessages] = React.useState(0)
    const unreadMessagesContextValue = React.useMemo<UseUnreadMessagesCount>(() => [unreadMessages, setUnreadMessages], [unreadMessages])

    return (
        <EnterBirthYearProvider>
            <UnreadMessagesContext.Provider value={unreadMessagesContextValue}>
                <UserStatsContext.Provider value={userStatsContextValue}>
                    <UserProfileContext.Provider value={profileDataContextValue}>
                        <SearchAgentCountContext.Provider value={searchAgentCountContextValue}>
                            <VerticalInfoContext.Provider value={verticalInfoContextValue}>{children}</VerticalInfoContext.Provider>
                        </SearchAgentCountContext.Provider>
                    </UserProfileContext.Provider>
                </UserStatsContext.Provider>
            </UnreadMessagesContext.Provider>
        </EnterBirthYearProvider>
    )
}

export const useVerticalsInfo = (): UseVerticalsInfo => {
    const [verticalInfo, setVerticalsInfo] = useContext(VerticalInfoContext)

    if (!setVerticalsInfo) {
        throw new Error(`useSetVerticalInfo must be used within a GlobalStateProvider`)
    }

    return [verticalInfo, setVerticalsInfo]
}

// messaging
export const useUnreadMessagesCount = (): UseUnreadMessagesCount => {
    const [unreadMessages, setUnreadMessages] = useContext(UnreadMessagesContext)

    if (!setUnreadMessages) {
        throw new Error(`useUnreadMessagesCount must be used within a GlobalStateProvider`)
    }

    return [unreadMessages, setUnreadMessages]
}

// user stuff

export const useProfileData = (): UseProfileData => {
    const [profileData, setProfileData] = useContext(UserProfileContext)

    if (!setProfileData) {
        throw new Error(`useProfileData must be used within a GlobalStateProvider`)
    }

    return [profileData, setProfileData]
}

export const cleanUpProfileData = (profileData: UserData) => {
    return {
        ...profileData.userData,
        contextLinkList: profileData.contextLinkList,
        firstName: profileData.userData.firstName ?? '',
        lastName: profileData.userData.lastName ?? '',
    }
}

// userstats
export const useUserStats = (): UseUserStats => {
    const [userStats, setUserStats] = useContext(UserStatsContext)

    if (!setUserStats) {
        throw new Error(`useUserStatsCount must be used within a GlobalStateProvider`)
    }

    return [userStats, setUserStats]
}

export const useSearchAgentCount = (): UseSearchAgentCount => {
    const [searchAgentCount, setSearchAgentCount] = useContext(SearchAgentCountContext)

    if (!setSearchAgentCount) {
        throw new Error(`useSearchAgentCount must be used within a GlobalStateProvider`)
    }

    return [searchAgentCount, setSearchAgentCount]
}
