export interface ContextLinkList {
    contextLink: ContextLink[]
}

export interface ContextLink {
    description: string
    id: string
    uri: string
    selected?: boolean
    relativePath: string | null
    serviceName: string
}

export interface JobsContextLink {
    id: string
    uri: string
}

export interface SimilarAdsContextLink extends ContextLink {
    displayText: string
    type: 'dealer' | 'similar-ads'
}

export const findContextLinkWithId = (id: ContextLinkId, contextLinkList: ContextLinkList) => {
    if (!contextLinkList?.contextLink) {
        return undefined
    }

    return contextLinkList.contextLink.find((contextLink) => contextLink.id === id)
}

export const findContextLinkWithIdFromArray = <T extends ContextLink | JobsContextLink>(
    id: ContextLinkId,
    contextLinkList: T[] | undefined,
): T | undefined => {
    if (!contextLinkList) {
        return undefined
    }

    return contextLinkList.find((contextLink) => contextLink.id === id)
}

export enum ContextLinkIdEnum {
    prevLink = '3',
    nextLink = '2',
}

export type ContextLinkId =
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | 'salesAd'
    | 'iadShareLink'
    | 'similarAdsRecommendationUrl'
    | 'similarAdsRecommendationWebUrl'
    | 'requestBillLink'
    | 'upsellingsActiveAd'
    | 'editImages'
    | 'editTenantProfileUri'
    | 'editText'
    | 'editRejectedAd'
    | 'replaceExpiredAd'
    | 'paymentService'
    | 'landingPageWebsite'
    | 'similarAdsSearchLink'
    | 'trustProfileLink'
    | 'resultListSeoNavigatorLink'
    | 'selfLink'
    | 'searchBaseLink'
    | 'seoLinkBoxLink'
    | 'searchAgentCreateLink'
    | 'searchAgentOptionsLink'
    | 'searchResetLink'
    | 'published.descending'
    | 'distance.ascending'
    | 'price.ascending'
    | 'price.descending'
    | 'mileage.ascending'
    | 'mileage.descending'
    | 'yearOfConstruction.ascending'
    | 'yearOfConstruction.descending'
    | 'bestMatch.ascending'
    | 'model.ascending'
    | 'setAdSold'
    | 'markAdReserved'
    | 'removeMarkAdReserved'
    | 'changeDeliveryOptions'
    | 'logSearchLink'
    | 'childAdsLink'
    | 'svg-normal'
    | 'png-normal'
    | 'svg-selected'
    | 'png-selected'
    | 'autocomplete'
    | 'adImageUrl'
    | 'adDetail'
    | 'searchAll'
    | 'sellerProfile'
    | 'sellerProfileWebLink'
    | 'sellerProfileImageUri'
    | 'sellerRecommendation'
    | 'unfollowUser'
    | 'followUser'
    | 'dismiss'
    | 'phoneVerificationInitUri'
    | 'phoneVerificationConfirmUri'
    | 'otpInitUri'
    | 'otpConfirmUri'
    | 'reportUser'
    | 'sellerProfileShare'
    | 'editAdyenProfileDataUri'
    | 'organisation.profileWeblink'
    | 'bulkDeleteGroupedAdsFromUser'
    | 'bulkActivateGroupedAdsFromUser'
    | 'bulkDeactivateGroupedAdsFromUser'
    | 'myFolderSimilarAdsRecommendationUrl'
    | 'searchApiLink'
    | 'searchWebLink'
    | 'webLink'
    | 'fetchTopAds'
    | 'mortgageLink'
    | 'isaActivated'
    | 'dac7form'
