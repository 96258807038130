import React, { FunctionComponent } from 'react'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { css } from 'styled-components'
import { Heading } from '@wh-components/core/Heading/Heading'

export const StoryblokAnchorHeading: FunctionComponent<StoryblokDefaultProps> = ({ blok }) => {
    return (
        <Heading
            text={blok.text as string}
            color={blok.color}
            level={blok.level as 2 | 3 | 4}
            id={blok.anchorId as string}
            marginBottom="m"
            marginTop="l"
            css={css`
                scroll-margin-top: ${FIXED_HEADER_HEIGHT_PHONE}px;

                ${(p) => p.theme.media.tablet} {
                    scroll-margin-top: 0;
                }
            `}
        />
    )
}
