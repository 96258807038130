import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'

export enum SearchId {
    Invalid = 0,
    Cars = 2,
    Motorcycles = 4,
    Trucks = 50,
    Caravans = 52,
    DealerCars = 61,
    DealerMotorcycles = 62,
    DealerTrucks = 63,
    DealerCaravans = 64,
    New_Constructions = 42, // Neubauprojekte
    Flat_Rent = 131, // Wohnung mieten
    Flat_Sale = 101, // Wohnung kaufen
    House_Rent = 132, // Haus mieten
    House_Sale = 102, // Haus kaufen
    Leisure_Rent = 32, // Ferienimmobilie mieten
    Leisure_Sale = 12, // Ferienimmobilie kaufen
    Commercial_Rent = 16, // Gewerbeimmobilie mieten
    Commercial_Sale = 15, // Gewerbeimmobilie kaufe
    Plots = 14, // Grundstück kaufen/pachten
    RealEstateMisc = 35, // Sonstige Immobilien
    RealEstateCommon = 90, // Alle Immobilien
    New_Constructions_DealerProfile = 542, // Neubauprojekte
    Flat_Rent_DealerProfile = 531, // Wohnung mieten
    Flat_Sale_DealerProfile = 501, // Wohnung kaufen
    House_Rent_DealerProfile = 532, // Haus mieten
    House_Sale_DealerProfile = 502, // Haus kaufen
    Leisure_Rent_DealerProfile = 533, // Ferienimmobilie mieten
    Leisure_Sale_DealerProfile = 512, // Ferienimmobilie kaufen
    Commercial_Rent_DealerProfile = 516, // Gewerbeimmobilie mieten
    Commercial_Sale_DealerProfile = 515, // Gewerbeimmobilie kaufe
    Plots_DealerProfile = 514, // Grundstück kaufen/pachten
    RealEstateMisc_DealerProfile = 535, // Sonstige Immobilien
    RealEstateCommon_DealerProfile = 43, // Alle Immobilien
    BapAll = 301,
    BapWebStore = 302,
    BapGiveAway = 304,
}

export const realEstateSearchIds: SearchId[] = [
    SearchId.New_Constructions,
    SearchId.Flat_Rent,
    SearchId.Flat_Sale,
    SearchId.House_Rent,
    SearchId.House_Sale,
    SearchId.Leisure_Rent,
    SearchId.Leisure_Sale,
    SearchId.Commercial_Rent,
    SearchId.Commercial_Sale,
    SearchId.Plots,
    SearchId.RealEstateMisc,
    SearchId.RealEstateCommon,
]

export const realEstateDealerProfileSearchIds: SearchId[] = [
    SearchId.New_Constructions_DealerProfile,
    SearchId.Flat_Rent_DealerProfile,
    SearchId.Flat_Sale_DealerProfile,
    SearchId.House_Rent_DealerProfile,
    SearchId.House_Sale_DealerProfile,
    SearchId.Leisure_Rent_DealerProfile,
    SearchId.Leisure_Sale_DealerProfile,
    SearchId.Commercial_Rent_DealerProfile,
    SearchId.Commercial_Sale_DealerProfile,
    SearchId.Plots_DealerProfile,
    SearchId.RealEstateMisc_DealerProfile,
    SearchId.RealEstateCommon_DealerProfile,
]

export const searchIdCanonicalMap: Record<SearchId, staticRelativeCanonicals | undefined> = {
    [SearchId.Invalid]: undefined,
    [SearchId.Cars]: staticRelativeCanonicals.MotorCarResultList,
    [SearchId.Motorcycles]: staticRelativeCanonicals.MotorMcResultList,
    [SearchId.Trucks]: staticRelativeCanonicals.MotorTruckResultList,
    [SearchId.Caravans]: staticRelativeCanonicals.MotorCaravanResultList,
    [SearchId.DealerCars]: undefined,
    [SearchId.DealerMotorcycles]: undefined,
    [SearchId.DealerTrucks]: undefined,
    [SearchId.DealerCaravans]: undefined,
    [SearchId.New_Constructions]: staticRelativeCanonicals.EstateNcResultList,
    [SearchId.Flat_Rent]: staticRelativeCanonicals.EstateFlatRentResultList,
    [SearchId.Flat_Sale]: staticRelativeCanonicals.EstateFlatSaleResultList,
    [SearchId.House_Rent]: staticRelativeCanonicals.EstateHouseRentResultList,
    [SearchId.House_Sale]: staticRelativeCanonicals.EstateHouseSaleResultList,
    [SearchId.Leisure_Rent]: staticRelativeCanonicals.EstateLeisureRentResultList,
    [SearchId.Leisure_Sale]: staticRelativeCanonicals.EstateLeisureSaleResultList,
    [SearchId.Commercial_Rent]: staticRelativeCanonicals.EstateCommercialRentResultList,
    [SearchId.Commercial_Sale]: staticRelativeCanonicals.EstateCommercialSaleResultList,
    [SearchId.Plots]: staticRelativeCanonicals.EstatePlotsResultList,
    [SearchId.RealEstateMisc]: staticRelativeCanonicals.EstateMiscResultList,
    [SearchId.RealEstateCommon]: staticRelativeCanonicals.EstateAllResultList,
    [SearchId.New_Constructions_DealerProfile]: undefined,
    [SearchId.Flat_Rent_DealerProfile]: undefined,
    [SearchId.Flat_Sale_DealerProfile]: undefined,
    [SearchId.House_Rent_DealerProfile]: undefined,
    [SearchId.House_Sale_DealerProfile]: undefined,
    [SearchId.Leisure_Rent_DealerProfile]: undefined,
    [SearchId.Leisure_Sale_DealerProfile]: undefined,
    [SearchId.Commercial_Rent_DealerProfile]: undefined,
    [SearchId.Commercial_Sale_DealerProfile]: undefined,
    [SearchId.Plots_DealerProfile]: undefined,
    [SearchId.RealEstateMisc_DealerProfile]: undefined,
    [SearchId.RealEstateCommon_DealerProfile]: undefined,
    [SearchId.BapAll]: staticRelativeCanonicals.BapResultList,
    [SearchId.BapWebStore]: undefined,
    [SearchId.BapGiveAway]: staticRelativeCanonicals.BapResultList,
}
