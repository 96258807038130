import { PageParameters, TaggingPage } from './taggingTypes'
import { TmsData } from '../../types/taggingData'
import { useProfileData } from '../../components/GlobalStateProvider/GlobalStateProvider'
import { useEffect } from 'react'
import { callPageView } from './tagging'

/**
 * Appends user_uuid from the user profile data to the parameters and calls callPageView.
 * Do not use if you have api-provided TaggingData for a page. Rather use callPageView directly in that case.
 */
export const usePageViewForPageWithHardcodedTaggingData = (
    eventName?: TaggingPage,
    parameters?: { tmsData?: TmsData; pageParameters?: PageParameters },
) => {
    const [profileData] = useProfileData()
    // stringify parameters so we don't need useMemo at the call site of this hook to prevent pageviews on rerender
    const tmsDataString = parameters?.tmsData ? JSON.stringify(parameters.tmsData) : undefined
    const pageParametersString = parameters?.pageParameters ? JSON.stringify(parameters.pageParameters) : undefined
    useEffect(() => {
        if (profileData === 'unsure') {
            return
        }
        if (!eventName) {
            return
        }

        void callPageView(eventName, {
            tmsData: { ...(tmsDataString ? JSON.parse(tmsDataString) : undefined), user_uuid: profileData?.uuid },
            pageParameters: pageParametersString ? { ...JSON.parse(pageParametersString) } : undefined,
        })
    }, [profileData, eventName, tmsDataString, pageParametersString])
}
