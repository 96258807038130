import React, { FunctionComponent } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { PositionProps } from '@wh-components/system/position'
import { adIsActive, adIsGivenAwayOrRentedOrSold } from '@bbx/search-journey/common/lib/ad-active-helper'
import { AttributesItem, getAttributeValue } from '@wh/common/chapter/types/Attributes'
import ExpireSoonIcon from '@wh-components/icons/Expiresoon'
import PricealarmIcon from '@wh-components/icons/Pricealarm'
import FlameIcon from '@wh-components/icons/Flame'
import { AdvertStatus } from '@wh/common/chapter/types/advertStatus'
import { CdcAdvertStatus } from '@bbx/common/types/UserFeed'
import { TestProps } from '@wh-components/core/common'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { AdBadge } from '@wh/common/chapter/components/ReservedBadge'
import { Box } from '@wh-components/core/Box/Box'

interface AdBadgesProps extends SpaceProps, PositionProps, TestProps {
    advertStatus: AdvertStatus | CdcAdvertStatus | null
    attributes: AttributesItem[]
}

export const AdBadges: FunctionComponent<AdBadgesProps> = ({ advertStatus, attributes, ...props }) => {
    if (!advertStatus) {
        return null
    }

    const isActive = adIsActive(advertStatus) && !adIsGivenAwayOrRentedOrSold(advertStatus)
    const labelActiveUntil = getAttributeValue(attributes, 'ACTIVE_UNTIL')
    const oldPrice = getAttributeValue(attributes, 'OLD_PRICE_FOR_DISPLAY')
    const isOfferBadge = getAttributeValue(attributes, 'OFFER_BADGE_ENABLED')
    const isAdInMotion = getAttributeValue(attributes, 'UPSELLING_RESULT_LIST_AD_IN_MOTION')
    const isPolePosition = getAttributeValue(attributes, 'REFERER') === 'pp'

    return (
        <Box display="flex" gap="xxs" flexDirection="column" alignItems="flex-start" {...props}>
            {isPolePosition && (
                <AdBadge fontSize="s" color1="palette.primary.main">
                    Premium
                </AdBadge>
            )}
            {isAdInMotion && (
                <AdBadge fontSize="s" color1="palette.primary.main">
                    SUPER-Anzeige
                </AdBadge>
            )}
            {isActive && oldPrice && isOfferBadge && (
                <AdBadge fontSize="s" color1="palette.primary.main" Icon={FlameIcon}>
                    Aktion
                </AdBadge>
            )}
            {isActive && labelActiveUntil && (
                <AdBadge fontSize="s" color1="palette.primary.main" Icon={ExpireSoonIcon}>
                    {labelActiveUntil}
                </AdBadge>
            )}
            {isActive && oldPrice && !isOfferBadge && (
                <AdBadge fontSize="s" color1="palette.primary.main" Icon={PricealarmIcon}>
                    Neuer Preis
                </AdBadge>
            )}
            {(!adIsActive(advertStatus) || advertStatus.id === adStatusFlag.RESERVED) && (
                <AdBadge fontSize="s" color1="palette.elephant">
                    {advertStatus.description}
                </AdBadge>
            )}
        </Box>
    )
}
