import React, { Fragment, FunctionComponent } from 'react'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import type { ISbStoryData } from 'storyblok-js-client'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { CmsStoryContent, mapCmsContentToToc } from './mapCmsContentToToc'
import { css } from 'styled-components'
import { Divider } from '@wh-components/core/Divider/Divider'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'

export interface TableOfContentType {
    level: string
    text: string
    anchorId: string
    subList?: TableOfContentType[]
}

export const StoryblokTableOfContent: FunctionComponent<StoryblokDefaultProps> = ({ story }) => {
    const result: TableOfContentType[] = mapCmsContentToToc(story as ISbStoryData<CmsStoryContent> | undefined)

    return (
        <Fragment>
            <Divider marginVertical="l" />
            <Box
                as="ol"
                margin="0"
                padding="0"
                css={css`
                    list-style-type: none;
                    counter-reset: item;
                `}
            >
                {result.map((tocEntry) => {
                    return (
                        <Text key={tocEntry.anchorId} as="li" fontWeight="bold" lineHeight="1.5" marginBottom="m">
                            <ClientRoutingAnchorLink type="anchor" href={`#${tocEntry.anchorId}`} color="palette.verydarkgrey">
                                {tocEntry.text}
                            </ClientRoutingAnchorLink>
                            <Box
                                as="ol"
                                paddingLeft="m"
                                css={css`
                                    list-style-type: none;
                                    counter-reset: item;
                                `}
                            >
                                <RecursiveSublist content={tocEntry?.subList} />
                            </Box>
                        </Text>
                    )
                })}
            </Box>
            <Divider marginVertical="l" />
        </Fragment>
    )
}

const RecursiveSublist: FunctionComponent<{ content: TableOfContentType[] | undefined }> = ({ content }) => {
    return (
        <Box
            as="ol"
            paddingLeft="m"
            css={css`
                list-style-type: none;
                counter-reset: item;
            `}
        >
            {content?.map((tocEntryChild) => {
                return (
                    <Text key={tocEntryChild.anchorId} as="li" fontWeight="normal">
                        <ClientRoutingAnchorLink type="anchor" href={`#${tocEntryChild.anchorId}`} color="palette.verydarkgrey">
                            {tocEntryChild.text}
                        </ClientRoutingAnchorLink>
                        <RecursiveSublist content={tocEntryChild?.subList} />
                    </Text>
                )
            })}
        </Box>
    )
}
