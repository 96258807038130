import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import locale_de from 'dayjs/locale/de'

dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.locale(locale_de)

export const formatPrice = (price: string | number, showZeroAsToGiveAway = false): string => {
    const priceAsString = typeof price === 'number' ? price.toString().replace('.', ',') : price.replace(/[.](\d{1,2})$/, ',$1')

    if (isFormattedPrice(priceAsString) || /[a-z%]/i.test(priceAsString)) {
        return priceAsString
    }

    if (priceAsString.trim() === '') {
        return ''
    }

    if (showZeroAsToGiveAway && Number(formatNumber(priceAsString)) === 0) {
        return 'zu verschenken'
    }

    return `€ ${formatNumber(priceAsString)}`
}

export const isFormattedPrice = (value: string) => /^(ab )?€ \d{1,3}([.]\d{3})*([,](?!00)\d{2})?( \/m²)?$/.test(value)

export const formatNumber = (
    number: string | number | undefined,
    thousandSeparators = true,
    mode: '2-fraction-digits' | 'arbitrary-fraction-digits' = '2-fraction-digits',
): string => {
    if (typeof number === 'undefined') {
        return ''
    }

    const numberAsString =
        typeof number === 'number'
            ? number.toString().replace('.', ',')
            : number.replace(mode === '2-fraction-digits' ? /[.](\d{1,2})$/ : /[.](\d{1,})$/, ',$1')

    if (isFormattedNumber(numberAsString) || /[a-z]/i.test(numberAsString)) {
        return numberAsString
    }

    if (numberAsString.trim() === '') {
        return ''
    }

    const [integer, decimal] = numberAsString.split(',')
    const integerWithoutLeadingZeros = integer.replace(/^0+(?!$)/, '')
    const numberWithSeparator = thousandSeparators
        ? integerWithoutLeadingZeros.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        : integerWithoutLeadingZeros

    if (mode === '2-fraction-digits') {
        const decimalWithTwoDigits = decimal?.padEnd(2, '0').substr(0, 2)

        if (decimalWithTwoDigits && Number(decimalWithTwoDigits)) {
            return `${numberWithSeparator},${decimalWithTwoDigits}`
        }
    } else {
        const decimalWithoutTrailingZeros = decimal?.replace(/0+$/, '')

        if (decimalWithoutTrailingZeros && Number(decimalWithoutTrailingZeros)) {
            return `${numberWithSeparator},${decimalWithoutTrailingZeros}`
        }
    }

    return numberWithSeparator
}

export const isFormattedNumber = (value: string) => /^\d{1,3}(?:[.]\d{3})*(?:[,](?!00)\d{2})?$/.test(value)

export const formatIsoDate = (isoDate: string, format: string = 'DD.MM.YYYY'): string => {
    return dayjs(fixIsoDate(isoDate)).format(format)
}

export const unixTimeInMilliseconds = (isoDate: string) => {
    return dayjs(fixIsoDate(isoDate)).unix() * 1000
}

/** IE11 does not support timezones without colon in the input date string */
export const fixIsoDate = (isoDate: string) => {
    return isoDate.replace(/([+-]\d{2})(\d{2})$/g, '$1:$2')
}

export const HTML_TAG_REGEX = /(<([^>]+)>)/gi

const euroCurrencyFormatterFactory = (maximumFractionDigits: number) =>
    new Intl.NumberFormat('de-AT', { style: 'currency', currency: 'EUR', maximumFractionDigits })

export const euroCurrencyFormatter = euroCurrencyFormatterFactory(2)

export const euroCurrencyFormatterNoCents = euroCurrencyFormatterFactory(0)
