// this enum contains just canonicals of pages with static urls, and where the page is seo-relevant, so not dynamic pages where we get the canonical from the api (dynamic routes like result list and ad detail), and also not which are not SEO-relevant traffic-wise
export enum staticRelativeCanonicals {
    Home = '/iad',
    MotorHome = '/iad/gebrauchtwagen/',
    MotorCarHome = '/iad/gebrauchtwagen/auto/',
    MotorMcHome = '/iad/gebrauchtwagen/motorrad/',
    MotorCarDetailSearch = '/iad/gebrauchtwagen/auto/detailsuche',
    MotorMcDetailSearch = '/iad/gebrauchtwagen/motorrad/detailsuche',
    MotorTruckDetailSearch = '/iad/gebrauchtwagen/nutzfahrzeuge/detailsuche',
    MotorCaravanDetailSearch = '/iad/gebrauchtwagen/wohnwagen-wohnmobile/detailsuche',
    MotorCarResultList = '/iad/gebrauchtwagen/auto/gebrauchtwagenboerse',
    MotorMcResultList = '/iad/gebrauchtwagen/motorrad/motorradboerse',
    MotorTruckResultList = '/iad/gebrauchtwagen/nutzfahrzeuge/nutzfahrzeugboerse',
    MotorCaravanResultList = '/iad/gebrauchtwagen/wohnwagen-wohnmobile/wohnwagenboerse',
    EstateHome = '/iad/immobilien/',
    EstateNcResultList = '/iad/immobilien/neubauprojekte/angebote',
    EstateFlatRentResultList = '/iad/immobilien/mietwohnungen/mietwohnung-angebote',
    EstateFlatSaleResultList = '/iad/immobilien/eigentumswohnung/eigentumswohnung-angebote',
    EstateHouseRentResultList = '/iad/immobilien/haus-mieten/haus-angebote',
    EstateHouseSaleResultList = '/iad/immobilien/haus-kaufen/haus-angebote',
    EstateLeisureRentResultList = '/iad/immobilien/ferienimmobilien-mieten/ferienimmobilien-angebote',
    EstateLeisureSaleResultList = '/iad/immobilien/ferienimmobilien-kaufen/ferienimmobilien-angebote',
    EstateCommercialRentResultList = '/iad/immobilien/gewerbeimmobilien-mieten/gewerbeimmobilien-angebote',
    EstateCommercialSaleResultList = '/iad/immobilien/gewerbeimmobilien-kaufen/gewerbeimmobilien-angebote',
    EstatePlotsResultList = '/iad/immobilien/grundstuecke/grundstueck-angebote',
    EstateMiscResultList = '/iad/immobilien/sonstige/immobilien-angebote',
    EstateAllResultList = '/iad/immobilien/immobilien/angebote',
    EstateDealerProfileAllResultList = '/iad/immobilien/immobilienmakler/angebote',
    EstateHouseSaleDetailSearch = '/iad/immobilien/haus-kaufen/detailsuche',
    EstateFlatSaleDetailSearch = '/iad/immobilien/eigentumswohnung/detailsuche',
    EstateHouseRentDetailSearch = '/iad/immobilien/haus-mieten/detailsuche',
    EstateFlatRentDetailSearch = '/iad/immobilien/mietwohnungen/detailsuche',
    EstateCommercialSaleDetailSearch = '/iad/immobilien/gewerbeimmobilien-kaufen/detailsuche',
    EstateCommercialRentDetailSearch = '/iad/immobilien/gewerbeimmobilien-mieten/detailsuche',
    EstateLeisureSaleDetailSearch = '/iad/immobilien/ferienimmobilien-kaufen/detailsuche',
    EstateLeisureRentDetailSearch = '/iad/immobilien/ferienimmobilien-mieten/detailsuche',
    EstatePlotsDetailSearch = '/iad/immobilien/grundstuecke/detailsuche',
    EstateMiscDetailSearch = '/iad/immobilien/sonstige/detailsuche',
    EstateCommonDetailSearch = '/iad/immobilien/immobilien/detailsuche',
    EstateNcDetailSearch = '/iad/immobilien/neubauprojekte/detailsuche',
    EstateDealerResultList = '/iad/immobilien/immobilienmakler',
    BapDealerResultList = '/iad/kaufen-und-verkaufen/webshop-shop-suche/',
    MotorDealerResultList = '/iad/gebrauchtwagen/gebrauchtwagenhaendler',
    BapHome = '/iad/kaufen-und-verkaufen/',
    BapFree = '/iad/kaufen-und-verkaufen/zu-verschenken',
    BapFreeResultList = '/iad/kaufen-und-verkaufen/zu-verschenken/marktplatz',
    BapResultList = '/iad/kaufen-und-verkaufen/marktplatz',
    PriceFinderRental = '/iad/immobilien/preisvergleich/miete',
    JobsHome = '/jobs/',
    AdInsertionVerticalSelection = '/iad/anzeigenaufgabe',
    AdInsertionProductSelectionEstate = '/iad/immobilie-vermieten-verkaufen',
    AdInsertionProductSelectionMotorCar = '/iad/auto-verkaufen',
    AdInsertionConfirmation = '/iad/anzeigenaufgabe/fertig',
}
