import { JsonDecoder, ok, err } from 'ts.data.json'

export const safeCSSValueStringDecoder = new JsonDecoder.Decoder<string>((json: unknown) => {
    if (typeof json === 'string' && !/[;{}]|\/\*|\*\//.test(json)) {
        return ok<string>(json)
    } else {
        return err<string>("expected string without characters ';', '{', '}' or opening or closing comment tokens '/*' or '*/'")
    }
})

export const stringOrNumberDecoder = JsonDecoder.oneOf<string | number>([safeCSSValueStringDecoder, JsonDecoder.number], 'string or number')

export const positionFixedOrAbsoluteDecoder = JsonDecoder.oneOf(
    (['fixed', 'absolute'] as const).map((s) => JsonDecoder.isExactly(s)),
    'position fixed/absolute',
)
