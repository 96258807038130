import React, { Fragment, FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import classNames from 'classnames'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { useRouter } from 'next/router'

interface SearchAgentLinksProps {
    alertsCount: number
    isHighlighted: boolean
}

export const SearchAgentLinks: FunctionComponent<SearchAgentLinksProps> = ({ alertsCount, isHighlighted }) => {
    const router = useRouter()
    return (
        <Fragment>
            <SearchAgentLink
                testId="verticalMenuSubitem-mysearches-bbx"
                title="Suchagent"
                href="/iad/myprofile/mysearches"
                active={isHighlighted && !router.pathname.includes('meinjob')}
                alertsCount={alertsCount}
            />
            <SearchAgentLink
                testId="verticalMenuSubitem-mysearches-jobs"
                title="Job Suchagent"
                href="/jobs/meinjob/suchagenten"
                active={isHighlighted && router.pathname.includes('meinjob')}
            />
        </Fragment>
    )
}

interface SearchAgentLinkProps {
    title: string
    href: string
    testId: string
    alertsCount?: number
    active?: boolean
}

const SearchAgentLink: FunctionComponent<SearchAgentLinkProps> = ({ alertsCount, title, href, active = false, testId }) => (
    <ClientRoutingAnchorLink
        type="anchor"
        className={classNames({
            'wh-vertical-menu--subitem': true,
            'wh-vertical-menu--subitem-selected': active,
        })}
        testId={testId}
        href={href}
    >
        <div className="wh-vertical-menu--subitem-text">
            <Box className="wh-vertical-menu--subitem-text-title" marginRight="s">
                <Text fontSize="s">{title}</Text>
            </Box>
            {alertsCount! > 0 && (
                <Text className="wh-vertical-menu--subitem-text-count" fontSize="s" color="palette.elephant">
                    {alertsCount}
                </Text>
            )}
        </div>
    </ClientRoutingAnchorLink>
)
