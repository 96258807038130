import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

export const SkeletonLine = styled.div.attrs<{ variant?: 'dark' | 'bright' }>((props) => ({ ...props }))`
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;

        background: linear-gradient(
            -90deg,
            ${(p) => (p.variant === 'bright' ? p.theme.colors.palette.babyseal : p.theme.colors.palette.dove)} 0%,
            ${(p) => (p.variant === 'bright' ? p.theme.colors.palette.dove : p.theme.colors.palette.owl)} 50%,
            ${(p) => (p.variant === 'bright' ? p.theme.colors.palette.babyseal : p.theme.colors.palette.dove)} 100%
        );
        animation: pulse 1.2s ease-in-out infinite;
        @keyframes pulse {
            0% {
                transform: translate(0%, 0%);
            }
            50% {
                transform: translate(-50%, 0%);
            }
        }
    }
`

export const BrightSkeletonLine: FunctionComponent<{}> = () => <SkeletonLine variant="bright" />
export const DarkSkeletonLine: FunctionComponent<{}> = () => <SkeletonLine variant="dark" />
