import { StoryblokLink } from '../types/built-in/StoryblokLink'

export const storyblokResolveLink = (link?: StoryblokLink | null) => {
    if (!link) {
        return ''
    }

    switch (link.linktype) {
        case 'url':
        case 'asset':
            return link.url
        case 'story':
            if (link.cached_url === '') {
                return ''
            }
            return `/${link.cached_url}`
        case 'email':
            if (link.email === '') {
                return ''
            }
            return `mailto:${link.email}`
    }
}
