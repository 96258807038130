export class ApiError extends Error {
    errorResponse: ApiErrorResponse
    statusCode?: number
    headers?: Record<string, string | string[] | undefined>
    isAbort: boolean

    constructor(
        message: string,
        errorResponse: ApiErrorResponse = DEFAULT_ERROR_RESPONSE,
        statusCode?: number,
        headers?: Record<string, string | string[] | undefined>,
        isAbort: boolean = false,
    ) {
        super(message)
        this.errorResponse = errorResponse
        this.statusCode = statusCode
        this.headers = headers
        this.isAbort = isAbort
    }
}

export const wrapInApiErrorIfNecessary = (error: unknown) => {
    if (error instanceof ApiError) {
        return error
    } else {
        return new ApiError(`Unexpected error during sending: ${error}`)
    }
}

export const DEFAULT_ERROR_RESPONSE: ApiErrorResponse = {
    title: 'Der Server ist momentan nicht erreichbar',
    message: 'Bitte probiere es in wenigen Augenblicken erneut.',
}

export interface ApiErrorResponse {
    title?: string
    message?: string
    errors?: (ApiSubError | ApiValidationSubError)[]
}

export type ApiErrorResponseWithApiSubErrors = Omit<ApiErrorResponse, 'errors'> & { errors: ApiSubError[] }
export type ApiErrorResponseWithApiValidationSubErrors = Omit<ApiErrorResponse, 'errors'> & { errors: ApiValidationSubError[] }

export interface CustomSsoErrorResponse {
    errorMessage: string
}

export const isApiErrorResponse = (error?: unknown): error is ApiErrorResponse => {
    return (
        typeof error !== 'undefined' &&
        (typeof (error as ApiErrorResponse).title === 'string' || typeof (error as ApiErrorResponse).message === 'string')
    )
}

export const isSsoErrorResponse = (error?: unknown): error is CustomSsoErrorResponse => {
    return typeof error !== 'undefined' && typeof (error as CustomSsoErrorResponse).errorMessage === 'string'
}

export const isApiErrorResponseWithApiSubErrors = (error?: unknown): error is ApiErrorResponseWithApiSubErrors => {
    return (
        isApiErrorResponse(error) &&
        typeof error.errors !== 'undefined' &&
        error.errors.length > 0 &&
        error.errors.every(
            (e) =>
                typeof (e as ApiSubError).field === 'string' &&
                typeof (e as ApiSubError).message === 'string' &&
                (typeof (e as ApiSubError).attributeKey === 'string' ||
                    typeof (e as ApiSubError).attributeKey === 'undefined' ||
                    (e as ApiSubError).attributeKey === null),
        )
    )
}

export const isApiErrorResponseWithApiValidationSubErrors = (error?: unknown): error is ApiErrorResponseWithApiValidationSubErrors => {
    return (
        isApiErrorResponse(error) &&
        typeof error.errors !== 'undefined' &&
        error.errors.length > 0 &&
        error.errors.every(
            (e) =>
                typeof (e as ApiValidationSubError).code === 'string' &&
                typeof (e as ApiValidationSubError).message === 'string' &&
                typeof (e as ApiValidationSubError).type === 'string',
        )
    )
}

export interface ApiSubError {
    field: string
    message: string
    attributeKey?: string | null
}

export interface ApiValidationSubError {
    code: string
    message: string
    type: string
}

export const isAbortError = (error: unknown): error is ApiError => error instanceof ApiError && error.isAbort === true

export interface JobsApiValidationErrorResponse {
    errors: JobsApiValidationError[]
}

export interface JobsApiValidationError {
    fieldName: string
    message: string
}

export const isJobsApiValidationErrorResponse = (response?: unknown): response is JobsApiValidationErrorResponse => {
    return (
        typeof response !== 'undefined' &&
        typeof (response as JobsApiValidationErrorResponse).errors === 'object' &&
        (response as JobsApiValidationErrorResponse).errors.length > 0 &&
        (response as JobsApiValidationErrorResponse).errors.every((e) => 'fieldName' in e && 'message' in e)
    )
}

export const mapJobsValidationErrorsToApiErrorResponse = (jobsValidationErrors: JobsApiValidationErrorResponse): ApiErrorResponse => {
    const mappedValidationErrors = jobsValidationErrors.errors.map((validationError) => {
        return {
            field: validationError.fieldName,
            message: validationError.message,
        }
    })
    return { errors: mappedValidationErrors }
}
