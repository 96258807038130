import React, { FunctionComponent, useContext } from 'react'
import { css } from 'styled-components'
import { RenderSlotId } from '@wh/common/digital-advertising/config'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import {
    position,
    top,
    right,
    bottom,
    left,
    width,
    height,
} from '@wh/common/digital-advertising/components/SkyscraperLargeRenderSlot/skyscraperLargeRenderSlotStyleCalculation'
import { werbungBeforeSkyCss } from '../werbungBeforeCss'

export const SkyscraperLargeRenderSlot: FunctionComponent = () => {
    const advertisingState = useContext(AdvertisingStateContext)
    const apnSkyscraperStyle = advertisingState.pageModifications.apnSkyscraperStyle || { baseStyle: 'hpa' }
    const isClosedAdSlot = (advertisingState.pageModifications.closedAdSlots || []).includes(RenderSlotId.SKYSCRAPER_LARGE)
    const currentLeaderboardTop = advertisingState.currentLeaderboardTop

    return (
        <div
            id={RenderSlotId.SKYSCRAPER_LARGE}
            className="apn-renderslot"
            aria-hidden="true"
            css={css`
                position: ${position(apnSkyscraperStyle)};
                top: ${top(apnSkyscraperStyle, currentLeaderboardTop)};
                right: ${right(apnSkyscraperStyle)};
                bottom: ${bottom(apnSkyscraperStyle)};
                left: ${left(apnSkyscraperStyle)};
                width: ${width(apnSkyscraperStyle)};
                height: ${height(apnSkyscraperStyle)};
                /* it is important to turn on pointer-events here, otherwise the ad cannot be clicked - see layouts/index.tsx for more info */
                pointer-events: auto;

                display: none;
                ${(p) => p.theme.media.desktop} {
                    display: ${isClosedAdSlot ? 'none' : 'inherit'};
                }

                ${(p) => p.theme.media.print} {
                    display: none;
                }
                ${werbungBeforeSkyCss}
            `}
        />
    )
}
