import { storyblokEditable } from '@storyblok/js'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { Divider } from '@wh-components/core/Divider/Divider'
import type { theme } from '@wh-components/core/theme'
import React, { FunctionComponent } from 'react'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'

type Spacing = '' | '0' | keyof typeof theme.space

interface StoryblokDivider extends StoryblokComponentType<string> {
    marginTop: Spacing
    marginBottom: Spacing
    marginTopMobile: Spacing
    marginBottomMobile: Spacing
}

interface Props extends StoryblokDefaultProps<StoryblokDivider> {}

export const StoryblokDivider: FunctionComponent<Props> = ({ blok }) => {
    return (
        <Divider
            marginTop={{ phone: blok.marginTopMobile === '' ? blok.marginTop : blok.marginTopMobile, tablet: blok.marginTop }}
            marginBottom={{
                phone: blok.marginBottomMobile === '' ? blok.marginBottom : blok.marginBottomMobile,
                tablet: blok.marginBottom,
            }}
            {...storyblokEditable(blok as unknown as SbBlokData)}
        />
    )
}
