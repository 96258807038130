import React from 'react'
import { useRouter } from 'next/router'
import { inverseVerticalIdMap, Vertical, verticalLongNames, verticals, VerticalShortName } from '@wh/common/chapter/types/verticals'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { MenuLink } from './MenuLink'
import { MenuLinkDeckView } from './MenuLinkDeckView'
import VerticalMarketplaceIcon from '@wh-components/icons/VerticalMarketplace'
import VerticalEstateIcon from '@wh-components/icons/VerticalEstate'
import VerticalCarIcon from '@wh-components/icons/VerticalCar'
import VerticalJobsIcon from '@wh-components/icons/VerticalJobs'
import { useVerticalsInfo } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { MenuLinkType } from './menuLinkTypes'
import { staticRelativeCanonicals } from '../../hocs/staticRelativeCanonicals'

const MENU_LINKS: Record<VerticalShortName, MenuLinkType> = {
    BAP: {
        verticalId: 5,
        icon: <VerticalMarketplaceIcon size="xlarge" color="palette.primary.main" marginRight="s" />,
        href: staticRelativeCanonicals.BapHome,
        testId: 'verticalMarketplaceLink',
        text: verticalLongNames[verticals.BAP],
        clickTag: 'header_click_marketplace',
        clientSideRouting: true,
        personalizedProfilePath: 'shop',
    },
    ESTATE: {
        verticalId: 2,
        icon: <VerticalEstateIcon size="xlarge" color="palette.primary.main" marginRight="s" />,
        href: staticRelativeCanonicals.EstateHome,
        testId: 'verticalImmoLink',
        text: verticalLongNames[verticals.ESTATE],
        clickTag: 'header_click_realestate',
        clientSideRouting: true,
    },
    MOTOR: {
        verticalId: 3,
        icon: <VerticalCarIcon size="xlarge" color="palette.primary.main" marginRight="s" />,
        href: staticRelativeCanonicals.MotorHome,
        testId: 'verticalCarLink',
        text: verticalLongNames[verticals.MOTOR],
        clickTag: 'header_click_motor',
        clientSideRouting: true,
        personalizedProfilePath: 'haendler',
    },
    JOB: {
        verticalId: 1,
        icon: <VerticalJobsIcon size="xlarge" color="palette.primary.main" marginRight="s" />,
        href: staticRelativeCanonicals.JobsHome,
        testId: 'verticalJobLink',
        text: verticalLongNames[verticals.JOB],
        clickTag: 'header_click_jobs',
        clientSideRouting: true,
    },
}

type MenuLinksProps = {
    isDeckView?: boolean
    onClick: () => void
    isHeaderBoxTransparent?: boolean
}

type VerticalListType = Partial<Record<VerticalShortName, Vertical>>

const reduceVerticals = (verticalsList: Vertical[]) => {
    return verticalsList.reduce((acc: VerticalListType, curr) => {
        const verticalIdName = inverseVerticalIdMap[curr.id]
        if (verticalIdName) {
            acc[verticalIdName] = curr
        }
        return acc
    }, {})
}

const VERTICAL_ORDER = [verticals.BAP, verticals.ESTATE, verticals.MOTOR, verticals.JOB]

export const MenuLinks: React.FunctionComponent<MenuLinksProps> = ({ isDeckView, onClick, isHeaderBoxTransparent = false }) => {
    let verticalObject: Partial<Record<verticals, Vertical>> | undefined
    const [verticalsInfo] = useVerticalsInfo()

    if (verticalsInfo) {
        verticalObject = reduceVerticals(verticalsInfo)
    }

    const router = useRouter()

    return (
        <React.Fragment>
            {VERTICAL_ORDER.map((vertical) => {
                const verticalItem = verticalObject?.[vertical]
                const isActive = isMenuLinkActiveForCurrentAsPath(MENU_LINKS[vertical], router.asPath)

                return isDeckView ? (
                    <MenuLink
                        key={vertical}
                        active={isActive}
                        item={MENU_LINKS[vertical]}
                        {...(verticalItem ? { nrOfAdverts: formatNumber(verticalItem.nrOfAdverts) } : {})}
                        onClick={onClick}
                        isHeaderBoxTransparent={isHeaderBoxTransparent}
                    />
                ) : (
                    <MenuLinkDeckView
                        key={vertical}
                        active={isActive}
                        item={MENU_LINKS[vertical]}
                        {...(verticalItem ? { nrOfAdverts: formatNumber(verticalItem.nrOfAdverts) } : {})}
                        onClick={onClick}
                    />
                )
            })}
        </React.Fragment>
    )
}

const isMenuLinkActiveForCurrentAsPath = (menuLink: MenuLinkType, asPath: string): boolean => {
    // asPath might be /iad/gebrauchtwagen while the vertical start page includes a trailing slash /iad/gebrauchtwagen/
    return (
        `${asPath}/`.includes(menuLink.href) ||
        (!!menuLink.personalizedProfilePath && asPath.startsWith(`/iad/${menuLink.personalizedProfilePath}`))
    )
}
