import React, { FunctionComponent } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { useIsMounted } from '@wh/common/chapter/hooks/useIsMounted'
import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'

interface SkipToContentProps {
    targetId: string
    text: string
}

export const SkipToContent: FunctionComponent<SkipToContentProps> = ({ targetId, text, ...props }) =>
    useIsMounted()
        ? ReactDOM.createPortal(
              <li>
                  <LinkWithButtonStyle
                      size="small"
                      color="primary"
                      variant="outline"
                      position="absolute"
                      top={8}
                      left={172}
                      {...props}
                      href={`#${targetId}`}
                      css={css`
                          &:not(:focus) {
                              top: -999px;
                              left: -999px;
                          }
                      `}
                  >
                      {text}
                  </LinkWithButtonStyle>
              </li>,
              document.getElementById('skip-to-content-container') || document.body,
          )
        : null

export const SkipToContentContainer = styled.ul.attrs({ id: 'skip-to-content-container' })`
    list-style-type: none;
    padding: 0;
    z-index: 1000;
    position: fixed;
`
