import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { staticRelativeCanonicals } from '../hocs/staticRelativeCanonicals'

export interface VerticalList {
    vertical: Vertical[]
}

export interface Vertical {
    id: VerticalId
    description: string
    nrOfAdverts: number
    selfLink: string
    searchConfigLink: string
    taggingData: TaggingData
}

export type VerticalId = 2 | 3 | 5 | 1 | 0 | -1

export const convertToVerticalId = (verticalNumber: number): VerticalId => {
    if ([0, 1, 2, 3, 5].includes(verticalNumber)) {
        return verticalNumber as VerticalId
    }

    return -1
}

export enum verticals {
    BAP = 'BAP',
    MOTOR = 'MOTOR',
    ESTATE = 'ESTATE',
    JOB = 'JOB',
}

export enum verticalLongNames {
    BAP = 'Marktplatz',
    MOTOR = 'Auto & Motor',
    ESTATE = 'Immobilien',
    JOB = 'Jobs',
}

export type VerticalShortName = verticals.BAP | verticals.MOTOR | verticals.ESTATE | verticals.JOB

export const verticalIdMap: Record<VerticalShortName, VerticalId> = {
    BAP: 5,
    MOTOR: 3,
    ESTATE: 2,
    JOB: 1,
}

export const inverseVerticalIdMap: Record<number, VerticalShortName | undefined> = {
    5: verticals.BAP,
    3: verticals.MOTOR,
    2: verticals.ESTATE,
    1: verticals.JOB,
}

export const verticalTaggingNameMap: Record<number, string> = {
    5: 'Marktplatz',
    3: 'AutoMotor',
    2: 'RealEstate',
    1: 'Jobs',
}

export const verticalIdToHomePathMap: Record<number, string> = {
    5: staticRelativeCanonicals.BapHome,
    3: staticRelativeCanonicals.MotorHome,
    2: staticRelativeCanonicals.EstateHome,
    1: staticRelativeCanonicals.JobsHome,
}
