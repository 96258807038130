export const replaceUmlauts = (string: string | undefined) => {
    if (!string) {
        return string
    }
    string = string.replace(/\u00c4/g, 'Ae')
    string = string.replace(/\u00e4/g, 'ae')
    string = string.replace(/\u00d6/g, 'Oe')
    string = string.replace(/\u00f6/g, 'oe')
    string = string.replace(/\u00dc/g, 'Ue')
    string = string.replace(/\u00fc/g, 'ue')
    string = string.replace(/\u00df/g, 'ss')
    return string
}

export const sanitize = (value: string | undefined) => {
    return replaceUmlauts(value)?.replace(/\//g, '_')?.replace(/&/g, '_')?.replace(/ /g, '')
}
