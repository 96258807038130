import React from 'react'

const hireText = `
           _  _  _  _             _
__      __(_)| || || |__    __ _ | |__    ___  _ __
\\ \\ /\\ / /| || || || '_ \\  / _\` || '_ \\  / _ \\| '_  \\
 \\ V  V / | || || || | | || (_| || |_) ||  __/| | | |
  \\_/\\_/  |_||_||_||_| |_| \\__,_||_.__/  \\___||_| |_|


We want to change the world. Can you give us the source code?

Bist du an unserem Quellcode interessiert?
Hast du Verbesserungsvorschläge?
Du möchtest deine Skills gerne an unseren über 1.5 Milliarden Page Impressions pro Monat testen?
Du willst gerne etwas programmieren, das von unseren über 4.4 Millionen Usern verwendet wird?

Du möchtest unseren Tech Blog https://tech.willhaben.at/ mitgestalten?

Bewirb dich bei uns - einem mehrfach ausgezeichneten Great Place to Work.

https://jobsbei.willhaben.at/`

export class HireComment extends React.Component {
    commentNode?: Comment

    componentDidMount() {
        if (!this.commentNode) {
            // Create the comment
            this.commentNode = document.createComment(hireText)

            // Append comment to <body>
            document.body.appendChild(this.commentNode)
        }
    }

    render() {
        return null
    }
}
