import React, { FunctionComponent, PropsWithChildren } from 'react'
import { ISbStoryData } from '@storyblok/react/dist/types/types'
import { StoryblokComponentType } from '@storyblok/js/dist/types/types'

export interface StoryblokState<Content = StoryblokComponentType<string>> {
    story?: ISbStoryData<Content>
    links: ISbStoryData<Content>[]
}

export const StoryblokContext = React.createContext<StoryblokState>({
    story: undefined,
    links: [],
})

interface StoryblokProviderProps<Content = StoryblokComponentType<string>> extends PropsWithChildren {
    story?: ISbStoryData<Content>
    links?: ISbStoryData<Content>[]
}

export const StoryblokContextProvider: FunctionComponent<StoryblokProviderProps> = ({ story, links, children }) => {
    return <StoryblokContext.Provider value={{ story: story, links: links ?? [] }}>{children}</StoryblokContext.Provider>
}
