import { useEffect, useState } from 'react'

export const useAutoScroller = (
    autoScrollIntervalMs: number | undefined,
    currentScrollPos: 'left' | 'right' | 'between',
    onAutoScroll: () => void,
) => {
    const updateMs = 1000
    const [scrollTimeMs, setScrollTimeMs] = useState(0)
    const [previousScrollPos, setPreviousScrollPos] = useState(currentScrollPos)

    useEffect(() => {
        if (autoScrollIntervalMs === undefined) {
            return
        }

        const interval = setInterval(() => {
            setScrollTimeMs(scrollTimeMs + updateMs)

            if (scrollTimeMs >= autoScrollIntervalMs) {
                setScrollTimeMs(0)
                onAutoScroll?.()
            }

            const scrollPositionChanged = currentScrollPos !== previousScrollPos
            if (scrollPositionChanged) {
                setScrollTimeMs(0)
            }
            setPreviousScrollPos(currentScrollPos)
        }, updateMs)

        return () => clearInterval(interval)
    }, [autoScrollIntervalMs, currentScrollPos, previousScrollPos, scrollTimeMs, onAutoScroll])

    return scrollTimeMs
}
