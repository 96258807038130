import { useCallback, useEffect, useState } from 'react'
import { useCookieState } from '@wh/common/chapter/hooks/useCookieState'
import { UAParser } from 'ua-parser-js'
import { useTimeout } from '../../hooks/useTimeout'

const DAYS_IN_MS = 1000 * 60 * 60 * 24

const AndroidOnelinkBannerSnoozeValue = {
    true: 'true',
    false: 'false',
}

declare global {
    interface Navigator {
        standalone?: boolean
    }
}

const BANNER_APPEARANCE_DELAY = 10000

export const useAndroidOnelinkBanner = (initialAppearanceDelay = BANNER_APPEARANCE_DELAY) => {
    const [willhabenOnelink, setWillhabenOnelink] = useState<string | undefined>(undefined)

    const isAndroidBrowser = useIsAndroidBrowser()

    useEffect(() => {
        const metaContent = document.querySelector('meta[name="google-play-app"]')?.getAttribute('content')
        if (!metaContent) {
            return
        }
        const onelink = /willhaben-onelink=([^\s,]+)/.exec(metaContent)?.[1]
        if (onelink) {
            setWillhabenOnelink(onelink)
        }
    }, [])

    const [isSnoozeActivatedString, setIsSnoozeActivated] = useCookieState<string>(
        'snooze_android_onelink_banner',
        AndroidOnelinkBannerSnoozeValue.false,
    )

    const handleSnoozeAndroidOnelinkBanner = useCallback(() => {
        setIsSnoozeActivated(AndroidOnelinkBannerSnoozeValue.true, new Date(new Date().getTime() + DAYS_IN_MS * 30))
    }, [setIsSnoozeActivated])

    const openAndroidOnelink = useCallback(() => {
        if (willhabenOnelink) {
            window.open(willhabenOnelink, '_blank')
        }
    }, [willhabenOnelink])

    const [initialDelayPassed, setInitialDelayPassed] = useState(false)

    useTimeout(() => {
        setInitialDelayPassed(true)
    }, initialAppearanceDelay)

    const showAndroidOnelinkBanner =
        initialDelayPassed &&
        isAndroidBrowser &&
        typeof willhabenOnelink !== 'undefined' &&
        isSnoozeActivatedString !== AndroidOnelinkBannerSnoozeValue.true

    return { showAndroidOnelinkBanner, handleSnoozeAndroidOnelinkBanner, openAndroidOnelink, isAndroidBrowser }
}

export const useIsAndroidBrowser = () => {
    const [isNavigatorStandalone, setIsNavigatorStandalone] = useState(false)
    // we cannot use the useUserAgent hook because it uses the UserAgentProvider context which is not available for SSG pages
    const [parsedUserAgent, setParsedUserAgent] = useState<UAParser.IResult | undefined>(undefined)

    useEffect(() => {
        const uaParser = new UAParser()
        uaParser.setUA(navigator.userAgent)
        setParsedUserAgent(uaParser.getResult())

        // we check for navigator.standalone to avoid detecting pages that are embedded in the android app
        setIsNavigatorStandalone(navigator.standalone === true)
    }, [])

    return !isNavigatorStandalone && isAndroid(parsedUserAgent)
}

const isAndroid = (parsedUserAgent: UAParser.IResult | undefined) => {
    if (!parsedUserAgent) {
        return false
    }

    return parsedUserAgent.os.name?.toLowerCase() === 'android'
}
