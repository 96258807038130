import Script from 'next/script'
import React from 'react'

export const BrandMetrics = () => {
    return (
        <>
            <Script src="https://cdn.brandmetrics.com/tag/0d996cb5a7ae49e493b818831fcc801e/willhaben.js" strategy="lazyOnload" />
        </>
    )
}
