import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'
import { hasPaylivery as hasPayliveyAttribute } from '@bbx/common/lib/isPayliveryAd'
import { CdcAdvertStatus } from '@bbx/common/types/UserFeed'
import { AdBadges } from '@bbx/search-journey/common/components/AdvertSummary/AdBadges'
import { Box } from '@wh-components/core/Box/Box'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { Text } from '@wh-components/core/Text/Text'
import { ResponsiveValue } from '@wh-components/system'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { PlaceholderImage } from '@wh/common/chapter/components/PlaceholderImage'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { AttributesItem, getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { verticals, VerticalShortName } from '@wh/common/chapter/types/verticals'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { joinTeaserAttributes } from '@bbx/search-journey/common/lib/joinTeaserAttributes'
import { TeaserAttribute } from '@wh/common/chapter/components/TeaserAttributes'

export type AdvertSummaryVariant = 'landscape' | 'portrait'

interface AdvertSummaryProps {
    attributes: AttributesItem[]
    href: string | undefined
    onClickTaggingEvent?: () => void | Promise<void>
    footerText?: string
    teaserAttributes?: TeaserAttribute[] | null
    vertical: VerticalShortName
    adTypeId: number
    description: string
    advertImage: string | undefined
    advertStatus: CdcAdvertStatus | null
    imgHeight?: ResponsiveValue<number>
    className?: string
    adId: string
    testIdPrefix?: string
    isActive?: boolean
    color?: string
    inactiveColor?: string
    accentColor?: string
    variant?: AdvertSummaryVariant
}

export const AdvertSummaryComponent: FunctionComponent<AdvertSummaryProps> = ({
    attributes,
    href = '#',
    onClickTaggingEvent,
    footerText,
    teaserAttributes,
    vertical,
    adTypeId,
    description,
    advertImage,
    advertStatus,
    imgHeight = 131,
    adId,
    testIdPrefix = 'ad-detail-similar-ad-entry',
    isActive = true,
    color = 'palette.verydarkgrey',
    inactiveColor = 'palette.grey',
    accentColor,
    variant = 'landscape',
}) => {
    const price = getAttributeValue(attributes, 'PRICE_FOR_DISPLAY')
    const hasPaylivery = hasPayliveyAttribute(attributes)
    const oldPrice = advertStatus?.id === 'active' ? getAttributeValue(attributes, 'OLD_PRICE_FOR_DISPLAY') : undefined

    const calculatedImageHeight = variant === 'landscape' ? imgHeight : undefined
    const aspectRatio = variant === 'landscape' ? undefined : '4/5'

    return (
        <Box as="article">
            <ClientRoutingAnchorLink
                type="anchor"
                href={isActive ? href : undefined}
                testId={`${testIdPrefix}-${adId}`}
                aria-label={`Zur Anzeige ${description}`}
                onClick={onClickTaggingEvent}
                color={color}
                underline="none"
                css={css`
                    outline: 0;
                `}
            >
                <Box
                    as="section"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    marginBottom="s"
                    backgroundColor="palette.babyseal"
                    opacity={!isActive ? 0.3 : undefined}
                >
                    {advertImage ? (
                        <ResponsiveImage
                            aspectRatio={aspectRatio}
                            cssWidth="100%"
                            cssHeight={calculatedImageHeight}
                            alt={description}
                            src={advertImage}
                            testId={`${testIdPrefix}-image-${adId}`}
                            objectFit="cover"
                            loading="lazy"
                        />
                    ) : (
                        <PlaceholderImage
                            adType={inverseAdTypeIdMap[adTypeId]}
                            vertical={vertical}
                            css={css`
                                width: 100%;
                                aspect-ratio: ${aspectRatio};
                                height: ${calculatedImageHeight}px;
                            `}
                        />
                    )}
                    <AdBadges advertStatus={advertStatus} attributes={attributes} position="absolute" top="xs" left="xs" />
                </Box>
                <Box as="section" display="flex" flexDirection="column" color={!isActive ? inactiveColor : undefined}>
                    <Box>
                        <Box testId={`${testIdPrefix}-description-${adId}`}>
                            <Text lineClamp={vertical === verticals.BAP && teaserAttributes && teaserAttributes.length > 0 ? 1 : 2}>
                                {description}
                            </Text>
                        </Box>
                        <Box height="100%">
                            {teaserAttributes && teaserAttributes.length > 0 && (
                                <Text
                                    lineClamp={1}
                                    lineHeight={1.75} // teaser attributes should have the same height as the normal text for vertical alignment (14px * 1.5 == 12px * 1.75)
                                    testId={`${testIdPrefix}-teaser-attributes-${adId}`}
                                    fontSize="s"
                                    // stupid design differences between bap and the other verticals
                                    color={vertical === verticals.BAP ? accentColor ?? 'palette.elephant' : undefined}
                                    fontWeight={vertical === verticals.BAP ? undefined : 'bold'}
                                    paddingTop={vertical === verticals.BAP ? undefined : 'xs'}
                                >
                                    {joinTeaserAttributes(teaserAttributes)}
                                </Text>
                            )}
                        </Box>
                        {price && (
                            <Box display="flex" alignItems="baseline" flexWrap="wrap">
                                <Text
                                    truncate={true}
                                    as="p"
                                    fontWeight="bold"
                                    color={isActive ? accentColor ?? 'palette.primary.main' : undefined}
                                >
                                    {price}
                                </Text>
                                {oldPrice && (
                                    <Text
                                        truncate={true}
                                        fontSize="s"
                                        color="palette.elephant"
                                        marginLeft="s"
                                        css={css`
                                            text-decoration: line-through;
                                        `}
                                        testId={`${testIdPrefix}-old-price-${adId}`}
                                    >
                                        {oldPrice}
                                    </Text>
                                )}
                                <Box marginLeft="s">
                                    {hasPaylivery && <PayliveryBadge fontSize="xxs" id={adId} variant={isActive ? 'normal' : 'disabled'} />}
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Text fontSize="s" truncate={true} as="p" testId={`${testIdPrefix}-footer-${adId}`}>
                        {footerText}
                    </Text>
                </Box>
            </ClientRoutingAnchorLink>
        </Box>
    )
}
