import { CdcAd, PulseMetadata } from '@bbx/common/types/UserFeed'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useEffect, useRef } from 'react'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'

export type TaggingWidgetType =
    | 'LastSearchesWidget'
    | 'FolderWidget'
    | 'RecommendationWidget'
    | 'ItemRecommendationWidget'
    | 'SearchAgentWidget'
    | 'SellerWidget'
    | 'SellerRecommendationWidget'
    | 'JobsWidget'
    | 'NearbyWidget'
    | 'LastViewedAdsWidget'
    | `TrendSlider`
    | `TrendSlider_${string}`
    | `TeaserImage_${string}`
    | `SearchResultList_${string}`

interface UseWidgetTaggingParams {
    taggingWidgetType: TaggingWidgetType
    source?: string
    page?: string
    adIds?: (string | number)[]
    pulseMetadata?: PulseMetadata
    additionalInfoJobs?: Record<string, unknown>
    additionalInfoObject?: Record<string, unknown>
}

export const useWidgetTagging = ({
    taggingWidgetType,
    source = 'homepage',
    page = 'Startpage',
    adIds,
    pulseMetadata,
    additionalInfoJobs,
    additionalInfoObject,
}: UseWidgetTaggingParams) => {
    const visibilityTrackingRef = useRef<HTMLDivElement>(null)
    const [isInView] = useIsInView(visibilityTrackingRef, '-100px', undefined, true)
    const taggingData = useFeedEmptyTaggingData()

    useEffect(() => {
        callSelfPromotionEvent('ad_widget_loaded', taggingData, {
            taggingWidgetType,
            source,
            page,
            adIds,
            listName: pulseMetadata?.listName,
            transactionId: pulseMetadata?.transactionId,
            additionalInfoJobs: additionalInfoJobs,
            additionalInfoObject: additionalInfoObject,
        })
        // only trigger event once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isInView) {
            callSelfPromotionEvent('ad_widget_in_view', taggingData, {
                taggingWidgetType,
                source,
                page,
                adIds,
                listName: pulseMetadata?.listName,
                transactionId: pulseMetadata?.transactionId,
                additionalInfoJobs: additionalInfoJobs,
                additionalInfoObject: additionalInfoObject,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInView])

    return { visibilityTrackingRef }
}

export const getPulseAdId = (ad: CdcAd) => (ad.verticalId === verticalIdMap.JOB ? `job${ad.id}` : String(ad.id))
