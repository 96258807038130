import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

interface StoryblokCard extends StoryblokComponentType<string> {
    children: SbBlokData[]
}

interface Props extends StoryblokDefaultProps<StoryblokCard> {}

/**
 * Removes horizontal spacing on mobile by applying a negative margin.
 * This allows for the basic page layout to remain unchanged.
 */
export const StoryblokNegativeHorizontalMarginMobileBox: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <NegativeMarginBox {...storyblokEditable(blok as unknown as SbBlokData)}>
            {blok.children.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
            ))}
        </NegativeMarginBox>
    )
}

const NegativeMarginBox = styled(Box)`
    margin-left: -${(p) => p.theme.space.m}px;
    margin-right: -${(p) => p.theme.space.m}px;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}px) {
        margin-left: 0;
        margin-right: 0;
    }
`
