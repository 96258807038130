import { inverseAdTypeMap } from '../../chapter/types/AdType'

export const isRentalAd = (currentAdType: number) => {
    return [inverseAdTypeMap.EstateRent.valueOf(), inverseAdTypeMap.EstateLeisureRent.valueOf()].includes(currentAdType)
}

export const rentalRoutes = {
    creditCheckEntryPoint: '/iad/myprofile/edittenant/creditcheck',
    immobilienEntryPoint: '/iad/immobilien',
    rentalAdvertLessorOverview: (id: string) => `/iad/myprofile/lessor/${id}`,
    tenantOverview: '/iad/myprofile/edittenant/myexchanges',
    tenantProfile: '/iad/myprofile/edittenant',
    tenantProfilePreview: '/iad/myprofile/edittenant/preview',
} as const
