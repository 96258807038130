import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { TestProps } from '@wh-components/core/common'
import { Alert } from '@wh-components/core/Alert/Alert'
import WarningIcon from '@wh-components/icons/AlertWarning'
import { SpaceProps } from '@wh-components/system/space'

interface WarningAlertProps extends TestProps, SpaceProps, PropsWithChildren {
    title?: string
    message?: ReactNode
    dismissable?: boolean
    onDidDismiss?: () => void
}

export const WarningAlert: FunctionComponent<WarningAlertProps> = ({
    title,
    message,
    dismissable = false,
    onDidDismiss,
    testId,
    children,
    ...spaceProps
}) => (
    <Alert
        title={title}
        variant="warning"
        Icon={WarningIcon}
        testId={testId || 'alert-warning'}
        dismissable={dismissable}
        onDidDismiss={onDidDismiss}
        {...spaceProps}
    >
        {children || message}
    </Alert>
)
